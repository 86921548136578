import { useContext, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { CustomerContext } from "../../../controllers/contexts/customer.context";

const CustomerListOptionsComponent = () => {
    const { saveProductCategories } = useContext(CustomerContext);
    const [listOption, setListOption] = useState<string>('');

    const isFormValid = (): boolean => {
        let isValid = false;
        isValid = listOption !== ""
        return !isValid;
    }

    const handleClose = () => {
        setListOption('');
    }

    const handleSave = () => {
        saveProductCategories(listOption);
        handleClose();
    }

    return <>
        <button type="button" className="d-none" id={`openNewListOptionModal`} data-bs-toggle="modal" data-bs-target="#newListOption" />
        <div className="modal fade" id="newListOption" tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-25">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{LabelConstants.ProductCategory}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="form-group col-12">
                                <label className="form-label">{LabelConstants.ProductCategory}<span className="text-danger">*</span></label>
                                <input className="form-control"
                                    id="newListOption"
                                    type="text"
                                    placeholder={LabelConstants.ProductCategory}
                                    value={listOption}
                                    onChange={(e) => setListOption(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer modal-footer-uniform">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => handleClose()}>Close</button>
                        <button type="button" className="btn btn-primary float-end" data-bs-dismiss="modal" disabled={isFormValid()} onClick={() => handleSave()}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default CustomerListOptionsComponent;