import React from 'react';
import ReactDOM from 'react-dom/client';
import LayoutComponent from './views/layout/layout.component';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <LayoutComponent />
);
