import { useContext, useEffect, useRef, useState } from "react";
import {
  ICustomer,
  IGrnContext,
  IListConfig,
  IListConfigGRN,
  ILoaderConfig,
  IProducts,
  IResult,
  IStatusUpdate,
  ISupplier,
} from "../../common/models";
import {
  IGRNOrderItem,
  IOrders,
  IGRNProductLocation,
  IGRNProductWithBatchDetails,
  IGoodsReceipt,
  IGoodsReceiptListServerResponseObject,
  IGoodsReceiptServerRequestObject,
} from "../../common/models/grn";
import { LabelConstants } from "../../common/constants/labelConstants";
import { GRNService } from "../../api/services/grn.service";
import { ProductService } from "../../api/services/product.service";
import { cloneDeep } from "lodash";
import {
  EmptyIOrders,
  EmptyListConfig,
  EmptyListGRNSearchConfig,
  EmptySpinner,
  EmptyStatusUpdate,
  FORM_MODES,
  NewGRNProduct,
  NewGRNProductLocation,
  NewGRNServerCreateObject,
  NewGRNServerCreateProductLocation,
  NewGRNServerCreateRequestProduct,
  NewGRNServerEditGRDetails,
  NewGRNServerEditObject,
  NewGRNServerEditProductLocation,
  NewGoodsReceipt,
  NewOrderItemDetails,
} from "../../common/constants/generalConstants";
import { GenericHelperService } from "../../api/generics/helper.service";
import {
  getNumberForString,
  isNullUndefined,
} from "../../common/methods/globalmethods";
import { toast } from "react-toastify";
import { IProductCategory } from "../../common/models/master";
import { MasterService } from "../../api/services/master.service";
import { SupplierService } from "../../api/services/supplier.service";
import _isEqual from "lodash/isEqual";
import { IReturnOrders } from "../../common/models/returnorders";
import { CustomerService } from "../../api/services/customer.service";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import { SamplingService } from "../../api/services/sampling.service";
import _ from "lodash";

var tempMasterCustomerList: ICustomer[] = [];
const useGrn = () => {
  const masterService: MasterService = new MasterService();
  const grnService: GRNService = new GRNService();
  const supplierService: SupplierService = new SupplierService();
  const customerService: CustomerService = new CustomerService();
  const samplingService: SamplingService = new SamplingService();

  const [GRNForm, setGRNForm] = useState<IGoodsReceipt>(null);
  const [productsList, setProductsList] = useState<IProducts[]>([]);
  const [orderList, setOrderList] = useState<any>();
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1);
  const [selectedTransferQty, setSelectedTransferQty] = useState("");
  const [batchToPalletMapping, setBatchToPalletMapping] = useState<
    IGRNProductWithBatchDetails[]
  >([]);
  const [masterProductList, setMasterProductList] = useState<IProducts[]>([]);
  const productService: ProductService = new ProductService();
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [grnfilters, setGrnFilters] = useState<IListConfigGRN>(
    EmptyListGRNSearchConfig
  );
  const [grnOrdersList, setGrnOrdersList] = useState<
    IGoodsReceiptListServerResponseObject[]
  >([]);
  const [ordersList, setOrdersList] = useState<IOrders[]>([]);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );
  const [masterSupplierList, setMasterSupplierList] = useState<ISupplier[]>([]);
  const [masterAllSupplierList, setMasterAllSupplierList] = useState<
    ISupplier[]
  >([]);
  const [masterCustomerList, setMasterCustomerList] = useState<ICustomer[]>([]);
  const [loader, setLoader] = useState<ILoaderConfig>(EmptySpinner);
  const [grnLogs, setGrnLogs] = useState<ILogsData[]>([]);

  const getGrnLogs = async (id: number) => {
    const _logs: ILogsProps[] = await samplingService.getLogs(`GRN${id}`);
    setGrnLogs(_logs);
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (grnfilters?.search === true) {
      queryGRNData();
    } else if (grnfilters?.search === false) {
      if (
        !isNullUndefined(masterAllSupplierList) &&
        masterAllSupplierList?.length > 0
      )
        getGRNOrdersList(masterAllSupplierList);
    }
  }, [grnfilters?.search, filters.currentPage]);

  const initData = async () => {
    await getMasterProductsList();
    setSelectedList(EmptyStatusUpdate);
    getProductCategoriesList();
    await getCustomersList();
    await getSupplierList();
    await getSupplierAllList((suppliers: ISupplier[]) => {
      getGRNOrdersList(suppliers);
      queryGRNData(suppliers);
    });
  };

  const removeUnrequiredAttributes = () => {
    let tempfilter: IListConfigGRN = cloneDeep(grnfilters);
    if (!isNullUndefined(tempfilter)) {
      for (const [key, value] of Object.entries(tempfilter)) {
        if (typeof key === "string" && _.isEmpty(value)) {
          delete tempfilter[key];
        } else if (typeof key === "boolean") {
          delete tempfilter[key];
        } else if (typeof key === "number" && value <= 0) {
          delete tempfilter[key];
        }
      }
    }
    return tempfilter;
  };

  const queryGRNData = async (suppliers: ISupplier[] = null) => {
    let tempgrnfilter: IListConfigGRN = removeUnrequiredAttributes();
    if (Object.entries(tempgrnfilter)?.length > 0) {
      let result = await grnService.searchGRNOrders(
        tempgrnfilter,
        filters?.currentPage - 1
      );
      let _filteredList: IGoodsReceiptListServerResponseObject[] =
        result?.content ?? [];
      let index = 0;
      _filteredList?.map(async (item) => {
        let _supplier: ISupplier = getSupplierInfo(item?.supplierId, suppliers);
        if (_supplier) {
          index++;
          item.supplierName = _supplier?.supName;
          if (index === _filteredList?.length) {
            setGrnOrdersList(_filteredList);
            updateFiltersByData(_filteredList, result);
          }
        }
      });
      setGrnOrdersList(_filteredList);
      updateFiltersByData(_filteredList, result);
    }
  };

  const updateFiltersByData = (data: any, result: IResult) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.searchQuery = "";
    _filters.totalRecords = result?.totalElements ?? data.length;
    _filters.totalPageCount =
      result?.totalPages ?? Math.ceil(data.length / _filters.currentPageSize);
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const FormatGRNDataForEditOrders = async (
    _order: IGoodsReceiptListServerResponseObject,
    _orderproducts: IProducts[]
  ): Promise<void> => {
    if (isNullUndefined(_order)) return;
    let tempItem: IGoodsReceipt = cloneDeep(NewGoodsReceipt);
    tempItem.goodsRecId = _order?.goodsRecId;
    tempItem.goodsReceiptNumber = _order?.goodsReceiptNumber;
    tempItem.warehouseId = _order?.warehouseId;
    tempItem.goodsRecType = _order?.goodsRecType;

    tempItem.orderNum = _order?.orderNum;
    tempItem.receiptDate = _order?.receiptDate;
    tempItem.isSupValid = _order?.supValid;
    tempItem.sealNum = _order?.sealNum;

    tempItem.isSealed = _order?.sealed;
    tempItem.description = _order?.description;

    tempItem.created_by = _order?.createdBy;
    tempItem.status = _order?.status;
    tempItem.isDeleted = _order?.deleted;

    tempItem.createdDate = _order?.createdDate;
    tempItem.modifiedDate = _order?.modifiedDate;

    tempItem.modifiedBy = _order?.modifiedBy;
    tempItem.isSamplingRequired = true;

    let _opindex = 0;
    _orderproducts.map((orderproduct) => {
      let isproductadded = false;
      _order?.grDetails
        ?.filter(
          (item) => getNumberForString(item?.prodId) === orderproduct?.productId
        )
        ?.map((gritem, index) => {
          isproductadded = true;
          if (isNullUndefined(tempItem.grDetails[_opindex])) {
            tempItem.grDetails[_opindex] = cloneDeep(NewOrderItemDetails);
          }

          if (isNullUndefined(tempItem.grDetails[_opindex]?.product[index])) {
            tempItem.grDetails[_opindex].product[index] =
              cloneDeep(NewGRNProduct);
          }
          tempItem.grDetails[_opindex].productInfo = orderproduct;
          tempItem.isSamplingRequired = gritem?.samplingReq;
          tempItem.grDetails[_opindex].product[index].prodId = gritem?.prodId;
          tempItem.grDetails[_opindex].product[index].prodCatId =
            gritem?.prodCatId;
          tempItem.grDetails[_opindex].product[index].batchNo = gritem?.batchNo;
          tempItem.grDetails[_opindex].product[index].batchExpDate =
            gritem?.batchExpDate;
          tempItem.grDetails[_opindex].product[index].status = gritem?.status;
          tempItem.grDetails[_opindex].product[index].receivedQty =
            gritem?.receivedQty;
          tempItem.grDetails[_opindex].product[index].palletsCount =
            gritem?.palletsCount;
          tempItem.grDetails[_opindex].product[index].isSamplingReq =
            gritem?.samplingReq;
          tempItem.grDetails[_opindex].product[index].grDetId = gritem?.grDetId;
          tempItem.grDetails[_opindex].product[index].goodsRecId =
            gritem?.goodsRecId;
          tempItem.grDetails[_opindex].product[index].remainingQty =
            gritem?.receivedQty;
          tempItem.grDetails[_opindex].product[index].aisleName = "";
          tempItem.grDetails[_opindex].product[index].palletAssignmentType =
            "M";
          gritem?.prodLocs?.map((prodloc, index2) => {
            if (
              isNullUndefined(
                tempItem.grDetails[_opindex].product[index].prodLocs[index2]
              )
            ) {
              tempItem.grDetails[_opindex].product[index].prodLocs[index2] =
                cloneDeep(NewGRNProductLocation);
            }
            tempItem.grDetails[_opindex].product[index].prodLocs[
              index2
            ].prodLocId = prodloc?.prodLocId;
            tempItem.grDetails[_opindex].product[index].prodLocs[
              index2
            ].locationId = prodloc?.locationId;
            tempItem.grDetails[_opindex].product[index].prodLocs[
              index2
            ].shelfId = prodloc?.shelfId;
            tempItem.grDetails[_opindex].product[index].prodLocs[
              index2
            ].locQty = prodloc?.locQty;
            tempItem.grDetails[_opindex].product[index].prodLocs[
              index2
            ].palletRefNum = prodloc?.palletRefNum;
            tempItem.defaultSelectedShelfs.push(prodloc?.locationId);
          });
        });
      if (isproductadded) {
        ++_opindex;
      }
    });

    setGRNForm(tempItem);
  };

  async function setGrnOrderFormInFormat(
    _order: IGoodsReceiptListServerResponseObject
  ) {
    let result = await getProductsListForOrder(
      _order?.goodsRecType,
      _order?.orderNum,
      false,
      FORM_MODES.Edit
    );
    let productDetails: IProducts[] = [];
    if (result?.result) {
      productDetails = result?.productsList;
    }
    FormatGRNDataForEditOrders(_order, productDetails);
  }

  const showHideSpinner = (
    visibility: boolean,
    message: string = "Please wait..."
  ) => {
    let spinner = cloneDeep(loader);
    spinner.showSpinner = visibility;
    spinner.spinnerMessage = message;
    setLoader(spinner);
  };

  const getGRNOrdersList = async (suppliers: ISupplier[] = null) => {
    const result = await grnService.getGRNOrders(filters?.currentPage - 1);
    let _grnorders: IGoodsReceiptListServerResponseObject[] =
      result?.content ?? [];
    _grnorders = _grnorders?.filter((item) => item?.deleted !== true);

    updateFiltersByData(_grnorders, result);
    let index = 0;
    _grnorders?.map(async (item) => {
      let _supplier: any;
      if (item?.goodsRecType === "PO")
        _supplier = getSupplierInfo(item?.supplierId, suppliers);
      else if (item?.goodsRecType === "RO") {
        _supplier = getCustomerInfo(item?.supplierId);
      }
      if (_supplier) {
        index++;
        if (item?.goodsRecType === "PO") item.supplierName = _supplier?.supName;
        else if (item?.goodsRecType === "RO")
          item.supplierName = _supplier?.custName;

        if (index === _grnorders?.length) {
          updateGRNFiltersToLocal();
          setGrnOrdersList(_grnorders ?? []);
        }
      }
    });

    updateGRNFiltersToLocal();
    setGrnOrdersList(_grnorders ?? []);
  };

  const updateGRNFiltersToLocal = () => {
    let _grnfilter = cloneDeep(grnfilters);
    _grnfilter.searchLocally = true;
    setGrnFilters(_grnfilter);
  };

  const getMasterProductsList = async () => {
    const _products = await productService.getProducts();
    setMasterProductList(_products);
  };

  const createGRNOrder = async () => {
    let result = await grnService.createGRN(createRequestObject());
    if (result?.result) {
      getGRNOrdersList();
    }
    console.log(JSON.stringify(result));
    return result;
  };

  const createRequestObject = () => {
    let serverRequestObject: IGoodsReceiptServerRequestObject = cloneDeep(
      NewGRNServerCreateObject
    );
    serverRequestObject.goodsRecType = GRNForm?.goodsRecType;
    serverRequestObject.warehouseId = GRNForm.warehouseId;
    serverRequestObject.orderNum = GRNForm.orderNum;
    serverRequestObject.receiptDate = GRNForm.receiptDate;
    serverRequestObject.sealNum = GRNForm.sealNum;
    serverRequestObject.description = GRNForm.description;
    serverRequestObject.status = GRNForm.status;
    serverRequestObject.createdDate = GRNForm.createdDate;
    serverRequestObject.createdBy = GRNForm.created_by;
    serverRequestObject.modifiedDate = GRNForm.modifiedDate;
    serverRequestObject.modifiedBy = GRNForm.modifiedBy;
    serverRequestObject.goodsReceiptNumber = GRNForm.goodsReceiptNumber;
    serverRequestObject.sealed = GRNForm.isSealed;
    serverRequestObject.deleted = false;
    serverRequestObject.supValid = GRNForm.isSupValid;
    serverRequestObject.supplierId = GRNForm.supplierId;

    GRNForm?.grDetails.map((gritem) => {
      gritem?.product?.map((productitem) => {
        let batchdetails: IGRNProductWithBatchDetails = cloneDeep(
          NewGRNServerCreateRequestProduct
        );

        batchdetails.prodId = gritem?.productInfo?.productId + "";
        batchdetails.prodCatId = gritem?.productInfo?.prodCategory + "";
        batchdetails.batchNo = productitem?.batchNo;
        batchdetails.batchExpDate = productitem?.batchExpDate;
        batchdetails.status = "QRN";
        batchdetails.receivedQty = productitem?.receivedQty;
        batchdetails.palletsCount = productitem?.palletsCount;
        batchdetails.remainingQty = productitem?.remainingQty;
        batchdetails.samplingReq = GRNForm?.isSamplingRequired;
        productitem?.prodLocs?.map((location) => {
          let locationdata: IGRNProductLocation = cloneDeep(
            NewGRNServerCreateProductLocation
          );
          locationdata.locationId = location?.locationId;
          locationdata.locQty = location?.locQty;
          locationdata.palletRefNum = location?.palletRefNum;
          locationdata.shelfId = location?.shelfId;
          locationdata.batchNo = productitem?.batchNo;
          batchdetails.prodLocs.push(locationdata);
        });
        serverRequestObject.grDetails.push(batchdetails);
      });
    });

    return serverRequestObject;
  };

  const UpdateGRNOrder = async () => {
    let result = await grnService.editGRNOrder(
      GRNForm?.goodsRecId,
      formEditRequestObject()
    );
    if (result?.result) {
      getGRNOrdersList();
    }
    console.log(JSON.stringify(result));
    return result;
  };

  const deleteOrder = async (_order: IGoodsReceiptListServerResponseObject) => {
    _order.deleted = true;
    let result = await grnService.editGRNOrder(_order?.goodsRecId, _order);
    if (result?.result) {
      getGRNOrdersList();
    }
    console.log(JSON.stringify(result));
    return result;
  };

  const formEditRequestObject = () => {
    let serverRequestObject: IGoodsReceiptServerRequestObject = cloneDeep(
      NewGRNServerEditObject
    );

    serverRequestObject.goodsRecId = GRNForm?.goodsRecId;
    serverRequestObject.warehouseId = GRNForm?.warehouseId;
    serverRequestObject.goodsRecType = GRNForm?.goodsRecType;
    serverRequestObject.orderNum = GRNForm?.orderNum;
    serverRequestObject.receiptDate = GRNForm?.receiptDate;
    serverRequestObject.sealNum = GRNForm?.sealNum;
    serverRequestObject.description = GRNForm?.description;
    serverRequestObject.status = GRNForm?.status;
    serverRequestObject.createdDate = GRNForm?.createdDate;
    serverRequestObject.createdBy = GRNForm?.created_by;
    serverRequestObject.modifiedDate = GRNForm?.modifiedDate;
    serverRequestObject.modifiedBy = GRNForm?.modifiedBy;
    serverRequestObject.goodsReceiptNumber = GRNForm?.goodsReceiptNumber;
    serverRequestObject.sealed = GRNForm?.isSealed;
    serverRequestObject.deleted = GRNForm?.isDeleted;
    serverRequestObject.supValid = GRNForm?.isSupValid;
    serverRequestObject.supplierId = GRNForm.supplierId;

    GRNForm?.grDetails.map((gritem) => {
      gritem?.product?.map((productitem) => {
        let batchdetails: IGRNProductWithBatchDetails = cloneDeep(
          NewGRNServerEditGRDetails
        );

        batchdetails.grDetId = productitem?.grDetId;
        batchdetails.goodsRecId = productitem?.goodsRecId;
        batchdetails.prodId = productitem?.prodId;
        batchdetails.prodCatId = productitem?.prodCatId;
        batchdetails.batchNo = productitem?.batchNo;
        batchdetails.batchExpDate = productitem?.batchExpDate;
        batchdetails.status = productitem?.status;
        batchdetails.receivedQty = productitem?.receivedQty;
        batchdetails.palletsCount = productitem?.palletsCount;
        batchdetails.remainingQty = productitem?.remainingQty;
        batchdetails.samplingReq = GRNForm?.isSamplingRequired;

        productitem?.prodLocs?.map((location) => {
          let locationdetails: IGRNProductLocation = cloneDeep(
            NewGRNServerEditProductLocation
          );
          locationdetails.prodLocId = location?.prodLocId;
          locationdetails.grDetId = productitem?.grDetId;
          locationdetails.locationId = location?.locationId;
          locationdetails.locQty = location?.locQty;
          locationdetails.palletRefNum = location?.palletRefNum;
          locationdetails.shelfId = location?.shelfId;
          locationdetails.batchNo = productitem?.batchNo;
          batchdetails.prodLocs.push(locationdetails);
        });

        serverRequestObject.grDetails.push(batchdetails);
      });
    });

    return serverRequestObject;
  };

  const getProductsListForOrder = async (
    type: string,
    orderNum: string = null,
    selected: boolean = false,
    mode: string = FORM_MODES.Create
  ) => {
    let result;
    if (type === "PO") {
      result = await getPOProductsList(mode, orderNum, selected);
    } else if (type === "RO") {
      result = await getROProductList(orderNum, selected);
    } else if (type === "TO") {
      result = await getTOProductList(orderNum, selected);
    }
    setProductsList(result?.productsList);
    setOrderList(result?.orderdetails);
    return result;
  };

  const getPOProductsList = async (
    mode: string,
    orderNum: string,
    selected: boolean
  ) => {
    let result = false;
    let productsList: IProducts[] = [];
    let orderdetails;
    if (mode === FORM_MODES.Create) {
      orderdetails = await grnService.getGRNOrdersById(
        orderNum ?? GRNForm?.orderNum
      );
    } else if (mode === FORM_MODES.Edit || mode === FORM_MODES.View) {
      orderdetails = await grnService.getAllGRNOrdersById(
        orderNum ?? GRNForm?.orderNum
      );
    }

    if (orderdetails?.poDetails?.length > 0) {
      orderdetails.poDetails.map((poitem, index) => {
        for (let i = 0; i < masterProductList?.length; i++) {
          if (masterProductList.at(i)?.productId === poitem?.prodId) {
            let product: IProducts = masterProductList.at(i);
            product.selected = selected;
            product.quantity = poitem?.quantity;
            product.receivedQty = poitem?.receivedQty;
            productsList.push(product);
            break;
          }
        }
      });
      result = true;
    }
    return { result, productsList };
  };

  const getCustomersList = async () => {
    await customerService.getCustomerAllList().then((_customer) => {
      setMasterCustomerList(_customer ?? []);
      tempMasterCustomerList = _customer;
    });
  };

  const getSupplierList = async (callback: Function = null) => {
    await supplierService.getSupplier().then((_Supplier) => {
      setMasterSupplierList(_Supplier ?? []);
      if (callback !== null) {
        callback(_Supplier);
      }
    });
  };

  const getSupplierAllList = async (callback: Function) => {
    await supplierService.getSupplierAllList().then((_Supplier) => {
      setMasterAllSupplierList(_Supplier ?? []);
      if (callback !== null) {
        callback(_Supplier);
      }
    });
  };

  const getSupplierInfo = (
    supplierId: number,
    suppliers: ISupplier[] = null
  ): ISupplier => {
    let suppliersList = suppliers ?? masterAllSupplierList;
    let data: ISupplier = suppliersList?.find(
      (item) => item?.supId === supplierId
    );
    return data;
  };

  const getCustomerInfo = (customerId: number): ICustomer => {
    let data: ICustomer = (
      masterCustomerList?.length <= 0
        ? tempMasterCustomerList
        : masterCustomerList
    )?.find((item) => item?.custId === customerId);
    return data;
  };

  const getSupplierInfoByPONumber = async (
    poNumber: string,
    suppliers: ISupplier[] = null
  ) => {
    let orderdetails = await grnService.getAllGRNOrdersById(poNumber);
    return getSupplierInfo(orderdetails?.supplierId, suppliers);
  };

  const getSupplierInfoByRONumber = async (roNumber: string) => {
    let orderdetails: IReturnOrders = await grnService.getRODetailsById(
      roNumber
    );
    return orderdetails;
  };

  const getSupplierInfoByTONumber = async (roNumber: string) => {
    let orderdetails: IReturnOrders = await grnService.getTODetailsById(
      roNumber
    );
    return orderdetails;
  };

  const getROProductList = async (roNumber: string, selected: boolean) => {
    let result = false;
    let orderdetails: IReturnOrders = await grnService.getRODetailsById(
      roNumber
    );
    let productsList: IProducts[] = [];
    if (orderdetails?.returnOrderDetails?.length > 0) {
      orderdetails.returnOrderDetails?.map((poitem, index) => {
        for (let i = 0; i < masterProductList?.length; i++) {
          if (
            masterProductList.at(i)?.productId ===
            getNumberForString(poitem?.prodId)
          ) {
            let product: IProducts = masterProductList.at(i);
            product.selected = selected;
            product.quantity = poitem?.returnQty;
            product.receivedQty = 0;
            productsList.push(product);
            break;
          }
        }
      });
      result = true;
    }
    return { result, productsList, orderdetails };
  };

  const getTOProductList = async (toNumber: string, selected: boolean) => {
    let result = false;
    let orderdetails: any = await grnService.getTODetailsById(toNumber);
    let productsList: IProducts[] = [];
    if (orderdetails?.batchDetailsDTOS?.length > 0) {
      orderdetails.batchDetailsDTOS?.map((poitem, index) => {
        for (let i = 0; i < masterProductList?.length; i++) {
          if (
            masterProductList.at(i)?.productId ===
            getNumberForString(poitem?.prodId)
          ) {
            let product: IProducts = masterProductList.at(i);
            product.selected = selected;
            product.quantity = poitem?.returnQty;
            product.receivedQty = 0;
            productsList.push(product);
            break;
          }
        }
      });
      result = true;
    }
    return { result, productsList, orderdetails };
  };

  const pushSelectedProducts = (productId: number) => {
    let isRemoved = true;
    let product: IProducts;
    let _form: IGoodsReceipt = cloneDeep(GRNForm);
    let selectedProductsData = productsList.filter((item) => item.selected);
    selectedProductsData.map((item) => {
      if (item.productId === productId) {
        isRemoved = false;
        product = item;
      }
    });
    if (isRemoved) {
      _form.grDetails = _form.grDetails.filter((item) =>
        item.productInfo.productId === productId ? false : true
      );
    } else {
      let item: IGRNOrderItem = cloneDeep(NewOrderItemDetails);
      item.productInfo = product;
      if (_form === undefined || _form === null) {
        _form = NewGoodsReceipt;
        _form.grDetails[0] = item;
      } else _form.grDetails.push(item);
    }
    if (GRNForm.goodsRecType === "TO") {
      _form["warehouseId"] = orderList.toWarehouseId;
    }
    setGRNForm(_form);
  };

  const updateSelectionOfProducts = async (productId: number) => {
    let _products = productsList;
    _products?.map((item) => {
      if (item.productId === productId) {
        item.selected = !item.selected;
      }
    });
    setProductsList(_products ?? []);
  };

  const getNextReceiptNumberForType = async (type: string) => {
    return await grnService.getNextReceiptNumberForType(type);
  };

  const getOrderTypeStringValue = () => {
    let orderType = GRNForm?.goodsRecType;
    let type = LabelConstants.Number;
    let name = LabelConstants.Name;
    switch (orderType) {
      case "PO":
        type = LabelConstants.PO_NUMBER;
        name = LabelConstants.SupplierName;
        break;
      case "TO":
        type = LabelConstants.TO_Number;
        name = LabelConstants.SupplierName;
        break;
      case "RO":
        type = LabelConstants.RO_Number;
        name = LabelConstants.CustomerName;
        break;
    }
    return { type, name };
  };

  const getPOsList = async (mode: string = FORM_MODES.Create) => {
    let resultArray: IOrders[] = [];
    let result;
    if (mode === FORM_MODES.Create) {
      result = await grnService.getEligiblePOsList();
    } else if (mode === FORM_MODES.Edit || mode === FORM_MODES.View) {
      result = await grnService.getAllPOsList();
    }
    result?.map((item: string) => {
      let itemvalues: IOrders = cloneDeep(EmptyIOrders);
      itemvalues.value = item;
      itemvalues.text = item;
      itemvalues.label = item;
      resultArray.push(itemvalues);
    });
    setOrdersList(resultArray);
    return resultArray;
  };

  const getTOsList = async (mode: string = FORM_MODES.Create) => {
    let resultArray: IOrders[] = [];
    let result;
    if (mode === FORM_MODES.Create) {
      result = await grnService.getEligibleTOsList();
    } else if (mode === FORM_MODES.Edit || mode === FORM_MODES.View) {
      result = await grnService.getAllTOsList();
    }
    result?.map((item: string) => {
      let itemvalues: IOrders = cloneDeep(EmptyIOrders);
      itemvalues.value = item;
      itemvalues.text = item;
      itemvalues.label = item;
      resultArray.push(itemvalues);
    });
    setOrdersList(resultArray);
    return resultArray;
  };

  const getROsList = async (appendOrderNo: string = null) => {
    let resultArray: IOrders[] = [];
    let result;
    result = await grnService.getEligibleROsList();
    result?.map((item: string) => {
      resultArray.push(createROItem(item));
    });
    if (appendOrderNo !== null) {
      resultArray.push(createROItem(appendOrderNo));
    }
    setOrdersList(resultArray);
    return resultArray;
  };

  const createROItem = (item: string) => {
    let itemvalues: IOrders = cloneDeep(EmptyIOrders);
    itemvalues.value = item;
    itemvalues.text = item;
    itemvalues.label = item;
    return itemvalues;
  };

  //#region Warehouse CRUD
  const updateStatus = async () => {
    const _updateStatus: boolean = await grnService.updateStatus(selectedList);
    if (_updateStatus) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };

  const getAssignedLocationsArray = (_form: IGoodsReceipt) => {
    let assignedLocations = [];
    _form?.grDetails.map((gritem) => {
      gritem?.product?.map((product) => {
        product?.prodLocs.map((loc) => {
          assignedLocations.push(loc?.locationId);
        });
      });
    });
    return assignedLocations;
  };

  return {
    GRNForm,
    setGRNForm,
    getOrderTypeStringValue,
    productsList,
    orderList,
    updateSelectionOfProducts,
    selectedProductIndex,
    setSelectedProductIndex,
    selectedTransferQty,
    setSelectedTransferQty,
    batchToPalletMapping,
    setBatchToPalletMapping,
    getProductsListForOrder,
    createGRNOrder,
    grnOrdersList,
    setFilters,
    filters,
    setGrnOrderFormInFormat,
    pushSelectedProducts,
    UpdateGRNOrder,
    getNextReceiptNumberForType,
    ordersList,
    updateStatus,
    selectedList,
    setSelectedList,
    deleteOrder,
    getPOsList,
    getROsList,
    getTOsList,
    productCateList,
    masterProductList,
    getSupplierInfoByPONumber,
    grnfilters,
    setGrnFilters,
    setLoader,
    loader,
    setOrdersList,
    getAssignedLocationsArray,
    getSupplierInfoByRONumber,
    getSupplierInfoByTONumber,
    getGrnLogs,
    grnLogs,
    queryGRNData,
  } as IGrnContext;
};

export default useGrn;
