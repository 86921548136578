import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { PurchaseContext } from "../../controllers/contexts/purchase.context";
import { PurchaseStatuses } from "../../common/constants/generalConstants";
import { ProductService } from "../../api/services/product.service";
import { ProductContext } from "../../controllers/contexts/product.context";
import { listenerCount } from "process";
import { cloneDeep } from "lodash";
import { IProducts, IPurchase, WarehouseFormErrors } from "../../common/models";
import { AppContext } from "../../controllers/contexts/app.context";
import { IProductCategory } from "../../common/models/master";
import moment from "moment";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  getDateIfValid,
  getProductCatNameById,
} from "../../common/methods/globalmethods";
import Select from "react-select";
import dayjs from "dayjs";

interface FormErrors {
  productName?: string;
  productCode?: string;
  packSize?: string;
  prodLicenseNo?: string;
  prodManufacturer?: string;
  prodCategory?: string;
  dosageForm?: string;
  gtin?: string;
  minStockQty?: string;
  comments?: string;
  pipCode?: string;
  status?: string; // ACTIVE / Inactive /
  // Add other form field errors as needed
}
const PurchaseOrderFormcomponent = () => {
  const {
    purchaseForm,
    setPurchaseForm,
    savePurchaseOrder,
    productsList,
    productCateList,
    supplierList,
    createNewPurchaseOrder,
    getNextPoNumber,
    nextPo,
    clearPoNumber,
  } = useContext(PurchaseContext);
  const { ProductCategories } = useContext(AppContext);
  const [formData, setFormData] = useState<any>({
    supplierName: "",
    poNumber: nextPo ? nextPo : "",
    poCreatedDate: "",
    expectedDeliveryDate: "",
    items: [
      {
        productCategory: "",
        productCode: "",
        quantity: 0,
        price: 0,
        carriageAmount: 0,
        vat: 0,
      },
    ],
    poStatus: "Draft",
    totalAmount: 0,
    totalTaxAmount: 0,
    referenceNumber: "",
    createdDate: "",
    createdBy: "",
    modifiedDate: "",
    modifiedBy: "",
    // ... other fields
  });

  const navigate = useNavigate();
  const [searchProduct, setSearchProduct] = useState<any>("");
  const [filteredProducts, setFilteredProducts] =
    useState<IProducts[]>(productsList);
  const [selectedListOption, setSelectedListOption] = useState<string>("");
  const [suplierOptions, setSuplierOptions] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>({
    poNum: 0,
    supplierId: null,
    poCreatedDate: new Date(),
    expDelDate: new Date(),
    amount: 0,
    taxAmount: 0,
    refNumber: "",
    status: "DRAFT",
    poDetails: [],
    purchaseOrderNumber: "",
    deleted: false,
    carriageAmount: 0,
  });

  // const [saveChangesStatus, setSaveChangesStatus] = useState<SavedChangesOption>({ changesSaved: false });
  const [error, setError] = useState<FormErrors>({});
  useEffect(() => {
    const filteredList = productsList.filter((product) => {
      const searchItem = searchProduct.toLowerCase();
      return (
        product.productName.toLowerCase().includes(searchItem) ||
        product.productCode.toLowerCase().includes(searchItem)
      );
    });
    setFilteredProducts(filteredList);
  }, [searchProduct]);
  useEffect(() => {
    if (!nextPo) {
      getNextPoNumber();
    }
    return () => clearPoNumber();
  }, []);
  useEffect(() => {
    if (supplierList.length > 0) {
      let suplierOptions = [];
      supplierList.map((supp: any) => {
        suplierOptions.push({
          value: supp.supId,
          label: supp.supName,
        });
      });
      setSuplierOptions(suplierOptions);
    }
  }, []);
  const onClickSearch = (e) => {
    setSearchProduct(e.target.value);
  };
  //// Function to handle checkbox clicks
  const handleCheckboxClick = (product: IProducts) => {
    const updatedProductsList = { ...selectedProducts }; // Create a copy
    const productIndex = updatedProductsList.poDetails.findIndex(
      (p) => p.productCode === product.productCode
    );
    if (productIndex !== -1) {
      // Item already in the list
      updatedProductsList.poDetails.splice(productIndex, 1); // Remove from the list
    } else {
      // Item not in the list
      updatedProductsList.poDetails.push({ ...product, selected: true }); // Add to the list with isChecked flag
    }
    setSelectedProducts(updatedProductsList);
  };

  const handleCategoryChange = (event) => {
    setSelectedListOption(event.target.value);
  };
  const handleProductQtyChange = (e: any, product: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.poDetails.map((_prod, i) => {
      if (_prod.productId === product.productId) {
        _prod.quantity = e.target.value ? JSON.parse(e.target.value) : "";
        _prod.carriageAmount = _prod.price * e.target.value;
        if (_prod.vatPercentage) {
          _prod.vat = (_prod.carriageAmount / 100) * _prod.vatPercentage;
          _prod.totalAmount = _prod.carriageAmount + _prod.vat;
        }
      }
    });
    setSelectedProducts(_newPurchaseOrder);
  };
  const handleProductPriceChange = (e: any, product: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.poDetails.map((_prod, i) => {
      if (_prod.productId === product.productId) {
        _prod.price = e.target.value ? JSON.parse(e.target.value) : "";
        _prod.carriageAmount = _prod.quantity * e.target.value;
        if (_prod.vatPercentage) {
          _prod.vat = (_prod.carriageAmount / 100) * _prod.vatPercentage;
          _prod.totalAmount = _prod.carriageAmount + _prod.vat;
        }
      }
    });
    setSelectedProducts(_newPurchaseOrder);
  };
  const handleProductVatPrChange = (e: any, product: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.poDetails.map((_prod, i) => {
      if (_prod.productId === product.productId) {
        _prod.vatPercentage = e.target.value ? e.target.value : "";
        if(_prod.vatPercentage) {
          _prod.vat =
            (_prod.carriageAmount / 100) * parseFloat(_prod.vatPercentage);
          _prod.totalAmount = _prod.carriageAmount + _prod.vat;
        }
      }
    });
    setSelectedProducts(_newPurchaseOrder);
  };
  const deleteProductInPo = (product: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _.remove(_newPurchaseOrder.poDetails, function (n) {
      return n.productId === product.productId;
    });
    setSelectedProducts(_newPurchaseOrder);
  };
  const handleSuppName = (e: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.supplierId = e.value;
    setSelectedProducts(_newPurchaseOrder);
  };
  const handleStatus = (e: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.status = e.target.value;
    setSelectedProducts(_newPurchaseOrder);
  };
  const handlePoCreatedDate = (e: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.poCreatedDate = e;
    setSelectedProducts(_newPurchaseOrder);
  };
  const handleExpDelDate = (e: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.expDelDate = e;
    setSelectedProducts(_newPurchaseOrder);
  };
  const handleRefNo = (e: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.refNumber = e.target.value;
    setSelectedProducts(_newPurchaseOrder);
  };
  const handleCarrAmount = (e: any) => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.carriageAmount = e.target.value;
    setSelectedProducts(_newPurchaseOrder);
  };
  const calculateTotalTaxVal = () => {
    let _purchaseOrderProducts = [...selectedProducts.poDetails];
    let totalTax = 0;
    _purchaseOrderProducts.map((_prod, i) => {
      totalTax = _prod.vat ? totalTax + _prod.vat : totalTax;
    });
    return totalTax.toFixed(2);
  };
  const calculateTotalAmountVal = () => {
    let _purchaseOrderProducts = [...selectedProducts.poDetails];
    let totalAmt = 0;
    _purchaseOrderProducts.forEach((_prod) => {
      totalAmt += _prod.totalAmount || 0;
    });
    totalAmt += parseFloat(selectedProducts.carriageAmount) || 0;
    return totalAmt.toFixed(2);
  };

  const handleCreatePo = () => {
    let _newPurchaseOrder = { ...selectedProducts };
    _newPurchaseOrder.taxAmount = calculateTotalTaxVal();
    _newPurchaseOrder.amount = calculateTotalAmountVal();
    _newPurchaseOrder.purchaseOrderNumber = nextPo ? nextPo : "";
    let poProductsList = _newPurchaseOrder.poDetails;
    let newPoProductsList = [];
    poProductsList.map((pr) => {
      newPoProductsList.push({
        prodId: pr.productId,
        prodCatId: pr.prodCategory,
        quantity: pr.quantity,
        price: pr.price,
        vat: pr.vat,
        carriageAmount: pr.carriageAmount,
        totalAmount: pr.totalAmount,
        vatPercentage: pr.vatPercentage,
      });
    });
    _newPurchaseOrder.poDetails = newPoProductsList;
    let date = new Date().toISOString().split("T");
    if (!_newPurchaseOrder.poCreatedDate?.includes("T")) {
      _newPurchaseOrder.poCreatedDate =
        _newPurchaseOrder?.poCreatedDate + "T" + date[1];
    }
    if (!_newPurchaseOrder.expDelDate?.includes("T")) {
      _newPurchaseOrder.expDelDate =
        _newPurchaseOrder?.expDelDate + "T" + date[1];
    }
    if (_newPurchaseOrder.poDetails.length > 0) {
      if (
        _newPurchaseOrder.taxAmount > 0 &&
        _newPurchaseOrder.supplierId &&
        _newPurchaseOrder.refNumber
      ) {
        createNewPurchaseOrder(_newPurchaseOrder, () => {
          navigate(RoutingConstants.purchase);
        });
      } else {
        toast.error("Please fill mandatory fields");
      }
    } else {
      toast.error("Please add products to purchase order");
    }
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${customerForm?.custId ? LabelConstants.Edit : LabelConstants.Create} ${LabelConstants.Customer}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderIn}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.purchase}
                      title={LabelConstants.PurchaseOrder}
                    >
                      {LabelConstants.PurchaseOrder}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Create} ${LabelConstants.PurchaseOrder}`}</li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary float-end"
            onClick={() => handleCreatePo()}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Products </h4>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-right"
                >
                  Add Product
                </button>
              </div>
              <div className="box-body">
                {/* products Table */}
                <div className="table-responsive">
                  <table className="table product-overview table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Price/ unit</th>
                        {/* <th className="w-70">Carriage Amount</th> */}
                        <th className="text-align:center">VAT(%)</th>
                        <th className="text-align:center">Tax</th>
                        <th className="text-align:center">Amount</th>
                        <th className="text-align:center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProducts.poDetails.length > 0 ? (
                        selectedProducts.poDetails.map(
                          (product: any, i: number) => (
                            <tr key={i}>
                              <td className="text-start">
                                {getProductCatNameById(
                                  product.prodCategory,
                                  productCateList
                                )}
                              </td>
                              <td className="text-start">
                                {product.productCode} - {product.productName}
                              </td>
                              <td className="fw-900 w-150">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={product.quantity}
                                  onChange={(e) =>
                                    handleProductQtyChange(e, product)
                                  }
                                />
                              </td>
                              <td className="fw-900 w-150">
                                <input
                                  type="number"
                                  className="form-control"
                                  min={0}
                                  value={product.price}
                                  onChange={(e) =>
                                    handleProductPriceChange(e, product)
                                  }
                                />
                              </td>
                              {/* <td className="w-150">
                                {product.carriageAmount
                                  ? product.carriageAmount
                                  : 0}
                              </td> */}
                              <td className="w-90">
                                <input
                                  type="text"
                                  className="form-control"
                                  min={0}
                                  value={product.vatPercentage}
                                  onChange={(e) =>
                                    handleProductVatPrChange(e, product)
                                  }
                                />
                              </td>
                              <td>
                                {product.vat ? product.vat.toFixed(2) : 0}
                              </td>
                              <td>
                                {" "}
                                {product.totalAmount
                                  ? product.totalAmount.toFixed(2)
                                  : 0}{" "}
                              </td>
                              <td align="center">
                                <div className="d-flex justify-content-center">
                                  <span
                                    onClick={() => deleteProductInPo(product)}
                                  >
                                    <i
                                      className="fa fa-trash text-danger fs-3"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={9} align="center">
                            <br />
                            <p>No products added to purchase order</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => navigate(RoutingConstants.purchase)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    onClick={() => handleCreatePo()}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* purchase order container*/}
          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">Purchase Order Details</h4>
                <p className="p-0 m-0">
                  #{LabelConstants.PoNumber} : {nextPo ? nextPo : "N/A"}
                </p>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.SupplierName}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    {/* <select
                      className="form-select"
                      onChange={(e) => {
                        handleSuppName(e);
                      }}
                      value={selectedProducts.supplierId}
                    >
                      <option>--</option>
                      {supplierList.map((supp: any, i: number) => {
                        return (
                          <option key={supp.supId} value={supp.supId}>
                            {supp.supName}
                          </option>
                        );
                      })}
                    </select> */}
                    {suplierOptions.length > 0 && (
                      <Select
                        value={suplierOptions.filter(
                          (option) =>
                            option.value === selectedProducts.supplierId
                        )}
                        options={suplierOptions}
                        isSearchable={true}
                        onChange={(e) => handleSuppName(e)}
                      />
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.CreatedDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    {/* <input 
                  className="form-control" 
                  type="date" 
                  min={new Date().toJSON().slice(0, 10)} 
                  value={selectedProducts.poCreatedDate}
                  defaultValue={moment().format('MM/DD/yyyy')}
                  /> */}
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(selectedProducts.poCreatedDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handlePoCreatedDate(date);
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.ExpectedDeliveryDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(selectedProducts.expDelDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleExpDelDate(date);
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      value={selectedProducts.status}
                      onChange={(e) => {
                        handleStatus(e);
                      }}
                    >
                      {PurchaseStatuses.map(
                        (_status: string, _statusIdx: number) => {
                          return (
                            (_status === "DRAFT" ||
                              _status === "SUBMITTED") && (
                              <option
                                key={`statusOption${_statusIdx}`}
                                value={_status}
                              >
                                {_status}
                              </option>
                            )
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.referenceNumber}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      className="form-control"
                      value={selectedProducts.handleRefNo}
                      onChange={(e) => handleRefNo(e)}
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      Carriage Amount
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="number"
                      min={0}
                      className="form-control"
                      value={selectedProducts.carriageAmount}
                      onChange={(e) => handleCarrAmount(e)}
                    />
                  </div>
                </div>
                <div className=" d-flex mt-20">
                  <div className="col-4">
                    <h5 className="fw-bold">Tax</h5>
                  </div>
                  <div className="col-8">
                    <p className="text-end fw-700 h6">
                      £{" "}
                      {selectedProducts.poDetails.length > 0
                        ? calculateTotalTaxVal()
                        : selectedProducts.poDetails.taxAmount
                        ? selectedProducts.poDetails.taxAmount
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="d-flex mt-20">
                  <div className="col-4">
                    <h5 className="bt-1 fw-bold">Payable Amount</h5>
                  </div>
                  <div className="col-8">
                    <p className="bt-1 text-end fw-900 fs-18">
                      £{" "}
                      {selectedProducts.poDetails.length > 0
                        ? calculateTotalAmountVal()
                        : selectedProducts.poDetails.totalAmount
                        ? selectedProducts.poDetails.totalAmount
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products list modal */}
      <div className="modal modal-right fade overflow-auto" id="modal-right">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <input
                type="search"
                placeholder="Search Product"
                value={searchProduct}
                className="form-control"
                onChange={onClickSearch}
              />
              <br />
              <ul className="list-group">
                {filteredProducts?.length > 0 &&
                  filteredProducts.map((product: IProducts) => (
                    <li
                      className={`list-group-item ${
                        selectedProducts.poDetails.some(
                          (p) => p.productId === product.productId
                        ) && "d-none"
                      }`}
                      key={product.productId}
                    >
                      <input
                        type="checkbox"
                        id={product.productId.toString()}
                        checked={selectedProducts.poDetails.some(
                          (p) => p.productId === product.productId
                        )} // Set checked based on presence in selectedProducts
                        onChange={() => handleCheckboxClick(product)} // Set checked based on presence in selectedProducts
                      />
                      <label htmlFor={product.productId.toString()}>
                        <b>{product.productCode}</b> - {product.productName}
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="modal-footer modal-footer-uniform">
              {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button> */}
              <button
                type="button"
                className="btn btn-primary float-end"
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PurchaseOrderFormcomponent;
