import { ILogsData, ILogsProps } from "../../common/models/logs";
import { Modal } from "react-bootstrap";
import {
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import ReactPaginate from "react-paginate";
import PageSortDescription from "./pageSortDescription.component";
import { useEffect, useState } from "react";
import { IListConfig } from "../../common/models";
import { EmptyListConfig } from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";

const LogsModalComponent = (props: ILogsProps) => {
  const {
    onOpen,
    onClose,
    logs,
    productsList,
    productCateList,
    createdDate = null,
    createdBy = null,
  } = props;
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [filteredList, setFilteredList] = useState<ILogsData[]>([]);

  useEffect(() => {
    updateFilters();
  }, [logs?.content]);

  useEffect(() => {
    const indexOfLastItem = filters.currentPage * filters.currentPageSize;
    const indexOfFirstItem = indexOfLastItem - filters.currentPageSize;

    let _filteredList: ILogsData[];

    _filteredList = cloneDeep(logs?.content ?? []);
    setFilteredList(
      _filteredList.slice(indexOfFirstItem, indexOfLastItem) ?? []
    );
  }, [filters, logs?.content]);

  const updateFilters = () => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = logs?.content?.length;
    _filters.totalPageCount = Math.ceil(
      logs?.content?.length / _filters.currentPageSize
    );
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const getDisplayValue = (field: string, value: any) => {
    if (field === "prodId") {
      return getProductNameById(value, productsList);
    } else if (field === "prodCatId") {
      return getProductCatNameById(value, productCateList);
    }
    if (value === "null") {
      return "-";
    }
    return value;
  };
  const getFieldName = (field: string) => {
    if (field === "prodId") {
      return "product";
    } else if (field === "prodCatId") {
      return "productCategory";
    }
    return field;
  };
  return (
    <Modal show={onOpen} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Logs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table table-hover table-bordered mb-20">
            <thead className="table-primary">
              <tr>
                <th>Field Name</th>
                <th>Old Value</th>
                <th>New Value</th>
                <th>Updated By</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {filteredList?.map((log, index) => (
                <tr key={index}>
                  <td>{getFieldName(log.field)}</td>
                  <td>{getDisplayValue(log.field, log.oldValue)}</td>
                  <td>{getDisplayValue(log.field, log.newValue)}</td>
                  <td>{log.updatedBy}</td>
                  <td>{log.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="m-0" style={{ color: "grey" }}>
            *The record was created by <strong>{createdBy ?? "-"}</strong> at{" "}
            <strong>{createdDate ?? "-"}</strong> time.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="box">
          <div className="box-body p-3">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <PageSortDescription
                  filters={filters}
                  dataList={filteredList}
                />
              </div>
              <div className="col-lg-6 d-flex justify-content-end">
                {filters.totalPageCount > 1 && (
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="productorder_paginate"
                  >
                    <ReactPaginate
                      forcePage={filters?.currentPage - 1}
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={
                        filters && filters.totalPageCount < 2
                          ? 0
                          : filters.totalPageCount
                      }
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      pageClassName={`paginate_button page-item`}
                      pageLinkClassName="page-link"
                      activeLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      breakClassName={`paginate_button page-item`}
                      disabledClassName={`disabled`}
                      previousClassName={`paginate_button page-item previous`}
                      nextClassName={`paginate_button page-item next`}
                      onPageChange={(e) => {
                        setFilters({
                          ...filters,
                          currentPage: e.selected + 1,
                        });
                      }}
                      containerClassName={`pagination mb-1`}
                      activeClassName={`active`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LogsModalComponent;
