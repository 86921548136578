import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import moment from "moment";
import PageSortDescription from "../common/pageSortDescription.component";
import ReactPaginate from "react-paginate";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import { GenericHelperService } from "../../api/generics/helper.service";
import { IListConfigOP, IStatusUpdate } from "../../common/models";
import {
  EmptyStatusUpdate,
  OrderPickingUpdateStatuses,
} from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import OPStatusUpdateComponent from "./orderPickingStatusUpdate.component";
import useAuth from "../../controllers/hooks/useAuth";
import LogsModalComponent from "../common/logsModal.component";

const OrderPickingListComponent = () => {
  const helperService: GenericHelperService = new GenericHelperService();
  const {
    filters,
    setFilters,
    getOrderPickingList,
    deleteOrderPicking,
    setSelectedList,
    opfilters,
    setOPFilters,
    pickingOrderLogs,
    getPickingOrderLogs,
    productCateList,
    productsList,
    queryOPList,
    orderPickingList,
  } = useContext(OrderPickingContext);
  const navigate = useNavigate();
  const { isAccessDisabled } = useAuth();
  const [deleteOP, setDeleteOP] = useState<any>(null);
  const [tempStatus, setTempStatus] = useState("");
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const [showLogsModal, setShowLogsModal] = useState(false);
  const handleShowLogsModal = (op) => {
    getPickingOrderLogs(op.id);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };
  useEffect(() => {
    getOrderPickingList();
  }, []);

  const handleQueryChange = (type: string, value: any) => {
    const _opfilters: IListConfigOP = cloneDeep(opfilters);
    _opfilters[type] = value;
    setOPFilters(_opfilters);
  };

  const clearSearch = () => {
    const _opfilters: IListConfigOP = cloneDeep(opfilters);
    _opfilters.search = false;
    _opfilters.soNum = "";
    _opfilters.pickingNum = "";
    _opfilters.status = [];
    _opfilters.customer = 0;
    _opfilters.createdFromDate = "";
    _opfilters.createdToDate = "";
    setOPFilters(_opfilters);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      opfilters.soNum === "" &&
      opfilters.status?.length <= 0 &&
      (opfilters.customer === null || opfilters.customer === 0) &&
      opfilters.pickingNum === "" &&
      opfilters.createdFromDate === "" &&
      opfilters.createdToDate === ""
    ) {
      result = true;
    }
    return result;
  };

  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setFilters({ ...filters, searchQuery: _searchQuery });
  };
  const setUpdateStatus = (prod: any) => {
    setTempStatus(prod.status);
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.id];
    _status.status = prod.status;
    setSelectedList(_status);
  };
  const cancelDelete = () => {
    setDeleteOP(null);
  };
  const confirmDelete = () => {
    deleteOrderPicking(deleteOP);
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.Customer}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Order Out
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Order Picking
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="row">
                  <div className="col-lg-10 d-flex">
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="SO Num"
                        value={opfilters?.soNum}
                        onChange={(event) =>
                          handleQueryChange("soNum", event.target.value)
                        }
                      />
                    </div>
                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={opfilters?.status}
                        onChange={(e) =>
                          handleQueryChange("status", [e.target.value])
                        }
                      >
                        <option value={""}>Status</option>
                        {OrderPickingUpdateStatuses.map(
                          (_statusType: any, _statusTypeIdx: number) => {
                            return (
                              <option
                                key={`Status${_statusTypeIdx}`}
                                value={_statusType}
                              >
                                {_statusType}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="OP No."
                        value={opfilters?.pickingNum}
                        onChange={(event) =>
                          handleQueryChange("pickingNum", event.target.value)
                        }
                      />
                    </div>
                    {/* <div className="input-group w-p40 ms-10">
                      <input
                        type="number"
                        className="form-control bg-lighter border-0"
                        placeholder="Customer"
                        value={opfilters?.customer}
                        onChange={(event) =>
                          handleQueryChange("customer", event.target.value)
                        }
                      />
                    </div> */}
                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Create From"
                        value={opfilters?.createdFromDate}
                        onChange={(event) =>
                          handleQueryChange(
                            "createdFromDate",
                            event.target.value
                          )
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Created From
                      </i>
                    </div>
                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Created To"
                        value={opfilters?.createdToDate}
                        onChange={(event) =>
                          handleQueryChange("createdToDate", event.target.value)
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        Created To
                      </i>
                    </div>
                    <div className="input-group w-p10 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success rounded"
                        disabled={checkIfSearchStringsAreEmpty()}
                        onClick={() => {
                          setFilters({
                            ...filters,
                            currentPage: 1,
                          });
                          if (opfilters?.search) {
                            queryOPList();
                          }
                          handleQueryChange("search", true);
                        }}
                      >
                        {LabelConstants.Search}
                      </button>
                    </div>
                    <div className="input-group w-p50 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success-light rounded"
                        onClick={() => {
                          clearSearch();
                        }}
                      >
                        {LabelConstants.Clear}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-2 d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isAccessDisabled("OT", "create", true, "OP")}
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        // newPurchaseOrder();
                        navigate(RoutingConstants.createOrderPicking);
                      }}
                    >
                      {" "}
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Create New Order Picking
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-bordered mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>OP No.</th>
                        <th>OP Date</th>
                        <th>Customer Name</th>
                        <th>SO No.</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderPickingList?.length > 0
                        ? orderPickingList?.map((op, _orderIdx) => {
                            return (
                              <tr>
                                <td>
                                  {(filters?.currentPage - 1) *
                                    filters?.currentPageSize +
                                    _orderIdx +
                                    1}
                                </td>{" "}
                                <td>{op.pickingNum}</td>
                                <td>
                                  {moment(op.pickingDate).format("DD/MM/yyyy")}
                                </td>
                                <td>{op.customerName}</td>
                                <td>{op.soNum}</td>
                                <td>{op.pickingType}</td>
                                <td>
                                  <span
                                    style={
                                      isAccessDisabled(
                                        "OT",
                                        "update_status",
                                        true,
                                        "OP"
                                      )
                                        ? {
                                            pointerEvents: "none",
                                            opacity: 0.5,
                                          }
                                        : {}
                                    }
                                    role="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#updateStatus"
                                    onClick={() => setUpdateStatus(op)}
                                    className={`badge bg-${helperService.getStatusColor(
                                      op.status
                                    )}-light rounded-pill`}
                                  >
                                    {op.status}
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      disabled={isAccessDisabled(
                                        "OT",
                                        "view",
                                        true,
                                        "OP"
                                      )}
                                      className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                      onClick={() => {
                                        navigate(
                                          RoutingConstants.orderPickingDetails,
                                          {
                                            state: {
                                              isEditable: false,
                                              op: op,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </button>

                                    <button
                                      type="button"
                                      disabled={
                                        op?.status !== "DRAFT" ||
                                        isAccessDisabled(
                                          "OT",
                                          "edit",
                                          true,
                                          "OP"
                                        )
                                      }
                                      className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                      onClick={() => {
                                        navigate(
                                          RoutingConstants.orderPickingDetails,
                                          {
                                            state: {
                                              isEditable: true,
                                              op: op,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                    </button>

                                    <button
                                      type="button"
                                      // disabled={isAccessDisabled(
                                      //   "OT",
                                      //   "print",
                                      //   true,
                                      //   "OP"
                                      // )}
                                      className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                      onClick={() => {
                                        navigate(
                                          RoutingConstants.orderPickingPrint,
                                          {
                                            state: { selectedItem: op },
                                          }
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-print"
                                        aria-hidden="true"
                                      ></i>
                                    </button>

                                    <button
                                      type="button"
                                      disabled={
                                        op?.status !== "DRAFT" ||
                                        isAccessDisabled(
                                          "OT",
                                          "delete",
                                          true,
                                          "OP"
                                        )
                                      }
                                      className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                      onClick={() => {
                                        setDeleteOP(op);
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteConfirmation"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      type="button"
                                      className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                      onClick={() => {
                                        setcreatedDate(op?.createdDate);
                                        setcreatedBy(op?.createdBy);
                                        handleShowLogsModal(op);
                                      }}
                                    >
                                      <i
                                        className="fa fa-book"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filters}
                      dataList={orderPickingList}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {filters.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          forcePage={filters?.currentPage - 1}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            filters && filters.totalPageCount < 2
                              ? 0
                              : filters.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setFilters({
                              ...filters,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OPStatusUpdateComponent tempStatus={tempStatus} />
      <DeleteConfirmationComponent
        title={deleteOP?.pickingNum ? deleteOP?.pickingNum : ""}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={pickingOrderLogs}
        productsList={productsList}
        productCateList={productCateList}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default OrderPickingListComponent;
