import { Outlet } from "react-router-dom";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import useWarehouse from "../../controllers/hooks/useWarehouse";

const WarehouseLayoutComponent = () => {
    const warehouseFunctions = useWarehouse();

    return <WarehouseContext.Provider value={warehouseFunctions}>
        <Outlet />
    </WarehouseContext.Provider>;
}

export default WarehouseLayoutComponent;
