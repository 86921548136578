import { NavLink, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { useContext, useState } from "react";
import { SamplingContext } from "../../controllers/contexts/sampling.context";
import { GenericHelperService } from "../../api/generics/helper.service";
import {
  IListConfig,
  IListConfigSampling,
  ISampling,
  IStatusUpdate,
} from "../../common/models";
import {
  EmptyStatusUpdate,
  SamplingStatuses,
} from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import PageSortDescription from "../common/pageSortDescription.component";
import ReactPaginate from "react-paginate";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import SamplingStatusUpdateComponent from "./samplingStatusUpadate.component";
import moment from "moment";
import useAuth from "../../controllers/hooks/useAuth";
import LogsModalComponent from "../common/logsModal.component";
const SamplingComponent = () => {
  const helperService: GenericHelperService = new GenericHelperService();
  const {
    samplingList,
    filters,
    setFilters,
    samplingForm,
    selectedList,
    setSamplingForm,
    setSelectedList,
    deleteSampling,
    newSampling,
    samplingLogs,
    getLogsSampling,
    setSampFilters,
    sampfilters,
    querySampList,
  } = useContext(SamplingContext);
  const navigate = useNavigate();
  const { isAccessDisabled } = useAuth();

  console.log("Sample List - ", samplingList);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const handleShowLogsModal = (_sam: ISampling) => {
    getLogsSampling(_sam.samplingId);
    setShowLogsModal(true);
  };

  const handleQueryChange = (type: string, value: any) => {
    const _sampfilters: IListConfigSampling = cloneDeep(sampfilters);
    _sampfilters[type] = value;
    setSampFilters(_sampfilters);
  };

  const clearSearch = () => {
    const _sampfilters: IListConfigSampling = cloneDeep(sampfilters);
    _sampfilters.search = false;
    _sampfilters.status = [];
    _sampfilters.fromDate = "";
    _sampfilters.batchNo = "";
    _sampfilters.toDate = "";
    setSampFilters(_sampfilters);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      sampfilters.batchNo === "" &&
      sampfilters.status?.length <= 0 &&
      sampfilters.fromDate === "" &&
      sampfilters.toDate === ""
    ) {
      result = true;
    }
    return result;
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };
  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setFilters({ ...filters, searchQuery: _searchQuery });
  };
  const setUpdateStatus = (prod: ISampling) => {
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.samplingId];
    _status.status = prod.status;
    setSelectedList(_status);
  };
  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };
  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
      _status.ids = samplingList.map((_prd) => _prd.samplingId);
      setSelectedList(_status);
    } else {
      setSelectedList(cloneDeep(EmptyStatusUpdate));
    }
  };
  const handleSelectItem = (item: ISampling, event: any) => {
    const _status: IStatusUpdate = cloneDeep(selectedList);
    if (event.target.checked) {
      _status.ids.push(item.samplingId);
    } else {
      const _itmIdx = _status.ids.findIndex((_itm) => _itm === item.samplingId);
      if (_itmIdx > -1) {
        _status.ids.splice(_itmIdx, 1);
      }
    }
    setSelectedList(_status);
  };
  const cancelDelete = () => {
    setSamplingForm(null);
  };
  const confirmDelete = () => {
    deleteSampling();
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.Sampling}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="row">
                  <div className="col-lg-10 d-flex">
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="Batch Number"
                        value={sampfilters?.batchNo}
                        onChange={(event) =>
                          handleQueryChange("batchNo", event.target.value)
                        }
                      />
                    </div>
                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={sampfilters?.status}
                        onChange={(e) =>
                          handleQueryChange("status", [e.target.value])
                        }
                      >
                        <option value={""}>Status</option>
                        {SamplingStatuses.map(
                          (_statusType: any, _statusTypeIdx: number) => {
                            return (
                              <option
                                key={`Status${_statusTypeIdx}`}
                                value={_statusType}
                              >
                                {_statusType}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="From Date"
                        value={sampfilters?.fromDate}
                        onChange={(event) =>
                          handleQueryChange("fromDate", event.target.value)
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        From Date
                      </i>
                    </div>
                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="To Date"
                        value={sampfilters?.toDate}
                        onChange={(event) =>
                          handleQueryChange("toDate", event.target.value)
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        To Date
                      </i>
                    </div>
                    <div className="input-group w-p10 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success rounded"
                        disabled={checkIfSearchStringsAreEmpty()}
                        onClick={() => {
                          setFilters({
                            ...filters,
                            currentPage: 1,
                          });
                          if (sampfilters?.search) {
                            querySampList();
                          }
                          handleQueryChange("search", true);
                        }}
                      >
                        {LabelConstants.Search}
                      </button>
                    </div>
                    <div className="input-group w-p50 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success-light rounded"
                        onClick={() => {
                          clearSearch();
                        }}
                      >
                        {LabelConstants.Clear}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-2 d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isAccessDisabled("SAM", "create")}
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        setSamplingForm(cloneDeep(newSampling));
                        navigate(RoutingConstants.samplingCreate);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      {`${LabelConstants.Create} ${LabelConstants.Sampling}`}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-bordered mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <thead className="table-primary">
                      <tr>
                        {/* <th>
                        <input type="checkbox" id={`md_checkbox_all`} className="chk-col-primary" checked={samplingList?.length > 0 && selectedList?.ids.length === samplingList?.length} onChange={(event) => handleSelectAll(event)} />
                        <label htmlFor={`md_checkbox_all`}></label>
                      </th> */}
                        {/* <th onClick={() => handleSort('samplingId')}>{LabelConstants.ID}<i className={`ms-2 fa fa-sort${filters?.sortItem === 'samplingId' ? `-${filters?.sortDirection} text-primary` : ' text-fade'}`} /></th> */}
                        <th>S.No</th>
                        <th onClick={() => handleSort("batchNum")}>
                          {LabelConstants.BatchNum}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "batchNum"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("createdDate")}>
                          {LabelConstants.CreatedDate}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "createdDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("modifiedDate")}>
                          {LabelConstants.ModifiedDate}.
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "modifiedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        {/* <th onClick={() => handleSort('palletRefNum')}>{LabelConstants.PalletRefNum}<i className={`ms-2 fa fa-sort${filters?.sortItem === 'palletRefNum' ? `-${filters?.sortDirection} text-primary` : ' text-fade'}`} /></th> */}
                        <th>{LabelConstants.desc}</th>
                        <th onClick={() => handleSort("status")}>
                          {LabelConstants.Status}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "status"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {samplingList &&
                        samplingList.map((_sam, _samIdx) => {
                          return (
                            <tr key={_samIdx}>
                              <td>
                                {(filters?.currentPage - 1) *
                                  filters?.currentPageSize +
                                  _samIdx +
                                  1}
                              </td>{" "}
                              <td>{_sam.batchNum}</td>
                              <td>
                                {moment(_sam.createdDate).format("DD/MM/yyyy")}
                              </td>
                              <td>
                                {moment(_sam.modifiedDate).format("DD/MM/yyyy")}
                              </td>
                              {/* <td>{_sam.palletRefNum}</td> */}
                              <td>{_sam.desc}</td>
                              <td>
                                <span
                                  role="button"
                                  style={
                                    isAccessDisabled("SAM", "updateStatus")
                                      ? { pointerEvents: "none", opacity: 0.5 }
                                      : {}
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#updateStatus"
                                  onClick={() => setUpdateStatus(_sam)}
                                  className={`badge bg-${helperService.getStatusColor(
                                    _sam.status
                                  )}-light rounded-pill`}
                                >
                                  {_sam.status}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="button"
                                    disabled={isAccessDisabled("SAM", "view")}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    onClick={() => {
                                      setSamplingForm(cloneDeep(_sam));
                                      navigate(
                                        RoutingConstants.samplingDetails,
                                        { state: { isEditable: false } }
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    disabled={
                                      _sam?.status !== "OPEN" ||
                                      isAccessDisabled("SAM", "edit")
                                    }
                                    onClick={() => {
                                      setSamplingForm(cloneDeep(_sam));
                                      navigate(
                                        RoutingConstants.samplingDetails,
                                        { state: { isEditable: true } }
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    disabled={
                                      _sam?.status !== "OPEN" ||
                                      isAccessDisabled("SAM", "delete")
                                    }
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setSamplingForm(cloneDeep(_sam));
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmation"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setcreatedDate(_sam?.createdDate);
                                      setcreatedBy(_sam?.createdBy);
                                      handleShowLogsModal(_sam);
                                    }}
                                  >
                                    <i
                                      className="fa fa-book"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filters}
                      dataList={samplingList}
                    />
                  </div>
                  {
                    <div className="col-lg-6 d-flex justify-content-end">
                      {filters.totalPageCount > 1 && (
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="productorder_paginate"
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={
                              filters && filters.totalPageCount < 2
                                ? 0
                                : filters.totalPageCount
                            }
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            pageClassName={`paginate_button page-item`}
                            pageLinkClassName="page-link"
                            activeLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            breakClassName={`paginate_button page-item`}
                            disabledClassName={`disabled`}
                            previousClassName={`paginate_button page-item previous`}
                            nextClassName={`paginate_button page-item next`}
                            onPageChange={(e) => {
                              setFilters({
                                ...filters,
                                currentPage: e.selected + 1,
                              });
                            }}
                            containerClassName={`pagination mb-1`}
                            activeClassName={`active`}
                          />
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SamplingStatusUpdateComponent />
      <DeleteConfirmationComponent
        title={samplingForm?.desc ?? ""}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={samplingLogs}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};
export default SamplingComponent;
