import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { cloneDeep } from "lodash";
import {
  getDateIfValid,
  getProductCatNameById,
  isNullUndefined,
} from "../../common/methods/globalmethods";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import moment from "moment";
import { DatePicker } from "antd";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { toast } from "react-toastify";
import { IDeleteConfirmationProps, IProducts } from "../../common/models";
import { NewDeleteConfirmationDialog } from "../../common/constants/generalConstants";
import { Modal } from "bootstrap";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import dayjs from "dayjs";
var myModal;
const orderTransferData = {
  transOrderNum: null,
  transferDate: new Date().toISOString(),
  fromWarehouseId: 0,
  toWarehouseId: 0,
  status: "DRAFT",
  reason: "",
  isDeleted: false,
  createdDate: new Date().toISOString(),
  createdBy: "",
  modifiedDate: new Date().toISOString(),
  modifiedBy: "",
  transferOrderDetails: [],
};
const WarehouseTransferCreate = () => {
  const {
    productsList,
    productCateList,
    getProductLocations,
    productLocations,
    clearLocations,
    createNewOrderPicking,
    createNewTransferOrderWarehouse,
    nextOP,
    clearOpNumber,
    getNextToNumber,
  } = useContext(OrderPickingContext);
  const { masterWarehouseList, getWarehouseMap, warehouseList } =
    useContext(WarehouseContext);
  const [newOP, setNewOP] = useState<any>(orderTransferData);
  const [dialoginfo, setDialoginfo] = useState<IDeleteConfirmationProps>();
  const [activePrs, setActivePrs] = useState<any>([]);
  const [pickedLocations, setPickedLocations] = useState<any>([]);
  const [pickedProducts, setPickedProducts] = useState<any>([]);
  const [tempProds, setTempProds] = useState<any>([]);
  const [transferQtys, setTransferQtys] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (!nextOP) {
      getNextToNumber();
    }
    return () => clearOpNumber();
  }, []);
  useEffect(() => {
    const modalElement = document.getElementById("deleteConfirmation");
    if (modalElement) {
      myModal = new Modal(modalElement, {});
    }
  }, []);

  const handleWerehouseChange = (e: any) => {
    let _newOP = { ...newOP };
    _newOP.fromWarehouseId = e.target.value;
    setNewOP(_newOP);
  };
  const handleDestinationWerehouseChange = (warehouseId: any) => {
    let _newOP = { ...newOP };
    _newOP.toWarehouseId = warehouseId;
    setNewOP(_newOP);
  };
  const handleOPCreatedDate = (e: any) => {
    let _newOP = { ...newOP };
    _newOP.transferDate = e;
    setNewOP(_newOP);
  };
  const handleOPStatus = (status: any) => {
    let _newOP = { ...newOP };
    _newOP.status = status;
    setNewOP(_newOP);
  };
  const handleTransferQtyChange = (e, loc) => {
    const newTransferQty = parseInt(e.target.value);
    setTransferQtys((prevState) => ({
      ...prevState,
      [loc.locationId]: newTransferQty,
    }));
  };
  const handleDescriptionChange = (e: any) => {
    let _newOP = { ...newOP };
    _newOP.description = e.target.value;
    setNewOP(_newOP);
  };
  const handlePrLocations = (prId: any, Qty: any) => {
    let _activePrs = [...activePrs];
    let prIndex = _activePrs.indexOf(prId);
    if (prIndex > -1) {
      _activePrs.splice(prIndex, 1);
    } else {
      _activePrs.push(prId);
      if (!tempProds.includes(prId)) {
        getProductLocations("MANUAL", prId, Qty, newOP.fromWarehouseId);
      }
    }
    setActivePrs(_activePrs);
    let _tempPr = [...tempProds];
    _tempPr.push(prId);
    setTempProds(_tempPr);
  };
  const handleLocCheckbox = (loc: any, e: any) => {
    let _pickedLocs = [...pickedLocations];
    if (e.target.checked) {
      _pickedLocs.push(loc);
    } else {
      const _locIdx = _pickedLocs.findIndex(
        (_itm) => _itm.prodId === loc.prodId
      );
      if (_locIdx > -1) {
        _pickedLocs.splice(_locIdx, 1);
      }
    }
    setPickedLocations(_pickedLocs);
  };
  const handleCheckboxClick = (product: IProducts) => {
    const index = pickedProducts.findIndex(
      (p) => p.productId === product.productId
    );
    if (index !== -1) {
      setPickedProducts((prevState) => {
        const updatedLocations = [...prevState];
        updatedLocations.splice(index, 1);
        return updatedLocations;
      });
    } else {
      setPickedProducts((prevState) => [...prevState, product]);
    }
  };

  const showDialog = (
    onCancel: Function,
    onConfirm: Function,
    header: string = "HEllo",
    title_prefix: string = "New",
    title: string = null
  ) => {
    let dialog: IDeleteConfirmationProps = cloneDeep(
      NewDeleteConfirmationDialog
    );
    dialog.cancel = onCancel;
    dialog.confirm = onConfirm;
    dialog.title = title;
    dialog.header = header;
    dialog.title_prefix = title_prefix;
    setDialoginfo(dialog);
    myModal.show();
  };

  const handleSaveOrderPicking = async () => {
    if (newOP.fromWarehouseId && newOP.description) {
      if (pickedLocations.length > 0) {
        newOP.transOrderNum = nextOP;
        newOP.transferDate = newOP.transferDate;
        newOP.status = newOP.status;
        newOP.reason = newOP.description;
        newOP.transferOrderDetails = [];
        pickedLocations.forEach((location) => {
          const transferOrderDetail = {
            batchNo: location.batchNo,
            batchExpiryDate: location.batchExpiryDate,
            prodId: location.prodId,
            prodCatId: location.prodCatId,
            locId: location.locationId,
            shelfId: location.shelfId,
            quantity: transferQtys[location.locationId],
            palletRefNum: location.palletRefNum,
          };

          newOP.transferOrderDetails.push(transferOrderDetail);
        });
        createNewTransferOrderWarehouse(newOP);
      }
    } else {
      toast.error("Please select mandatory fields");
    }
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.warehouseTransfer}
                    >
                      Stock Transfer
                    </NavLink>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create W2W Transfer Order
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-success float-end"
            onClick={() => {}}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Products </h4>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-right"
                  disabled={
                    newOP.fromWarehouseId === undefined ||
                    newOP.fromWarehouseId === 0 ||
                    newOP.toWarehouseId === undefined ||
                    newOP.toWarehouseId === 0
                  }
                >
                  Add Product
                </button>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pickedProducts.length > 0 ? (
                        pickedProducts.map((_prod: any, j: number) => {
                          return (
                            <tr key={j}>
                              <td>{j + 1}</td>
                              <td className="text-start">
                                {getProductCatNameById(
                                  _prod.prodCategory,
                                  productCateList
                                )}
                              </td>
                              <td className="text-start">
                                {_prod.productName}
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-circle btn-danger btn-xs"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#modalTop-right-${j}`}
                                >
                                  <i className="ti-arrow-right"></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} align="center">
                            <br />
                            <p>No products added to Transfer</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => navigate(RoutingConstants.warehouseTransfer)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success float-end"
                    onClick={() => {
                      handleSaveOrderPicking();
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">Transfer Order Details</h4>
                <p className="p-0 m-0">
                  #{LabelConstants.ToNumber} : {nextOP}
                </p>
              </div>
              <div className="box-body">
                <div className="row mt-20 align-items-center">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.TrnasferDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(newOP.transferDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleOPCreatedDate(date);
                      }}
                    />
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Source WareHouse
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      onChange={(e) => handleWerehouseChange(e)}
                      value={newOP.fromWarehouseId}
                      disabled={newOP.toWarehouseId}
                    >
                      <option value={""}>Select Warehouse Name</option>
                      {masterWarehouseList.length > 0 &&
                        masterWarehouseList.map((wereHouse: any, i: number) => {
                          return (
                            <option key={i} value={wereHouse.wareHouseId}>
                              {wereHouse.warehouseName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Destination WareHouse
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        const _warehouse = warehouseList.find(
                          (_) => _.wareHouseId === Number(e.target.value)
                        );
                        showDialog(
                          () => {},
                          () => {
                            let warehouseid = "";
                            if (!isNullUndefined(_warehouse?.wareHouseId)) {
                              warehouseid = _warehouse?.wareHouseId + "";
                              getWarehouseMap(_warehouse);
                            }
                            handleDestinationWerehouseChange(warehouseid);
                          },
                          "Confirm warehouse selection",
                          "Warehouse once selected cannot be changed -",
                          `\n ${_warehouse?.warehouseName}`
                        );
                      }}
                      value={newOP.toWarehouseId}
                      disabled={!newOP.fromWarehouseId || newOP.toWarehouseId}
                    >
                      <option value={""}>Select Warehouse Name</option>
                      {newOP.fromWarehouseId &&
                        masterWarehouseList.length > 0 &&
                        masterWarehouseList
                          .filter(
                            (warehouse: any) =>
                              warehouse.wareHouseId !==
                              parseInt(newOP.fromWarehouseId)
                          )
                          .map((warehouse: any, i: number) => {
                            return (
                              <option key={i} value={warehouse.wareHouseId}>
                                {warehouse.warehouseName}
                              </option>
                            );
                          })}
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Status
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        if (e.target.value === "DISPATCHED") {
                          showDialog(
                            () => {},
                            () => {
                              handleOPStatus("DISPATCHED");
                            },
                            "Confirm Status selection",
                            `Status once selected cannot be changed -`,
                            ``
                          );
                        } else {
                          handleOPStatus(e.target.value);
                        }
                      }}
                      value={newOP.status}
                    >
                      <option>--</option>
                      <option value={"DRAFT"}>DRAFT</option>
                      <option value={"DISPATCHED"}>DISPATCHED</option>
                    </select>
                  </div>
                </div>

                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      Description
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      value={newOP.description}
                      onChange={(e) => handleDescriptionChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products list modal */}
      <div className="modal modal-right fade overflow-auto" id="modal-right">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <input
                type="search"
                placeholder="Search Product"
                className="form-control"
              />
              <br />
              <ul className="list-group">
                {productsList?.length > 0 &&
                  productsList?.map((product: IProducts) => {
                    if (product?.selected) return;
                    return (
                      <li
                        className={`list-group-item "d-none"
                        }`}
                        key={product.productId}
                      >
                        <input
                          type="checkbox"
                          id={product?.productId?.toString()}
                          onChange={() => handleCheckboxClick(product)} // Set checked based on presence in selectedProducts
                        />
                        <label htmlFor={product?.productId?.toString()}>
                          <b>{product?.productCode}</b> - {product?.productName}
                        </label>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-primary float-end"
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* product details */}

      {pickedProducts.length > 0 &&
        pickedProducts.map((pr: any, i: number) => {
          return (
            <div
              className="modal modal-center fade overflow-auto"
              id={`modalTop-right-${i}`}
            >
              <div
                className="modal-dialog modal-lg"
                style={{ width: "1000px", maxWidth: "100%" }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Products List</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        clearLocations();
                        setActivePrs([]);
                        setTempProds([]);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body" style={{ overflowY: "auto" }}>
                    <table className="table table-bordered text-center">
                      <thead className="table-primary">
                        <tr>
                          <th>S.No</th>
                          <th>Product Category</th>
                          <th>Product Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {getProductCatNameById(
                                pr.prodCategory,
                                productCateList
                              )}
                            </td>
                            <td>{pr.productName}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handlePrLocations(pr.productId, 0)
                                }
                              >
                                Locations
                              </button>
                            </td>
                          </tr>
                          {activePrs.includes(pr.productId) && (
                            <tr style={{ background: "#f8f8f8" }}>
                              <td colSpan={7}>
                                <table className="table table-bordered m-0">
                                  <thead className="table-secondary">
                                    <tr>
                                      <th></th>
                                      <td>Location ID</td>
                                      {/* <td>GRN No</td> */}
                                      <td>Location</td>
                                      <td>Batch No</td>
                                      <td>Pallet Ref No</td>
                                      <td>Available Qty</td>
                                      <td>Transfer Qty</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {productLocations.length > 0 ? (
                                      productLocations.map(
                                        (loc: any, j: number) => {
                                          if (loc.prodId === pr.productId) {
                                            return (
                                              <tr>
                                                <td>
                                                  <input
                                                    type="checkbox"
                                                    id={`md_checkbox_all${j}`}
                                                    className="chk-col-primary"
                                                    checked={
                                                      pickedLocations.findIndex(
                                                        (_itm: any) =>
                                                          _itm.locationId ===
                                                          loc.locationId
                                                      ) >= 0
                                                    }
                                                    onChange={(e) => {
                                                      handleLocCheckbox(loc, e);
                                                    }}
                                                  />
                                                  <label
                                                    htmlFor={`md_checkbox_all${j}`}
                                                    style={{
                                                      paddingLeft: "20px",
                                                      height: "15px",
                                                    }}
                                                  ></label>
                                                </td>
                                                <td>{loc.locationId}</td>
                                                {/* <td>{loc.grnNum}</td> */}
                                                <td>{loc.shelfId}</td>
                                                <td>{loc.batchNo}</td>
                                                <td>{loc.palletRefNum}</td>
                                                <td>{loc.quantity}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    className="form-control text-start"
                                                    min={0}
                                                    max={loc.quantity}
                                                    onChange={(e) => {
                                                      handleTransferQtyChange(
                                                        e,
                                                        loc
                                                      );
                                                    }}
                                                    value={
                                                      transferQtys[
                                                        loc.locationId
                                                      ]
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={6}>
                                          Product not Available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer modal-footer-uniform">
                    {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button> */}
                    <button
                      type="button"
                      className="btn btn-primary float-end"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        clearLocations();
                        setActivePrs([]);
                        setTempProds([]);
                      }}
                      disabled={
                        pickedLocations.length === 0 ||
                        pickedLocations.some(
                          (location) =>
                            !transferQtys[location.locationId] ||
                            transferQtys[location.locationId] <= 0 ||
                            transferQtys[location.locationId] >
                              location.quantity
                        )
                      }
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <DeleteConfirmationComponent
        title={dialoginfo?.title}
        cancel={dialoginfo?.cancel}
        confirm={dialoginfo?.confirm}
        header={dialoginfo?.header}
        title_prefix={dialoginfo?.title_prefix}
      />
    </>
  );
};

export default WarehouseTransferCreate;
