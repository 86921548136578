import {
  CustomerList,
  CustomerSave,
  CustomerStatusUpdate,
  CustomerUpdateById,
  CustomersAllList,
  searchCustomersByCriteria,
} from "../../common/constants/apiConstants";
import { IListConfigCustomer, IStatusUpdate } from "../../common/models";
import { ICustomer } from "../../common/models/customer";
import { AxiosService } from "../generics/axios.service";
import { ICustomerService } from "../interfaces/ICustomer";

export class CustomerService implements ICustomerService {
  private axiosService: AxiosService = new AxiosService();

  async getCustomer(): Promise<ICustomer[]> {
    try {
      const result: any = await this.axiosService.Get(CustomerList);
      const _customer: ICustomer[] = result.data ?? [];
      console.log("Response: Customer List - ", _customer);
      return _customer;
    } catch (error) {
      console.error("Error: Customer List - ", error);
      return [];
    }
  }

  async getCustomerAllList(): Promise<ICustomer[]> {
    try {
      const result: any = await this.axiosService.Get(CustomersAllList);
      const _customer: ICustomer[] = result.data?.content ?? [];
      console.log("Response: getCustomerAllList List - ", _customer);
      return _customer;
    } catch (error) {
      console.error("Error: getCustomerAllList List - ", error);
      return [];
    }
  }

  async searchCustomers(filter: IListConfigCustomer): Promise<ICustomer[]> {
    try {
      const result: any = await this.axiosService.Post(
        searchCustomersByCriteria,
        filter
      );
      const _products: ICustomer[] = result?.data?.content ?? [];
      console.log("Response: searchCustomers List - ", _products);
      return _products;
    } catch (error) {
      console.error("Error: searchCustomers List - ", error);
      return [];
    }
  }

  async saveCustomer(customer: ICustomer): Promise<string | number> {
    try {
      if (!customer?.custId)
        return await this.axiosService.Post(CustomerSave, customer);
      else {
        const _result = await this.axiosService.Put(
          CustomerUpdateById(customer?.custId),
          customer
        );
        return _result.data.custId;
      }
    } catch (error) {
      console.error("Error: Save customer - ", error.response.data);
      return error.response.data[0]?.errorMessage ?? "Error saving customer.";
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Put(CustomerStatusUpdate, status);
      return true;
    } catch (error) {
      console.error("Error: Update Customer Status - ", error);
      return false;
    }
  }
}
