import { useContext, useEffect, useState } from "react";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";

const WarehouseStatsComponent = () => {
    const { warehouseMap, getWarehouseStats, selectedWarehouse, wareHouseStats } = useContext(WarehouseContext);
    const [stats, setStats] = useState(null);

    useEffect(() => {
        selectedWarehouse?.wareHouseId && getWarehouseStats(selectedWarehouse?.wareHouseId)        
    }, [selectedWarehouse]);

    return <>
        {wareHouseStats ?
        <div className="row">
            <div className="col-xl-3 col-lg-6">
                <div className="box">
                    <div className="box-body text-center">
                        <div className="d-flex justify-content-around">
                            <div>
                                <h2 className="my-0">{wareHouseStats?.totalShelves ?? 0}</h2>
                                <p className="mb-0 text-fade">Total Shelves</p>
                            </div>
                            <div className="b-1"></div>
                            <div>
                                <h2 className="my-0">{`${wareHouseStats?.locationUsed ?? 0}%`}</h2>
                                <p className="mb-0 text-fade">Location Used(%)</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-6">
                <div className="box">
                    <div className="box-body text-center">
                        <div className="d-flex justify-content-around">
                            <div>
                                <h2 className="my-0">{wareHouseStats?.loadedShelves ?? 0}</h2>
                                <p className="mb-0 text-fade">Loaded Shelves</p>
                            </div>
                            <div className="b-1"></div>
                            <div>
                                <h2 className="my-0">{wareHouseStats?.emptyShelves ?? 0}</h2>
                                <p className="mb-0 text-fade">Empty Shelves</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3">
                <div className="box">
                    <div className="box-body text-center">
                        <div className="d-flex justify-content-around">
                            <div>
                                <h2 className="my-0">{wareHouseStats?.inUseCount ?? 0}</h2>
                                <p className="mb-0 text-fade">In Use</p>
                            </div>
                            <div className="b-1"></div>
                            <div>
                                <h2 className="my-0">{wareHouseStats?.notInUseCount ?? 0}</h2>
                                <p className="mb-0 text-fade">Not In Use</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3">
                <div className="box">
                    <div className="box-body text-center">
                        <div className="d-flex justify-content-around">
                            <div>
                                <h2 className="my-0">{wareHouseStats?.rejectedCount ?? 0}</h2>
                                <p className="mb-0 text-fade">Rejected</p>
                            </div>
                            <div className="b-1"></div>
                            <div>
                                <h2 className="my-0">{wareHouseStats?.quarentineCount ?? 0}</h2>
                                <p className="mb-0 text-fade">Quarentine</p>
                            </div>
                            <div className="b-1"></div>
                            <div>
                                <h2 className="my-0">{wareHouseStats?.releaseCount ?? 0}</h2>
                                <p className="mb-0 text-fade">Release</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : ""}
    </>;
};

export default WarehouseStatsComponent;
