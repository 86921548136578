import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { DatePicker } from "antd";
import moment from "moment";
import { SamplingContext } from "../../controllers/contexts/sampling.context";
import { toast } from "react-toastify";
import { SamplingStatuses } from "../../common/constants/generalConstants";
import {
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import Select from "react-select";
import _ from "lodash";
import dayjs from "dayjs";

interface CreateSampleFormProps {}

const SamplingFormComponent: React.FC<CreateSampleFormProps> = ({}) => {
  const {
    getBatchDetails,
    batchList,
    saveSampling,
    getNextSaNumber,
    nextSa,
    clearSaNumber,
    productCateList,
    productsList,
  } = useContext(SamplingContext);
  const newSample = {
    samplingId: 0,
    batchNum: "",
    prodId: null,
    prodCatId: null,
    totalSamplingQty: null,
    desc: "",
    status: "OPEN",
    samplingNum: "",
    samplingDetails: [],
    createdDate: "",
    createdBy: "",
    modifiedDate: "",
    modifiedBy: "",
    deleted: false,
  };
  const navigate = useNavigate();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [newSampling, setNewSampling] = useState(newSample);
  const [batchListData, setbatchListData] = useState(batchList);
  useEffect(() => {
    getBatchDetails();
  }, []);
  useEffect(() => {
    if (!nextSa) {
      getNextSaNumber();
    }
    return () => clearSaNumber();
  }, []);

  useEffect(() => {
    formatBatchListData(batchList);
  }, [batchList]);

  const formatBatchListData = (batchList: any[]) => {
    let batchData = [];
    batchList?.map((batchItem) => {
      batchItem?.batchDetails?.map((batchDetails) => {
        let item = {
          value: "",
          label: "",
          batchNo: "",
        };
        item.label = `${batchDetails?.batchNo} - ${batchItem?.grnNum} | ${batchItem.poNum} | ${batchDetails?.prodCode}`;
        item.value = `${batchDetails?.batchNo} - ${batchItem?.grnNum} | ${batchItem.poNum} | ${batchDetails?.prodCode}`;
        item.batchNo = `${batchDetails?.batchNo}-${batchDetails?.prodCode}`;
        batchData.push(item);
      });
    });
    setbatchListData(batchData);
  };

  const handleSelectedBatch = (e: any) => {
    setNewSampling(newSample);
    setSelectedBatch(e?.value);
    batchList.map((batchDtls: any, j: number) => {
      return batchDtls.batchDetails.map((batch: any, i: number) => {
        if (`${batch?.batchNo}-${batch?.prodCode}` === e?.batchNo) {
          let _newSample = { ...newSample };
          _newSample.batchNum = batch.batchNo;
          _newSample.prodId = batch.prodId;
          _newSample.prodCatId = batch.prodCatId;
          // _newSample.status = batch.status
          batch.prodLocations.map((loc) => {
            _newSample.samplingDetails.push({
              id: null,
              samplingId: null,
              locationId: loc.locationId,
              shelfId: loc.shelfId,
              samplingQty: 0,
              palletRefNum: loc.palletRefNum,
              locQty: loc.locationQty,
            });
          });
          setNewSampling(_newSample);
        }
      });
    });
  };
  const handleSampleQtyChange = (e, loc) => {
    const value = e.target.value.trim(); // Remove leading/trailing whitespace

    // Check if the value is empty or not a number
    if (value === "" || isNaN(parseFloat(value))) {
      // If value is empty or not a number, set samplingQty to null
      let _newSample = { ...newSampling };
      _newSample.samplingDetails = _newSample.samplingDetails.map(
        (sampleLoc) => {
          if (
            sampleLoc.locationId === loc.locationId &&
            sampleLoc?.palletRefNum === loc?.palletRefNum
          ) {
            return { ...sampleLoc, samplingQty: null }; // Set samplingQty to null
          }
          return sampleLoc;
        }
      );
      setNewSampling(_newSample);
      return; // Exit the function
    }

    if (parseFloat(value) < parseFloat(loc.locQty)) {
      let _newSample = { ...newSampling };
      _newSample.samplingDetails.map((sampleLoc) => {
        if (
          sampleLoc.locationId === loc.locationId &&
          sampleLoc?.palletRefNum === loc?.palletRefNum
        ) {
          sampleLoc.samplingQty = value;
        }
      });
      setNewSampling(_newSample);
    } else {
      toast.error("Invalid Sample quantity");
    }
  };

  const handleSampleChange = (e: any, key: string) => {
    let _newSample = { ...newSampling };
    _newSample[key] = e.target.value;
    setNewSampling(_newSample);
  };
  const handleSaveBtn = () => {
    let totalSampleQty = 0;
    newSampling.samplingDetails.map((loc) => {
      totalSampleQty = totalSampleQty + _.toNumber(loc.samplingQty);
    });
    newSampling.totalSamplingQty = totalSampleQty;
    newSampling.samplingNum = nextSa ? nextSa : "";
    if (selectedBatch) {
      if (
        newSampling.totalSamplingQty &&
        newSampling.totalSamplingQty > 0 &&
        newSampling.samplingNum &&
        newSampling.desc
      ) {
        console.log("final", newSampling);
        saveSampling(newSampling, () => {
          navigate(RoutingConstants.sampling);
        });
      } else {
        toast.error("Please fill mandatory fields");
      }
    } else {
      toast.error("Please select batch number");
    }
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.sampling}
                      title={LabelConstants.Sampling}
                    >
                      {LabelConstants.Sampling}
                    </NavLink>{" "}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-success float-end"
            onClick={() => handleSaveBtn()}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Sampling Overview </h4>
                {/* <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modal-right">
            </button> */}
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Location</th>
                        <th>Batch.Qty</th>
                        <th>Sample.Qty</th>
                        <th>Final.Qty</th>
                        {/* <th>Status</th> */}
                        {/* <th className="text-align:center">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {newSampling.samplingDetails.length > 0 ? (
                        newSampling.samplingDetails.map(
                          (loc: any, j: number) => {
                            return (
                              <tr key={j}>
                                <td>{j + 1}</td>

                                <td className="text-start">
                                  {getProductCatNameById(
                                    newSampling.prodCatId,
                                    productCateList
                                  )}
                                </td>
                                <td className="text-start">
                                  {getProductNameById(
                                    newSampling.prodId,
                                    productsList
                                  )}{" "}
                                </td>
                                <td>{loc.shelfId}</td>
                                <td>{loc.locQty}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    max={loc.locQty}
                                    value={loc.samplingQty}
                                    onChange={(e) =>
                                      handleSampleQtyChange(e, loc)
                                    }
                                  />
                                </td>
                                <td>
                                  {loc.samplingQty
                                    ? loc.locQty - loc.samplingQty
                                    : loc.locQty}
                                </td>
                                {/* <td>{newSampling.status}</td> */}
                                {/* <td></td> */}
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={9} align="center">
                            <br />
                            <p>Please select batch number</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      navigate(RoutingConstants.sampling);
                      setNewSampling(newSample);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success float-end"
                    onClick={() => handleSaveBtn()}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-primary p-4">
                <h4 className="box-title">BatchDetails</h4>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label" htmlFor="BatchNumber">
                      {LabelConstants.BatchNumber}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    {batchListData?.length > 0 && (
                      <Select
                        value={batchListData.filter(
                          (option) => option.value === selectedBatch
                        )}
                        options={batchListData}
                        isSearchable={true}
                        onChange={(e) => handleSelectedBatch(e)}
                      />
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="samplignumber">
                      {LabelConstants.SampligNumber}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      readOnly
                      // onChange={(e)=>handleSampleChange(e,'samplingNum')}
                      value={nextSa ? nextSa : ""}
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="Status">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select disabled className="form-control">
                      {SamplingStatuses.map(
                        (_status: string, _statusIdx: number) => {
                          return (
                            <option
                              key={`statusOption${_statusIdx}`}
                              value={_status}
                            >
                              {_status}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.CreatedDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      disabled
                      value={dayjs()}
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="Description">
                      {LabelConstants.Description}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      onChange={(e) => handleSampleChange(e, "desc")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SamplingFormComponent;
