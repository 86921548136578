import { useContext, useEffect, useState } from "react";
import { TransporterService } from "../../api/services/transporter.Service";
import { AppContext } from "../contexts/app.context";
import {
  IDocuments,
  IListConfig,
  IListConfigTransport,
  IStatusUpdate,
  ITransporter,
  ITransporterContext,
} from "../../common/models";
import {
  DocumentUploadKeys,
  EmptyListConfig,
  EmptyListConfigTransport,
  EmptyStatusUpdate,
  NewTransporter,
} from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { GenericHelperService } from "../../api/generics/helper.service";
import { DocumentService } from "../../api/services/document.service";
import { MasterService } from "../../api/services/master.service";
import { ILogsData, ILogsProps } from "../../common/models/logs";

const useTransporter = () => {
  const transporterService: TransporterService = new TransporterService();
  const documentService: DocumentService = new DocumentService();
  const helperService: GenericHelperService = new GenericHelperService();
  const masterService: MasterService = new MasterService();

  const { setLoader } = useContext(AppContext);
  const [masterTransporterList, setMasterTransporterList] = useState<
    ITransporter[]
  >([]);
  const [filteredMasterTransporterList, setFilteredMasterTransporterList] =
    useState<ITransporter[]>([]);
  const [transporterList, setTransporterList] = useState<ITransporter[]>([]);
  const [transporterForm, setTransporterForm] = useState<ITransporter>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [viewFiles, setViewFiles] = useState<IDocuments[]>([]);
  const [transportfilters, setTransportFilters] =
    useState<IListConfigTransport>(EmptyListConfigTransport);
    const [transporterLogs, setTransporterLogs] = useState<ILogsData[]>([]);


  const getLogsTransporter = async (id: number) => {
    const _logs: ILogsProps[] = await masterService.getLogs(`TRANS${id}`);
    setTransporterLogs(_logs);
  }

  useEffect(() => {
    setLoader(true);
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const indexOfLastItem = filters.currentPage * filters.currentPageSize;
    const indexOfFirstItem = indexOfLastItem - filters.currentPageSize;
    let _filteredList: ITransporter[];
    if (transportfilters?.searchLocally) {
      _filteredList = cloneDeep(masterTransporterList);
    } else {
      _filteredList = cloneDeep(filteredMasterTransporterList);
    }

    _filteredList = _filteredList.filter(
      (_sup: ITransporter, _supIdx: number) => {
        return _sup.transName
          .toLowerCase()
          .includes(filters.searchQuery.toLowerCase());
      }
    );
    _filteredList.sort((a, b) =>
      helperService.sort(
        filters.sortDirection === "asc" ? a : b,
        filters.sortDirection === "asc" ? b : a,
        filters.sortItem
      )
    );
    console.log("Filtered Sorted list - ", _filteredList);
    setTransporterList(
      _filteredList.slice(indexOfFirstItem, indexOfLastItem) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, masterTransporterList, filteredMasterTransporterList]);

  useEffect(() => {
    if (transportfilters.search) {
      queryTransportList();
    }
  }, [transportfilters?.search]);

  const queryTransportList = async (transporters: ITransporter[] = null) => {
    let _filteredList: ITransporter[] =
      await transporterService.searchTransportOrders(transportfilters);
    updateFilters(_filteredList);
    setFilteredMasterTransporterList(_filteredList ?? []);
  };

  const getTransporterList = async () => {
    const _transporter = await transporterService.getTransporter();
    updateFilters(_transporter);
    setMasterTransporterList(_transporter ?? []);
  };

  const updateFilters = (data: ITransporter[]) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = data?.length;
    _filters.totalPageCount = Math.ceil(
      data?.length / _filters.currentPageSize
    );
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const resetFilterWithMastersData = () => {
    updateFilters(masterTransporterList);
  };

  const initData = () => {
    getTransporterList();
    setTransporterForm(null);
    setSelectedList(EmptyStatusUpdate);
    setUploadedFiles([]);
  };

  const viewTransporter = async (item: ITransporter) => {
    setTransporterForm(item);
    const _documents: IDocuments[] = await documentService.getFiles(
      `${DocumentUploadKeys.Transporter}_${item?.transId}`
    );
    setViewFiles(_documents);
  };

  const clearViewTransporter = () => {
    setTransporterForm(null);
    setViewFiles([]);
  };

  const saveTransporter = async () => {
    const _transporterSave = await transporterService.saveTransporter(
      transporterForm
    );
    console.log("_transporterSave", _transporterSave);
    if (_transporterSave) {
      if (typeof _transporterSave == "string") {
        toast.error(_transporterSave);
      } else {
        if (typeof _transporterSave == "boolean") {
          await uploadFiles(transporterForm?.transId);
          await deleteFiles();
        } else {
          await uploadFiles(_transporterSave);
        }
        toast.success("Transporter saved successfully.");
        initData();
      }
    } else {
      toast.error("Error saving product.");
    }
  };

  const newTransporter = () => {
    setTransporterForm(cloneDeep(NewTransporter));
    setUploadedFiles([]);
    setViewFiles([]);
  };

  const updateStatus = async () => {
    const _updateStatus: boolean = await transporterService.updateStatus(
      selectedList
    );
    if (_updateStatus) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };

  const deleteTransporter = async () => {
    const _deleteItem: ITransporter = cloneDeep(transporterForm);
    _deleteItem.deleted = true;
    const _itemSave = await transporterService.saveTransporter(_deleteItem);
    if (_itemSave) {
      toast.success("Transporter deleted successfully.");
      initData();
    } else {
      toast.error("Error deleting transporter.");
    }
  };

  const uploadFiles = async (itemId: any) => {
    if (uploadedFiles.length > 0) {
      const _userId: string = `${DocumentUploadKeys.Transporter}_${
        itemId?.data ? itemId?.data : itemId
      }`;
      await documentService.uploadFiles(uploadedFiles, _userId);
    }
    return true;
  };

  const deleteFiles = async () => {
    const _deleteFiles: IDocuments[] = viewFiles.filter((_doc) =>
      _doc.deleted !== undefined ? _doc.deleted : false
    );
    for (let fileIdx = 0; fileIdx < _deleteFiles.length; fileIdx++) {
      const delFiles = _deleteFiles[fileIdx];
      await documentService.deleteFiles(delFiles.documentId);
    }
    return true;
  };

  return {
    transporterList,
    selectedList,
    viewFiles,
    filters,
    transporterForm,
    setFilters,
    setTransporterForm,
    saveTransporter,
    newTransporter,
    setSelectedList,
    updateStatus,
    deleteTransporter,
    setViewFiles,
    uploadedFiles,
    setUploadedFiles,
    viewTransporter,
    clearViewTransporter,
    resetFilterWithMastersData,
    transportfilters,
    setTransportFilters,
    getLogsTransporter,
    transporterLogs,
  } as ITransporterContext;
};

export default useTransporter;
