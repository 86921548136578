import { Outlet } from "react-router-dom";
import useSettings from "../../controllers/hooks/useSettings";
import { SettingsContext } from "../../controllers/contexts/settings.context";

const SettingsLayoutComponent = () => {
  const SettingFunctions = useSettings();

  return (
    <SettingsContext.Provider value={SettingFunctions}>
      <Outlet />
    </SettingsContext.Provider>
  );
};

export default SettingsLayoutComponent;
