import {
  OrdersApiBase,
  getNextSalesOrderNumber,
  salesOrderUpdate,
  deliveryOrderList,
  deliveryOrderSave,
  deliveryOrderUpdateById,
  deliveryOrderUpdate,
  getNextDeliveryOrderNumber,
  searchDOByCriteria,
  deliveryPrintData,
} from "../../common/constants/apiConstants";
import { IListConfigDO, IStatusUpdate } from "../../common/models";
import { IDeliveryOrder } from "../../common/models/deliveryorder";
import { AxiosService } from "../generics/axios.service";

export class DeliveryOrderService {
  private axiosService: AxiosService = new AxiosService();

  async getNextDoNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextDeliveryOrderNumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next DO Number - ", error);
      return [];
    }
  }

  async getDeliveryPrintData(deliveryNum: string) {
    try {
      const result: any = await this.axiosService.Get(
        deliveryPrintData(deliveryNum),
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: geDeliveryPrintData - ", error);
      return null;
    }
  }

  async searchDOList(filter: IListConfigDO, pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        searchDOByCriteria(pageNo),
        filter,
        OrdersApiBase
      );
      return result?.data ?? null;
    } catch (error) {
      console.error("Error: searchDOList List - ", error);
      return null;
    }
  }

  async createNewDeliveryOrder(
    newSalesOrder: IDeliveryOrder
  ): Promise<string | number> {
    try {
      const result: any = await this.axiosService.Post(
        deliveryOrderSave,
        newSalesOrder,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Save Delivery order - ", error.response.data);
      return (
        error?.response?.data[0]?.errorMessage ??
        "Error saving new Delivery Order."
      );
    }
  }

  async getDeliveryOrderList(pageNo: number) {
    try {
      const result: any = await this.axiosService.Get(
        deliveryOrderList(pageNo),
        OrdersApiBase
      );
      return result.data ?? null;
    } catch (error) {
      console.error("Error: Delivery order List - ", error);
      return null;
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Post(deliveryOrderUpdate, status, OrdersApiBase);
      return true;
    } catch (error) {
      console.error(
        "Error: Update DO Status - ",
        error.response.data[0]?.errorMessage
      );
      return error.response.data[0]?.errorMessage ?? false;
    }
  }

  async updateDeliveryOrderData(newSalesOrder: IDeliveryOrder): Promise<any> {
    try {
      if (!newSalesOrder?.id)
        return await this.axiosService.Post(
          deliveryOrderSave,
          newSalesOrder,
          OrdersApiBase
        );
      else {
        const _result = await this.axiosService.Put(
          deliveryOrderUpdateById(newSalesOrder?.id),
          newSalesOrder,
          OrdersApiBase
        );
        return _result.data;
      }
    } catch (error) {
      console.error("Error: updated delivery order - ", error.response.data);
      return (
        error?.response?.data[0]?.errorMessage ??
        "Error updating Delivery Order."
      );
    }
  }
}
