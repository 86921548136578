import axios, { AxiosRequestConfig } from "axios";
import { StorageService } from "./storage.service";
import { toast } from "react-toastify";
import { Authenticate, ApiBaseHost } from "../../common/constants/apiConstants";

export class AxiosService {
  private storageService: StorageService = new StorageService();
  private baseApiUrl: string;

  constructor() {
    this.baseApiUrl = `${ApiBaseHost}/api/` ?? "http://localhost:8080/";
  }

  private logOut = () => {
    this.storageService.clearContextInfo();
    toast.error("Session expired\nPlease login again!!");
    window.location.assign("/");
  };

  private checkIfSessionIsValid = (error) => {
    let result = true;
    if (error?.response?.status === 401) {
      result = false;
    }
    return result;
  };

  private createBasicConfig(baseURL = "") {
    const config: AxiosRequestConfig = {
      baseURL: baseURL === "" ? this.baseApiUrl : baseURL,
      headers: {
        Accept: "application/json, application/javascript",
        Authorization: this.storageService.ContextInfo()?.token,
      },
      // timeout: 30000, // 30 seconds
      withCredentials: false,
    };
    return config;
  }

  private createConfig(baseURL = ""): any {
    let ContextInfo = this.storageService.ContextInfo();
    const config: AxiosRequestConfig = {
      baseURL: baseURL === "" ? this.baseApiUrl : baseURL,
      headers: {
        Authorization: ContextInfo?.token,
        Accept: "application/json, application/javascript",
        UserID: ContextInfo ? ContextInfo.empId : null, // temporary
      },
      // timeout: 30000, // 30 seconds
      withCredentials: false,
    };
    return config;
  }

  public GetAnonymous(url: string, baseURL: string = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(url, this.createBasicConfig(baseURL)).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (!this.checkIfSessionIsValid(error)) {
            this.logOut();
          }
          reject(error);
        }
      );
    });
  }
  public PostAnonymous(
    url: string,
    data: any,
    baseURL: string = ""
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(url, data, this.createBasicConfig(baseURL)).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (url !== Authenticate && !this.checkIfSessionIsValid(error)) {
            this.logOut();
          }
          reject(error);
        }
      );
    });
  }

  public Get(url: string, baseURL: string = ""): Promise<any> {
    console.log("this.createConfig(baseURL)", this.createConfig(baseURL));
    return new Promise((resolve, reject) => {
      axios.get(url, this.createConfig(baseURL)).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (!this.checkIfSessionIsValid(error)) {
            this.logOut();
          }
          reject(error);
        }
      );
    });
  }
  public Post(url: string, data: any, baseURL: string = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(url, data, this.createConfig(baseURL)).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (!this.checkIfSessionIsValid(error)) {
            this.logOut();
          }
          reject(error);
        }
      );
    });
  }

  public Put(url: string, data: any, baseURL: string = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.put(url, data, this.createConfig(baseURL)).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (!this.checkIfSessionIsValid(error)) {
            this.logOut();
          }
          reject(error);
        }
      );
    });
  }
  public Delete(url: string, baseURL: string = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.delete(url, this.createConfig(baseURL)).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (!this.checkIfSessionIsValid(error)) {
            this.logOut();
          }
          reject(error);
        }
      );
    });
  }
}
