import { OrdersApiBase } from "../../common/constants/apiConstants";
import { AxiosService } from "../generics/axios.service";

export class DashboardService {
  private axiosService: AxiosService = new AxiosService();

  async makeGetCallWithEndPoint(
    endPoint: string,
    apiUrl: string = OrdersApiBase
  ): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(endPoint, apiUrl);
      return result.data;
    } catch (error) {
      console.error("Error: makeGetCallWithEndPoint - ", error);
      return null;
    }
  }
}
