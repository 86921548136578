import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts/app.context";
import {
  EmptyListConfig,
  EmptyStatusUpdate,
} from "../../common/constants/generalConstants";
import { EmployeeService } from "../../api/services/employee.service";
import {
  IListConfig,
  IEmployee,
  IStatusUpdate,
  IEmployeeContext,
  IEmployeeRole,
} from "../../common/models";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { GenericHelperService } from "../../api/generics/helper.service";
import { MasterService } from "../../api/services/master.service";
import { ILogsData, ILogsProps } from "../../common/models/logs";

const useEmployee = () => {
  const employeeService: EmployeeService = new EmployeeService();
  const helperService: GenericHelperService = new GenericHelperService();
  const masterService: MasterService = new MasterService();
  const { setLoader } = useContext(AppContext);
  const [masterEmployeeList, setMasterEmployeeList] = useState<IEmployee[]>([]);
  const [employeeList, setEmployeeList] = useState<IEmployee[]>([]);
  const [employeeForm, setEmployeeForm] = useState<IEmployee>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [employeeRoles, setemployeeRoles] = useState<IEmployeeRole[]>([]);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [employeeLogs, setEmployeeLogs] = useState<ILogsData[]>([]);

  const getLogsEmployee = async (id: number) => {
    const _logs: ILogsProps[] = await masterService.getLogs(`EMP${id}`);
    setEmployeeLogs(_logs);
  };

  const getEmployeeList = async () => {
    const _employee = await employeeService.getEmployee();
    const _filters: IListConfig = cloneDeep(filters);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = _employee.length;
    _filters.totalPageCount = Math.ceil(
      _employee.length / _filters.currentPageSize
    );
    // _filters.sortItem = "modifiedDate";
    // _filters.sortDirection = "desc";
    // _filters.sortIsDate = true;
    setFilters(_filters);
    setMasterEmployeeList(_employee ?? []);
  };

  const initData = () => {
    getEmployeeList();
    setEmployeeForm(null);
    setSelectedList(EmptyStatusUpdate);
  };

  useEffect(() => {
    setLoader(true);
    initData();
    getEmployeeRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const indexOfLastItem = filters.currentPage * filters.currentPageSize;
    const indexOfFirstItem = indexOfLastItem - filters.currentPageSize;
    let _filteredList: IEmployee[] = cloneDeep(masterEmployeeList);
    _filteredList = _filteredList.filter((_sup: IEmployee, _supIdx: number) => {
      return _sup.empName
        .toLowerCase()
        .includes(filters.searchQuery.toLowerCase());
    });
    _filteredList.sort((a, b) =>
      helperService.sort(
        filters.sortDirection === "asc" ? a : b,
        filters.sortDirection === "asc" ? b : a,
        filters.sortItem,
        filters.sortIsDate
      )
    );
    console.log("Filtered Sorted list - ", _filteredList);
    setEmployeeList(
      _filteredList.slice(indexOfFirstItem, indexOfLastItem) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, masterEmployeeList]);

  const saveEmployee = async () => {
    const _employeeSave: boolean = await employeeService.saveEmployee(
      employeeForm
    );
    if (_employeeSave) {
      if (typeof _employeeSave == "string") {
        toast.error(_employeeSave);
      } else {
        toast.success("Employee saved successfully.");

        initData();
      }
    } else {
      toast.error("Error employee Data.");
    }
  };

  const getEmployeeRoles = async () => {
    let roles = await employeeService.getEmployeeRoles();
    setemployeeRoles(roles);
  };

  const updateStatus = async () => {
    const _updateStatus: boolean = await employeeService.updateStatus(
      selectedList
    );
    if (_updateStatus) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };

  const deleteEmployee = async () => {
    const _deleteSupplier: IEmployee = cloneDeep(employeeForm);
    _deleteSupplier.deleted = true;
    const _supplierSave: boolean = await employeeService.saveEmployee(
      _deleteSupplier
    );
    if (_supplierSave) {
      toast.success("Employee deleted successfully.");
      initData();
    } else {
      toast.error("Error deleting employee.");
    }
  };

  return {
    employeeList,
    filters,
    employeeForm,
    selectedList,
    setSelectedList,
    setFilters,
    setEmployeeForm,
    saveEmployee,
    deleteEmployee,
    updateStatus,
    employeeRoles,
    employeeLogs,
    getLogsEmployee,
  } as IEmployeeContext;
};

export default useEmployee;
