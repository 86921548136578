import { useContext, useState } from "react";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { GenericHelperService } from "../../api/generics/helper.service";
import { useLocation, useNavigate } from "react-router-dom";
import { IListConfig, IStatusUpdate, IWarehouse } from "../../common/models";
import { cloneDeep } from "lodash";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import PageSortDescription from "../common/pageSortDescription.component";
import {
  EmptyStatusUpdate,
  NewWarehouse,
} from "../../common/constants/generalConstants";
import WarehouseStatusUpdateComponent from "./warehouseStatusUpdate.component";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import useAuth from "../../controllers/hooks/useAuth";
import LogsModalComponent from "../common/logsModal.component";
const WarehouseListingComponent = () => {
  const {
    warehouseList,
    filters,
    selectedList,
    warehouseForm,
    setWarehouseForm,
    setFilters,
    setSelectedList,
    deleteWarehouse,
    getLogsWarehouse,
    WarehouseLogs,
  } = useContext(WarehouseContext);
  const { isAccessDisabled } = useAuth();
  const pathname = useLocation()?.pathname;

  const helperService: GenericHelperService = new GenericHelperService();
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const handleShowLogsModal = (_sup: IWarehouse) => {
    getLogsWarehouse(_sup.wareHouseId);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };
  const navigate = useNavigate();

  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setFilters({ ...filters, searchQuery: _searchQuery });
  };

  const setUpdateStatus = (prod: IWarehouse) => {
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.wareHouseId];
    _status.status = prod.status;
    setSelectedList(_status);
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
      _status.ids = warehouseList.map((_prd) => _prd.wareHouseId);
      setSelectedList(_status);
    } else {
      setSelectedList(cloneDeep(EmptyStatusUpdate));
    }
  };

  const handleSelectItem = (item: IWarehouse, event: any) => {
    const _status: IStatusUpdate = cloneDeep(selectedList);
    if (event.target.checked) {
      _status.ids.push(item.wareHouseId);
    } else {
      const _itmIdx = _status.ids.findIndex(
        (_itm) => _itm === item.wareHouseId
      );
      if (_itmIdx > -1) {
        _status.ids.splice(_itmIdx, 1);
      }
    }
    setSelectedList(_status);
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };

  const cancelDelete = () => {
    setWarehouseForm(null);
  };

  const confirmDelete = () => {
    deleteWarehouse();
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="box">
            <div className="box-body p-3">
              <div className="row">
                <div className="col-lg-6 d-flex">
                  <div className="input-group w-p50">
                    <span className="input-group-addon bg-lighter border-0">
                      <i className="fa fa-search"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control bg-lighter border-0"
                      placeholder="Search..."
                      id={`search`}
                      value={filters.searchQuery}
                      onChange={(event) => searchQueryChange(event)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  <button
                    type="button"
                    disabled={isAccessDisabled("WH", "create")}
                    className="waves-effect waves-light btn btn-success rounded"
                    onClick={() => {
                      setWarehouseForm(cloneDeep(NewWarehouse));
                      navigate(
                        pathname === RoutingConstants?.warehousel2l
                          ? RoutingConstants.warehousel2lCreate
                          : RoutingConstants.warehouseCreate
                      );
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    {`${LabelConstants.Create} ${LabelConstants.Warehouse}`}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="box-body">
              <div className="table-responsive">
                <table className="table table-hover table-bordered mb-0">
                  <thead className="table-primary">
                    <tr>
                      {/* <th>
                        <input
                          type="checkbox"
                          id={`md_checkbox_all`}
                          className="chk-col-primary"
                          checked={
                            warehouseList?.length > 0 &&
                            selectedList?.ids.length === warehouseList?.length
                          }
                          onChange={(event) => handleSelectAll(event)}
                        />
                        <label htmlFor={`md_checkbox_all`}></label>
                      </th> */}
                      <th onClick={() => handleSort("wareHouseId")}>
                        {LabelConstants.ID}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "wareHouseId"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("warehouseName")}>
                        {LabelConstants.WarehouseName}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "warehouseName"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("clientName")}>
                        {LabelConstants.ClientName}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "clientName"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("warehouseType")}>
                        {LabelConstants.WarehouseType}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "warehouseType"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("warehouseScope")}>
                        {LabelConstants.WarehouseScope}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "warehouseScope"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("modifiedDate")}>
                        {LabelConstants.ModifiedDate}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "modifiedDate"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("status")}>
                        {LabelConstants.Status}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "status"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {warehouseList.map((_sup, _supIdx) => (
                      <tr key={`warehouseListing${_sup.wareHouseId}`}>
                        {/* <td>
                          <input
                            type="checkbox"
                            id={`md_checkbox_${_supIdx}`}
                            className="chk-col-primary"
                            checked={
                              selectedList?.ids.findIndex(
                                (_itm) => _itm === _sup.wareHouseId
                              ) >= 0
                            }
                            onChange={(event) => handleSelectItem(_sup, event)}
                          />
                          <label htmlFor={`md_checkbox_${_supIdx}`}></label>
                        </td> */}
                        <td>{_sup.wareHouseId}</td>
                        <td>{_sup.warehouseName}</td>
                        <td>{_sup.clientName}</td>
                        <td>{_sup.warehouseType}</td>
                        <td>{_sup.warehouseScope}</td>
                        <td>{_sup.modifiedDate}</td>
                        <td>
                          <span
                            style={
                              isAccessDisabled("WH", "updateStatus")
                                ? { pointerEvents: "none", opacity: 0.5 }
                                : {}
                            }
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#updateStatus"
                            onClick={() => setUpdateStatus(_sup)}
                            className={`badge bg-${helperService.getStatusColor(
                              _sup.status
                            )}-light rounded-pill point`}
                          >
                            {_sup.status}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex justify-content-start">
                            <button
                              type="button"
                              disabled={isAccessDisabled("WH", "view")}
                              className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                              onClick={() => {
                                setWarehouseForm(_sup);
                                navigate(
                                  pathname === RoutingConstants?.warehousel2l
                                    ? RoutingConstants.warehousel2lDetails
                                    : RoutingConstants.warehouseDetails
                                );
                              }}
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </button>
                            <button
                              disabled={isAccessDisabled("WH", "edit")}
                              type="button"
                              className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                              onClick={() => {
                                setWarehouseForm(_sup);
                                navigate(
                                  pathname === RoutingConstants?.warehousel2l
                                    ? RoutingConstants.warehousel2lEdit
                                    : RoutingConstants.warehouseEdit
                                );
                              }}
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <button
                              type="button"
                              className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                              onClick={() => {
                                setWarehouseForm(cloneDeep(_sup));
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#deleteConfirmation"
                              disabled={isAccessDisabled("WH", "delete")}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                            <button
                              type="button"
                              className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                              onClick={() => {
                                setcreatedDate(_sup?.createdDate);
                                setcreatedBy(_sup?.createdBy);
                                handleShowLogsModal(_sup);
                              }}
                            >
                              <i className="fa fa-book" aria-hidden="true"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="box-body p-3">
              <div className="row">
                <div className="col-lg-6 d-flex align-items-center">
                  <PageSortDescription
                    filters={filters}
                    dataList={warehouseList}
                  />
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  {filters.totalPageCount > 1 && (
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="productorder_paginate"
                    >
                      <ReactPaginate
                        forcePage={filters.currentPage - 1}
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={
                          filters && filters.totalPageCount < 2
                            ? 0
                            : filters.totalPageCount
                        }
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        pageClassName={`paginate_button page-item`}
                        pageLinkClassName="page-link"
                        activeLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        breakClassName={`paginate_button page-item`}
                        disabledClassName={`disabled`}
                        previousClassName={`paginate_button page-item previous`}
                        nextClassName={`paginate_button page-item next`}
                        onPageChange={(e) => {
                          setFilters({
                            ...filters,
                            currentPage: e.selected + 1,
                          });
                        }}
                        containerClassName={`pagination mb-1`}
                        activeClassName={`active`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WarehouseStatusUpdateComponent />
      <DeleteConfirmationComponent
        title={warehouseForm?.warehouseName ?? ""}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={WarehouseLogs}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default WarehouseListingComponent;
