import { Outlet } from "react-router-dom";
import { DashboardContext } from "../../controllers/contexts/dashboard.context";
import useDashboard from "../../controllers/hooks/useDashboard";

const DashboardLayoutComponent = () => {
  const DashboardFunctions = useDashboard();

  return (
    <DashboardContext.Provider value={DashboardFunctions}>
      <Outlet />
    </DashboardContext.Provider>
  );
};

export default DashboardLayoutComponent;
