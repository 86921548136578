import { IPageSortDescriptionProps } from "../../common/models";

const PageSortDescription = (props: IPageSortDescriptionProps) => {
    const { filters, dataList } = props;

    const showStartAndToRecordsCount = (filterObject, isStart) => {
        if (filterObject) {
            let initCount =
                (filterObject.currentPage - 1) * filterObject.currentPageSize;
            let resultCount = initCount;
            if (isStart) {
                resultCount = initCount + 1;
            } else {
                resultCount =
                    filterObject.currentPageSize < filterObject.totalRecords
                        ? initCount +
                        (filterObject.currentPageSize > dataList.length
                            ? dataList.length
                            : filterObject.currentPageSize)
                        : (resultCount = filterObject.totalRecords);
            }

            return resultCount;
        }
        return 0;
    };

    return <div className="dataTables_info">
        Showing {showStartAndToRecordsCount(filters, true)}{" "}
        {filters &&
            filters.totalRecords > 1 &&
            filters.currentPageSize > 1 &&
            dataList.length > 1 &&
            `to ${showStartAndToRecordsCount(filters, false)}`}{" "}
        of {(filters && filters.totalRecords) || 0} entries
    </div>
};

export default PageSortDescription;