import { Outlet } from "react-router-dom";
import { SalesOrderContext } from "../../controllers/contexts/salesorder.context";
import useSalesOrder from "../../controllers/hooks/useSalesOrder";

const SalesOrderLayoutComponent = () => {
  const SalesOrderFunctions = useSalesOrder();

  return (
    <SalesOrderContext.Provider value={SalesOrderFunctions}>
      <Outlet />
    </SalesOrderContext.Provider>
  );
};

export default SalesOrderLayoutComponent;
