import { useContext, useEffect } from "react";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GenericHelperService } from "../../api/generics/helper.service";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const WarehouseDetailsComponent = () => {
  const { warehouseForm, setWarehouseForm } = useContext(WarehouseContext);
  const helperService: GenericHelperService = new GenericHelperService();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const handleBack = () => {
    navigate(-1);
    setWarehouseForm(null);
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.warehouse}
                      title={LabelConstants.Warehouse}
                    >
                      {LabelConstants.Warehouse}
                    </NavLink>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Warehouse} ${LabelConstants.Details}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <h4 className="box-title">{LabelConstants.WarehouseInfo}</h4>
                <div className="row">
                  <div className="col-12">
                    <p className="mb-1">
                      <strong>{LabelConstants.Name}</strong> :
                      <span className="text-gray ps-10">
                        {warehouseForm?.warehouseName}
                      </span>
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.ClientName}</strong> :
                      <span className="text-gray ps-10">
                        {warehouseForm?.clientName}
                      </span>
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.Description}</strong> :
                      <span className="text-gray ps-10">
                        {warehouseForm?.description}
                      </span>
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.WarehouseScope}</strong> :
                      <span className="text-gray ps-10">
                        {warehouseForm?.warehouseScope}
                      </span>
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.WarehouseType}</strong> :
                      <span className="text-gray ps-10">
                        {warehouseForm?.warehouseType}
                      </span>
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.Status}</strong> :
                      <span
                        className={`badge bg-${helperService.getStatusColor(
                          warehouseForm?.status
                        )}-light rounded-pill`}
                      >
                        {warehouseForm?.status}
                      </span>
                    </p>
                  </div>
                </div>
                <h4 className="box-title mt-3">{`${LabelConstants.Warehouse} ${LabelConstants.Address}`}</h4>
                <div className="row">
                  <div className="col-12 p-0">
                    <div key={`supDetailsAddress`}>
                      <div className="box box-bordered">
                        <div className="box-body">
                          <p className="mb-1 ms-2">
                            <strong>{LabelConstants.Address1}</strong> :
                            <span className="text-gray ps-10">
                              {warehouseForm?.wareHouseAddress?.address1}
                            </span>
                          </p>
                          <p className="mb-1 ms-2">
                            <strong>{LabelConstants.Address2}</strong> :
                            <span className="text-gray ps-10">
                              {warehouseForm?.wareHouseAddress?.address2}
                            </span>
                          </p>
                          <p className="mb-1 ms-2">
                            <strong>{LabelConstants.City}</strong> :
                            <span className="text-gray ps-10">
                              {warehouseForm?.wareHouseAddress?.city}
                            </span>
                          </p>
                          <p className="mb-1 ms-2">
                            <strong>{LabelConstants.County}</strong> :
                            <span className="text-gray ps-10">
                              {warehouseForm?.wareHouseAddress?.county}
                            </span>
                          </p>
                          <p className="mb-1 ms-2">
                            <strong>{LabelConstants.Country}</strong> :
                            <span className="text-gray ps-10">
                              {warehouseForm?.wareHouseAddress?.country}
                            </span>
                          </p>
                          <p className="mb-1 ms-2">
                            <strong>{LabelConstants.PostCode}</strong> :
                            <span className="text-gray ps-10">
                              {warehouseForm?.wareHouseAddress?.postCode}
                            </span>
                          </p>

                          <p className="my-2">
                            <strong>{LabelConstants.Contact}s</strong>
                          </p>
                          <div className="row">
                            {warehouseForm?.wareHouseAddress?.warehouseCom.map(
                              (_addCont, _addContIdx) => (
                                <>
                                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 pb-3">
                                    <div className="box box-bordered m-0">
                                      <div className="box-body p-2">
                                        <p className="my-2 ms-1">
                                          <strong>
                                            {LabelConstants.Contact} #
                                            {_addContIdx + 1}
                                          </strong>
                                        </p>
                                        <p className="mb-1 ms-2">
                                          <strong>{LabelConstants.Name}</strong>
                                          :
                                          <span className="text-gray ps-10">
                                            {_addCont.contactName ?? ""}
                                          </span>
                                        </p>
                                        <p className="mb-1 ms-2">
                                          <strong>
                                            {LabelConstants.Phone}
                                          </strong>
                                          :
                                          <span className="text-gray ps-10">
                                            {formatPhoneNumberIntl(
                                              `${
                                                _addCont.designationCountryCode ??
                                                ""
                                              }${_addCont.phoneNumber ?? ""}`
                                            ) ?? ""}
                                          </span>
                                        </p>
                                        <p className="mb-1 ms-2">
                                          <strong>
                                            {LabelConstants.Email}
                                          </strong>
                                          :
                                          <span className="text-gray ps-10">
                                            {_addCont.email ?? ""}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <button
                  type="button"
                  className="btn btn-danger float-end"
                  onClick={() => handleBack()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WarehouseDetailsComponent;
