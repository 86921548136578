import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import {
  getDateIfValid,
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import { DatePicker } from "antd";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { toast } from "react-toastify";
import { IProducts } from "../../common/models";

const WarehouseTransferDetails = () => {
  const location = useLocation();
  const isEditableOP = location.state.isEditable;
  const orderPickingData = location.state.op;
  const {
    productsList,
    productCateList,
    updateTransferWarehouse,
    clearLocations,
    getProductLocations,
    productLocations,
    warehouseTransferList,
  } = useContext(OrderPickingContext);

  const [updateOP, setUpdateOP] = useState<any>(orderPickingData);
  const [activePrs, setActivePrs] = useState<any>([]);
  const [tempProds, setTempProds] = useState<any>([]);
  const { masterWarehouseList } = useContext(WarehouseContext);
  const [transferQtys, setTransferQtys] = useState({});
  const [pickedProducts, setPickedProducts] = useState<any>([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (warehouseTransferList?.length === 0) {
      navigate(RoutingConstants.warehouseTransfer);
    }
  }, [warehouseTransferList]);
  const handleOPCreatedDate = (e: any) => {
    let _updateOP = { ...updateOP };
    _updateOP.pickingDate = e;
    setUpdateOP(_updateOP);
  };
  const handleCheckboxClick = (product: IProducts) => {
    const index = pickedProducts.findIndex(
      (p) => p.productId === product.productId
    );
    if (index !== -1) {
      setPickedProducts((prevState) => {
        const updatedLocations = [...prevState];
        updatedLocations.splice(index, 1);
        return updatedLocations;
      });
    } else {
      setPickedProducts((prevState) => [...prevState, product]);
    }
  };

  const handleLocCheckbox = (loc: any, e: any) => {
    const isChecked = e.target.checked;
    setUpdateOP((prevOP) => {
      let updatedOP = { ...prevOP };
      const existingDetailIndex = updatedOP.transferOrderDetails.findIndex(
        (detail) => detail.locId === loc.locationId
      );
      if (isChecked) {
        if (existingDetailIndex === -1) {
          updatedOP.transferOrderDetails.push({
            locId: loc.locationId,
            shelfId: loc.shelfId,
            prodId: loc.prodId,
            prodCatId: loc.prodCatId,
            batchNo: loc.batchNo,
            batchExpiryDate: loc.batchExpiryDate,
            quantity: loc.quantity,
            palletRefNum: loc.palletRefNum,
          });
        }
      } else {
        if (existingDetailIndex !== -1) {
          updatedOP.transferOrderDetails.splice(existingDetailIndex, 1);
        }
      }
      return updatedOP;
    });
  };
  const handleTransferQtyChange = (e, loc) => {
    const newTransferQty = parseInt(e.target.value);
    setUpdateOP((prevOP) => {
      const updatedDetails = prevOP.transferOrderDetails.map((detail) => {
        if (detail.locId === loc.locationId) {
          return { ...detail, quantity: newTransferQty };
        } else {
          return detail;
        }
      });
      return { ...prevOP, transferOrderDetails: updatedDetails };
    });
  };
  useEffect(() => {
    if (
      warehouseTransferList[0] &&
      warehouseTransferList[0]?.transferOrderDetails
    ) {
      const initialTransferQtys = {};
      warehouseTransferList[0]?.transferOrderDetails.forEach((detail) => {
        initialTransferQtys[detail.locId] = detail.quantity;
      });
      setTransferQtys(initialTransferQtys);
    }
  }, [warehouseTransferList]);

  useEffect(() => {
    updateOP.transferOrderDetails.forEach((detail) => {
      getProductLocations(
        "MANUAL",
        detail.prodId,
        detail.quantity,
        updateOP.fromWarehouseId
      );
    });

    const filteredProducts = productsList.filter((product) =>
      updateOP?.transferOrderDetails?.some(
        (detail) => detail.prodId === product.productId
      )
    );
    setPickedProducts(filteredProducts);
  }, []);
  const handleTransferStatus = (e: any) => {
    let _updateOP = { ...updateOP };
    _updateOP.status = e.target.value;
    setUpdateOP(_updateOP);
  };
  const handleDescriptionChange = (e: any) => {
    let _updateOP = { ...updateOP };
    _updateOP.reason = e.target.value;
    setUpdateOP(_updateOP);
  };
  const handlePrLocations = (prId: any, Qty: any) => {
    let _activePrs = [...activePrs];
    let prIndex = _activePrs.indexOf(prId);
    if (prIndex > -1) {
      _activePrs.splice(prIndex, 1);
    } else {
      _activePrs.push(prId);
      if (!tempProds.includes(prId)) {
        getProductLocations("MANUAL", prId, Qty, updateOP.fromWarehouseId);
      }
    }
    setActivePrs(_activePrs);

    let _tempPr = [...tempProds];
    _tempPr.push(prId);
    setTempProds(_tempPr);
  };
  const handleSaveOrderPicking = () => {
    if (updateOP.transferOrderDetails.every((order) => order.quantity > 0)) {
      updateTransferWarehouse(updateOP);
    } else {
      toast.error("Please select valid details");
    }
  };

  const getViewItemQty = (loc) => {
    let qty = 0;
    qty =
      productLocations?.find(
        (item) =>
          item?.locationId === loc?.locId &&
          item?.shelfId === loc?.shelfId &&
          item?.batchNo === loc?.batchNo &&
          item?.prodId === loc?.prodId &&
          item?.palletRefNum === loc?.palletRefNum
      )?.quantity ?? 0;
    return qty;
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.warehouseTransfer}
                    >
                      Stock Transfer
                    </NavLink>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.warehouseTransfer}
                    >
                      W2W Stock Transfer
                    </NavLink>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Stock Transfer Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {isEditableOP && (
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => handleSaveOrderPicking()}
            >
              Save changes
            </button>
          )}
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Stock Transfer </h4>
                {isEditableOP && (
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-right"
                  >
                    Add Product
                  </button>
                )}
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {!isEditableOP ? (
                      <tbody>
                        {updateOP?.transferOrderDetails.length > 0 &&
                          updateOP.transferOrderDetails?.map(
                            (detail, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="text-start">
                                  {getProductCatNameById(
                                    detail.prodCatId,
                                    productCateList
                                  )}
                                </td>
                                <td className="text-start">
                                  {getProductNameById(
                                    detail.prodId,
                                    productsList
                                  )}
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-circle btn-danger btn-xs"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#modalTop-right-${index}`}
                                  >
                                    <i className="ti-arrow-right"></i>
                                  </a>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    ) : (
                      <tbody>
                        {pickedProducts.length > 0 ? (
                          pickedProducts.map((_prod: any, j: number) => {
                            return (
                              <tr key={j}>
                                <td>{j + 1}</td>
                                <td className="text-start">
                                  {getProductCatNameById(
                                    _prod.prodCategory,
                                    productCateList
                                  )}
                                </td>
                                <td className="text-start">
                                  {_prod.productName}
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-circle btn-danger btn-xs"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#modalTop-right-${j}`}
                                  >
                                    <i className="ti-arrow-right"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10} align="center">
                              <br />
                              <p>No products added to Transfer</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => navigate(RoutingConstants.warehouseTransfer)}
                  >
                    Close
                  </button>
                  {isEditableOP && (
                    <button
                      type="button"
                      className="btn btn-success float-end"
                      onClick={() => {
                        handleSaveOrderPicking();
                      }}
                    >
                      Save changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">Transfer Order Details</h4>
                <p className="p-0 m-0">
                  #{LabelConstants.ToNumber} : {updateOP?.transOrderNum}
                </p>
              </div>
              <div className="box-body">
                <div className="row mt-20 align-items-center">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.TrnasferDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(updateOP?.transferDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleOPCreatedDate(date);
                      }}
                      disabled={!isEditableOP}
                    />
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Source WareHouse
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      // onChange={(e) => handleWerehouseChange(e)}
                      value={updateOP?.fromWarehouseId}
                      disabled={true}
                    >
                      <option value={""}>Select Warehouse Name</option>
                      {masterWarehouseList.length > 0 &&
                        masterWarehouseList.map((wereHouse: any, i: number) => {
                          return (
                            <option key={i} value={wereHouse.wareHouseId}>
                              {wereHouse.warehouseName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Destination WareHouse
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      // onChange={(e) => handleDestinationWerehouseChange(e)}
                      value={updateOP?.toWarehouseId}
                      disabled={true}
                    >
                      <option value={""}>Select Warehouse Name</option>
                      {masterWarehouseList.length > 0 &&
                        masterWarehouseList.map((wereHouse: any, i: number) => {
                          return (
                            <option key={i} value={wereHouse.wareHouseId}>
                              {wereHouse.warehouseName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Status
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        handleTransferStatus(e);
                      }}
                      disabled={!isEditableOP}
                      value={updateOP?.status}
                    >
                      <option>--</option>
                      <option value={"DRAFT"}>DRAFT</option>
                      <option value={"DISPATCHED"}>DISPATCHED</option>
                      <option value={"DELIVERED"}>DELIVERED</option>
                    </select>
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      Description
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      value={updateOP?.reason}
                      onChange={(e) => handleDescriptionChange(e)}
                      disabled={!isEditableOP}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!isEditableOP ? (
        <>
          {updateOP?.transferOrderDetails.length > 0 &&
            updateOP?.transferOrderDetails?.map((detail, index) => (
              <div
                className="modal modal-center fade overflow-auto"
                id={`modalTop-right-${index}`}
              >
                <div
                  className="modal-dialog modal-lg"
                  style={{ width: "1000px", maxWidth: "100%" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Products List</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          clearLocations();
                          setActivePrs([]);
                          setTempProds([]);
                        }}
                      ></button>
                    </div>
                    <div className="modal-body" style={{ overflowY: "auto" }}>
                      <table className="table table-bordered text-center">
                        <thead className="table-primary">
                          <tr>
                            <th>S.No</th>
                            <th>Product Category</th>
                            <th>Product Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-start">
                              {getProductCatNameById(
                                detail.prodCatId,
                                productCateList
                              )}
                            </td>
                            <td className="text-start">
                              {getProductNameById(detail.prodId, productsList)}
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handlePrLocations(detail.prodId, index)
                                }
                              >
                                Locations
                              </button>
                            </td>
                          </tr>
                          {activePrs.includes(detail.prodId) && (
                            <tr style={{ background: "#f8f8f8" }}>
                              <td colSpan={7}>
                                <table className="table table-bordered m-0">
                                  <thead className="table-secondary">
                                    <tr>
                                      <td>Location ID</td>
                                      <td>Location</td>
                                      <td>Batch No</td>
                                      <td>Pallet Ref No</td>
                                      {updateOP?.status !== "DISPATCHED" && (
                                        <td>Available Qty</td>
                                      )}
                                      <td>Transfer Qty</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {productLocations.length > 0 ? (
                                      updateOP.transferOrderDetails.map(
                                        (loc: any, j: number) => {
                                          if (loc.prodId === detail.prodId) {
                                            return (
                                              <tr>
                                                <td>{loc.locId}</td>
                                                <td>{loc.shelfId}</td>
                                                <td>{loc.batchNo}</td>
                                                <td>{loc.palletRefNum}</td>
                                                {updateOP?.status !==
                                                  "DISPATCHED" && (
                                                  <td>{getViewItemQty(loc)}</td>
                                                )}
                                                <td>
                                                  <input
                                                    type="number"
                                                    className="form-control text-start"
                                                    max={loc.quantity}
                                                    onChange={(e) => {
                                                      handleTransferQtyChange(
                                                        e,
                                                        loc
                                                      );
                                                    }}
                                                    value={
                                                      // transferQtys[loc.locId]
                                                      loc.quantity
                                                    }
                                                    disabled={!isEditableOP}
                                                  />
                                                </td>
                                              </tr>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={6}>
                                          Product not Available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer modal-footer-uniform">
                      <button
                        type="button"
                        className="btn btn-primary float-end"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          clearLocations();
                          setActivePrs([]);
                          setTempProds([]);
                        }}
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}{" "}
        </>
      ) : (
        <div>
          {pickedProducts.length > 0 &&
            pickedProducts.map((pr: any, i: number) => {
              const maxQuantity = Math.max(
                ...productLocations
                  .filter((loc) => loc.prodId === pr.productId)
                  .map((loc) => loc.quantity)
              );

              return (
                <div
                  className="modal modal-center fade overflow-auto"
                  id={`modalTop-right-${i}`}
                >
                  <div
                    className="modal-dialog modal-lg"
                    style={{ width: "1000px", maxWidth: "100%" }}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Products List</h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            clearLocations();
                            setActivePrs([]);
                            setTempProds([]);
                          }}
                        ></button>
                      </div>
                      <div className="modal-body" style={{ overflowY: "auto" }}>
                        <table className="table table-bordered text-center">
                          <thead className="table-primary">
                            <tr>
                              <th>S.No</th>
                              <th>Product Category</th>
                              <th>Product Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                  {getProductCatNameById(
                                    pr.prodCategory,
                                    productCateList
                                  )}
                                </td>
                                <td>{pr.productName}</td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() =>
                                      handlePrLocations(pr.productId, 0)
                                    }
                                  >
                                    Locations
                                  </button>
                                </td>
                              </tr>
                              {activePrs.includes(pr.productId) && (
                                <tr style={{ background: "#f8f8f8" }}>
                                  <td colSpan={7}>
                                    <table className="table table-bordered m-0">
                                      <thead className="table-secondary">
                                        <tr>
                                          <th></th>
                                          <td>Location ID</td>
                                          {/* <td>GRN No</td> */}
                                          <td>Location</td>
                                          <td>Batch No</td>
                                          <td>Pallet Ref No</td>
                                          <td>Available Qty</td>
                                          <td>Transfer Qty</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {productLocations.length > 0 ? (
                                          productLocations.map(
                                            (loc: any, j: number) => {
                                              if (loc.prodId === pr.productId) {
                                                return (
                                                  <tr>
                                                    <td>
                                                      <input
                                                        type="checkbox"
                                                        id={`md_checkbox_all${j}`}
                                                        className="chk-col-primary"
                                                        checked={updateOP.transferOrderDetails.some(
                                                          (detail) =>
                                                            detail.locId ===
                                                            loc.locationId
                                                        )}
                                                        onChange={(e) =>
                                                          handleLocCheckbox(
                                                            loc,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        htmlFor={`md_checkbox_all${j}`}
                                                        style={{
                                                          paddingLeft: "20px",
                                                          height: "15px",
                                                        }}
                                                      ></label>
                                                    </td>
                                                    <td>{loc.locationId}</td>
                                                    {/* <td>{loc.grnNum}</td> */}
                                                    <td>{loc.shelfId}</td>
                                                    <td>{loc.batchNo}</td>
                                                    <td>{loc.palletRefNum}</td>
                                                    <td>{loc.quantity}</td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        className="form-control text-start"
                                                        min={0}
                                                        max={loc.quantity}
                                                        onChange={(e) => {
                                                          handleTransferQtyChange(
                                                            e,
                                                            loc
                                                          );
                                                        }}
                                                        value={
                                                          updateOP.transferOrderDetails.find(
                                                            (detail) =>
                                                              detail.locId ===
                                                              loc.locationId
                                                          )?.quantity || ""
                                                        }
                                                      />
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={6}>
                                              Product not Available
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </>
                          </tbody>
                        </table>
                      </div>
                      <div className="modal-footer modal-footer-uniform">
                        <button
                          type="button"
                          className="btn btn-primary float-end"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            clearLocations();
                            setActivePrs([]);
                            setTempProds([]);
                          }}
                          disabled={updateOP.transferOrderDetails.some(
                            (detail) =>
                              detail.quantity < 1 ||
                              Number.isNaN(detail.quantity) ||
                              detail.quantity > maxQuantity
                          )}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <div className="modal modal-right fade overflow-auto" id="modal-right">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <input
                type="search"
                placeholder="Search Product"
                className="form-control"
              />
              <br />
              <ul className="list-group">
                {productsList?.length > 0 &&
                  productsList?.map((product: IProducts) => {
                    if (product?.selected) return;
                    const isChecked = pickedProducts.some(
                      (pickedProduct) =>
                        pickedProduct.productId === product.productId
                    );

                    return (
                      <li
                        className={`list-group-item "d-none"
                        }`}
                        key={product.productId}
                      >
                        <input
                          type="checkbox"
                          id={product?.productId?.toString()}
                          checked={isChecked}
                          onChange={() => handleCheckboxClick(product)} // Set checked based on presence in selectedProducts
                        />
                        <label htmlFor={product?.productId?.toString()}>
                          <b>{product?.productCode}</b> - {product?.productName}
                        </label>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-primary float-end"
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WarehouseTransferDetails;
