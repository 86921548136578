import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { IEmployeeRole, ISettingsContext } from "../../common/models";
import { SettingsService } from "../../api/services/settings.service";
import { IAccessControlAndPermissions } from "../../common/models/settings";
import { toast } from "react-toastify";
import { EmployeeService } from "../../api/services/employee.service";
import useAuth from "./useAuth";
import { isNullUndefined } from "../../common/methods/globalmethods";

let commonPermissionkeys = [
  "create",
  "view",
  "edit",
  "approval",
  "delete",
  "createLocations",
  "visible",
  "all",
  "download",
];

const useSettings = () => {
  const settingsService: SettingsService = new SettingsService();
  const employeeService: EmployeeService = new EmployeeService();

  const [useraccessPermission, setuseraccessPermission] =
    useState<IAccessControlAndPermissions>();
  const [useraccessPermissionUISample, setuseraccessPermissionUISample] =
    useState<IAccessControlAndPermissions>();
  const [userRoles, setuserRoles] = useState<IEmployeeRole[]>([]);
  const { setUserAccessPermissionsForCurrentUser } = useAuth();

  useEffect(() => {
    getUserAccessPermissionsForRole("UISAMPLE");
    getEmployeeRoles();
  }, []);

  const getUserAccessPermissionsForRole = async (roleCode: string) => {
    const sample = await employeeService.getUserAccessPermissionsForRole(
      roleCode
    );

    sample?.menuAndSubMenuAccess?.map((mainitem) => {
      let mainkeys = [...commonPermissionkeys, "updateStatus"];
      for (let i = 0; i < mainkeys.length; i++) {
        if (mainitem[mainkeys[i]] === "N") {
          mainitem["all"] = "N";
          break;
        } else {
          mainitem["all"] = "Y";
        }
      }
      mainitem?.subMenuAccess?.map((subitem) => {
        let subkeys = [...commonPermissionkeys, "update_status"];
        for (let i = 0; i < subkeys.length; i++) {
          if (subitem[subkeys[i]] === "N") {
            subitem["all"] = "N";
            break;
          } else {
            subitem["all"] = "Y";
          }
        }
      });
    });

    if (roleCode === "UISAMPLE") {
      setuseraccessPermissionUISample(sample);
    }
    setuseraccessPermission(sample);
  };

  const getEmployeeRoles = async () => {
    let roles = await employeeService.getEmployeeRoles();
    roles?.map((item: any) => {
      item.value = item.roleCode;
      item.label = item.roleName;
    });
    setuserRoles(roles);
  };

  const updateUserAccessPermissionByRole = async (rolecode: string) => {
    let data: IAccessControlAndPermissions = cloneDeep(useraccessPermission);
    if (data.roleCode === "UISAMPLE") {
      //create
      data.roleCode = rolecode;
      data = formatUseraccessPermissionRequest(data); //update
    }
    let result = await settingsService.updateUserAccessPermissionByRole(data);
    if (result) {
      let userrole = JSON.parse(window?.localStorage?.getItem("contextInfo"))
        ?.data?.designation;
      if (rolecode === userrole) {
        setUserAccessPermissionsForCurrentUser(userrole);
      }
      toast.success("User access permissions updated successfully!!");
    } else {
      toast.error("Error updating user access permissions!!");
    }
  };

  const formatUseraccessPermissionRequest = (
    data: IAccessControlAndPermissions
  ) => {
    delete data.id;
    for (let i = 0; i < data?.menuAndSubMenuAccess?.length; i++) {
      delete data.menuAndSubMenuAccess[i].id;
      delete data.menuAndSubMenuAccess[i].accessId;
      data?.menuAndSubMenuAccess[i]?.subMenuAccess?.map(
        (submenuitem, index) => {
          delete submenuitem.id;
          delete submenuitem.menuAccessId;
        }
      );
    }
    return data;
  };

  const updateUserAccessPermissions = (
    menuCode: string,
    key: string,
    value: string,
    isSubMenu: boolean = false,
    subMenuCode: string = ""
  ) => {
    let data: IAccessControlAndPermissions = cloneDeep(
      useraccessPermission ?? useraccessPermissionUISample
    );
    for (let i = 0; i < data?.menuAndSubMenuAccess?.length; i++) {
      let item = data?.menuAndSubMenuAccess[i];
      if (item?.menuCode === menuCode) {
        if (isSubMenu) {
          let submenuitems = item?.subMenuAccess;
          submenuitems?.map((submenuitem, index) => {
            if (submenuitem.subMenuCode === subMenuCode) {
              data.menuAndSubMenuAccess[i].subMenuAccess[index][key] = value;
            }
          });
        } else {
          data.menuAndSubMenuAccess[i][key] = value;
        }
        break;
      }
    }
    setuseraccessPermission(data);
  };

  const updateUserAccessPermissionsSelectall = (
    menuCode: string,
    value: string,
    isSubMenu: boolean = false,
    subMenuCode: string = ""
  ) => {
    let data: IAccessControlAndPermissions = cloneDeep(
      useraccessPermission ?? useraccessPermissionUISample
    );
    for (let i = 0; i < data?.menuAndSubMenuAccess?.length; i++) {
      let item = data?.menuAndSubMenuAccess[i];
      if (item?.menuCode === menuCode) {
        if (isSubMenu) {
          let submenuitems = item?.subMenuAccess;
          submenuitems?.map((submenuitem, index) => {
            if (submenuitem.subMenuCode === subMenuCode) {
              [...commonPermissionkeys, "update_status"]?.map((key) => {
                if (
                  !isNullUndefined(
                    data.menuAndSubMenuAccess[i].subMenuAccess[index][key]
                  )
                ) {
                  data.menuAndSubMenuAccess[i].subMenuAccess[index][key] =
                    value;
                }
              });
            }
          });
        } else {
          [...commonPermissionkeys, "updateStatus"]?.map((key) => {
            if (!isNullUndefined(data.menuAndSubMenuAccess[i][key])) {
              data.menuAndSubMenuAccess[i][key] = value;
            }
          });
        }
        break;
      }
    }
    setuseraccessPermission(data);
  };

  return {
    useraccessPermission,
    updateUserAccessPermissions,
    updateUserAccessPermissionByRole,
    userRoles,
    getUserAccessPermissionsForRole,
    useraccessPermissionUISample,
    updateUserAccessPermissionsSelectall,
  } as ISettingsContext;
};

export default useSettings;
