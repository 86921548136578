import { useContext, useEffect, useState } from "react";
import { GenericHelperService } from "../../api/generics/helper.service";
import { MasterService } from "../../api/services/master.service";
import { SamplingService } from "../../api/services/sampling.service";
import {
  IDocuments,
  IListConfig,
  IListConfigSampling,
  IProducts,
  IResult,
  ISamplingContext,
  IStatusUpdate,
} from "../../common/models";
import { AppContext } from "../contexts/app.context";
import { cloneDeep } from "lodash";
import { ISampling } from "../../common/models/sampling";
import {
  EmptyListConfig,
  EmptyListConfigSampling,
  EmptyStatusUpdate,
} from "../../common/constants/generalConstants";
import { toast } from "react-toastify";
import { IProductCategory } from "../../common/models/master";
import { ProductService } from "../../api/services/product.service";
import _ from "lodash";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import {
  getProductCode,
  isNullUndefined,
} from "../../common/methods/globalmethods";

const useSampling = () => {
  const samplingService: SamplingService = new SamplingService();
  const masterService: MasterService = new MasterService();
  const productService: ProductService = new ProductService();
  const { setLoader } = useContext(AppContext);
  const [samplingList, setSamplingList] = useState<ISampling[]>([]);
  const [samplingForm, setSamplingForm] = useState<ISampling>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [viewFiles, setViewFiles] = useState<IDocuments[]>([]);
  const [batchList, setBatchDetails] = useState([]);
  const [nextSa, setNextSa] = useState(null);
  const [productsList, setProductsList] = useState<IProducts[]>([]);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );
  const [samplingLogs, setSamplingLogs] = useState<ILogsData[]>([]);
  const [sampfilters, setSampFilters] = useState<IListConfigSampling>(
    EmptyListConfigSampling
  );

  useEffect(() => {
    setLoader(true);
    initData();
  }, []);

  useEffect(() => {
    if (sampfilters?.search) {
      querySampList();
    }
  }, [sampfilters?.search]);

  useEffect(() => {
    if (sampfilters?.search === true) {
      querySampList();
    } else if (sampfilters?.search === false) {
      getSamplingList();
    }
  }, [sampfilters?.search, filters.currentPage]);

  const querySampList = async () => {
    let tempfilter: IListConfigSampling = removeUnrequiredAttributes();
    if (Object.entries(tempfilter)?.length > 0) {
      let result = await samplingService.searchSampList(
        tempfilter,
        filters?.currentPage - 1
      );
      let _sampling: ISampling[] = result?.content ?? [];

      updateFilters(_sampling, result);
      setSamplingList(_sampling ?? []);
    }
  };

  const updateFilters = (data: ISampling[], result: IResult) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.searchQuery = "";
    _filters.totalRecords = result?.totalElements ?? data.length;
    _filters.totalPageCount =
      result?.totalPages ?? Math.ceil(data.length / _filters.currentPageSize);
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const removeUnrequiredAttributes = () => {
    let tempfilter: IListConfigSampling = cloneDeep(sampfilters);
    if (!isNullUndefined(tempfilter)) {
      for (const [key, value] of Object.entries(tempfilter)) {
        if (typeof key === "string" && _.isEmpty(value)) {
          delete tempfilter[key];
        } else if (typeof key === "boolean") {
          delete tempfilter[key];
        } else if (typeof key === "number" && value <= 0) {
          delete tempfilter[key];
        }
      }
    }
    return tempfilter;
  };

  const getBatchDetails = async () => {
    let prodsList = productsList;
    prodsList = await getProductsList(false);
    const _BatchList = await samplingService.getBatchDetails();
    _BatchList?.map((batchItem) => {
      batchItem?.batchDetails?.map((batchDetails) => {
        batchDetails.prodCode = getProductCode(batchDetails?.prodId, prodsList);
      });
    });

    setBatchDetails(_BatchList ?? []);
  };

  const initData = () => {
    getProductsList();
    getProductCategoriesList();
    getSamplingList();
    setSamplingForm(null);
    setSelectedList(EmptyStatusUpdate);
  };

  const getLogsSampling = async (id: number) => {
    const _logs: ILogsProps[] = await samplingService.getLogs(`QO${id}`);
    setSamplingLogs(_logs);
  };

  const getSamplingList = async () => {
    const result = await samplingService.getSampling(filters?.currentPage - 1);
    let _sampling: ISampling[] = result?.content ?? [];
    updateFilters(_sampling, result);
    setSamplingList(_sampling ?? []);
  };

  const getProductsList = async (updateSelection = true) => {
    const _products = await productService.getProducts();
    if (updateSelection) {
      _products?.map((item) => {
        item.selected = false;
      });
    }
    let _filteredProducts = _.filter(_products, { status: "ACTIVE" });
    setProductsList(_filteredProducts ?? []);
    return _filteredProducts;
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };

  const saveSampling = async (_newsample: any, callback: Function) => {
    const _samplingSave: boolean = await samplingService.saveSampling(
      _newsample
    );
    if (_samplingSave) {
      if (typeof _samplingSave == "string") {
        toast.error(_samplingSave);
      } else {
        toast.success("Sampling saved successfully.");
        initData();
        callback();
        setSamplingForm(null);
      }
    } else {
      toast.error("Error saving Sampling.");
    }
  };

  const updateStatus = async () => {
    const _samplingSave: boolean = await samplingService.updateStatus(
      selectedList
    );
    if (_samplingSave) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };

  const clearViewSampling = () => {
    setSamplingForm(null);
    setViewFiles([]);
  };

  const newSampling = () => {
    //setSamplingForm(cloneDeep(NewSampling));
  };

  const deleteSampling = async () => {
    const _deleteItem: ISampling = cloneDeep(samplingForm);
    _deleteItem.deleted = true;

    const _itemSave = await samplingService.saveSampling(_deleteItem);
    if (_itemSave) {
      toast.success("Sampling deleted successfully.");
      initData();
    } else {
      toast.error("Error deleting Sampling.");
    }
  };

  const getNextSaNumber = async () => {
    const _saNo = await samplingService.getNextSaNumber();
    if (_saNo) {
      setNextSa(_saNo);
    } else {
      toast.error("Error getting next sample number");
    }
  };
  const clearSaNumber = () => {
    setNextSa(null);
  };

  return {
    samplingList,
    filters,
    viewFiles,
    samplingForm,
    selectedList,
    setSelectedList,
    setFilters,
    setSamplingForm,
    newSampling,
    deleteSampling,
    setViewFiles,
    setUploadedFiles,
    clearViewSampling,
    saveSampling,
    updateStatus,
    getBatchDetails,
    batchList,
    getNextSaNumber,
    nextSa,
    clearSaNumber,
    productsList,
    productCateList,
    samplingLogs,
    getLogsSampling,
    sampfilters,
    setSampFilters,
    querySampList,
  } as ISamplingContext;
};

export default useSampling;
