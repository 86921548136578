import { useEffect, useState } from "react";
import { MasterService } from "../../api/services/master.service";
import { IDosage, IProductCategory } from "../../common/models/master";

const useMaster = () => {
  const masterService: MasterService = new MasterService();
  const [productCategory, setProductCategory] = useState<IProductCategory[]>(
    []
  );
  const [dosageForm, setDosageForm] = useState<IDosage[]>([]);

  const initData = async () => {
    const _productCategories = await masterService.getProductCategories();
    const _dosageForms = await masterService.getDosageForms();
    setProductCategory(_productCategories);
    setDosageForm(_dosageForms);
  };

  return { productCategory, dosageForm, refreshData: initData };
};

export default useMaster;
