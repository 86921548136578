import React from "react";
import BoxItemWithValue from "./BoxItemWithValue";

export default function BoxItemWithKeyValue(key, value, styleval = "") {
  return (
    <div
      className={`row show-grid ${styleval}`}
      style={{ borderColor: "green" }}
    >
      <div
        className="col-5 text-start text-start"
        style={{ margin: 0, padding: 0 }}
      >
        {BoxItemWithValue(key, "pe-10 ps-10", null)}
      </div>
      <div className="col-7" style={{ margin: 0, padding: 0 }}>
        {BoxItemWithValue(value, "ps-10", null, null)}
      </div>
    </div>
  );
}
