import React from "react";
import { Outlet } from "react-router-dom";
import useOrderPicking from "../../controllers/hooks/useOrderPicking";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import useWarehouse from "../../controllers/hooks/useWarehouse";

const WarehouseTransferLayoutComponent = () => {
  const orderPickingFunctions = useOrderPicking();
  const wrhFunctions = useWarehouse();

  return (
    <WarehouseContext.Provider value={wrhFunctions}>
      <OrderPickingContext.Provider value={orderPickingFunctions}>
        <Outlet />
      </OrderPickingContext.Provider>
    </WarehouseContext.Provider>
  );
};

export default WarehouseTransferLayoutComponent;
