import { useContext, useEffect, useState } from "react";
import {
  IPurchaseContext,
  IProducts,
  IStatusUpdate,
  IListConfig,
  ISupplier,
  IListConfigPO,
  IResult,
} from "../../common/models";
import { IPurchase } from "../../common/models/purchase";
import { cloneDeep } from "lodash";
import { ProductService } from "../../api/services/product.service";
import { AppContext } from "../contexts/app.context";
import {
  EmptyListConfig,
  EmptyListPOSearchConfig,
  EmptyStatusUpdate,
  NewCustomer,
} from "../../common/constants/generalConstants";
import { toast } from "react-toastify";
import { PurchaseService } from "../../api/services/PurchaseService.service";
import { SupplierService } from "../../api/services/supplier.service";
import _ from "lodash";
import { IProductCategory } from "../../common/models/master";
import { MasterService } from "../../api/services/master.service";
import { isNullUndefined } from "../../common/methods/globalmethods";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import { SamplingService } from "../../api/services/sampling.service";

const usePurchase = () => {
  const supplierService: SupplierService = new SupplierService();
  const productService: ProductService = new ProductService();
  const purchaseService: PurchaseService = new PurchaseService();
  const masterService: MasterService = new MasterService();
  const samplingService: SamplingService = new SamplingService();

  const [productsList, setProductsList] = useState<IProducts[]>([]);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );
  const [supplierList, setSupplierList] = useState<ISupplier[]>([]);
  const [supplierAllList, setSupplierAllList] = useState<ISupplier[]>([]);

  const { setLoader } = useContext(AppContext);
  const [purchaseOrderList, setPurchaseOrderList] = useState<IPurchase[]>([]);
  const [purchaseForm, setPurchaseForm] = useState<IPurchase>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [nextPo, setNextPo] = useState(null);
  const [pofilters, setPOFilters] = useState<IListConfigPO>(
    EmptyListPOSearchConfig
  );
  const [purchaseLogs, setPurchaseLogs] = useState<ILogsData[]>([]);

  const getPurchaseLogs = async (id: number) => {
    const _logs: ILogsProps[] = await samplingService.getLogs(`PO${id}`);
    setPurchaseLogs(_logs);
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoader(true);
    setPurchaseForm(null);
    setSelectedList(EmptyStatusUpdate);
  }, []);

  useEffect(() => {
    if (pofilters?.search === true) {
      queryPOData();
    } else if (pofilters?.search === false) {
      if (!isNullUndefined(supplierList) && supplierList?.length > 0)
        getPurchaseOrderList(supplierList);
    }
  }, [pofilters?.search, filters.currentPage]);

  const initData = async () => {
    getProductsList();
    getProductCategoriesList();
    await getSupplierList();
    getSupplierAllList((suppliers: ISupplier[]) => {
      getPurchaseOrderList(suppliers);
      queryPOData(suppliers);
    });
  };

  const getProductsList = async () => {
    const _products = await productService.getProducts();
    _products?.map((item) => {
      item.selected = false;
    });
    let _filteredProducts = _.filter(_products, { status: "ACTIVE" });
    setProductsList(_filteredProducts ?? []);
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };

  const getSupplierList = async (callback: Function = null) => {
    await supplierService.getSupplier().then((_Supplier) => {
      let _filteredSupplier = _.filter(_Supplier, { status: "ACTIVE" });
      setSupplierList(_filteredSupplier ?? []);
      if (callback !== null) {
        callback(_filteredSupplier);
      }
    });
  };

  const getSupplierAllList = async (callback: Function) => {
    await supplierService.getSupplierAllList().then((_Supplier) => {
      setSupplierAllList(_Supplier);
      if (callback !== null) {
        callback(_Supplier);
      }
    });
  };

  const removeUnrequiredPOAttributes = () => {
    let tempPOfilter: IListConfigPO = cloneDeep(pofilters);
    if (!isNullUndefined(tempPOfilter)) {
      for (const [key, value] of Object.entries(tempPOfilter)) {
        if (typeof key === "string" && _.isEmpty(value)) {
          delete tempPOfilter[key];
        } else if (typeof key === "boolean") {
          delete tempPOfilter[key];
        } else if (typeof key === "number" && value <= 0) {
          delete tempPOfilter[key];
        }
      }
    }
    return tempPOfilter;
  };

  const queryPOData = async (suppliers: ISupplier[] = null) => {
    let tempPOfilter: IListConfigPO = removeUnrequiredPOAttributes();
    if (Object.entries(tempPOfilter)?.length > 0) {
      await purchaseService
        .searchPOOrders(tempPOfilter, filters?.currentPage - 1)
        .then((result) => {
          if (!isNullUndefined(result)) {
            let _filteredList: IPurchase[] = result?.content;
            _filteredList = updateSupplierName(_filteredList, suppliers);
            updateFiltersByData(_filteredList, result);
            setPurchaseOrderList(_filteredList ?? []);
          }
        });
    }
  };

  const getPurchaseOrderList = async (supplierList: ISupplier[]) => {
    await purchaseService
      .getPurchase(filters?.currentPage - 1)
      .then((result) => {
        let _customer: IPurchase[] = result?.content ?? [];
        _customer = updateSupplierName(_customer, supplierList);
        updateFiltersByData(_customer, result);
        setPurchaseOrderList(_customer ?? []);
      });
  };

  const updateFiltersByData = (podata: IPurchase[], result: IResult) => {
    // console.log("_customer",_customer?.content)
    const _filters: IListConfig = cloneDeep(filters);
    _filters.searchQuery = "";
    _filters.totalRecords = result?.totalElements ?? podata.length;
    _filters.totalPageCount =
      result?.totalPages ?? Math.ceil(podata.length / _filters.currentPageSize);
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const updateSupplierName = (
    _customers: IPurchase[],
    suppliers: ISupplier[]
  ) => {
    if (suppliers === null || suppliers?.length <= 0)
      suppliers = supplierAllList;
    _customers.map((customer) => {
      customer.supplierName =
        suppliers?.find((item) => item?.supId === customer?.supplierId)
          ?.supName ?? "";
    });
    return _customers;
  };

  const viewPurchase = async (item: IPurchase) => {
    setPurchaseForm(item);
  };
  const clearViewPurchase = () => {
    setPurchaseForm(null);
  };

  const savePurchaseOrder = async (_purchaseOrder) => {
    const _customerSave = await purchaseService.savePurchase(_purchaseOrder);
    if (_customerSave) {
      if (typeof _customerSave == "string") {
        toast.error(_customerSave);
      } else {
        toast.success("Purchase order saved successfully.");
        initData();
      }
    } else {
      toast.error("Error saving Purchase order.");
    }
  };

  const createNewPurchaseOrder = async (_newPurchaseOrder, callback) => {
    const _poSave = await purchaseService.createNewPurchaseOrder(
      _newPurchaseOrder
    );
    if (_poSave) {
      if (typeof _poSave == "string") {
        toast.error(_poSave);
      } else {
        toast.success("Purchase order saved successfully.");
        initData();
        callback();
      }
    } else {
      toast.error("Error saving purchase order.");
    }
  };

  const newPurchaseOrder = () => {
    setPurchaseForm(cloneDeep(NewCustomer));
  };

  const updateStatus = async () => {
    const _updateStatus: boolean = await purchaseService.updateStatus(
      selectedList
    );
    console.log("jkdfgkbfkdjsgk", _updateStatus);
    if (_updateStatus) {
      if (typeof _updateStatus == "string") {
        toast.error(_updateStatus);
      } else {
        toast.success("Status updated successfully.");
        initData();
      }
    } else {
      toast.error("Error updating status.");
    }
  };

  const deletePurchase = async () => {
    const _deleteItem: IPurchase = cloneDeep(purchaseForm);
    _deleteItem.deleted = true;
    const _itemSave = await purchaseService.savePurchase(_deleteItem);
    if (_itemSave) {
      if (typeof _itemSave == "string") {
        toast.error(_itemSave);
      } else {
        toast.success("Purchase order deleted successfully.");
        initData();
      }
    } else {
      toast.error("Error deleting purchase order.");
    }
  };

  const getNextPoNumber = async () => {
    const _poNo = await purchaseService.getNextPoNumber();
    if (_poNo) {
      setNextPo(_poNo);
    } else {
      toast.error("Error getting next PO number");
    }
  };
  const clearPoNumber = () => {
    setNextPo(null);
  };

  return {
    purchaseOrderList,
    productsList,
    productCateList,
    supplierList,
    filters,
    purchaseForm,
    selectedList,
    setSelectedList,
    setFilters,
    setPurchaseForm,
    newPurchaseOrder,
    savePurchaseOrder,
    updateStatus,
    deletePurchase,
    viewPurchase,
    clearViewPurchase,
    createNewPurchaseOrder,
    getNextPoNumber,
    nextPo,
    clearPoNumber,
    getProductsList,
    getProductCategoriesList,
    pofilters,
    setPOFilters,
    getPurchaseLogs,
    purchaseLogs,
    queryPOData,
  } as IPurchaseContext;
};

export default usePurchase;
