import { Outlet } from "react-router-dom";
import { PurchaseContext } from "../../controllers/contexts/purchase.context";
import usePurchase from "../../controllers/hooks/usePurchase";

const PurchaseOrderLayoutComponent = () => {
  const PurchaseFunctions = usePurchase();

  return (
    <PurchaseContext.Provider value={PurchaseFunctions}>
      <Outlet />
    </PurchaseContext.Provider>
  );
};

export default PurchaseOrderLayoutComponent;
