import { useContext, useState } from "react";
import { AuthService } from "../../api/services/auth.service";
import { toast } from "react-toastify";
import {
  IChangePwdRequest,
  IContextInfo,
  IForgotPwdRequest,
  ILoginRequest,
} from "../../common/models";
import {
  EmptyChangePwdRequest,
  EmptyForgotPwdRequest,
  EmptyLoginRequest,
} from "../../common/constants/generalConstants";
import { AppContext } from "../contexts/app.context";
import { EmployeeService } from "../../api/services/employee.service";
import { IAccessControlAndPermissions } from "../../common/models/settings";
import { RoutingConstants } from "../../common/constants/routingConstants";

const useAuth = () => {
  const authService: AuthService = new AuthService();
  const employeeService: EmployeeService = new EmployeeService();
  const { setUserContext } = useContext(AppContext);
  const [loginRequest, setLoginRequest] =
    useState<ILoginRequest>(EmptyLoginRequest);
  const [forgotPwdRequest, setForgotPwdRequest] = useState<IForgotPwdRequest>(
    EmptyForgotPwdRequest
  );
  const [changePwdRequest, setChangePwdRequest] = useState<IChangePwdRequest>(
    EmptyChangePwdRequest
  );
  const [userRoleInfo, setuserRoleInfo] =
    useState<IAccessControlAndPermissions>(
      JSON.parse(window.localStorage.getItem("userAccess"))
    );

  const authenticateUser = async (
    shouldContinueWithLoginRoute = true
  ): Promise<boolean> => {
    // Check if the username and password are correct
    delete loginRequest["passcode"];
    const apiResponse = await authService.authenticate(loginRequest);
    console.log(JSON.stringify(apiResponse));
    if (apiResponse?.status === 200 && apiResponse?.data?.empId) {
      if (!shouldContinueWithLoginRoute) {
        setDataVariables(apiResponse, false);
      }
      return true;
    } else {
      toast.error("Incorrect username or password. Please try again.");
      return false;
    }
  };

  const forgotPasswordStep1 = async (): Promise<boolean> => {
    const { username, passcode } = forgotPwdRequest;
    const apiResponse = await authService.forgotPasswordStep1(
      username,
      passcode
    );
    console.log(JSON.stringify(apiResponse));
    if (apiResponse?.status === 200) {
      return true;
    } else {
      toast.error(
        apiResponse.response?.data?.errorMessage ?? "Reset password failed"
      );
      return false;
    }
  };

  const passwordReset = async (): Promise<boolean> => {
    const { username, newPassword } = forgotPwdRequest;
    const apiResponse = await authService.passwordReset(username, newPassword);
    console.log(JSON.stringify(apiResponse));
    if (apiResponse?.status === 200) {
      toast.success("Reset password success");
      setForgotPwdRequest(EmptyForgotPwdRequest);
      return true;
    } else {
      toast.error(
        apiResponse.response?.data?.errorMessage ?? "Reset password failed"
      );
      return false;
    }
  };

  const changePasswordStep1 = async (): Promise<boolean> => {
    const { username, oldPassword, passcode } = changePwdRequest;
    const apiResponse = await authService.changePasswordStep1(
      username,
      oldPassword,
      passcode
    );
    console.log(JSON.stringify(apiResponse));
    if (apiResponse?.status === 200) {
      return true;
    } else {
      toast.error(
        apiResponse.response?.data?.errorMessage ?? "Change password failed"
      );
      return false;
    }
  };

  const changePassword = async (): Promise<boolean> => {
    const { username, newPassword } = changePwdRequest;
    const apiResponse = await authService.passwordReset(username, newPassword);
    console.log(JSON.stringify(apiResponse));
    if (apiResponse?.status === 200) {
      toast.success("Change password success");
      setChangePwdRequest(EmptyChangePwdRequest);
      return true;
    } else {
      toast.error(
        apiResponse.response?.data?.errorMessage ?? "Change password failed"
      );
      return false;
    }
  };

  const setDataVariables = async (
    _authResult,
    shouldContinueWithLoginRoute = true
  ) => {
    window.localStorage.setItem("contextInfo", JSON.stringify(_authResult));
    setUserContext(_authResult);
    await setUserAccessPermissionsForCurrentUser(
      _authResult?.data?.designation
    );
    if (shouldContinueWithLoginRoute) {
      window.location.href = RoutingConstants.dashboard;
    }
  };

  const validatePasscode = async () => {
    // Continue with the passcode validation
    // delete loginRequest["password"];
    try {
      const _authResult = await authService.authenticate(loginRequest);
      if (_authResult?.status === 200 && _authResult?.data?.empId) {
        //Do not change below order of setters
        setDataVariables(_authResult);
        toast.success("Logged in successfully.");
      } else {
        toast.error("Invalid passcode.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error("Error logging in.");
    }
  };

  const setUserAccessPermissionsForCurrentUser = async (roleCode: string) => {
    const accesspermissons =
      await employeeService.getUserAccessPermissionsForRole(roleCode);
    window.localStorage.setItem(
      "userAccess",
      JSON.stringify(accesspermissons ?? {})
    );
    setuserRoleInfo(JSON.parse(window.localStorage.getItem("userAccess")));
  };

  const isAccessDisabled = (
    menuCode: string,
    key: string,
    isSubMenu: boolean = false,
    subMenuCode: string = ""
  ) => {
    let data = userRoleInfo;
    let result = true;
    for (let i = 0; i < data?.menuAndSubMenuAccess?.length; i++) {
      let item = data?.menuAndSubMenuAccess[i];
      if (item?.menuCode === menuCode) {
        if (isSubMenu) {
          let submenuitems = item?.subMenuAccess;
          submenuitems?.map((submenuitem, index) => {
            if (submenuitem.subMenuCode === subMenuCode) {
              result = checkIfDisabled(
                data.menuAndSubMenuAccess[i].subMenuAccess[index][key]
              );
            }
          });
        } else {
          result = checkIfDisabled(data.menuAndSubMenuAccess[i][key]);
        }
        break;
      }
    }
    return result;
  };

  const checkIfDisabled = (item) => {
    let result = true;
    if (item === "Y") result = false;
    return result;
  };

  return {
    loginRequest,
    setLoginRequest,
    authenticateUser,
    validatePasscode,
    forgotPwdRequest,
    setForgotPwdRequest,
    passwordReset,
    changePwdRequest,
    setChangePwdRequest,
    changePassword,
    forgotPasswordStep1,
    changePasswordStep1,
    isAccessDisabled,
    setUserAccessPermissionsForCurrentUser,
  };
};

export default useAuth;
