import { NavLink } from "react-router-dom";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import GRNListingOrdersComponent from "./grn_list_orders.component";
const GrnListComponent = () => {
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.GRN}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.OrderIn}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.ListGRN}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="tab-content">
          <div className="tab-pane active" id="warehouseHome" role="tabpanel">
            <GRNListingOrdersComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default GrnListComponent;
