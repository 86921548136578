import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../common/constants/labelConstants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { GenericHelperService } from "../../api/generics/helper.service";
import { SamplingContext } from "../../controllers/contexts/sampling.context";
import { toast } from "react-toastify";
import { SamplingStatuses } from "../../common/constants/generalConstants";
import {
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import moment from "moment";

const SamplingDetailsComponent = () => {
  const location = useLocation();
  const { samplingForm, saveSampling, productCateList, productsList } =
    useContext(SamplingContext);
  const navigate = useNavigate();
  const [saDetails, setSaDetails] = useState(samplingForm);
  const isEditableSa = location.state.isEditable;
  useEffect(() => {
    if (saDetails === null) {
      navigate(RoutingConstants.sampling);
    }
  }, [saDetails]);
  const handleSampleQtyChange = (e: any, samLoc: any) => {
    if (JSON.parse(e.target.value) <= samLoc.locQty) {
      let _newSample = { ...saDetails };
      _newSample.samplingDetails.map((sampleLoc: any) => {
        if (sampleLoc.locationId === samLoc.locationId) {
          sampleLoc.samplingQty = e.target.value;
        }
      });
      setSaDetails(_newSample);
    } else {
      toast.error("Invalid Sample quantity");
    }
  };
  const handleSampleChange = (e: any, key: string) => {
    let _newSample = { ...saDetails };
    _newSample[key] = e.target.value;
    setSaDetails(_newSample);
  };
  const handleSaveBtn = () => {
    if (saDetails) {
      if (saDetails.totalSamplingQty && saDetails.totalSamplingQty > 0) {
        console.log("final", saDetails);
        saveSampling(saDetails, () => {
          navigate(RoutingConstants.sampling);
        });
      } else {
        toast.error("Please fill mandatory fields");
      }
    } else {
      toast.error("Please select batch number");
    }
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${LabelConstants.Customer} ${LabelConstants.Details}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.sampling}
                      title={LabelConstants.Sampling}
                    >
                      {LabelConstants.Sampling}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Sampling} ${LabelConstants.Details}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {saDetails ? (
        <section className="content">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="box">
                <div className="box-header  bg-primary d-flex justify-content-between">
                  <h4 className="box-title">Sampling Overview </h4>
                  {/* <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modal-right">
            </button> */}
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="table-primary">
                        <tr>
                          <th>S.No</th>
                          <th>Product Category</th>
                          <th>Product Name</th>
                          <th>Location</th>
                          <th>Batch.Qty</th>
                          <th>Sample.Qty</th>
                          <th>Final.Qty</th>
                          {/* <th>Status</th> */}
                          {/* <th className="text-align:center">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                    <td>1</td>
                    <td>{getProductCatNameById(saDetails.prodCatId, productCateList)}</td>
                    <td>{getProductNameById(saDetails.prodId,productsList)} </td>
                    <td>{saDetails.locId}</td>
                    <td>{saDetails.locQty}</td>
                    <td>{<input type="number" readOnly={isEditableSa && saDetails.status === 'OPEN' ? false : true} className="form-control" value={saDetails.samplingQty} onChange={(e)=>handleSampleQtyChange(e)} />}</td>
                    <td>{saDetails.finalQty}</td>
                    <td>{saDetails.status}</td>
                    </tr> */}
                        {saDetails.samplingDetails.map(
                          (samLoc: any, i: number) => {
                            return (
                              <tr>
                                <td>1</td>
                                <td>
                                  {getProductCatNameById(
                                    saDetails.prodCatId,
                                    productCateList
                                  )}
                                </td>
                                <td>
                                  {getProductNameById(
                                    saDetails.prodId,
                                    productsList
                                  )}{" "}
                                </td>
                                <td>{samLoc.shelfId}</td>
                                <td>{samLoc.locQty}</td>
                                <td>
                                  {
                                    <input
                                      type="number"
                                      readOnly={
                                        isEditableSa &&
                                        saDetails.status === "OPEN"
                                          ? false
                                          : true
                                      }
                                      className="form-control"
                                      value={samLoc.samplingQty}
                                      onChange={(e) =>
                                        handleSampleQtyChange(e, samLoc)
                                      }
                                    />
                                  }
                                </td>
                                <td>{samLoc.locQty - samLoc.samplingQty}</td>
                                {/* <td>{saDetails.status}</td> */}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        navigate(RoutingConstants.sampling);
                      }}
                    >
                      Close
                    </button>
                    {isEditableSa && (
                      <button
                        type="button"
                        className="btn btn-success float-end"
                        onClick={() => handleSaveBtn()}
                      >
                        Save changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="box">
                <div className="box-header bg-primary p-4">
                  <h4 className="box-title">BatchDetails</h4>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-4">
                      <label className="form-label" htmlFor="BatchNumber">
                        {LabelConstants.BatchNumber}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={saDetails.batchNum}
                      />
                    </div>
                  </div>
                  <div className="row mt-20">
                    <div className="col-4">
                      <label className="form-label" htmlFor="samplignumber">
                        {LabelConstants.SampligNumber}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        className="form-control"
                        value={saDetails.samplingNum}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mt-20">
                    <div className="col-4">
                      <label className="form-label" htmlFor="Status">
                        {LabelConstants.Status}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-8">
                      <select
                        disabled={!isEditableSa}
                        className="form-control"
                        value={saDetails.status}
                        onChange={(e) => handleSampleChange(e, "status")}
                      >
                        {/* {SamplingStatuses.map((_status: string, _statusIdx: number) => {
                        return <option key={`statusOption${_statusIdx}`} value={_status}>{_status}</option>
                    })} */}
                        {saDetails.status === "OPEN" ? (
                          <>
                            <option value={"OPEN"}>OPEN</option>
                            <option value={"PASS"}>PASS</option>
                            <option value={"FAIL"}>FAIL</option>
                          </>
                        ) : saDetails.status === "PASS" ? (
                          <option value={"PASS"}>PASS</option>
                        ) : saDetails.status === "FAIL" ? (
                          <option value={"FAIL"}>FAIL</option>
                        ) : (
                          ""
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-20">
                    <div className="col-4">
                      <label
                        className="form-label"
                        htmlFor="goodsReceiptNumber"
                      >
                        {LabelConstants.CreatedDate}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={moment(saDetails.createdDate).format('DD/MM/YYYY')}
                      />
                    </div>
                  </div>
                  <div className="row mt-20">
                    <div className="col-4">
                      <label className="form-label" htmlFor="Description">
                        {LabelConstants.Description}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-8">
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        value={saDetails.desc}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default SamplingDetailsComponent;
