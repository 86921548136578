import {
  OrdersApiBase,
  OrderPickingList,
  ProductsBySO,
  ProductLocations,
  createOrderPicking,
  getNextOpNumber,
  updateOrderPicking,
  OPStatusUpdate,
  searchOPByCriteria,
  createNewTransferOrderWarehouse,
  updateTransferWarehouse,
  WarehouseTransferList,
  getNextTONumber,
} from "../../common/constants/apiConstants";
import {
  IBatchManagement,
  IListConfigOP,
  IStatusUpdate,
} from "../../common/models";
import { AxiosService } from "../generics/axios.service";
import { IOrderPickingService } from "../interfaces/IOrderPicking";

export class OrderPickingService implements IOrderPickingService {
  private axiosService: AxiosService = new AxiosService();

  async getWarehouseTransferOrdersList(): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Get(
        WarehouseTransferList,
        OrdersApiBase
      );
      const _batchAllList: any = result.data?.content ?? [];
      return _batchAllList;
    } catch (error) {
      console.error("Error: Order warehouse  List - ", error);
      return [];
    }
  }

  async getProductsBySO(so: string): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Get(
        ProductsBySO(so),
        OrdersApiBase
      );
      const _productsList: any = result.data?.soProductDetails ?? [];
      console.log("PR List - ", _productsList);
      return _productsList;
    } catch (error) {
      console.error("Error: PR  List - ", error);
      return [];
    }
  }
  async getPickingOrdersList(pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(
        OrderPickingList(pageNo),
        OrdersApiBase
      );
      return result.data ?? null;
    } catch (error) {
      console.error("Error: Order Picking  List - ", error);
      return null;
    }
  }

  async searchOPList(filter: IListConfigOP, pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        searchOPByCriteria(pageNo),
        filter,
        OrdersApiBase
      );
      return result?.data ?? null;
    } catch (error) {
      console.error("Error: searchOPList List - ", error);
      return null;
    }
  }
  async createNewTransferOrderWarehouse(transferOrder: any): Promise<boolean> {
    try {
      const result: any = await this.axiosService.Post(
        createNewTransferOrderWarehouse,
        transferOrder,
        OrdersApiBase
      );
      if (result) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error: save OP - ", error);
      return false;
    }
  }
  async updateNewOrderPicking(newOP: any): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Put(
        updateOrderPicking(newOP.id),
        newOP,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: save OP - ", error);
      return error.response.data?.errorMessage ?? "Error saving Order Picking.";
    }
  }

  async updateTransferWarehouse(updateTransfer: any): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Put(
        updateTransferWarehouse(updateTransfer.id),
        updateTransfer,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: save OP - ", error);
      return error.response.data?.errorMessage ?? "Error saving Order Picking.";
    }
  }
  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Post(OPStatusUpdate, status, OrdersApiBase);
      return true;
    } catch (error) {
      console.error(
        "Error: Update OP Status - ",
        error.response.data[0]?.errorMessage
      );
      return error.response.data[0]?.errorMessage ?? false;
    }
  }
  async getProductLocations(
    type: any,
    prId: any,
    qty: any,
    whId: any
  ): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Get(
        ProductLocations(type, prId, qty, whId),
        OrdersApiBase
      );
      const _prLocations: any = result.data ?? [];
      console.log("PR locations List - ", _prLocations);
      return _prLocations;
    } catch (error) {
      console.error("Error: PR locations List - ", error);
      return [];
    }
  }
  async createNewOrderPicking(newOP: any): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Post(
        createOrderPicking,
        newOP,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: save OP - ", error);
      return (
        error.response.data?.errorMessage ?? "Error saving new Order Picking."
      );
    }
  }
  async getNextOpNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextOpNumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next OP Number - ", error);
      return [];
    }
  }
  async getNextToNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextTONumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next TO Number - ", error);
      return [];
    }
  }
}
