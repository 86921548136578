import { NavLink, useLocation } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../controllers/contexts/settings.context";
import {
  SettingsData,
  UserAccessKeys,
} from "../../common/constants/generalConstants";
import { IAccessControlAndPermissions } from "../../common/models/settings";
import { Checkbox } from "@mui/material";
import Select from "react-select";
import { toast } from "react-toastify";
import { isNullUndefined } from "../../common/methods/globalmethods";

const SettingsComponent = () => {
  const {
    useraccessPermission,
    updateUserAccessPermissions,
    updateUserAccessPermissionByRole,
    userRoles,
    getUserAccessPermissionsForRole,
    useraccessPermissionUISample,
    updateUserAccessPermissionsSelectall,
  } = useContext(SettingsContext);
  const [rolesData, setrolesData] = useState<IAccessControlAndPermissions>();
  const [userrole, setuserrole] = useState("");
  const location = useLocation();

  useEffect(() => {
    let data = useraccessPermission;
    if (isNullUndefined(useraccessPermission)) {
      data = useraccessPermissionUISample;
    }
    setrolesData(data);
  }, [useraccessPermission]);

  const checkStatus = (item) => {
    let result = false;
    if (item === "Y") result = true;
    return result;
  };

  const showCheckBox = (
    menucode: string,
    keystring: string,
    value: string,
    issubmenu: boolean = false,
    submenucode: string = ""
  ) => {
    if (isNullUndefined(value)) return;
    return (
      <Checkbox
        checked={checkStatus(value)}
        onClick={() => {
          if (userrole !== "") {
            if (keystring === "all") {
              updateUserAccessPermissionsSelectall(
                menucode,
                value === "Y" ? "N" : "Y",
                issubmenu,
                submenucode
              );
            } else {
              updateUserAccessPermissions(
                menucode,
                keystring,
                value === "Y" ? "N" : "Y",
                issubmenu,
                submenucode
              );
            }
          } else {
            toast.error("Select designation!!");
          }
        }}
      />
    );
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="col-lg-10">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {
                      SettingsData.find(
                        (item) => item?.path === location?.pathname
                      )?.name
                    }
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="content-header" style={{ height: 100 }}>
        <div className="box">
          <div className="box-body">
            <div className="col-lg-12 d-flex">
              <div className="col-4"></div>
              <div className="col-4 ms-10">
                <Select
                  value={userRoles?.filter(
                    (option: any) => option.value === userrole
                  )}
                  placeholder="Designation"
                  options={userRoles}
                  isSearchable={true}
                  onChange={(e: any) => {
                    let role = e.value;
                    setuserrole(role);
                    if (role !== "") {
                      getUserAccessPermissionsForRole(e.value);
                    }
                  }}
                />
              </div>
              <div className="input-group w-p10 ms-10">
                <button
                  type="button"
                  className="waves-effect waves-light btn btn-success rounded"
                  onClick={() => {
                    if (userrole !== "" && !isNullUndefined(rolesData)) {
                      updateUserAccessPermissionByRole(userrole);
                    } else {
                      toast.error("Unable to proceed!!");
                    }
                  }}
                >
                  {LabelConstants.Save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-bordered mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <thead className="table-primary">
                      <tr>
                        {UserAccessKeys?.map((item) => (
                          <th style={{ maxWidth: 50 }}>{item.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {rolesData?.menuAndSubMenuAccess?.map((item) => (
                        <>
                          <tr key={"test"}>
                            <td style={{ textAlign: "left" }}>
                              {item?.description}
                            </td>
                            <td>
                              {showCheckBox(
                                item.menuCode,
                                "create",
                                item?.create
                              )}
                            </td>
                            <td>
                              {showCheckBox(item.menuCode, "view", item?.view)}
                            </td>
                            <td>
                              {showCheckBox(item.menuCode, "edit", item?.edit)}
                            </td>
                            <td>
                              {showCheckBox(
                                item.menuCode,
                                "approval",
                                item?.approval
                              )}
                            </td>
                            <td>
                              {showCheckBox(
                                item.menuCode,
                                "delete",
                                item?.delete
                              )}
                            </td>
                            <td>
                              {showCheckBox(
                                item.menuCode,
                                "updateStatus",
                                item?.updateStatus
                              )}
                            </td>
                            <td>
                              {showCheckBox(
                                item.menuCode,
                                "createLocations",
                                item?.createLocations
                              )}
                            </td>
                            <td>
                              {showCheckBox(
                                item.menuCode,
                                "visible",
                                item?.visible
                              )}
                            </td>
                            <td>
                              {showCheckBox(
                                item.menuCode,
                                "download",
                                item?.download
                              )}
                            </td>
                            <td>
                              {showCheckBox(item.menuCode, "all", item?.all)}
                            </td>
                          </tr>
                          {item?.subMenuAccess?.map((submenuitem) => (
                            <tr key={"test1"}>
                              <td
                                style={{ textAlign: "left", paddingLeft: 30 }}
                              >{` - ${submenuitem?.description}`}</td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "create",
                                  submenuitem?.create,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "view",
                                  submenuitem?.view,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "edit",
                                  submenuitem?.edit,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "approval",
                                  submenuitem?.approval,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "delete",
                                  submenuitem?.delete,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "update_status",
                                  submenuitem?.update_status,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                              <td></td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "visible",
                                  submenuitem?.visible,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "download",
                                  submenuitem?.download,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                              <td>
                                {showCheckBox(
                                  item.menuCode,
                                  "all",
                                  submenuitem?.all,
                                  true,
                                  submenuitem?.subMenuCode
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsComponent;
