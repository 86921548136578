import { useEffect, useState } from "react";
import {
  ICustomer,
  IListConfig,
  IListConfigOP,
  IOrderPickingContext,
  IProducts,
  IResult,
  IStatusUpdate,
} from "../../common/models";
import { ProductService } from "../../api/services/product.service";
import { MasterService } from "../../api/services/master.service";
import { IProductCategory } from "../../common/models/master";
import _ from "lodash";
import { cloneDeep } from "lodash";
import { ISalesOrder } from "../../common/models/salesorder";
import { SalesOrderService } from "../../api/services/SalesOrderService.service";
import { OrderPickingService } from "../../api/services/orderPicking.service";
import {
  EmptyListConfig,
  EmptyListConfigOP,
  EmptyListConfigSO,
  EmptyStatusUpdate,
} from "../../common/constants/generalConstants";
import { toast } from "react-toastify";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { useNavigate } from "react-router-dom";
import { GenericHelperService } from "../../api/generics/helper.service";
import { isNullUndefined } from "../../common/methods/globalmethods";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import { SamplingService } from "../../api/services/sampling.service";
import { CustomerService } from "../../api/services/customer.service";

const useOrderPicking = () => {
  const navigate = useNavigate();
  const productService: ProductService = new ProductService();
  const masterService: MasterService = new MasterService();
  const salesOrderService: SalesOrderService = new SalesOrderService();
  const orderPickingService: OrderPickingService = new OrderPickingService();
  const samplingService: SamplingService = new SamplingService();
  const customerService: CustomerService = new CustomerService();

  const [customersList, setCustomersList] = useState<ICustomer[]>([]);
  const helperService: GenericHelperService = new GenericHelperService();
  const [productsList, setProductsList] = useState<IProducts[]>([]);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );
  const [salesOrdersList, setSalesOrdersList] = useState<ISalesOrder[]>([]);
  const [salesOrdersListAll, setSalesOrdersListAll] = useState<ISalesOrder[]>(
    []
  );
  const [orderPickingList, setOrderPickingList] = useState<any[]>([]);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);

  const [warehouseTransferList, setWarehouseTransferList] = useState<any[]>([]);
  const [filtersWarehouseTransfer, setWarehouseTransferFilters] =
    useState<IListConfig>(EmptyListConfig);
  const [salesOrderProducts, setSalesOrderProducts] = useState<any>([]);
  const [productLocations, setProductLocations] = useState<any>([]);
  const [nextOP, setNextOP] = useState(null);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [selectedListTransfer, setSelectedListTransfer] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [opfilters, setOPFilters] = useState<IListConfigOP>(EmptyListConfigOP);

  const [pickingOrderLogs, setOrderPickingLogs] = useState<ILogsData[]>([]);

  const getPickingOrderLogs = async (id: number) => {
    const _logs: ILogsProps[] = await samplingService.getLogs(`OP${id}`);
    setOrderPickingLogs(_logs);
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    getProductsList();
    getProductCategoriesList();
    getSalesOrderList();
  };

  useEffect(() => {
    if (opfilters?.search === true) {
      queryOPList();
    } else if (opfilters?.search === false) {
      getOrderPickingList();
    }
  }, [opfilters?.search, filters.currentPage]);

  const getCustomersList = async () => {
    const _customers = await customerService.getCustomerAllList();
    setCustomersList(_customers ?? []);
    return _customers;
  };

  const queryOPList = async () => {
    let tempFilter = removeUnrequiredAttributes();
    if (Object.entries(tempFilter)?.length > 0) {
      let result = await orderPickingService.searchOPList(
        tempFilter,
        filters?.currentPage - 1
      );
      let _filteredList: any[] = result?.content ?? [];
      let customers = cloneDeep(customersList);
      if (isNullUndefined(customersList) || customersList?.length <= 0) {
        customers = await getCustomersList();
      }
      _filteredList?.map((item) => {
        item.customerName = customers?.find(
          (cust) => cust?.custId === item?.customerId
        )?.custName;
      });
      updateFilters(_filteredList, result);
      setOrderPickingList(_filteredList ?? []);
    }
  };

  const removeUnrequiredAttributes = () => {
    let tempOPfilter: IListConfigOP = cloneDeep(opfilters);
    if (!isNullUndefined(tempOPfilter)) {
      for (const [key, value] of Object.entries(tempOPfilter)) {
        if (typeof key === "string" && _.isEmpty(value)) {
          delete tempOPfilter[key];
        } else if (typeof key === "boolean") {
          delete tempOPfilter[key];
        } else if (typeof key === "number" && value <= 0) {
          delete tempOPfilter[key];
        }
      }
    }
    return tempOPfilter;
  };

  const getProductsList = async () => {
    const _products = await productService.getProducts();
    _products?.map((item) => {
      item.selected = false;
    });
    let _filteredProducts = _.filter(_products, { status: "ACTIVE" });
    setProductsList(_filteredProducts ?? []);
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };

  const getSalesOrderList = async () => {
    const result = await salesOrderService.getSalesList(0, 10000);
    let _SOList = result?.content ?? [];
    let tempSoList = _SOList?.filter((item) => {
      if (
        item?.status === "FULL_FILL" ||
        item?.status === "CLOSED" ||
        item?.status === "DRAFT" ||
        item?.status === "CANCELLED"
      )
        return false;
      return true;
    });

    tempSoList?.map((item) => {
      item.value = `${item?.soNum} - ${item?.customerId}`;
      item.label = `${item?.soNum} | ${item?.customerName}`;
    });
    _SOList?.map((item) => {
      item.value = `${item?.soNum} - ${item?.customerId}`;
      item.label = `${item?.soNum} | ${item?.customerName}`;
    });
    setSalesOrdersListAll(_SOList ?? []);
    setSalesOrdersList(tempSoList ?? []);
  };

  const getSalesOrderBySoNum = async (soNum: string) => {
    let data = cloneDeep(EmptyListConfigSO);
    data.soNum = soNum;
    Object.keys(data)?.map((item) => {
      if (item !== "soNum") {
        delete data[item];
      }
    });
    let result = await salesOrderService.searchSOList(data, 0, 10000);
    return result?.content ?? [];
  };

  const getProductsBySO = async (SO: string, WH: string) => {
    const _SoProducts = await orderPickingService.getProductsBySO(
      SO + "?wareHouseId=" + WH
    );
    setSalesOrderProducts(_SoProducts ?? []);
  };
  const getProductLocations = async (
    type: any,
    prId: any,
    qty: any,
    whId: any
  ) => {
    const _prLocations = await orderPickingService.getProductLocations(
      type,
      prId,
      qty,
      whId
    );

    // Check if the location already exists in productLocations
    const existingLocations = _prLocations.filter((prLocation) =>
      productLocations.some(
        (location) => location.locationId === prLocation.locationId
      )
    );

    // Filter out duplicate locations and add only new ones
    const newLocations = _prLocations.filter((prLocation) =>
      existingLocations.every(
        (existingLocation) =>
          existingLocation.locationId !== prLocation.locationId
      )
    );

    // Merge existing locations with new ones and update productLocations state
    const updatedLocations = [...productLocations, ...newLocations];
    setProductLocations(updatedLocations);
  };

  const updateFilters = (data: ISalesOrder[], result: IResult) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.searchQuery = "";
    _filters.totalRecords = result?.totalElements ?? data.length;
    _filters.totalPageCount =
      result?.totalPages ?? Math.ceil(data.length / _filters.currentPageSize);
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const getOrderPickingList = async () => {
    let customers = customersList;
    if (isNullUndefined(customersList) || customersList?.length <= 0) {
      customers = await getCustomersList();
    }
    const result = await orderPickingService.getPickingOrdersList(
      filters?.currentPage - 1
    );
    let _pickingOrders = result?.content ?? [];
    _pickingOrders?.map((item) => {
      item.customerName = customers?.find(
        (cust) => cust?.custId === item?.customerId
      )?.custName;
    });
    updateFilters(_pickingOrders, result);
    setOrderPickingList(_pickingOrders ?? []);
  };

  const getWarehouseTranferList = async () => {
    const _pickingOrders =
      await orderPickingService.getWarehouseTransferOrdersList();
    const _filters: IListConfig = cloneDeep(filtersWarehouseTransfer);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = _pickingOrders.length;
    _filters.totalPageCount = Math.ceil(
      _pickingOrders.length / _filters.currentPageSize
    );
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setWarehouseTransferFilters(_filters);
    setWarehouseTransferList(_pickingOrders ?? []);
  };

  const createNewOrderPicking = async (newOP: any) => {
    const _opSave = await orderPickingService.createNewOrderPicking(newOP);
    if (_opSave) {
      if (typeof _opSave == "string") {
        toast.error(_opSave);
      } else {
        toast.success("Order Picking saved successfully.");
        clearData();
        navigate(RoutingConstants.orderPickingList);
      }
    } else {
      toast.error("Error saving Order Picking.");
    }
  };

  const createNewTransferOrderWarehouse = async (transferOrder: any) => {
    const _opSave = await orderPickingService.createNewTransferOrderWarehouse(
      transferOrder
    );
    if (_opSave) {
      if (typeof _opSave == "string") {
        toast.error(_opSave);
      } else {
        toast.success("Order Transfered successfully.");
        clearData();
        navigate(RoutingConstants.warehouseTransfer);
      }
    } else {
      toast.error("Error saving Order Picking.");
    }
  };

  const updateNewOrderPicking = async (newOP: any) => {
    const _opSave = await orderPickingService.updateNewOrderPicking(newOP);
    if (_opSave) {
      if (typeof _opSave == "string") {
        toast.error(_opSave);
      } else {
        toast.success("Order Picking saved successfully.");
        clearData();
        navigate(RoutingConstants.orderPickingList);
      }
    } else {
      toast.error("Error saving Order Picking.");
    }
  };

  const updateTransferWarehouse = async (newOP: any) => {
    const _opSave = await orderPickingService.updateTransferWarehouse(newOP);
    if (_opSave) {
      if (typeof _opSave == "string") {
        toast.error(_opSave);
      } else {
        toast.success("Order Picking saved successfully.");
        clearData();
        navigate(RoutingConstants.warehouseTransferList);
      }
    } else {
      toast.error("Error saving Order Picking.");
    }
  };
  const deleteOrderPicking = async (newOP: any) => {
    newOP.isDeleted = true;
    const _opSave = await orderPickingService.updateNewOrderPicking(newOP);
    if (_opSave) {
      if (typeof _opSave == "string") {
        toast.error(_opSave);
      } else {
        toast.success("Order Picking deleted successfully.");
        clearData();
        navigate(RoutingConstants.orderPickingList);
      }
    } else {
      toast.error("Error delete Order Picking.");
    }
  };

  const deleteWarehouseTransfer = async (deleteTransfer: any) => {
    deleteTransfer.isDeleted = true;
    const _opSave = await orderPickingService.updateTransferWarehouse(
      deleteTransfer
    );
    if (_opSave) {
      if (typeof _opSave == "string") {
        toast.error(_opSave);
      } else {
        toast.success("Order Picking deleted successfully.");
        clearData();
        navigate(RoutingConstants.warehouseTransferList);
      }
    } else {
      toast.error("Error delete Order Picking.");
    }
  };

  const getNextOpNumber = async () => {
    const _opNo = await orderPickingService.getNextOpNumber();
    if (_opNo) {
      setNextOP(_opNo);
    } else {
      toast.error("Error getting next sample number");
    }
  };

  const getNextToNumber = async () => {
    const _opNo = await orderPickingService.getNextToNumber();
    if (_opNo) {
      setNextOP(_opNo);
    } else {
      toast.error("Error getting next sample number");
    }
  };
  const clearOpNumber = () => {
    setNextOP(null);
  };

  const clearLocations = () => {
    setProductLocations([]);
  };
  const clearData = () => {
    setProductLocations([]);
    setSalesOrderProducts([]);
    getOrderPickingList();
  };
  const updateStatus = async () => {
    const _updateStatus: boolean = await orderPickingService.updateStatus(
      selectedList
    );
    if (_updateStatus) {
      if (typeof _updateStatus == "string") {
        toast.error(_updateStatus);
      } else {
        toast.success("Status updated successfully.");
        getOrderPickingList();
      }
    } else {
      toast.error("Error updating status.");
    }
  };

  const updateStatusTransfer = async () => {
    const _updateStatus = await orderPickingService.updateTransferWarehouse(
      selectedListTransfer
    );
    if (_updateStatus) {
      if (typeof _updateStatus == "string") {
        toast.error(_updateStatus);
      } else {
        toast.success("Stock Transfer Update successful.");
        clearData();
        getWarehouseTranferList();
        navigate(RoutingConstants.warehouseTransferList);
      }
    } else {
      toast.error("Error Update Stock Transfer.");
    }
  };

  return {
    filters,
    setFilters,
    filtersWarehouseTransfer,
    setWarehouseTransferFilters,
    productsList,
    productCateList,
    salesOrdersList,
    getOrderPickingList,
    getWarehouseTranferList,
    orderPickingList,
    getProductsBySO,
    salesOrderProducts,
    getProductLocations,
    productLocations,
    clearLocations,
    createNewOrderPicking,
    createNewTransferOrderWarehouse,
    getNextOpNumber,
    getNextToNumber,
    clearOpNumber,
    nextOP,
    updateNewOrderPicking,
    updateTransferWarehouse,
    deleteOrderPicking,
    deleteWarehouseTransfer,
    selectedList,
    selectedListTransfer,
    setSelectedList,
    setSelectedListTransfer,
    updateStatus,
    updateStatusTransfer,
    opfilters,
    setOPFilters,
    salesOrdersListAll,
    getPickingOrderLogs,
    pickingOrderLogs,
    customersList,
    getSalesOrderBySoNum,
    queryOPList,
    warehouseTransferList,
  } as IOrderPickingContext;
};
export default useOrderPicking;
