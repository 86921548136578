import React, { CSSProperties, useEffect, useRef } from 'react';

interface AutoSizeOptions {
  minRows?: number;
  maxRows?: number;
}

interface TextareaProps {
  autoSize?: boolean | AutoSizeOptions;
  classNames?: string;
  styles?: CSSProperties;
  id?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onPressEnter?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<TextareaProps> = ({
  autoSize = false,
  classNames,
  styles,
  id,
  value,
  defaultValue,
  placeholder,
  disabled,
  maxLength,
  onChange,
  onPressEnter,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && onPressEnter) {
      onPressEnter(e);
    }
  };

  useEffect(() => {
    if (autoSize && textareaRef.current) {
      const textarea = textareaRef.current;
      const handleResize = () => {
        if (textarea) {
          const autoSizeOptions = typeof autoSize === 'object' ? autoSize : {};
          const minRows = autoSizeOptions.minRows || 2;
          const maxRows = autoSizeOptions.maxRows || 6;

          // Reset the height to auto so that scrollHeight includes only the text
          textarea.style.height = 'auto';
          const rowCount = Math.min(
            maxRows,
            Math.max(minRows, textarea.scrollHeight / parseFloat(window.getComputedStyle(textarea).lineHeight))
          );
          textarea.style.height = `${rowCount * parseFloat(window.getComputedStyle(textarea).lineHeight)}px`;
        }
      };
      // Call resize handler immediately to resize on initial render
      handleResize();

      // Call resize handler whenever window is resized
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [autoSize]); // Effect dependencies

  return (
    <textarea
      ref={textareaRef}
      id={id}
      className={`form-control ${classNames || ''}`}
      style={styles}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
      onChange={onChange}
      onKeyPress={handleKeyPress}
      // Automatically resize to fit content if autoSize is enabled
      {...(autoSize ? { style: { ...styles, overflowY: 'hidden' } } : {})}
    />
  );
};

export default Textarea;
