import { IDeleteConfirmationProps } from "../../common/models";
import FeatherIcon from "feather-icons-react";

const DeleteConfirmationComponent = (props: IDeleteConfirmationProps) => {
  const {
    title,
    confirm,
    cancel,
    header = "Are you sure?",
    title_prefix = "Do you want to delete - ",
  } = props;
  return (
    <div className="modal fade" id="deleteConfirmation" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center">
              <FeatherIcon
                icon="alert-circle"
                size={100}
                className={"text-warning"}
              />
              <h2>{header ?? ""}</h2>
              <p>
                {title_prefix ?? ""}
                <strong>{title ?? ""}</strong>
              </p>
              <div className="w-p100 d-flex justify-content-center">
                <button
                  type="button"
                  className="waves-effect waves-light btn btn-secondary w-p25"
                  data-bs-dismiss="modal"
                  onClick={() => cancel()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="waves-effect waves-light btn btn-danger w-p25 ms-4"
                  data-bs-dismiss="modal"
                  onClick={() => confirm()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationComponent;
