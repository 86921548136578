import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import {
  EmptyListConfigPORpt,
  EmptyPOReport,
  POReportKeys,
  PurchaseStatuses,
} from "../../../common/constants/generalConstants";
import {
  IListConfig,
  IListConfigPORpt,
  ISupplier,
} from "../../../common/models";
import { cloneDeep } from "lodash";
import { ReportsContext } from "../../../controllers/contexts/reports.context";
import { IPOReport } from "../../../common/models/reports";
import moment from "moment";
import _ from "lodash";
import { isNullUndefined } from "../../../common/methods/globalmethods";
import Select from "react-select";

const POReportViewComponent = () => {
  const {
    suppliers,
    masterFilter,
    setMasterfilter,
    filteredMasterData,
    getPOReports,
    clearMasterData,
    setFilteredMasterData,
    masterData,
    products,
  } = useContext(ReportsContext);

  const [filter, setfilter] = useState<IListConfigPORpt>(EmptyListConfigPORpt);

  useEffect(() => {
    clearSearch();
  }, []);

  const handleQueryChange = (
    type: string,
    value: any,
    search: boolean = false
  ) => {
    const _filter: IListConfigPORpt = cloneDeep(filter);
    if (search) {
      _filter.searchData[type] = value;
      searchData(_filter);
    } else {
      _filter[type] = value;
    }
    setfilter(_filter);
  };

  const returnSearchFilter = (_filter: IPOReport) => {
    let tempfilter: IPOReport = cloneDeep(_filter);
    for (const [key, value] of Object.entries(tempfilter)) {
      if (typeof value === "string" && _.isEmpty(value)) {
        delete tempfilter[key];
      } else if (Array.isArray(value) && value?.length <= 0) {
        delete tempfilter[key];
      } else if (typeof value === "number" && value <= 0) {
        delete tempfilter[key];
      } else if (value === null || value === undefined) {
        delete tempfilter[key];
      }
    }
    return tempfilter;
  };

  const searchData = (_filter: IListConfigPORpt) => {
    let tempFilter: IPOReport = returnSearchFilter(_filter.searchData);
    let _filteredList = masterData?.filter((_item: any, _itemIdx: number) => {
      let result = true;
      for (const [key, value] of Object.entries(tempFilter)) {
        const data = _item[key]?.toString()?.toLowerCase();
        result = result && data?.includes(value?.toLowerCase());
      }
      return result;
    });
    setFilteredMasterData(_filteredList);
  };

  const clearSearch = () => {
    const _filter: IListConfigPORpt = cloneDeep(filter);
    _filter.createdDateFrom = "";
    _filter.createdDateTo = "";
    _filter.exDeliveryDateFrom = "";
    _filter.exDeliveryDateTo = "";
    _filter.poNum = "";
    _filter.productId = null;
    _filter.status = [];
    _filter.supplier = null;
    _filter.searchData = cloneDeep(EmptyPOReport);
    setfilter(_filter);
    clearMasterData();
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filter: IListConfig = cloneDeep(masterFilter);
    if (_filter.sortItem === column) {
      _filter.sortDirection = _filter.sortDirection === "asc" ? "desc" : "asc";
      _filter.sortIsDate = isDate;
    } else {
      _filter.sortItem = column;
      _filter.sortDirection = "asc";
      _filter.sortIsDate = isDate;
    }
    setMasterfilter(_filter);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      filter?.createdDateFrom === "" &&
      filter?.createdDateTo === "" &&
      filter?.exDeliveryDateFrom === "" &&
      filter?.exDeliveryDateTo === "" &&
      (isNullUndefined(filter?.supplier) || filter?.supplier === 0) &&
      filter?.status?.length <= 0 &&
      filter?.poNum === "" &&
      (isNullUndefined(filter?.productId) || filter?.productId === 0)
    ) {
      result = true;
    }
    return result;
  };

  return (
    <>
      <div className="box">
        <div className="box-body">
          <div className="col-lg-12 d-flex">
            <div className="col-3">
              <Select
                value={products?.filter(
                  (option: any) => option.value === filter?.productId
                )}
                placeholder="Product"
                options={products}
                isSearchable={true}
                onChange={(e: any) => handleQueryChange("productId", e.value)}
              />
            </div>

            <div className="input-group w-p45 ms-10">
              <select
                className="form-select"
                value={filter?.status ?? ""}
                onChange={(e) => {
                  let value: any = e.target.value;
                  if (value === "") {
                    value = [];
                  } else {
                    value = [value];
                  }
                  handleQueryChange("status", value);
                }}
              >
                <option value={""}>Status</option>
                <option key={`all`} value={LabelConstants.All}>
                  All
                </option>
                {PurchaseStatuses?.map((value: string, id: number) => {
                  if (value === "DRAFT") return;
                  return (
                    <option key={`status${id}`} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-group w-p45 ms-10">
              <select
                className="form-select"
                value={
                  suppliers?.find((item) => item?.supId === filter?.supplier)
                    ?.supName ?? ""
                }
                onChange={(e) => {
                  let id = suppliers?.find(
                    (item) => item?.supName === e.target.value
                  )?.supId;
                  handleQueryChange("supplier", id);
                }}
              >
                <option value={""}>Supplier</option>
                {suppliers?.map((_item: ISupplier, _itemIdx: number) => {
                  return (
                    <option key={`roStatus${_itemIdx}`} value={_item?.supName}>
                      {_item?.supName}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* <div className="input-group w-p30 ms-10">
              <input
                className="form-control"
                type="date"
                placeholder="From Date"
                value={filter?.createdDateFrom}
                onChange={(event) =>
                  handleQueryChange("createdDateFrom", event.target.value)
                }
              />
              <i
                style={{
                  position: "absolute",
                  top: -15,
                  left: 0,
                  fontSize: 10,
                }}
              >
                Created From
              </i>
            </div>

            <div className="input-group w-p30 ms-10">
              <input
                className="form-control"
                type="date"
                placeholder="To Date"
                value={filter?.createdDateTo}
                onChange={(event) =>
                  handleQueryChange("createdDateTo", event.target.value)
                }
              />
              <i
                style={{
                  position: "absolute",
                  top: -15,
                  left: 0,
                  fontSize: 10,
                }}
              >
                Created To
              </i>
            </div> */}

            <div className="input-group w-p30 ms-10">
              <input
                className="form-control"
                type="date"
                placeholder="From Date"
                value={filter?.exDeliveryDateFrom}
                onChange={(event) =>
                  handleQueryChange("exDeliveryDateFrom", event.target.value)
                }
              />
              <i
                style={{
                  position: "absolute",
                  top: -15,
                  left: 0,
                  fontSize: 10,
                }}
              >
                Delivery From
              </i>
            </div>

            <div className="input-group w-p30 ms-10">
              <input
                className="form-control"
                type="date"
                placeholder="To Date"
                value={filter?.exDeliveryDateTo}
                onChange={(event) =>
                  handleQueryChange("exDeliveryDateTo", event.target.value)
                }
              />
              <i
                style={{
                  position: "absolute",
                  top: -15,
                  left: 0,
                  fontSize: 10,
                }}
              >
                Delivery To
              </i>
            </div>
            <div className="input-group w-p10 ms-10">
              <button
                type="button"
                className="waves-effect waves-light btn btn-success rounded"
                disabled={checkIfSearchStringsAreEmpty()}
                onClick={() => {
                  getPOReports(filter);
                }}
              >
                {LabelConstants.Search}
              </button>
            </div>
            <div className="input-group w-p10 ms-10">
              <button
                type="button"
                className="waves-effect waves-light btn btn-success-light rounded"
                onClick={() => {
                  clearSearch();
                }}
              >
                {LabelConstants.Clear}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="box-body">
          <div className="table-responsive">
            <table
              className="table table-hover table-bordered mb-0"
              style={{ textAlign: "center" }}
            >
              <thead className="table-primary">
                <tr>
                  <th>S.No</th>
                  {POReportKeys?.map((item) => (
                    <th onClick={() => handleSort(item.key)}>
                      {item.placeholder}
                      <i
                        className={`ms-2 fa fa-sort${
                          masterFilter?.sortItem === item.key
                            ? `-${masterFilter?.sortDirection} text-primary`
                            : " text-fade"
                        }`}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr key={"test"}>
                  <td></td>
                  {POReportKeys?.map((item) => (
                    <td>
                      <input
                        className="form-control"
                        placeholder={item?.placeholder}
                        value={filter?.searchData[item.key] ?? ""}
                        onChange={(event) => {
                          handleQueryChange(item.key, event.target.value, true);
                        }}
                      />
                    </td>
                  ))}
                </tr>
                {filteredMasterData?.map(
                  (_item: IPOReport, _itemIdx: number) => {
                    return (
                      <tr key={_itemIdx}>
                        <td>{_itemIdx + 1}</td>
                        {POReportKeys?.map((keydata) => {
                          if (keydata.type === "date") {
                            return (
                              <td>
                                {isNullUndefined(_item[keydata.key])
                                  ? ""
                                  : moment(_item[keydata.key]).format(
                                      "DD/MM/yyyy"
                                    )}
                              </td>
                            );
                          } else {
                            return <td>{_item[keydata.key] ?? ""}</td>;
                          }
                        })}
                      </tr>
                    );
                  }
                )}
                {(isNullUndefined(filteredMasterData) ||
                  filteredMasterData?.length <= 0) && (
                  <tr>
                    <td colSpan={POReportKeys?.length + 1} align="center">
                      No data to display!!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default POReportViewComponent;
