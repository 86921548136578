import { LabelConstants } from "../../../common/constants/labelConstants";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { useContext, useEffect, useState } from "react";
import { GrnContext } from "../../../controllers/contexts/grn.context";
import { cloneDeep } from "lodash";
import {
  ERROR_MESSAGES,
  GENERAL_CONSTANTS,
  NewDeleteConfirmationDialog,
  NewGRNAisleToBatchLocationMap,
  NewGRNAisleToBatchMapDialog,
  NewGRNProduct,
  NewGRNProductLocation,
  SEQUENCE_PREFIXES,
} from "../../../common/constants/generalConstants";
import {
  IGRNProductWithBatchDetails,
  IGRNProductLocation,
  IGoodsReceipt,
  IGRNAisleToBatchProps,
  IGRNAisleToBatchLocationInfo,
} from "../../../common/models/grn";
import { WarehouseContext } from "../../../controllers/contexts/warehouse.context";
import {
  getDateIfValid,
  getNumberForString,
  isNullUndefined,
  isNumeric,
  isSideMenuVisible,
} from "../../../common/methods/globalmethods";
import {
  IDeleteConfirmationProps,
  ILocationDetails,
  IWarehouseBayLocation,
  IWarehouseMap,
} from "../../../common/models";
import _isEqual from "lodash/isEqual";
import _ from "lodash";
import { toast } from "react-toastify";
import DeleteConfirmationComponent from "../../common/deleteConfirmation.component";
import { Modal } from "bootstrap";
import GRNAisleBatchDetailsComponent from "./grn_aisle_batch_details";
import GRNAisleBatchLocationDetailsComponent from "./grn_aisle_batch_location_details";
import { DatePicker } from "antd";

var deleteModal,
  batchAisleModal,
  batchAisleLocationModal,
  clickTimeout = null,
  uniqueBatchPrefix,
  warehouseName = "";

const GrnBatchComponent = () => {
  const {
    GRNForm,
    setGRNForm,
    selectedProductIndex,
    batchToPalletMapping,
    setBatchToPalletMapping,
    getNextReceiptNumberForType,
    getAssignedLocationsArray,
    selectedTransferQty,
  } = useContext(GrnContext);
  const {
    warehouseMap,
    updateWarehouseDetails,
    deallocateUnusedAislesIfAny,
    warehouseList,
    getLocationDtlsById,
  } = useContext(WarehouseContext);
  const [selectedAisle, setSelectedAisle] = useState("");
  const [currentBatchIndex, setCurrentBatchIndex] = useState(null);
  const [selectedShelfs, setselectedShelfs] = useState([]);
  const [batchError, setBatchError] = useState(false);
  const [aisleErrorIndex, setAisleErrorIndex] = useState(-1);
  const [deleteDialoginfo, setDeleteDialoginfo] =
    useState<IDeleteConfirmationProps>();
  const [aisleToBatchdialoginfo, setAisleToBatchdialoginfo] =
    useState<IGRNAisleToBatchProps>();
  const [isDisabled, setisDisabled] = useState(true);
  const [hideLocationDetails, sethideLocationDetails] = useState(
    GRNForm?.status !== "APPROVED"
  );
  const [searchLocation, setSearchLocation] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNullUndefined(selectedProductIndex) || selectedProductIndex < 0) {
      exit();
      return;
    }
    deleteModal = new Modal(document.getElementById("deleteConfirmation"), {});
    batchAisleModal = new Modal(
      document.getElementById("grnaislebatchdetails"),
      {}
    );
    batchAisleLocationModal = new Modal(
      document.getElementById("grnaislebatchlocationdetails"),
      {}
    );
    if (isSideMenuVisible())
      document.getElementById("header-sidemenu")?.click();
    fillData();
    if (location?.pathname !== RoutingConstants.grn_batch_view) {
      setisDisabled(false);
    }
    setBatchSequence();
    setWarehouseName();
    return () => {
      setselectedShelfs([]);
      setBatchToPalletMapping([]);
    };
  }, []);

  const setWarehouseName = () => {
    warehouseName = warehouseList.find(
      (_) => _.wareHouseId === Number(GRNForm?.warehouseId)
    )?.warehouseName;
  };

  const setBatchSequence = async () => {
    uniqueBatchPrefix = await getNextReceiptNumberForType(SEQUENCE_PREFIXES.PR);
  };

  const getMaxBatchSeqNumber = () => {
    let batchNumbers: Array<number> = [];
    let batchSequeneNo = 1;
    batchToPalletMapping?.map(
      (batch: IGRNProductWithBatchDetails, index: number) => {
        batch?.prodLocs?.map((_location: IGRNProductLocation) => {
          let refNum = _location?.palletRefNum;
          if (refNum?.includes("-") && refNum.split("-")?.length >= 2) {
            batchNumbers.push(parseInt(refNum.split("-")[1]));
            uniqueBatchPrefix = refNum.split("-")[0];
          }
        });
      }
    );
    if (batchNumbers?.length > 0) {
      batchSequeneNo = Math.max(...batchNumbers) + 1;
    }
    return uniqueBatchPrefix + "-" + batchSequeneNo;
  };

  const exit = (form: IGoodsReceipt = null) => {
    deallocateUnusedAislesIfAny(
      selectedShelfs,
      getAssignedLocationsArray(form ?? GRNForm)
    );
    if (location?.pathname === RoutingConstants.grn_batch_create)
      navigate(RoutingConstants.grn_order_create);
    else if (location?.pathname === RoutingConstants.grn_batch_edit)
      navigate(RoutingConstants.grn_order_edit);
    else if (location?.pathname === RoutingConstants.grn_batch_view)
      navigate(RoutingConstants.grn_order_view);
  };

  const confirmExit = () => {
    if (
      _.isEqual(
        GRNForm?.grDetails[selectedProductIndex]?.product,
        batchToPalletMapping
      )
    ) {
      exit();
    } else {
      showDeleteDialog(
        () => {
          exit();
        },
        () => {
          let result = saveBatchDetails();
          if (result?.result) {
            exit(result?._form);
          }
        },
        "Save batch changes ?",
        "",
        ""
      );
    }
  };

  const showDeleteDialog = (
    onCancel: Function,
    onConfirm: Function,
    header: string,
    title_prefix: string,
    title: string = null
  ) => {
    let dialog: IDeleteConfirmationProps = cloneDeep(
      NewDeleteConfirmationDialog
    );
    dialog.cancel = onCancel;
    dialog.confirm = onConfirm;
    dialog.title = title;
    dialog.header = header;
    dialog.title_prefix = title_prefix;
    setDeleteDialoginfo(dialog);
    deleteModal.show();
  };

  const showBatchDialog = (onCancel: Function, onConfirm: Function) => {
    if (currentBatchIndex === null || currentBatchIndex < 0) return;
    let dialog: IGRNAisleToBatchProps = cloneDeep(NewGRNAisleToBatchMapDialog);
    dialog.oncancel = onCancel;
    dialog.onconfirm = onConfirm;
    dialog.maxQty = getNumberForString(
      batchToPalletMapping[currentBatchIndex]?.receivedQty
    );
    batchToPalletMapping[currentBatchIndex]?.prodLocs?.map((item, index) => {
      let aisleBatchMap = cloneDeep(NewGRNAisleToBatchLocationMap);
      aisleBatchMap.shelfId = item?.shelfId;
      aisleBatchMap.quantity = item?.locQty;
      aisleBatchMap.referenceNumber = item?.palletRefNum;
      dialog.locationData.push(aisleBatchMap);
    });
    setAisleToBatchdialoginfo(dialog);
    batchAisleModal.show();
  };

  const createNewBatch = async (batchData: IGRNProductWithBatchDetails) => {
    let _form: IGRNProductWithBatchDetails[] = cloneDeep(batchToPalletMapping);
    if (GRNForm?.goodsRecType === "TO") {
      const totalReceivedQty = GRNForm?.grDetails[
        selectedProductIndex
      ]?.product.reduce((acc, curr) => acc + parseInt(curr.receivedQty), 0);
      if (_form.length + 1 > totalReceivedQty) {
        toast.error("Exceeds the total quantity");
        return;
      }
    }
    if (GRNForm?.goodsRecType === "TO") {
      const lastBatchData = _form[_form.length - 1];
      const newBatchData: IGRNProductWithBatchDetails = {
        ...lastBatchData,
        ...batchData,
        status: "QRN",
        prodId: lastBatchData.prodId,
        prodCatId: lastBatchData.prodCatId,
        batchNo: lastBatchData.batchNo,
        batchExpDate: lastBatchData.batchExpDate,
        receivedQty: lastBatchData.receivedQty,
        palletsCount: lastBatchData.palletsCount,
        isSamplingReq: lastBatchData.isSamplingReq,
        remainingQty: lastBatchData.remainingQty,
        prodLocs: lastBatchData.prodLocs,
      };
      _form.push(newBatchData);
      setBatchToPalletMapping(_form);
      updateCurrentBatchIndex(_form.length - 1);
      setSelectedAisle(
        _form.length - 1 <= 0 ? "" : _form[_form.length - 1]?.aisleName
      );
      setBatchError(false);
    } else {
      batchData.status = "QRN";
      batchData.prodId =
        GRNForm?.grDetails[selectedProductIndex]?.productInfo?.productId + "";
      batchData.prodCatId =
        GRNForm?.grDetails[selectedProductIndex]?.productInfo?.prodCategory;
      _form.push(batchData);
      setBatchToPalletMapping(_form);
      updateCurrentBatchIndex(_form.length - 1);
      setSelectedAisle(
        _form.length - 1 <= 0 ? "" : _form[_form.length - 1]?.aisleName
      );
      setBatchError(false);
    }
  };

  const handleBatchToPalletForm = async (
    index: number,
    types: string[],
    values: any[]
  ) => {
    const _form = cloneDeep(batchToPalletMapping);
    types.map((key: string, iterator: number) => {
      _form[index][key] = values[iterator];
    });
    await setBatchToPalletMapping(_form);
    updateCurrentBatchIndex(index);
  };

  const updateCurrentBatchIndex = (index: number) => {
    setCurrentBatchIndex(index);
    displayLocationChart(index);
  };

  const displayLocationChart = (index: number) => {
    let data = batchToPalletMapping[index]?.prodLocs;
    if (data?.length > 0) {
      let locid = data[0]?.locationId;
      setAisleNameFromBatchDetails(locid);
    }
  };

  const fillData = () => {
    const _form = cloneDeep(GRNForm);
    let batchData: IGRNProductWithBatchDetails[] =
      _form["grDetails"][selectedProductIndex]["product"];
    if (GRNForm.goodsRecType !== "TO") {
      setBatchToPalletMapping(batchData);
    } else {
      if (batchData.length > 0) {
        setBatchToPalletMapping(batchData);
      }
    }
    if (batchData.length > 0) {
      setSelectedAisle(batchData[0]?.aisleName);
      if (batchData[0]?.prodLocs.length > 0)
        setAisleNameFromBatchDetails(batchData[0]?.prodLocs[0]?.locationId);
      updateCurrentBatchIndex(0);
    }
    initAlreadyAllocatedShelfs();
  };

  const setAisleNameFromBatchDetails = (locationid: number) => {
    let result = true;
    warehouseMap?.map((_warehouseMap, _warehouseMapIdx) => {
      {
        (_warehouseMap.bays ?? []).map((_bay, _bayIdx) => {
          {
            (_bay.baysLocations ?? [])
              .filter((_loc) => _loc.deleted === false)
              .map(async (_loc, _locIdx) => {
                if (_loc?.locId === locationid) {
                  result = false;
                  setSelectedAisle(_warehouseMap?.asileName);
                }
              });
          }
        });
      }
    });
  };

  const initAlreadyAllocatedShelfs = () => {
    let locations = [];

    GRNForm?.grDetails?.map((item) => {
      item?.product?.map((batch) => {
        batch?.prodLocs?.map((item) => {
          locations.push(item?.locationId);
        });
      });
    });

    setselectedShelfs(locations);
  };

  const saveBatchDetails = () => {
    let result = false;
    const _form = cloneDeep(GRNForm);
    let totalReceivedQty = 0;
    batchToPalletMapping.forEach((batch) => {
      totalReceivedQty += parseInt(batch.receivedQty);
    });
    const totalReceivedQtyString = totalReceivedQty.toString();

    if (
      GRNForm.goodsRecType === "TO" &&
      selectedTransferQty !== totalReceivedQtyString
    ) {
      toast.error(
        "Selected transfer quantity does not match total received quantity."
      );
      return { result, _form };
    }
    if (validateBatchDetails()) {
      _form["grDetails"][selectedProductIndex]["product"] =
        batchToPalletMapping;
      setGRNForm(_form);
      result = true;
    }
    return { result, _form };
  };

  const validateBatchDetails = () => {
    let result = true;
    batchToPalletMapping.map((batch, index) => {
      if (
        result &&
        (_.isEmpty(batch?.batchNo) ||
          _.isEmpty(batch?.batchExpDate) ||
          _.isEmpty(batch?.receivedQty) ||
          _.isEmpty(batch?.palletsCount) ||
          !validatePalletsAllocation(batch, index))
      ) {
        setBatchError(true);
        result = false;
      }
    });

    return result;
  };

  const validatePalletsAllocation = (
    batch: IGRNProductWithBatchDetails,
    index: number
  ) => {
    let result = false,
      isShelfEmpty = { result: false, location: "" };

    let totalreceived = getNumberForString(batch?.receivedQty);
    let totalqtyassigned = 0;
    batch?.prodLocs?.map((item) => {
      if (item?.locQty <= 0) {
        isShelfEmpty = { result: true, location: item?.shelfId };
      }
      totalqtyassigned += item?.locQty;
    });

    let palletsCount = getNumberForString(batch?.palletsCount);
    if (palletsCount !== batch?.prodLocs?.length) {
      toast.error("Number of pallets required and assigned doen`t match");
    } else if (totalreceived !== totalqtyassigned) {
      toast.error(
        `Total quantity received(${totalreceived}) and assigned(${totalqtyassigned}) doesn't match!!`
      );
    } else if (isShelfEmpty?.result) {
      toast.error(
        `Shelf ${isShelfEmpty?.location} has not been assigned any quantity!!`
      );
    } else {
      result = true;
    }

    if (!result) {
      setAisleErrorIndex(index);
      setCurrentBatchIndex(index);
    }

    return result;
  };

  const assignAisleAutomatically = () => {
    let cannotProceedWithAllocation = false;
    let locationNums: number[] = [];
    warehouseMap?.map((_warehouseMap, _warehouseMapIdx) => {
      {
        (_warehouseMap.bays ?? []).map((_bay, _bayIdx) => {
          {
            (_bay.baysLocations ?? [])
              .filter((_loc) => {
                if (
                  _loc?.status === GENERAL_CONSTANTS.IN_USE &&
                  _loc.deleted === false
                ) {
                  return true;
                }
                return false;
              })
              .map(async (_loc, _locIdx) => {
                if (cannotProceedWithAllocation) return;
                if (isNullUndefined(currentBatchIndex) || currentBatchIndex < 0)
                  return;
                let requirementCount: string =
                  batchToPalletMapping[currentBatchIndex]?.palletsCount ?? "";
                if (
                  isNullUndefined(requirementCount) ||
                  requirementCount === ""
                )
                  return;
                let maxAllocation = isNumeric(requirementCount)
                  ? parseInt(requirementCount)
                  : 0;
                if (maxAllocation <= 0) return;
                let alreadyAllocated =
                  getAllocatedLocationsForBatch()?.length ?? 0;
                if (
                  _loc?.status === GENERAL_CONSTANTS.IN_USE &&
                  (alreadyAllocated <= 0 ||
                    alreadyAllocated <= maxAllocation - 1)
                ) {
                  locationNums.push(_loc?.locId);
                  let result = await assignPallets(
                    _loc,
                    _warehouseMap,
                    "A",
                    alreadyAllocated === maxAllocation - 1,
                    locationNums
                  );
                  if (!result?.status) {
                    if (result?.message === ERROR_MESSAGES.MAX_ALLOCATED) {
                    } else toast.error(result?.message);
                    cannotProceedWithAllocation = true;
                  }
                } else {
                  if (
                    _loc?.status === GENERAL_CONSTANTS.IN_USE ||
                    maxAllocation <= 0
                  ) {
                    cannotProceedWithAllocation = true;
                    if (maxAllocation <= 0) {
                      toast.error(ERROR_MESSAGES.ENTER_NO_OF_PALLETS);
                    }
                  }
                }
              });
          }
        });
      }
    });
  };

  const allocateAisleToBatch = async (
    _loc: IWarehouseBayLocation,
    _warehouseMap: IWarehouseMap,
    allocationType: string
  ) => {
    if (!isNullUndefined(currentBatchIndex) && currentBatchIndex >= 0) {
      let locationarray: IGRNProductLocation[] =
        batchToPalletMapping[currentBatchIndex]?.prodLocs;
      let _form: IGRNProductLocation = cloneDeep(NewGRNProductLocation);
      _form.locationId = _loc?.locId;
      _form.palletRefNum = getMaxBatchSeqNumber();
      _form.shelfId = _loc?.shelfId;
      _form.locQty = 0;
      locationarray.push(_form);
      await handleBatchToPalletForm(
        currentBatchIndex,
        ["aisleName", "palletAssignmentType", "prodLocs"],
        [_warehouseMap?.asileName, allocationType, locationarray]
      );
    }
  };

  const updateBatchAisleInfo = async (
    locationsData: IGRNAisleToBatchLocationInfo[]
  ) => {
    if (!isNullUndefined(currentBatchIndex) && currentBatchIndex >= 0) {
      let locationarray: IGRNProductLocation[] =
        batchToPalletMapping[currentBatchIndex]?.prodLocs;
      locationsData?.map((_locData) => {
        locationarray?.map((location) => {
          if (location?.shelfId === _locData?.shelfId) {
            location.locQty = _locData?.quantity;
            location.palletRefNum = _locData?.referenceNumber;
          }
        });
      });

      await handleBatchToPalletForm(
        currentBatchIndex,
        ["prodLocs"],
        [locationarray]
      );
    }
  };

  const manualAutomaticAssigment = (batchIndex: number, value: string) => (
    <div>
      <div className="row">
        <div
          className={
            "iradio_flat-green col-6" + (value === "A" ? " checked" : "")
          }
          style={isDisabled ? { pointerEvents: "none" } : {}}
          onClick={() => {
            assignAisleAutomatically();
          }}
        ></div>
        <label className="col-6">{LabelConstants.Automatic}</label>
      </div>
      <div className="row mt-5">
        <div
          className={
            "iradio_flat-green col-6" + (value === "M" ? " checked" : "")
          }
          style={isDisabled ? { pointerEvents: "none" } : {}}
          onClick={() => {
            handleBatchToPalletForm(
              batchIndex,
              ["palletAssignmentType"],
              ["M"]
            );
          }}
        ></div>
        <label className="col-6">{LabelConstants.Manual}</label>
      </div>
    </div>
  );

  const assignPallets = async (
    _loc: IWarehouseBayLocation,
    _warehouseMap: IWarehouseMap,
    allocationType: string,
    allocate: boolean = false,
    locationNums: number[] = []
  ) => {
    let assingment = checkIfPalletCanBeAssigned(_loc);
    if (!assingment?.status) {
      let removeCheck = removePalletFromBatchIfInUse(_loc);
      if (removeCheck?.status) {
        return removeCheck;
      }
      return assingment;
    }
    let shelfs = selectedShelfs;
    shelfs.push(_loc?.locId);
    setselectedShelfs(shelfs);
    await allocateAisleToBatch(_loc, _warehouseMap, allocationType);
    setSelectedAisle(_warehouseMap?.asileName);
    if (allocate) {
      await updateWarehouseDetails(
        locationNums?.length <= 0 ? [_loc?.locId] : locationNums,
        GENERAL_CONSTANTS.OCCUPIED,
        "Pallet allocated successfully!!",
        false
      );
    }
    return { status: true, message: "" };
  };

  const checkIfPalletCanBeAssigned = (_loc: IWarehouseBayLocation) => {
    let result = true;
    let message: string = "";

    let requirementCount: string =
      batchToPalletMapping[currentBatchIndex]?.palletsCount ?? "0";
    let maxAllocation = isNumeric(requirementCount)
      ? parseInt(requirementCount)
      : 0;
    let allocatedCount =
      batchToPalletMapping[currentBatchIndex]?.prodLocs?.length ?? 0;
    if (
      // (
      _loc?.status !== GENERAL_CONSTANTS.IN_USE ||
      // && _loc?.status !== GENERAL_CONSTANTS.OCCUPIED)
      maxAllocation <= 0 ||
      allocatedCount >= maxAllocation
    ) {
      result = false;
      if (maxAllocation <= 0) {
        message = ERROR_MESSAGES.ENTER_NO_OF_PALLETS;
      } else if (allocatedCount >= maxAllocation) {
        message = ERROR_MESSAGES.MAX_ALLOCATED;
      } else if ((message = ERROR_MESSAGES.PALLOT_NOT_IN_USE)) {
        message = ERROR_MESSAGES.PALLOT_NOT_IN_USE;
      }
    }
    // if (
    //   _loc.status === GENERAL_CONSTANTS.OCCUPIED &&
    //   checkIfLocationIsAlreadyAddedToBatch(_loc)
    // ) {
    //   result = false;
    // }

    return { status: result, message };
  };

  const removePalletFromBatchIfInUse = (_loc: IWarehouseBayLocation) => {
    let result = false;
    let message: string = "";
    if (getAllocatedLocationsForBatch()?.includes(_loc?.locId)) {
      let locationarray: IGRNProductLocation[] =
        batchToPalletMapping[currentBatchIndex]?.prodLocs;

      const _itmIdx = locationarray.findIndex(
        (_itm) => _itm.locationId === _loc.locId
      );
      if (_itmIdx > -1) {
        locationarray.splice(_itmIdx, 1);
        handleBatchToPalletForm(
          currentBatchIndex,
          ["prodLocs"],
          [locationarray]
        );
        updateWarehouseDetails(
          [_loc?.locId],
          GENERAL_CONSTANTS.IN_USE,
          "Pallet deallocated successfully!!"
        );
        removeItemFromSelectionList(_loc);
        result = true;
        message = ERROR_MESSAGES.PALLOT_DE_ALLOCATION_SUCCESS;
      }
    }
    return { status: result, message };
  };

  const removeItemFromSelectionList = (_loc: IWarehouseBayLocation) => {
    const _itmIdx = selectedShelfs.findIndex((_itm) => _itm === _loc.locId);

    if (_itmIdx > -1) {
      let shelfs = selectedShelfs;
      shelfs.splice(_itmIdx, 1);
      setselectedShelfs(shelfs);
    }
  };

  const isPalletAssignedToBatch = (locationid: number) => {
    let result = false;
    if (selectedShelfs.includes(locationid)) {
      result = getAllocatedLocationsForBatch()?.includes(locationid);
    }
    return result;
  };

  const getAllocatedLocationsForBatch = () => {
    let locations: number[] = [];
    if (!isNullUndefined(currentBatchIndex) && currentBatchIndex >= 0) {
      batchToPalletMapping[currentBatchIndex]?.prodLocs?.map((item) => {
        locations.push(item?.locationId);
      });
    }
    return locations;
  };

  const handlePalletClicks = (onClick: Function, onDoubleClick: Function) => {
    if (clickTimeout !== null) {
      clearTimeout(clickTimeout);
      clickTimeout = null;
      onDoubleClick();
    } else {
      clickTimeout = setTimeout(() => {
        clearTimeout(clickTimeout);
        clickTimeout = null;
        onClick();
      }, 300);
    }
  };

  const showBatchDetails = (_loc: IWarehouseBayLocation) => (
    <h4 className="text-white my-0">
      {`${_loc.shelfId} ${
        _loc?.status !== GENERAL_CONSTANTS.IN_USE ? "Loaded" : "Empty"
      }`}
    </h4>
  );

  const getBackPageName = () => {
    let name = LabelConstants.CreateGRN;
    if (location?.pathname === RoutingConstants.grn_batch_edit) {
      name = LabelConstants.EditGRN;
    } else if (location?.pathname === RoutingConstants.grn_batch_view) {
      name = LabelConstants.ViewGRN;
    }
    return name;
  };

  const getCurrentPageName = () => {
    let name = LabelConstants.CreateBatch;
    if (location?.pathname === RoutingConstants.grn_batch_edit) {
      name = LabelConstants.EditBatch;
    } else if (location?.pathname === RoutingConstants.grn_batch_view) {
      name = LabelConstants.ViewBatch;
    }
    return name;
  };

  const checkIfLocationIsAlreadyAddedToBatch = (
    _loc: IWarehouseBayLocation
  ) => {
    let result = false;
    let locationarray: IGRNProductLocation[] =
      batchToPalletMapping[currentBatchIndex]?.prodLocs;

    const _itmIdx = locationarray.findIndex(
      (_itm) => _itm.locationId === _loc.locId
    );

    if (_itmIdx > -1) {
      result = true;
    }
    return result;
  };

  const checkIfPalletHasSameItem = async (_loc: IWarehouseBayLocation) => {
    if (
      _loc?.status !== GENERAL_CONSTANTS.OCCUPIED ||
      selectedShelfs.includes(_loc.locId)
    ) {
      return true;
    }

    let result = false;
    let currentProductId =
      GRNForm?.grDetails[selectedProductIndex]?.productInfo.productId;
    let locdetails: ILocationDetails[] = await getLocationDtlsById(_loc);
    for (let i = 0; i < locdetails?.length; i++) {
      if (locdetails[i]?.prodId === currentProductId) {
        result = true;
        break;
      }
    }
    return result;
  };

  return (
    <div className="container-full">
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderIn}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {getBackPageName()}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {getCurrentPageName()}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="box">
              <div className="box-header bg-primary">
                <h4 className="box-title">{`#${GRNForm?.goodsReceiptNumber} - ${GRNForm?.grDetails[selectedProductIndex]?.productInfo?.productName}`}</h4>
                {GRNForm?.goodsRecType !== "TO" && (
                  <a
                    href="javascript:void(0)"
                    className="btn btn-circle btn-danger btn-xs pull-right"
                    style={{ visibility: isDisabled ? "hidden" : "visible" }}
                    onClick={() => {
                      createNewBatch(NewGRNProduct);
                    }}
                  >
                    <i className="ti-plus"></i>
                  </a>
                )}
              </div>

              <div className="box-body">
                <div className="table-responsive">
                  <table className="table product-overview table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>{LabelConstants.Batch}</th>
                        <th>{LabelConstants.ExpiryDate}</th>
                        <th>{LabelConstants.ReceivedQuantity}</th>
                        <th>{LabelConstants.NoOfPallets}</th>
                        {/* <th>{LabelConstants.QuantityOnPallet}</th> */}
                        <th>{LabelConstants.AssignPallet}</th>
                        {/* <th>{LabelConstants.PalletReferenceNumber}</th> */}
                        <th style={{ textAlign: "center" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batchToPalletMapping?.map(
                        (
                          _batch: IGRNProductWithBatchDetails,
                          _batchIdx: number
                        ) => {
                          return (
                            <tr
                              style={
                                aisleErrorIndex === _batchIdx
                                  ? {
                                      borderColor: "red",
                                      borderWidth: 1.5,
                                    }
                                  : {}
                              }
                              className={
                                currentBatchIndex === _batchIdx
                                  ? "bg-secondary"
                                  : ""
                              }
                              onClick={() => updateCurrentBatchIndex(_batchIdx)}
                            >
                              <td className="col-2">
                                <input
                                  id={"batchNo" + _batchIdx}
                                  type="text"
                                  disabled={
                                    isDisabled ||
                                    ["TO", "RO"].includes(GRNForm.goodsRecType)
                                  }
                                  className="form-control"
                                  placeholder={LabelConstants.BatchNumber}
                                  value={_batch?.batchNo}
                                  style={
                                    batchError && _.isEmpty(_batch?.batchNo)
                                      ? {
                                          borderColor: "red",
                                        }
                                      : {}
                                  }
                                  onChange={(e) => {
                                    handleBatchToPalletForm(
                                      _batchIdx,
                                      ["batchNo"],
                                      [e.target.value]
                                    );
                                  }}
                                />
                              </td>
                              <td className="col-2">
                                <DatePicker
                                  id={"batchExpDate" + _batchIdx}
                                  className="form-control bg-white"
                                  disabled={
                                    isDisabled ||
                                    ["TO", "RO"].includes(GRNForm.goodsRecType)
                                  }
                                  value={getDateIfValid(_batch?.batchExpDate)}
                                  format="DD-MM-YYYY"
                                  style={
                                    batchError &&
                                    _.isEmpty(_batch?.batchExpDate)
                                      ? {
                                          borderColor: "red",
                                          width: 130,
                                        }
                                      : { width: 130 }
                                  }
                                  onChange={(e, ds) => {
                                    let date = e?.format("YYYY-MM-DD");
                                    handleBatchToPalletForm(
                                      _batchIdx,
                                      ["batchExpDate"],
                                      [date]
                                    );
                                  }}
                                />
                              </td>
                              <td width={100}>
                                <input
                                  id={"receivedQty" + _batchIdx}
                                  type="number"
                                  min={0}
                                  disabled={
                                    isDisabled ||
                                    ["TO", "RO"].includes(GRNForm.goodsRecType)
                                  }
                                  className="form-control"
                                  placeholder="value"
                                  value={_batch?.receivedQty}
                                  style={
                                    batchError && _.isEmpty(_batch?.receivedQty)
                                      ? {
                                          borderColor: "red",
                                        }
                                      : {}
                                  }
                                  onChange={(e) => {
                                    handleBatchToPalletForm(
                                      _batchIdx,
                                      ["receivedQty"],
                                      [e.target.value]
                                    );
                                  }}
                                />
                              </td>
                              <td width={100}>
                                <input
                                  id={"palletsCount" + _batchIdx}
                                  type="number"
                                  min={0}
                                  disabled={isDisabled}
                                  className="form-control"
                                  placeholder={"value"}
                                  value={_batch?.palletsCount}
                                  style={
                                    batchError &&
                                    _.isEmpty(_batch?.palletsCount)
                                      ? {
                                          borderColor: "red",
                                        }
                                      : {}
                                  }
                                  onChange={(e) => {
                                    handleBatchToPalletForm(
                                      _batchIdx,
                                      ["palletsCount"],
                                      [e.target.value]
                                    );
                                  }}
                                />
                              </td>
                              <td width={100} align="center">
                                {manualAutomaticAssigment(
                                  _batchIdx,
                                  _batch.palletAssignmentType
                                )}
                              </td>
                              <td align="center" width={10}>
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-circle btn-danger btn-xs"
                                  style={
                                    isDisabled
                                      ? { pointerEvents: "none", opacity: 0.5 }
                                      : {}
                                  }
                                  onClick={() => {
                                    showDeleteDialog(
                                      () => {},
                                      () => {
                                        const _form: IGRNProductWithBatchDetails[] =
                                          cloneDeep(batchToPalletMapping);
                                        _form.splice(_batchIdx, 1);
                                        updateCurrentBatchIndex(
                                          _form.length - 1
                                        );
                                        setBatchToPalletMapping(_form);
                                      },
                                      undefined,
                                      undefined,
                                      _batch?.batchNo
                                    );
                                  }}
                                >
                                  <i className="ti-trash"></i>
                                </a>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <button
                    className="btn btn-success pull-right"
                    style={{ visibility: isDisabled ? "hidden" : "visible" }}
                    onClick={() => {
                      let result = saveBatchDetails();
                      if (result?.result) {
                        exit(result?._form);
                      }
                    }}
                  >
                    {LabelConstants.Save}
                  </button>
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      confirmExit();
                    }}
                  >
                    {LabelConstants.Back}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-12 col-lg-6"
            style={
              isDisabled && hideLocationDetails ? { pointerEvents: "none" } : {}
            }
          >
            <div className="box">
              <div className="box-header bg-info">
                <div className="row">
                  <div className="col-6">
                    <div className="input-group w-p80">
                      <span className="input-group-addon">
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for a location"
                        id={`search`}
                        value={searchLocation}
                        onChange={(event) =>
                          setSearchLocation(event.target.value)
                        }
                      />
                      <span
                        className="input-group-addon"
                        onClick={() => setSearchLocation("")}
                      >
                        <i className="fa fa-close"></i>
                      </span>
                    </div>
                  </div>
                  <h4 className="box-title col-1 mt-5">
                    {LabelConstants.Asile}
                  </h4>
                  <div className="col-5">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setSelectedAisle(e.target.value);
                      }}
                      value={selectedAisle ?? ""}
                    >
                      <option
                        value={""}
                      >{`Select Aisle (${warehouseName})`}</option>
                      {warehouseMap.map((_warehouseMap, _warehouseMapIdx) => {
                        return (
                          <option
                            value={_warehouseMap.asileName}
                            key={_warehouseMapIdx}
                          >
                            Aisle {_warehouseMap.asileName} ({warehouseName})
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row" style={{ minHeight: 600 }}>
                {(warehouseMap ?? []).map((_warehouseMap, _warehouseMapIdx) => {
                  if (_warehouseMap?.asileName !== selectedAisle) return;
                  return (
                    <div className="col-12">
                      <div className="box box-transparent no-shadow">
                        <div className="box p-5">
                          <h4 className="box-title">
                            Aisle {_warehouseMap?.asileName}
                          </h4>
                          <div className="d-flex">
                            <div className="d-flex flex-column-reverse">
                              <div className="section-bx">
                                <div className="w-35 h-35 m-1 d-flex align-items-center justify-content-center border border-warning rounded10" />
                              </div>
                              {(_warehouseMap.bays[0].baysLocations ?? []).map(
                                (_loc, _locIdx) => (
                                  <div className="section-bx">
                                    <div className="w-35 h-35 m-1 d-flex align-items-center justify-content-center border border-warning rounded10">
                                      {_locIdx}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            {(_warehouseMap.bays ?? []).map((_bay, _bayIdx) => {
                              return (
                                <div className="d-flex flex-column-reverse">
                                  <div className="section-bx">
                                    <div className="w-35 h-35 m-1 d-flex align-items-center justify-content-center border border-warning rounded10">
                                      {_bayIdx + 1}
                                    </div>
                                  </div>
                                  {(_bay.baysLocations ?? [])
                                    .filter((_loc) => _loc.deleted === false)
                                    .map((_loc, _locIdx) => {
                                      console.log("HEY", _loc);
                                      console.log("_locin", _locIdx);
                                      console.log("Bay", _bay.baysLocations);
                                      console.log(
                                        "ware",
                                        _warehouseMap.bays[0].baysLocations
                                      );
                                      return (
                                        <div
                                          className={`section-bx ${
                                            searchLocation?.toLowerCase() ===
                                            _loc?.shelfId?.toLowerCase()
                                              ? "b-dashed"
                                              : ""
                                          }`}
                                        >
                                          <a
                                            href="javascript:void(0)"
                                            className={`w-35 h-35 m-1 d-block rounded10 ${
                                              _loc?.status !==
                                              GENERAL_CONSTANTS.IN_USE
                                                ? "bg-warning"
                                                : "bg-warning-light"
                                            }`}
                                            onClick={async () => {
                                              if (
                                                isDisabled &&
                                                !hideLocationDetails &&
                                                selectedShelfs.includes(
                                                  _loc.locId
                                                )
                                              ) {
                                                getLocationDtlsById(_loc);
                                                batchAisleLocationModal.show();
                                                return;
                                              }

                                              handlePalletClicks(
                                                //single click event
                                                async () => {
                                                  if (
                                                    !isPalletAssignedToBatch(
                                                      _loc?.locId
                                                    )
                                                  ) {
                                                    console.log(
                                                      "pallet not assigned to batch!!"
                                                    );
                                                    return;
                                                  }
                                                  showBatchDialog(
                                                    () => {
                                                      batchAisleModal.hide();
                                                    },
                                                    (
                                                      locationInfo: IGRNAisleToBatchLocationInfo[]
                                                    ) => {
                                                      batchAisleModal.hide();
                                                      updateBatchAisleInfo(
                                                        locationInfo
                                                      );
                                                    }
                                                  );
                                                },
                                                //double click event
                                                async () => {
                                                  // let res =
                                                  //   await checkIfPalletHasSameItem(
                                                  //     _loc
                                                  //   );
                                                  // if (!res) {
                                                  //   toast.error(
                                                  //     "Pallet already allocated with different item!!"
                                                  //   );
                                                  //   return;
                                                  // }

                                                  let result =
                                                    await assignPallets(
                                                      _loc,
                                                      _warehouseMap,
                                                      "M",
                                                      true
                                                    );
                                                  if (!result?.status) {
                                                    let message =
                                                      result?.message;
                                                    if (
                                                      batchToPalletMapping?.length <=
                                                      0
                                                    ) {
                                                      message =
                                                        "Create a batch to proceed with allocation!!";
                                                    }
                                                    toast.error(message);
                                                  }
                                                }
                                              );
                                            }}
                                          >
                                            <div
                                              className={`align-items-center justify-content-center d-flex h-p100 rounded10 ${
                                                isPalletAssignedToBatch(
                                                  _loc?.locId
                                                )
                                                  ? "text-success-light bg-success"
                                                  : selectedShelfs.includes(
                                                      _loc.locId
                                                    )
                                                  ? "text-success bg-success-light"
                                                  : ""
                                              }`}
                                            >
                                              {_loc.shelfId}
                                            </div>
                                            <div className="bx-dec">
                                              <div
                                                className={`d-flex align-items-center ${
                                                  _loc?.status !==
                                                  GENERAL_CONSTANTS.IN_USE
                                                    ? "section-dec-loaded"
                                                    : "section-dec"
                                                }`}
                                              >
                                                <div className="dec">
                                                  {showBatchDetails(_loc)}
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="d-flex m-10">
                  <div className="d-flex">
                    <div className="w-30 h-20 bg-warning-light rounded"></div>
                    <h5 className="mx-15 my-0">Free Place</h5>
                  </div>
                  <div className="d-flex">
                    <div className="w-30 h-20 bg-warning rounded"></div>
                    <h5 className="mx-15 my-0">Loaded Place</h5>
                  </div>
                </div>
                {/* <div className="d-flex">
                  <h5 className="mx-15 my-0">
                    {`Pallets - ${getAllocatedLocationsForBatch()}`}
                  </h5>
                  <h5 className="mx-15 my-0">
                    {`Selected - ${selectedShelfs}`}
                  </h5>
                  <h5 className="mx-15 my-0"></h5>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteConfirmationComponent
        title={deleteDialoginfo?.title}
        cancel={deleteDialoginfo?.cancel}
        confirm={deleteDialoginfo?.confirm}
        header={deleteDialoginfo?.header}
        title_prefix={deleteDialoginfo?.title_prefix}
      />
      <GRNAisleBatchDetailsComponent data={aisleToBatchdialoginfo} />
      <GRNAisleBatchLocationDetailsComponent
        close={() => batchAisleLocationModal.hide()}
      />
    </div>
  );
};
export default GrnBatchComponent;
