import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import PageSortDescription from "../common/pageSortDescription.component";
import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { GenericHelperService } from "../../api/generics/helper.service";
import {
  ICustomer,
  IListConfig,
  IListConfigRO,
  IStatusUpdate,
} from "../../common/models";
import { EmptyStatusUpdate } from "../../common/constants/generalConstants";
import moment from "moment";
import { ReturnOrdersContext } from "../../controllers/contexts/returnorders.context";
import ReturnOrdersStatusUpdateComponent from "./returnOrdersStatusUpdate.component";
import { IReturnOrders } from "../../common/models/returnorders";
import useAuth from "../../controllers/hooks/useAuth";
import LogsModalComponent from "../common/logsModal.component";

const ReturnOrdersComponent = () => {
  const helperService: GenericHelperService = new GenericHelperService();
  const {
    returnOrdersList,
    filters,
    setSelectedList,
    setFilters,
    returnOrdersForm,
    setReturnOrdersForm,
    newReturnOrders,
    deleteReturnOrder,
    viewReturnOrder,
    rofilters,
    setROFilters,
    returnLogs,
    getReturnLogs,
    productCateList,
    roproductsList,
    queryROData,
    customersList,
  } = useContext(ReturnOrdersContext);
  console.log("ReturnOrderList", returnOrdersList);
  const navigate = useNavigate();
  const { isAccessDisabled } = useAuth();
  const [tempStatus, setTempStatus] = useState("");
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const [showLogsModal, setShowLogsModal] = useState(false);
  const handleShowLogsModal = (_ro: IReturnOrders) => {
    getReturnLogs(_ro.id);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };

  const setUpdateStatus = (prod: IReturnOrders) => {
    setTempStatus(prod.status);
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.id];
    _status.status = prod.status;
    setSelectedList(_status);
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };

  const handleQueryChange = (type: string, value: any) => {
    const _rofilter: IListConfigRO = cloneDeep(rofilters);
    _rofilter[type] = value;
    setROFilters(_rofilter);
  };

  const clearSearch = () => {
    const _rofilter: IListConfigRO = cloneDeep(rofilters);
    _rofilter.returnOrderNum = "";
    _rofilter.customerId = 0;
    _rofilter.soNum = "";
    _rofilter.deliveryNum = "";
    _rofilter.fromDate = "";
    _rofilter.toDate = "";
    _rofilter.search = false;
    setROFilters(_rofilter);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      rofilters?.returnOrderNum === "" &&
      rofilters?.customerId === 0 &&
      rofilters?.soNum === "" &&
      rofilters?.deliveryNum === "" &&
      rofilters?.fromDate === "" &&
      rofilters?.toDate === ""
    ) {
      result = true;
    }
    return result;
  };

  const cancelDelete = () => {
    setReturnOrdersForm(null);
  };

  const confirmDelete = () => {
    deleteReturnOrder();
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.ReturnOrders}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="row">
                  <div className="col-lg-10 d-flex">
                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={rofilters?.returnOrderNum}
                        onChange={(e) =>
                          handleQueryChange("returnOrderNum", e.target.value)
                        }
                      >
                        <option value={""}>RO No</option>
                        {returnOrdersList?.map(
                          (_order: IReturnOrders, _orderIdx: number) => {
                            return (
                              <option
                                key={`roStatus${_orderIdx}`}
                                value={_order?.returnOrderNum}
                              >
                                {_order?.returnOrderNum}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>

                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={rofilters?.customerId}
                        onChange={(e) =>
                          handleQueryChange("customerId", e.target.value)
                        }
                      >
                        <option value={""}>Customer</option>
                        {customersList?.map(
                          (_customer: ICustomer, _customerId: number) => {
                            return (
                              <option
                                key={`roStatus${_customerId}`}
                                value={_customer?.custId}
                              >
                                {_customer?.custName}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={rofilters?.soNum}
                        onChange={(e) =>
                          handleQueryChange("soNum", e.target.value)
                        }
                      >
                        <option value={""}>SO No.</option>
                        {[
                          ...new Set(
                            returnOrdersList?.map((item) => item.soNum)
                          ),
                        ]?.map((soNum: string, _soNumIdx: number) => {
                          return (
                            <option key={`roStatus${_soNumIdx}`} value={soNum}>
                              {soNum}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={rofilters?.deliveryNum}
                        onChange={(e) =>
                          handleQueryChange("deliveryNum", e.target.value)
                        }
                      >
                        <option value={""}>DO No.</option>
                        {[
                          ...new Set(
                            returnOrdersList?.map((item) => item.deliveryNum)
                          ),
                        ]?.map(
                          (deliveryNum: string, _deliveryNumIdx: number) => {
                            return (
                              <option
                                key={`roStatus${_deliveryNumIdx}`}
                                value={deliveryNum}
                              >
                                {deliveryNum}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>

                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="From Date"
                        value={rofilters?.fromDate}
                        onChange={(event) =>
                          handleQueryChange("fromDate", event.target.value)
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        From
                      </i>
                    </div>

                    <div className="input-group w-p30 ms-10">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="To Date"
                        value={rofilters?.toDate}
                        onChange={(event) =>
                          handleQueryChange("toDate", event.target.value)
                        }
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: -15,
                          left: 0,
                          fontSize: 10,
                        }}
                      >
                        To
                      </i>
                    </div>

                    <div className="input-group w-p10 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success rounded"
                        disabled={checkIfSearchStringsAreEmpty()}
                        onClick={() => {
                          setFilters({
                            ...filters,
                            currentPage: 1,
                          });
                          if (rofilters?.search) {
                            queryROData();
                          }
                          handleQueryChange("search", true);
                        }}
                      >
                        {LabelConstants.Search}
                      </button>
                    </div>
                    <div className="input-group w-p50 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success-light rounded"
                        onClick={() => {
                          clearSearch();
                        }}
                      >
                        {LabelConstants.Clear}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-2 d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isAccessDisabled("RO", "create")}
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        newReturnOrders();
                        navigate(RoutingConstants.returnordersCreate);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      {`${LabelConstants.Create} ${LabelConstants.ReturnOrders}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-bordered mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th onClick={() => handleSort("returnOrderNum")}>
                          {"RO No."}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "returnOrderNum"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("roCreatedDate")}>
                          {"Created"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "roCreatedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("modifiedDate")}>
                          {"Modified"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "modifiedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("soNum")}>
                          {"SO Num"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "soNum"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("customerName")}>
                          {"Cust. Name"}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "customerName"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("deliveryNum")}>
                          {"DO. No."}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "deliveryNum"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("totalAmount")}>
                          {"Return Amt."}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "totalAmount"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("status")}>
                          {"Status"}
                          <i className={`ms-2 fa fa-sort$`} />
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "status"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {returnOrdersList &&
                        returnOrdersList.map((_ro, _roIdx) => {
                          return (
                            <tr key={_roIdx}>
                              <td>
                                {(filters?.currentPage - 1) *
                                  filters?.currentPageSize +
                                  _roIdx +
                                  1}
                              </td>
                              <td>{_ro?.returnOrderNum}</td>
                              <td>
                                {moment(_ro?.roCreatedDate).format(
                                  "MM/DD/yyyy"
                                )}
                              </td>
                              <td>
                                {moment(_ro?.modifiedDate).format("MM/DD/yyyy")}
                              </td>
                              <td>{_ro?.soNum}</td>
                              <td>{_ro?.customerName ?? ""}</td>
                              <td>{_ro?.deliveryNum ?? ""}</td>
                              <td>
                                {(_ro?.totalAmount)
                                  .toFixed(2)
                                  .replace(/\.00$/, "")}
                              </td>
                              <td>
                                <span
                                  style={
                                    isAccessDisabled("RO", "updateStatus")
                                      ? { pointerEvents: "none", opacity: 0.5 }
                                      : {}
                                  }
                                  role="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#updateStatus"
                                  onClick={() => setUpdateStatus(_ro)}
                                  className={`badge bg-${helperService.getStatusColor(
                                    _ro.status
                                  )}-light rounded-pill`}
                                >
                                  {_ro.status}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="button"
                                    disabled={isAccessDisabled("RO", "view")}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    onClick={() => {
                                      viewReturnOrder(_ro, () => {
                                        navigate(
                                          RoutingConstants.returnordersDetails
                                        );
                                      });
                                    }}
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    disabled={
                                      _ro?.status !== "DRAFT" ||
                                      isAccessDisabled("RO", "edit")
                                    }
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    onClick={() => {
                                      viewReturnOrder(_ro, () => {
                                        navigate(
                                          RoutingConstants.returnordersEdit
                                        );
                                      });
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    // disabled={isAccessDisabled(
                                    //   "RO",
                                    //   "print",
                                    // )}
                                    onClick={() => {
                                      navigate(
                                        RoutingConstants.returnordersPrint,
                                        {
                                          state: { selectedItem: _ro },
                                        }
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-print"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    disabled={
                                      _ro?.status !== "DRAFT" ||
                                      isAccessDisabled("RO", "delete")
                                    }
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setReturnOrdersForm(cloneDeep(_ro));
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmation"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setcreatedDate(_ro?.createdDate);
                                      setcreatedBy(_ro?.createdBy);
                                      handleShowLogsModal(_ro);
                                    }}
                                  >
                                    <i
                                      className="fa fa-book"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filters}
                      dataList={returnOrdersList}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {filters.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          forcePage={filters?.currentPage - 1}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            filters && filters.totalPageCount < 2
                              ? 0
                              : filters.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setFilters({
                              ...filters,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReturnOrdersStatusUpdateComponent tempStatus={tempStatus} />
      <DeleteConfirmationComponent
        title={returnOrdersForm?.returnOrderNum}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={returnLogs}
        productsList={roproductsList}
        productCateList={productCateList}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default ReturnOrdersComponent;
