import { useContext, useEffect, useState } from "react";
import {
  EmptyGoodsMovementReport,
  EmptyListConfigGoodsMovementRpt,
  GoodsMovementReportKeys,
  MovementTypes,
  PlanningReportKeys,
} from "../../../common/constants/generalConstants";
import {
  IListConfig,
  IListConfigGoodsMovementRpt,
  IProducts,
} from "../../../common/models";
import { cloneDeep } from "lodash";
import { ReportsContext } from "../../../controllers/contexts/reports.context";
import { IGoodsMovementReport } from "../../../common/models/reports";
import moment from "moment";
import _ from "lodash";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { isNullUndefined } from "../../../common/methods/globalmethods";
import Select from "react-select";

const GoodsMovementViewComponent = () => {
  const {
    masterFilter,
    setMasterfilter,
    filteredMasterData,
    getGoodsMovementReports,
    clearMasterData,
    setFilteredMasterData,
    masterData,
    products,
  } = useContext(ReportsContext);

  const [filter, setfilter] = useState<IListConfigGoodsMovementRpt>(
    EmptyListConfigGoodsMovementRpt
  );

  useEffect(() => {
    clearSearch();
  }, []);

  const handleQueryChange = (
    type: string,
    value: any,
    search: boolean = false
  ) => {
    const _filter: IListConfigGoodsMovementRpt = cloneDeep(filter);
    if (search) {
      _filter.searchData[type] = value;
      searchData(_filter);
    } else {
      _filter[type] = value;
    }
    setfilter(_filter);
  };

  const returnSearchFilter = (_filter: IGoodsMovementReport) => {
    let tempfilter: IGoodsMovementReport = cloneDeep(_filter);
    for (const [key, value] of Object.entries(tempfilter)) {
      if (typeof value === "string" && _.isEmpty(value)) {
        delete tempfilter[key];
      } else if (Array.isArray(value) && value?.length <= 0) {
        delete tempfilter[key];
      } else if (typeof value === "number" && value <= 0) {
        delete tempfilter[key];
      } else if (value === null || value === undefined) {
        delete tempfilter[key];
      }
    }
    return tempfilter;
  };

  const searchData = (_filter: IListConfigGoodsMovementRpt) => {
    let tempFilter: IGoodsMovementReport = returnSearchFilter(
      _filter.searchData
    );
    let _filteredList = masterData?.filter((_item: any, _itemIdx: number) => {
      let result = true;
      for (const [key, value] of Object.entries(tempFilter)) {
        const data = _item[key]?.toString()?.toLowerCase();
        result = result && data?.includes(value?.toLowerCase());
      }
      return result;
    });
    setFilteredMasterData(_filteredList);
  };

  const clearSearch = () => {
    const _filter: IListConfigGoodsMovementRpt = cloneDeep(filter);
    _filter.prodId = null;
    _filter.movementType = "";
    _filter.searchData = cloneDeep(EmptyGoodsMovementReport);
    setfilter(_filter);
    clearMasterData();
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filter: IListConfig = cloneDeep(masterFilter);
    if (_filter.sortItem === column) {
      _filter.sortDirection = _filter.sortDirection === "asc" ? "desc" : "asc";
      _filter.sortIsDate = isDate;
    } else {
      _filter.sortItem = column;
      _filter.sortDirection = "asc";
      _filter.sortIsDate = isDate;
    }
    setMasterfilter(_filter);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      (isNullUndefined(filter?.prodId) || filter?.prodId === 0) &&
      filter?.movementType === ""
    ) {
      result = true;
    }
    return result;
  };

  return (
    <>
      <div className="box">
        <div className="box-body">
          <div className="col-lg-12 d-flex">
            <div className="col-3">
              <Select
                value={products?.filter(
                  (option: any) => option.value === filter?.prodId
                )}
                placeholder="Product"
                options={products}
                isSearchable={true}
                onChange={(e: any) => handleQueryChange("prodId", e.value)}
              />
            </div>
            <div className="input-group w-p45 ms-10">
              <select
                className="form-select"
                value={filter?.movementType}
                onChange={(e) =>
                  handleQueryChange("movementType", e.target.value)
                }
              >
                <option value={""}>Movement Type</option>
                {MovementTypes?.map((item: any, id: number) => {
                  return (
                    <option key={`status${id}`} value={item?.value}>
                      {item?.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-group w-p10 ms-10">
              <button
                type="button"
                className="waves-effect waves-light btn btn-success rounded"
                disabled={checkIfSearchStringsAreEmpty()}
                onClick={() => {
                  getGoodsMovementReports(filter);
                }}
              >
                {LabelConstants.Search}
              </button>
            </div>

            <div className="input-group w-p10 ms-10">
              <button
                type="button"
                className="waves-effect waves-light btn btn-success-light rounded"
                onClick={() => {
                  clearSearch();
                }}
              >
                {LabelConstants.Clear}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="box-body">
          <div className="table-responsive">
            <table
              className="table table-hover table-bordered mb-0"
              style={{ textAlign: "center" }}
            >
              <thead className="table-primary">
                <tr>
                  <th>S.No</th>
                  {GoodsMovementReportKeys?.map((item) => (
                    <th onClick={() => handleSort(item.key)}>
                      {item.placeholder}
                      <i
                        className={`ms-2 fa fa-sort${
                          masterFilter?.sortItem === item.key
                            ? `-${masterFilter?.sortDirection} text-primary`
                            : " text-fade"
                        }`}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr key={"test"}>
                  <td></td>
                  {GoodsMovementReportKeys?.map((item) => (
                    <td>
                      <input
                        className="form-control"
                        placeholder={item?.placeholder}
                        value={filter?.searchData[item.key] ?? ""}
                        onChange={(event) => {
                          handleQueryChange(item.key, event.target.value, true);
                        }}
                      />
                    </td>
                  ))}
                </tr>
                {filteredMasterData?.map(
                  (_item: IGoodsMovementReport, _itemIdx: number) => {
                    return (
                      <tr key={_itemIdx}>
                        <td>{_itemIdx + 1}</td>
                        {GoodsMovementReportKeys?.map((keydata) => {
                          if (
                            keydata.type === "date" ||
                            keydata.type === "datetime"
                          ) {
                            return (
                              <td>
                                {!isNullUndefined(_item[keydata.key])
                                  ? moment(_item[keydata.key]).format(
                                      keydata.type === "datetime"
                                        ? "DD/MM/yyyy hh:mm:ss"
                                        : "DD/MM/yyyy"
                                    )
                                  : ""}
                              </td>
                            );
                          } else {
                            if (
                              (keydata.key === "fromWarehouseName" ||
                                keydata.key === "toWarehouseName") &&
                              _item["movementType"] === "L2L"
                            )
                              return <td>{_item[keydata.l2ldata] ?? ""}</td>;
                            else return <td>{_item[keydata.key] ?? ""}</td>;
                          }
                        })}
                      </tr>
                    );
                  }
                )}
                {(isNullUndefined(filteredMasterData) ||
                  filteredMasterData?.length <= 0) && (
                  <tr>
                    <td
                      colSpan={GoodsMovementReportKeys?.length + 1}
                      align="center"
                    >
                      No data to display!!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoodsMovementViewComponent;
