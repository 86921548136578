import {
  OrdersApiBase,
  purchaseOrderList,
  purchaseOrderSave,
  purchaseOrderUpdate,
  purchaseOrderUpdateById,
  getNextPoNumber,
  poSearch,
} from "../../common/constants/apiConstants";
import { IListConfigPO, IPurchase, IStatusUpdate } from "../../common/models";
import { AxiosService } from "../generics/axios.service";
import { IPurchaseService } from "../interfaces/IPurchase";

export class PurchaseService {
  private axiosService: AxiosService = new AxiosService();

  async getPurchase(pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(
        purchaseOrderList(pageNo),
        OrdersApiBase
      );
      return result?.data;
    } catch (error) {
      console.error("Error: Purchase order List - ", error);
      return null;
    }
  }

  async searchPOOrders(filter: IListConfigPO, pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        poSearch(pageNo),
        filter,
        OrdersApiBase
      );
      return result?.data;
    } catch (error) {
      console.error("Error: SearchPOOrders List - ", error);
      return null;
    }
  }

  async savePurchase(purchaseOrder: IPurchase): Promise<string | number> {
    try {
      if (!purchaseOrder?.poNum)
        return await this.axiosService.Post(
          purchaseOrderSave,
          purchaseOrder,
          OrdersApiBase
        );
      else {
        const _result = await this.axiosService.Put(
          purchaseOrderUpdateById(purchaseOrder?.poNum),
          purchaseOrder,
          OrdersApiBase
        );
        return _result.data.poNum;
      }
    } catch (error) {
      console.error("Error: Save Purchase order - ", error.response.data);
      return error.response.data?.errorMessage ?? "Error saving Purchase.";
    }
  }
  async createNewPurchaseOrder(
    newPurchaseOrder: any
  ): Promise<string | number> {
    try {
      const result: any = await this.axiosService.Post(
        purchaseOrderSave,
        newPurchaseOrder,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Save Purchase order - ", error.response.data);
      return (
        error.response.data[0]?.errorMessage ??
        "Error saving new Purchase Order."
      );
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Post(purchaseOrderUpdate, status, OrdersApiBase);
      return true;
    } catch (error) {
      console.error(
        "Error: Update PO Status - ",
        error.response.data[0]?.errorMessage
      );
      return error.response.data[0]?.errorMessage ?? false;
    }
  }

  async getNextPoNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextPoNumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next PO Number - ", error);
      return [];
    }
  }
}
