import { Authenticate, changePassword, resetPassword, updatePassword } from "../../common/constants/apiConstants";
import { IChangePwdRequest, IContextInfo, IForgotPwdRequest, ILoginRequest } from "../../common/models";
import { AxiosService } from "../generics/axios.service";
import { IAuthService } from "../interfaces/IAuth";

export class AuthService implements IAuthService {
  private axiosService: AxiosService = new AxiosService();

  async authenticate(authRequest: ILoginRequest): Promise<any> {
    try {
      const result: any = await this.axiosService.PostAnonymous(
        Authenticate,
        authRequest
      );
      return result;
    } catch (error) {
      console.error("Error: Login - ", error);
      return null;
    }
  }

  async passwordReset(username: string,newPassword:string): Promise<any> {
    let authRequest = {username:username,newPassword:newPassword}
    try {
      const result: any = await this.axiosService.PostAnonymous(
        updatePassword,
        authRequest
      );
      return result;
    } catch (error) {
      console.error("Error: Forgot Password - ", error);
      return error;
    }
  }

  async forgotPasswordStep1(username:string,passcode:number): Promise<any> {
    let authRequest = {username:username,passcode:passcode}
    try {
      const result: any = await this.axiosService.PostAnonymous(
        resetPassword,
        authRequest
      );
      return result;
    } catch (error) {
      console.error("Error: Forgot Password - ", error);
      return error;
    }
  }

  async changePasswordStep1(username:string,oldPassword:string,passcode:number): Promise<any> {
    let authRequest = {username:username,oldPassword:oldPassword,passcode:passcode}
    try {
      const result: any = await this.axiosService.PostAnonymous(
        changePassword,
        authRequest
      );
      return result;
    } catch (error) {
      console.error("Error: Change Password - ", error);
      return error;
    }
  }
  async changePassword(username: string,newPassword:string): Promise<any> {
    let authRequest = {username:username,newPassword:newPassword}
    try {
      const result: any = await this.axiosService.PostAnonymous(
        changePassword,
        authRequest
      );
      return result;
    } catch (error) {
      console.error("Error: Change Password - ", error);
      return error;
    }
  }
  
}
