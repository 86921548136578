import { Outlet } from "react-router-dom";
import { DeliveryOrderContext } from "../../controllers/contexts/deliveryorder.context";
import useDeliveryOrder from "../../controllers/hooks/useDeliveryOrder";

const DeliveryOrderLayoutComponent = () => {
  const DeliveryOrderFunctions = useDeliveryOrder();

  return (
    <DeliveryOrderContext.Provider value={DeliveryOrderFunctions}>
      <Outlet />
    </DeliveryOrderContext.Provider>
  );
};

export default DeliveryOrderLayoutComponent;
