import React, { useContext, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import {
  getDateIfValid,
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import { DatePicker } from "antd";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { toast } from "react-toastify";
const OrderPickingDetailsComponent = () => {
  const location = useLocation();
  const isEditableOP = location.state.isEditable;
  const orderPickingData = location.state.op;
  const {
    productsList,
    productCateList,
    salesOrdersListAll,
    updateNewOrderPicking,
    clearLocations,
    salesOrderProducts,
    getProductLocations,
    productLocations,
    getProductsBySO,
  } = useContext(OrderPickingContext);
  const { warehouseList } = useContext(WarehouseContext);
  const [updateOP, setUpdateOP] = useState<any>(orderPickingData);
  const [activePrs, setActivePrs] = useState<any>([]);
  const [tempProds, setTempProds] = useState<any>([]);
  const [pickedLocations, setPickedLocations] = useState<any>([]);
  const navigate = useNavigate();

  const handleOPCreatedDate = (e: any) => {
    let _updateOP = { ...updateOP };
    _updateOP.pickingDate = e;
    setUpdateOP(_updateOP);
  };
  const handleOPStatus = (e: any) => {
    let _updateOP = { ...updateOP };
    _updateOP.status = e.target.value;
    setUpdateOP(_updateOP);
  };
  const handleDescriptionChange = (e: any) => {
    let _updateOP = { ...updateOP };
    _updateOP.description = e.target.value;
    setUpdateOP(_updateOP);
  };
  const handlePrLocations = (prId: any, Qty: any) => {
    let _activePrs = [...activePrs];
    let prIndex = _activePrs.indexOf(prId);
    if (prIndex > -1) {
      _activePrs.splice(prIndex, 1);
    } else {
      _activePrs.push(prId);
      if (!tempProds.includes(prId)) {
        getProductLocations(
          updateOP.pickingType,
          prId,
          Qty,
          updateOP.warehouseId
        );
      }
    }
    setActivePrs(_activePrs);
    let _tempPr = [...tempProds];
    _tempPr.push(prId);
    setTempProds(_tempPr);
  };
  const handleLocCheckbox = (loc: any, e: any) => {
    let _pickedLocs = { ...updateOP };
    // [...updateOP.orderPickingDetails];
    if (e.target.checked) {
      _pickedLocs.orderPickingDetails.push({
        id: loc.id,
        orderPickId: null,
        locationId: loc.locationId,
        shelfId: loc.shelfId,
        prodId: loc.prodId,
        prodCatId: loc.prodCatId,
        saleOrderQty: getProductQty(loc),
        batchNo: loc.batchNo,
        batchExpiry: loc.batchExpiryDate,
        availableQty: loc.quantity,
        pickingQty: 0,
        quantityBreakDown: "",
      });
    } else {
      const _locIdx = _pickedLocs.orderPickingDetails.findIndex(
        (_itm) => _itm.locationId === loc.locationId
      );
      if (_locIdx > -1) {
        _pickedLocs.orderPickingDetails.splice(_locIdx, 1);
      }
    }
    // setPickedLocations(_pickedLocs);
    setUpdateOP(_pickedLocs);

    // let _updateOP = { ...updateOP };
    // _updateOP.orderPickingDetails.findIndex(
    //   (_itm) => _itm.prodId === loc.prodId
    // );
  };
  const getProductQty = (_prod: any) => {
    let qty = 0;
    salesOrderProducts.map((soPr) => {
      if (soPr.prodId === _prod.prodId) {
        qty = soPr.orderedQty;
      }
    });
    return qty;
  };
  const handlePickedQty = (prod: any, e: any) => {
    if (e.target.value <= prod.availableQty) {
      let _updateOP = { ...updateOP };
      _updateOP.orderPickingDetails.map((_loc) => {
        if (_loc.locationId === prod.locationId) {
          _loc.pickingQty = e.target.value;
        }
      });
      setUpdateOP(_updateOP);
    } else {
      toast.error("Please enter valid quantity");
    }
  };
  const handleqtyBreakDown = (prod: any, e: any) => {
    let _updateOP = { ...updateOP };
    _updateOP.orderPickingDetails.map((_loc) => {
      if (_loc.locationId === prod.locationId) {
        _loc.quantityBreakDown = e.target.value;
      }
    });
    setUpdateOP(_updateOP);
  };
  const handleSaveOrderPicking = () => {
    if (
      updateOP.orderPickingDetails.every((order: any) => order.pickingQty > 0)
    ) {
      updateNewOrderPicking(updateOP);
    } else {
      toast.error("Please select mandatory fields");
    }
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.orderPickingList}
                    >
                      Order Picking
                    </NavLink>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Order Picking Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {isEditableOP && (
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => handleSaveOrderPicking()}
            >
              Save changes
            </button>
          )}
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Order Picking </h4>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-right"
                  disabled={updateOP.status !== "DRAFT" || !isEditableOP}
                  onClick={() =>
                    getProductsBySO(updateOP.soNum, updateOP?.warehouseId)
                  }
                >
                  Add Product
                </button>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Batch</th>
                        <th>Location</th>
                        {/* <th>Pallet</th> */}
                        <th>Ordered Qty</th>
                        <th>Available Qty</th>
                        <th>Picking Qty</th>
                        {/* <th>Qty Breakdown</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {updateOP && updateOP.orderPickingDetails.length > 0 ? (
                        updateOP.orderPickingDetails.map(
                          (_prod: any, j: number) => {
                            return (
                              <tr key={j}>
                                <td>{j + 1}</td>
                                <td className="text-start">
                                  {getProductCatNameById(
                                    _prod.prodCatId,
                                    productCateList
                                  )}
                                </td>
                                <td className="text-start">
                                  {getProductNameById(
                                    _prod.prodId,
                                    productsList
                                  )}
                                </td>
                                <td>{_prod.batchNo}</td>
                                <td>{_prod?.shelfId}</td>
                                <td>{_prod.saleOrderQty}</td>
                                <td>{_prod.availableQty}</td>
                                <td>
                                  <input
                                    type="number"
                                    readOnly={!isEditableOP}
                                    className="form-control"
                                    value={_prod.pickingQty}
                                    onChange={(e) => handlePickedQty(_prod, e)}
                                  />
                                </td>
                                {/* <td>
                                  <input
                                    type="text"
                                    readOnly={!isEditableOP}
                                    className="form-control"
                                    value={_prod.quantityBreakDown}
                                    onChange={(e) =>
                                      handleqtyBreakDown(_prod, e)
                                    }
                                  />
                                </td> */}
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={10} align="center">
                            <br />
                            <p>No products added to Order Picking</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => navigate(RoutingConstants.orderPickingList)}
                  >
                    Close
                  </button>
                  {isEditableOP && (
                    <button
                      type="button"
                      className="btn btn-success float-end"
                      onClick={() => {
                        handleSaveOrderPicking();
                      }}
                    >
                      Save changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">Order Picking Details</h4>
                <p className="p-0 m-0">
                  #{LabelConstants.PoNumber} : {updateOP.pickingNum}
                </p>
              </div>
              <div className="box-body">
                <div className="row align-items-center">
                  <div className="col-4">
                    <label className="form-label">
                      Picking Type
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      disabled
                      onChange={() => {}}
                      value={updateOP.pickingType}
                    >
                      <option value="AUTO">AUTO</option>
                      <option value="MANUAL">MANUAL</option>
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Sales Order
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      disabled
                      onChange={() => {}}
                      value={updateOP.soNum + "-" + updateOP.customerId}
                    >
                      <option value={""}>--</option>
                      {salesOrdersListAll &&
                        salesOrdersListAll.length > 0 &&
                        salesOrdersListAll.map((so: any, i: number) => {
                          if (so.status !== "DRAFT") {
                            return (
                              <option
                                key={i}
                                value={so.soNum + "-" + so.customerId}
                              >
                                {so.soNum} | {so.customerName}
                              </option>
                            );
                          }
                        })}
                    </select>
                  </div>
                </div>
                <div className="row mt-20 align-items-center">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.CreatedDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(updateOP.pickingDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleOPCreatedDate(date);
                      }}
                      disabled={!isEditableOP}
                    />
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Status
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      disabled={!isEditableOP}
                      onChange={(e) => {
                        handleOPStatus(e);
                      }}
                      value={updateOP.status}
                    >
                      {updateOP.status === "DRAFT" ? (
                        <>
                          <option value={"DRAFT"}>DRAFT</option>
                          <option value={"PICKING"}>PICKING</option>
                          <option value={"CANCELLED"}>CANCELLED</option>
                        </>
                      ) : updateOP.status === "PICKING" ? (
                        <>
                          <option value={"PICKING"}>PICKING</option>
                          <option value={"PART_PICKED"}>
                            PARTIALLY PICKED
                          </option>
                          <option value={"PICKED"}>PICKED</option>
                          <option value={"CANCELLED"}>CANCELLED</option>
                        </>
                      ) : updateOP.status === "PART_PICKED" ? (
                        <>
                          <option value={"PART_PICKED"}>
                            PARTIALLY PICKED
                          </option>
                          <option value={"PICKED"}>PICKED</option>
                        </>
                      ) : updateOP.status === "PICKED" ? (
                        <>
                          <option value={"PICKED"}>PICKED</option>
                          <option value={"READY_FOR_DISPATCH"}>
                            READY FOR DISPATCH
                          </option>
                        </>
                      ) : updateOP.status === "READY_FOR_DISPATCH" ? (
                        <option value={"READY_FOR_DISPATCH"}>
                          READY FOR DISPATCH
                        </option>
                      ) : updateOP.status === "DISPATCHED" ? (
                        <option value={"DISPATCHED"}>DISPATCHED</option>
                      ) : updateOP.status === "CANCELLED" ? (
                        <option value={"CANCELLED"}>CANCELLED</option>
                      ) : updateOP.status === "PART_DISPATCHED" ? (
                        <option value={"PART_DISPATCHED"}>
                          PART_DISPATCHED
                        </option>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      WareHouse Name
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      disabled
                      onChange={() => {}}
                      value={updateOP.warehouseId}
                    >
                      <option value={""}>--</option>
                      {warehouseList.length > 0 &&
                        warehouseList.map((wereHouse: any, i: number) => {
                          return (
                            <option key={i} value={wereHouse.wareHouseId}>
                              {wereHouse.warehouseName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      Description
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      readOnly={!isEditableOP}
                      value={updateOP.description}
                      onChange={(e) => handleDescriptionChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products list modal */}
      <div className="modal modal-center fade overflow-auto" id="modal-right">
        <div
          className="modal-dialog modal-lg"
          style={{ width: "1000px", maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  clearLocations();
                  setActivePrs([]);
                  setTempProds([]);
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <table className="table table-bordered text-center">
                <thead className="table-primary">
                  <tr>
                    <th>S.No</th>
                    <th>Product Category</th>
                    <th>Product Name</th>
                    <th>Ordered Qty</th>
                    <th>Received Qty</th>
                    <th>Remaining Qty</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {salesOrderProducts.length > 0 ? (
                    salesOrderProducts.map((pr: any, i: number) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {getProductCatNameById(
                                pr.prodCatId,
                                productCateList
                              )}
                            </td>
                            <td>
                              {getProductNameById(pr.prodId, productsList)}
                            </td>
                            <td>{pr.orderedQty}</td>
                            <td>{pr.receivedQty}</td>
                            <td>{pr.orderedQty - pr.receivedQty}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handlePrLocations(pr.prodId, pr.orderedQty)
                                }
                              >
                                Locations
                              </button>
                            </td>
                          </tr>
                          {activePrs.includes(pr.prodId) && (
                            <tr style={{ background: "#f8f8f8" }}>
                              <td colSpan={7}>
                                <table className="table table-bordered m-0">
                                  <thead className="table-secondary">
                                    <tr>
                                      <th></th>
                                      <td>Location ID</td>
                                      {/* <td>GRN No</td> */}
                                      <td>Location</td>
                                      <td>Batch No</td>
                                      <td>Pallet Ref No</td>
                                      <td>Qty</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {productLocations.length > 0 ? (
                                      productLocations.map(
                                        (loc: any, j: number) => {
                                          if (loc.prodId === pr.prodId) {
                                            return (
                                              <tr>
                                                <td>
                                                  <input
                                                    type="checkbox"
                                                    id={`md_checkbox_all${j}`}
                                                    className="chk-col-primary"
                                                    checked={
                                                      updateOP.orderPickingDetails.findIndex(
                                                        (_itm: any) =>
                                                          _itm.locationId ===
                                                          loc.locationId
                                                      ) >= 0
                                                    }
                                                    onChange={(e) => {
                                                      handleLocCheckbox(loc, e);
                                                    }}
                                                  />
                                                  <label
                                                    htmlFor={`md_checkbox_all${j}`}
                                                    style={{
                                                      paddingLeft: "20px",
                                                      height: "15px",
                                                    }}
                                                  ></label>
                                                </td>
                                                <td>{loc.locationId}</td>
                                                {/* <td>{loc.grnNum}</td> */}
                                                <td>{loc.shelfId}</td>
                                                <td>{loc.batchNo}</td>
                                                <td>{loc.palletRefNum}</td>
                                                <td>{loc.quantity}</td>
                                              </tr>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={6}>
                                          Product not Available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}> No Products Available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer modal-footer-uniform">
              {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button> */}
              <button
                type="button"
                className="btn btn-primary float-end"
                data-bs-dismiss="modal"
                onClick={() => {
                  clearLocations();
                  setActivePrs([]);
                  setTempProds([]);
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderPickingDetailsComponent;
