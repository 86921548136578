import {
  OrdersApiBase,
  samplingUpdateById,
  SamplingList,
  samplingSave,
  samplingStatusUpdate,
  BatchList,
  getNextSaNumber,
  Logs,
  MAX_PAGE_RECORD_SIZE,
  searchSamplingByCriteria,
} from "../../common/constants/apiConstants";
import { IListConfigSampling, IStatusUpdate } from "../../common/models";
import { ISampling } from "../../common/models/sampling";
import { AxiosService } from "../generics/axios.service";
import { ISamplingService } from "../interfaces/ISampling";

export class SamplingService implements ISamplingService {
  private axiosService: AxiosService = new AxiosService();

  async getBatchDetails(): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(BatchList, OrdersApiBase);
      const _batchList: any = result.data ?? [];
      console.log("Response: Batch List - ", _batchList);
      return _batchList;
    } catch (error) {
      console.error("Error: Batch  List - ", error);
      return [];
    }
  }
  async getLogs(id: string): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Get(Logs(id), OrdersApiBase);
      const _list: any[] = result.data ?? [];
      console.log("Response: Dosage Forms List - ", _list);
      return _list;
    } catch (error) {
      console.error("Error: Dosage Forms List - ", error);
      return error.response.data[0]?.errorMessage ?? [];
    }
  }

  async getSampling(pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(
        SamplingList(pageNo),
        OrdersApiBase
      );
      return result.data ?? null;
    } catch (error) {
      console.error("Error: Sampling  List - ", error);
      return null;
    }
  }

  async searchSampList(
    filter: IListConfigSampling,
    pageNo: number,
    maxSize: number = MAX_PAGE_RECORD_SIZE
  ): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        searchSamplingByCriteria(pageNo, maxSize),
        filter,
        OrdersApiBase
      );
      return result?.data ?? null;
    } catch (error) {
      console.error("Error: searchSampList List - ", error);
      return null;
    }
  }

  async saveSampling(sampling: ISampling): Promise<boolean> {
    try {
      if (!sampling?.samplingId)
        await this.axiosService.Post(samplingSave, sampling, OrdersApiBase);
      else
        await this.axiosService.Put(
          samplingUpdateById(sampling?.samplingId),
          sampling,
          OrdersApiBase
        );
      return true;
    } catch (error) {
      console.error("Error: Save sampling - ", error);
      return false;
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Post(samplingStatusUpdate, status, OrdersApiBase);
      return true;
    } catch (error) {
      console.error(
        "Error: Update Sampling Status - ",
        error.response.data[0]?.errorMessage
      );
      return error.response.data[0]?.errorMessage ?? false;
    }
  }

  async getNextSaNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextSaNumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next Sa Number - ", error);
      return [];
    }
  }
}
