import {
  OrdersApiBase,
  BatchAllList,
  batchManagementUpdateById,
} from "../../common/constants/apiConstants";
import { IBatchManagement } from "../../common/models";
import { AxiosService } from "../generics/axios.service";
import { IBatchMangementService } from "../interfaces/IBatchManagement";

export class BatchManagementService implements IBatchMangementService {
  private axiosService: AxiosService = new AxiosService();

  async getAllBatchDetails(): Promise<IBatchManagement[]> {
    try {
      const result: any = await this.axiosService.Post(
        BatchAllList + "?pageNo=0&pageSize=500&order=DESC&sort=grDetId",
        {},
        OrdersApiBase
      );
      const _batchAllList: any = result.data?.content ?? [];
      console.log("Response: Batch List - ", _batchAllList);
      return _batchAllList;
    } catch (error) {
      console.error("Error: Batch  List - ", error);
      return [];
    }
  }

  async updateBatchStatus(id: any, data: any): Promise<any> {
    try {
      await this.axiosService.Put(
        batchManagementUpdateById(id),
        data,
        OrdersApiBase
      );
      return "success";
    } catch (error) {
      console.error("Error: Update Batch Status - ", error.response.data);
      return error?.response?.data ?? false;
    }
  }
}
