import { useContext } from "react";
import { LabelConstants } from "../../common/constants/labelConstants";
import { EmptyStatusUpdate } from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import { ReturnOrdersContext } from "../../controllers/contexts/returnorders.context";

const ReturnOrdersStatusUpdateComponent = (props) => {
  const { selectedList, setSelectedList, updateStatus } =
    useContext(ReturnOrdersContext);

  const handleCustomerForm = (type: string, value: any) => {
    const _form = cloneDeep(selectedList);
    _form[type] = value;
    setSelectedList(_form);
  };

  const isFormValid = (): boolean => {
    let isValid = false;
    isValid = selectedList?.comments !== "" && selectedList?.status !== "";
    return !isValid;
  };

  return (
    <div className="modal fade" id="updateStatus" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-25">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{LabelConstants.UpdateStatus}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group col-6">
                <label className="form-label">
                  {LabelConstants.Status}
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  value={selectedList.status}
                  onChange={(e) => handleCustomerForm("status", e.target.value)}
                >
                  {props.tempStatus === "DRAFT" ? (
                    <>
                      <option value={"DRAFT"}>DRAFT</option>
                      <option value={"SUBMITTED"}>SUBMITTED</option>
                    </>
                  ) : props.tempStatus === "SUBMITTED" ? (
                    <>
                      <option value={"SUBMITTED"}> SUBMITTED </option>
                      <option value={"CANCELLED"}>CANCELLED</option>
                    </>
                  ) : props.tempStatus === "CANCELLED" ? (
                    <>
                      <option value={"CLOSED"}>CLOSED</option>
                      <option value={"CANCELLED"}>CANCELLED</option>
                    </>
                  ) : props.tempStatus === "CLOSED" ? (
                    <>
                      <option value={"CLOSED"}>CLOSED</option>
                    </>
                  ) : (
                    ""
                  )}
                </select>
              </div>
              <div className="form-group col-12">
                <label className="form-label" htmlFor="comments">
                  {LabelConstants.Comments}
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  id={"comments"}
                  value={selectedList.comments}
                  onChange={(e) =>
                    handleCustomerForm("comments", e.target.value)
                  }
                  rows={5}
                  cols={5}
                  className="form-control"
                  placeholder="Commnets"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer modal-footer-uniform">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={() => setSelectedList(EmptyStatusUpdate)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary float-end"
              data-bs-dismiss="modal"
              disabled={isFormValid()}
              onClick={() => updateStatus()}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnOrdersStatusUpdateComponent;
