import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { toast } from "react-toastify";
import {
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import { BatchMangementContext } from "../../controllers/contexts/batchmangement.context";
import Select from "react-select";
import moment from "moment";
import useAuth from "../../controllers/hooks/useAuth";
import LogsModalComponent from "../common/logsModal.component";
import {
  BatchStatuses,
  NewDeleteConfirmationDialog,
} from "../../common/constants/generalConstants";
import { IDeleteConfirmationProps } from "../../common/models";
import { cloneDeep } from "lodash";
import { Modal } from "bootstrap";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";

var myModal;
const BatchManagementComponent = () => {
  const {
    getAllBatchDetails,
    batchAllList,
    updateBatchStatus,
    productsList,
    productCateList,
    getLogsBatch,
    batchLogs,
  } = useContext(BatchMangementContext);
  const navigate = useNavigate();
  const { isAccessDisabled } = useAuth();

  const [selectedBatch, setSelectedBatch] = useState<string | null>(null);
  const [selBatch, setSelBatch] = useState<any>(null);
  const [batchStatus, setBatchStatus] = useState("");
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [dialoginfo, setDialoginfo] = useState<IDeleteConfirmationProps>();
  const handleShowLogsModal = (_sel) => {
    getLogsBatch(_sel.grDetId);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };

  useEffect(() => {
    myModal = new Modal(document.getElementById("deleteConfirmation"), {});
    getAllBatchDetails();
  }, []);

  const handleUpdateBatch = async () => {
    console.log(selectedBatch);
    if (batchAllList && batchAllList.length > 0 && selectedBatch) {
      let selectedBatchData;
      batchAllList.map((batchDtls: any, j: number) => {
        if (batchDtls.value === selectedBatch) {
          selectedBatchData = batchDtls;
        }
      });
      selectedBatchData.status = selBatch.status;
      await updateBatchStatus(selectedBatchData.grDetId, selectedBatchData);
      await getAllBatchDetails();
      setSelectedBatch(null);
      setSelBatch(null);
    } else {
      toast.warning("Please select Batch Number");
    }
  };

  const handleSelectedBatch = (value: string) => {
    let selectedBatchData;
    batchAllList.map((batchDtls: any, j: number) => {
      if (
        `${batchDtls?.grDetId}~${batchDtls?.goodsRecId}~${batchDtls?.batchNo}` ===
        value
      ) {
        selectedBatchData = batchDtls;
      }
    });
    setSelectedBatch(selectedBatchData?.value);
    setSelBatch(selectedBatchData);
  };

  const showDialog = (
    onCancel: Function,
    onConfirm: Function,
    header: string,
    title_prefix: string,
    title: string = null
  ) => {
    let dialog: IDeleteConfirmationProps = cloneDeep(
      NewDeleteConfirmationDialog
    );
    dialog.cancel = onCancel;
    dialog.confirm = onConfirm;
    dialog.title = title;
    dialog.header = header;
    dialog.title_prefix = title_prefix;
    setDialoginfo(dialog);
    myModal.show();
  };

  const handleStatus = (e: any) => {
    let val = e.target.value;
    setBatchStatus(val);
    let a = { ...selBatch };
    a.status = val;
    setSelBatch(a);
    // let _newBatchmanagement = { ...selectedProducts }
    // _newBatchmanagement.status = e.target.value
    // setSelectedProducts(_newBatchmanagement);
  };

  // const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const value = e.target.value;
  //   setSelectedStatus(value);
  //   // You can perform any necessary actions based on the selected status here
  // };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.batchmanagement}
                      title={LabelConstants.Batch}
                    >
                      {LabelConstants.BatchManagement}
                    </NavLink>{" "}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-success float-end"
            disabled={isAccessDisabled("BAMAGT", "create")}
            onClick={() => handleUpdateBatch()}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Batch Management </h4>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Pallet Ref Num</th>
                        <th>Location</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batchAllList && batchAllList.length > 0 ? (
                        selectedBatch ? (
                          batchAllList.map((batchDtls: any, j: number) => {
                            // return batchDtls.batchDetails.map((batchDtls: any, i: number) => {
                            if (batchDtls.value === selectedBatch) {
                              return batchDtls.prodLocs.map(
                                (loc: any, j: number) => {
                                  return (
                                    <tr key={j}>
                                      <td>{j + 1}</td>
                                      <td className="text-start">
                                        {getProductCatNameById(
                                          batchDtls.prodCatId,
                                          productCateList
                                        )}
                                      </td>
                                      <td className="text-start">
                                        {getProductNameById(
                                          batchDtls.prodId,
                                          productsList
                                        )}
                                      </td>
                                      {/* Iterate over prodLocs and display location details */}
                                      {batchDtls.prodLocs.length > 0 && (
                                        <>
                                          <td>{loc.palletRefNum}</td>
                                          <td>{loc.shelfId}</td>
                                        </>
                                      )}
                                      <td>{loc.locQty}</td>
                                    </tr>
                                  );
                                }
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={9} align="center">
                              <br />
                              <p>Please select batch number</p>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan={9} align="center">
                            <br />
                            <p>No products added to Batch Management</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    disabled={isAccessDisabled("BAMAGT", "create")}
                    className="btn btn-success float-end"
                    onClick={() => handleUpdateBatch()}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-primary p-4 d-flex justify-content-between align-items-center">
                <h4 className="box-title mb-0">Batch Details</h4>
                <button
                  type="button"
                  className="waves-effect waves-light btn btn-sm btn-rounded btn-secondary"
                  onClick={() => {
                    console.log("batch", selBatch);
                    handleShowLogsModal(selBatch);
                  }}
                  disabled={selBatch === null}
                >
                  <i className="fa fa-book" aria-hidden="true"></i>
                </button>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label" htmlFor="BatchNumber">
                      {LabelConstants.BatchNumber}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    {batchAllList?.length > 0 && (
                      <Select
                        value={batchAllList.filter(
                          (option) =>
                            option.value ===
                            `${selBatch?.grDetId}~${selBatch?.goodsRecId}~${selBatch?.batchNo}`
                        )}
                        options={batchAllList}
                        isSearchable={true}
                        onChange={(e) => handleSelectedBatch(e.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label"> Received Qty </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      value={selBatch ? selBatch.receivedQty : ""}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label"> Remaining Qty </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      value={selBatch ? selBatch.remainingQty : ""}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label"> Is Sampling Done </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      value={
                        selBatch ? (selBatch.samplingReq ? "YES" : "NO") : ""
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label"> Expired Date </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control"
                      value={
                        selBatch
                          ? moment(selBatch.batchExpDate).format("DD/MM/yyyy")
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      value={selBatch ? selBatch.status : ""}
                      onChange={async (e) => {
                        if (e.target.value === "DES") {
                          await showDialog(
                            () => {},
                            async () => {
                              e.target.value = "DES";
                              handleStatus(e);
                            },
                            undefined,
                            "Continue with Destroy?",
                            undefined
                          );
                        } else {
                          handleStatus(e);
                        }
                      }}
                    >
                      {selBatch?.status !== "DES" && (
                        <>
                          <option value={""}>--</option>
                          <option value={"QRN"}>QUARANTINE</option>
                          <option value={"REL"}>RELEASE</option>
                          <option value={"REJ"}>REJECT</option>
                        </>
                      )}
                      {(selBatch?.status === "REJ" ||
                        selBatch?.status === "DES") && (
                        <>
                          <option value={"DES"}>DESTROY</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={batchLogs}
        createdDate={selBatch?.createdDate}
        createdBy={selBatch?.createdBy}
      />
      <DeleteConfirmationComponent
        title={dialoginfo?.title}
        cancel={dialoginfo?.cancel}
        confirm={dialoginfo?.confirm}
        header={dialoginfo?.header}
        title_prefix={dialoginfo?.title_prefix}
      />
    </>
  );
};

export default BatchManagementComponent;
