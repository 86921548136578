import { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import WarehouseListingComponent from "./warehouseListing.component";
import WarehouseStatsComponent from "./warehouseStats.component";
import WarehouseMapComponent from "./warehouseMap.component";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { IWarehouse } from "../../common/models";
import WarehouseMapDetailsComponent from "./warehouseMapDetails.component";
const WarehouseComponent = () => {
  const {
    warehouseList,
    selectedWarehouse,
    getWarehouseMap,
    setSelectedLocation,
    setSelectedLocationDtls,
  } = useContext(WarehouseContext);
  const location = useLocation();
  const [isDashboard, setIsDashboard] = useState(
    location?.state?.tabIndex ?? 0
  );

  useEffect(() => {
    if (isDashboard === 1) {
      document.getElementById("warehouseDetailsAnchor")?.click();
    }
  }, []);

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <h4 className="page-title">{LabelConstants.Warehouse}</h4>
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.Warehouse}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-9">
            <ul className="nav nav-pills customtab2 mb-3" role="tablist">
              <li className="nav-item" onClick={() => setIsDashboard(0)}>
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#warehouseHome"
                  role="tab"
                >
                  <span className="hidden-sm-up">
                    <i className="ion-home"></i>
                  </span>
                  <span className="hidden-xs-down">Home</span>
                </a>
              </li>
              <li className="nav-item" onClick={() => setIsDashboard(1)}>
                <a
                  id="warehouseDetailsAnchor"
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#warehouseDetails"
                  role="tab"
                  onClick={() => {
                    console.log("click!!");
                  }}
                >
                  <span className="hidden-sm-up">
                    <i className="ion-person"></i>
                  </span>
                  <span className="hidden-xs-down">Details</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-3">
            {isDashboard === 1 && (
              <div className="form-group row">
                <label
                  htmlFor="example-search-input"
                  className="col-sm-4 col-form-label"
                >
                  Warehouse:
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-select"
                    value={selectedWarehouse?.wareHouseId}
                    onChange={(e) => {
                      const _warehouse = warehouseList.find(
                        (_) => _.wareHouseId === Number(e.target.value)
                      );
                      setSelectedLocation(null);
                      setSelectedLocationDtls(null);
                      getWarehouseMap(_warehouse);
                    }}
                  >
                    <option value={""} hidden>
                      Select Warehouse
                    </option>
                    {warehouseList.map((_wre: IWarehouse, _wreIdx: number) => {
                      return (
                        <option
                          key={`warehouseOption${_wreIdx}`}
                          value={_wre.wareHouseId}
                        >
                          {_wre.warehouseName}
                        </option>
                      );
                    })}
                  </select>
                  {/* <input className="form-control" type="search" value="How do I shoot web" id="example-search-input" /> */}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="warehouseHome" role="tabpanel">
            <WarehouseListingComponent />
          </div>
          <div className="tab-pane" id="warehouseDetails" role="tabpanel">
            <WarehouseStatsComponent />
            <div className="row">
              <div className="col-8">
                <WarehouseMapComponent />
              </div>
              <div className={`col-4`}>
                <WarehouseMapDetailsComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseComponent;
