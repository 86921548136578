import { Outlet } from "react-router-dom";
import { SamplingContext } from "../../controllers/contexts/sampling.context";
import useSampling from "../../controllers/hooks/useSampling"

const SamplingLayoutComponent = () =>{
    const SamplingFunction = useSampling();
    console.log('Sample List - ', SamplingFunction.samplingList);

    return(
       <SamplingContext.Provider value={SamplingFunction}>
        <Outlet></Outlet>
       </SamplingContext.Provider> 
    )
}
export default SamplingLayoutComponent