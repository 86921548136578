import { NavLink, useLocation } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { useContext, useEffect, useState } from "react";
import {
  getProductCode,
  getProductName,
  getProductPackSize,
  pagePrintPorStyle,
} from "../../common/methods/globalmethods";
import moment from "moment";
import { SalesOrderContext } from "../../controllers/contexts/salesorder.context";
import { ISalesOrder } from "../../common/models/salesorder";
import { ICustomerAddress } from "../../common/models";
import PrintHeader from "./PrintHeader";
import BoxItemWithKeyValue from "./Components/BoxItemWithKeyValue";

const SalesOrderPrintFormat = () => {
  const { productsList, customersList } = useContext(SalesOrderContext);
  const location = useLocation();
  const [soItem, setsoItem] = useState<ISalesOrder>(
    location?.state?.selectedItem
  );
  const [customerAddress, setcustomerAddress] =
    useState<ICustomerAddress>(null);

  const getSubTotal = () => {
    let subtotal = 0;
    soItem?.soProductDetails?.map((item) => {
      subtotal += item?.productAmount;
    });
    return subtotal?.toFixed(2);
  };

  useEffect(() => {
    getCustomerAddress();
  }, []);

  const getCustomerAddress = () => {
    let customer = customersList?.filter(
      (item) => item.custId === parseInt(soItem?.customerId)
    );
    if (customer?.length > 0) {
      customer[0]?.custAddresses?.map((address) => {
        if (
          address?.custAddressId?.toString() === soItem?.addressId?.toString()
        ) {
          setcustomerAddress(address);
        }
      });
    }
  };

  return (
    <div>
      <div className="content-header row">
        <div className="d-flex align-items-center col-md-11">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.sales_order}
                    >
                      {LabelConstants.OrderOut}
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.sales_order}
                    >
                      Sales Order
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Print
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <button
            type="button"
            className="waves-effect waves-light btn btn-success rounded"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <style>{pagePrintPorStyle}</style>
        <div style={{ width: 210 + "mm" }}>
          <div
            className="col-12 p-30 pt-0"
            style={{
              backgroundColor: "white",
            }}
          >
            <PrintHeader centerText="SALES ORDER" />
            <div className="row m-0">
              <div className="col-7">
                {BoxItemWithKeyValue("SO #", soItem?.soNum)}
                {BoxItemWithKeyValue("Customer Name", soItem?.customerName)}
              </div>
              <div className="col-5">
                {BoxItemWithKeyValue(
                  "Order Date",
                  moment(soItem?.soCreatedDate).format("DD/MM/YYYY")
                )}
                {BoxItemWithKeyValue("Order Ref. #", soItem?.customerRefNum)}
              </div>
            </div>

            <div
              className="col-12 table-responsive"
              style={{ backgroundColor: "white", paddingTop: 10 }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ fontSize: 12, margin: 0 }}>#</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Product Code</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Product Name</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Pack Size</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Price</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Order Qty</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {soItem?.soProductDetails?.map((item, index) => (
                    <tr className="col-12 p-0">
                      <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                        <p>{index + 1}</p>
                      </td>
                      <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                        <p>{getProductCode(item?.prodId, productsList)}</p>
                      </td>
                      <td className="col-7" style={{ fontSize: 12, margin: 0 }}>
                        <p>{getProductName(item.prodId, productsList)}</p>
                      </td>
                      <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                        <p>{getProductPackSize(item.prodId, productsList)}</p>
                      </td>
                      <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                        <p style={{ fontSize: 12, margin: 0 }}>
                          {item.unitPrice}
                        </p>
                      </td>
                      <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                        <p style={{ fontSize: 12, margin: 0 }}>
                          {item.quantity}
                        </p>
                      </td>
                      <td className="col-1" style={{ fontSize: 12, margin: 0 }}>
                        <p style={{ fontSize: 12, margin: 0 }}>
                          {item.productAmount}
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="b-1 border-black" colSpan={4} rowSpan={3}>
                      <p className="text-bold">VAT Analysis:</p>
                      <p>{`Standard:      ${soItem?.totalTaxAmount?.toFixed(
                        2
                      )}`}</p>
                    </td>
                    <td className="b-1 border-black text-bold" colSpan={2}>
                      SUB TOTAL
                    </td>
                    <td className="text-bold">{getSubTotal()}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="b-1 border-black text-bold">
                      TAX RATE
                    </td>
                    <td className="text-bold">{soItem?.totalTaxAmount}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="b-1 border-black text-bold">
                      TOTAL
                    </td>
                    <td className="text-bold">{soItem?.totalProductsAmount}</td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12 border-grey b-1 h-150 mt-30 p-10">
                <p className="text-bold">
                  <u>Special Instructions :</u>
                </p>
                <p>
                  All deliveries must be pre-booked prior to delivery : email -
                  supplychain@medleypharma.co.uk
                </p>
              </div>
              <div className="col-md-4 border-grey b-1 mt-30 p-20">
                <p className="text-bold" style={{ marginLeft: -5 }}>
                  <u>Delivery Address :</u>
                </p>
                <p style={{ marginBottom: 0 }}>{soItem?.customerName}</p>
                <p style={{ marginBottom: 0 }}>{customerAddress?.address1}</p>
                {customerAddress?.address2 !== "" && (
                  <p style={{ marginBottom: 0 }}>{customerAddress?.address2}</p>
                )}
                <p style={{ marginBottom: 0 }}>
                  {customerAddress?.city},{customerAddress?.county}
                </p>
                <p style={{ marginBottom: 0 }}>
                  {customerAddress?.country.toUpperCase()},
                  {customerAddress?.postCode.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesOrderPrintFormat;
