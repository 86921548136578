import { useContext } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { SupplierContext } from "../../../controllers/contexts/supplier.context";
import { IDocuments } from "../../../common/models";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { GenericHelperService } from "../../../api/generics/helper.service";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";

const SupplierDetailsComponent = () => {
  const helperService: GenericHelperService = new GenericHelperService();

  const { SupplierForm, clearViewSupplier, supplierFiles } =
    useContext(SupplierContext);
  const navigate = useNavigate();

  const handleDownload = (path: string) => {
    const _path = path.split("html")[1];
    window.open(_path, "_blank");
  };

  const handleBack = () => {
    navigate(-1);
    clearViewSupplier(null);
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${LabelConstants.Supplier} ${LabelConstants.Details}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.Master}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.supplier}
                      title={LabelConstants.Supplier}
                    >
                      {LabelConstants.Supplier}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Supplier} ${LabelConstants.Details}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <h4 className="box-title">{LabelConstants.SupplierInfo}</h4>
                <div className="row">
                  <div className="col-12">
                    <p className="mb-1">
                      <strong>{LabelConstants.Name}</strong> :
                      <span className="text-gray ps-10">
                        {SupplierForm?.supName}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.SupplierType}</strong> :
                      <span className="text-gray ps-10">
                        {SupplierForm?.supType}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.Validity}</strong> :
                      <span className="text-gray ps-10">{`${moment(
                        SupplierForm?.validFrom
                      ).format("DD/MM/yyyy")} to ${moment(
                        SupplierForm?.validTo
                      ).format("DD/MM/yyyy")}`}</span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.Status}</strong> :{" "}
                      <span
                        className={`badge bg-${helperService.getStatusColor(
                          SupplierForm?.status
                        )}-light rounded-pill`}
                      >
                        {SupplierForm?.status}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <h4 className="box-title">{LabelConstants.Files}</h4>
                <div className="row">
                  <div className="col-12 p-0">
                    <div className="box box-bordered">
                      <div className="box-body p-3">
                        {supplierFiles && supplierFiles.length > 0 ? (
                          supplierFiles.map(
                            (_file: IDocuments, _fileIdx: number) => {
                              return (
                                <div className="media media-single p-1 col-6">
                                  <div className="ms-0 me-15 bg-primary-light h-50 w-50 l-h-50 rounded text-center">
                                    <span className="fs-24 text-primary">
                                      <i className="fa fa-file-o"></i>
                                    </span>
                                  </div>
                                  <span className="title fw-500 fs-16">
                                    {_file.documentName}
                                  </span>
                                  <button
                                    type="button"
                                    className="waves-effect waves-circle btn btn-circle btn-danger-light btn-xs"
                                    onClick={() =>
                                      handleDownload(_file.documentPath)
                                    }
                                  >
                                    <i className="fa fa-eye"></i>
                                  </button>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <>
                            <div className="row">
                              <div className="d-flex justify-content-center align-items-center">
                                <h3 className="text-body-secondary">
                                  No files available.
                                </h3>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="box-title">{`${LabelConstants.Transporter} ${LabelConstants.Address}`}</h4>
                <div className="row">
                  <div className="col-12 p-0">
                    {SupplierForm?.supAddresses.map((_add, _addIdx) => (
                      <div key={`supDetailsAddress${_addIdx}`}>
                        <p className="mb-2">
                          <strong>Address #{_addIdx + 1}</strong>
                        </p>
                        <div className="box box-bordered">
                          <div className="box-body">
                            <p className="mb-1 ms-2">
                              <strong>{LabelConstants.Address1}</strong> :
                              <span className="text-gray ps-10">
                                {_add?.address1}
                              </span>{" "}
                            </p>
                            <p className="mb-1 ms-2">
                              <strong>{LabelConstants.Address2}</strong> :
                              <span className="text-gray ps-10">
                                {_add?.address2}
                              </span>{" "}
                            </p>
                            <p className="mb-1 ms-2">
                              <strong>{LabelConstants.City}</strong> :
                              <span className="text-gray ps-10">
                                {_add?.city}
                              </span>{" "}
                            </p>
                            <p className="mb-1 ms-2">
                              <strong>{LabelConstants.County}</strong> :
                              <span className="text-gray ps-10">
                                {_add?.county}
                              </span>{" "}
                            </p>
                            <p className="mb-1 ms-2">
                              <strong>{LabelConstants.Country}</strong> :
                              <span className="text-gray ps-10">
                                {_add?.country}
                              </span>{" "}
                            </p>
                            <p className="mb-1 ms-2">
                              <strong>{LabelConstants.PostCode}</strong> :
                              <span className="text-gray ps-10">
                                {_add?.postCode}
                              </span>{" "}
                            </p>

                            <p className="my-2">
                              <strong>{LabelConstants.Contact}s</strong>
                            </p>
                            <div className="row">
                              {_add?.supCommunication.map(
                                (_addCont, _addContIdx) => (
                                  <>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 pb-3">
                                      <div className="box box-bordered m-0">
                                        <div className="box-body p-2">
                                          <p className="my-2 ms-1">
                                            <strong>
                                              {LabelConstants.Contact} #
                                              {_addContIdx + 1}
                                            </strong>
                                          </p>
                                          <p className="mb-1 ms-2">
                                            <strong>
                                              {LabelConstants.Name}
                                            </strong>{" "}
                                            :
                                            <span className="text-gray ps-10">
                                              {_addCont.contactName ?? ""}
                                            </span>{" "}
                                          </p>
                                          <p className="mb-1 ms-2">
                                            <strong>
                                              {LabelConstants.Phone}
                                            </strong>{" "}
                                            :
                                            <span className="text-gray ps-10">
                                              {formatPhoneNumberIntl(
                                                `${
                                                  _addCont.designationCountryCode ??
                                                  ""
                                                }${_addCont.phoneNumber ?? ""}`
                                              ) ?? ""}
                                            </span>{" "}
                                          </p>
                                          <p className="mb-1 ms-2">
                                            <strong>
                                              {LabelConstants.Email}
                                            </strong>{" "}
                                            :
                                            <span className="text-gray ps-10">
                                              {_addCont.email ?? ""}
                                            </span>{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <button
                  type="button"
                  className="btn btn-danger float-end"
                  onClick={() => handleBack()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierDetailsComponent;
