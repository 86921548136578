import { useContext } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { ProductContext } from "../../../controllers/contexts/product.context";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { GenericHelperService } from "../../../api/generics/helper.service";
import { AppContext } from "../../../controllers/contexts/app.context";
import { getNumberForString } from "../../../common/methods/globalmethods";

const ProductDetailsComponent = () => {
  const helperService: GenericHelperService = new GenericHelperService();

  const { productForm, setProductForm } = useContext(ProductContext);
  const { ProductCategories } = useContext(AppContext);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
    setProductForm(null);
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${LabelConstants.Products} ${LabelConstants.Details}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.Master}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.product}
                      title={LabelConstants.Products}
                    >
                      {LabelConstants.Products}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Products} ${LabelConstants.Details}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <h4 className="box-title">{LabelConstants.ProductInfo}</h4>
                <div className="row">
                  <div className="col-12">
                    <p className="mb-1">
                      <strong>{LabelConstants.Name}</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.productName}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.productCode}</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.productCode}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.PackSize}</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.packSize}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.ProductLicenseNo}.</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.prodLicenseNo}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.Manufacturer}</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.prodManufacturer}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.Category}</strong>:
                      <span className="text-gray ps-10">
                        {
                          ProductCategories.find(
                            (item) =>
                              item?.categoryId ===
                              getNumberForString(productForm?.prodCategory)
                          )?.categoryName
                        }
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.DosageForm}</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.dosageForm}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.GTINBarcode}</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.gtin}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.PIPCode}</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.pipCode}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.MinStockQty}</strong>:
                      <span className="text-gray ps-10">
                        {productForm?.minStockQty}
                      </span>{" "}
                    </p>
                    <p className="mb-1">
                      <strong>{LabelConstants.Status}</strong>:{" "}
                      <span
                        className={`badge bg-${helperService.getStatusColor(
                          productForm?.status
                        )}-light rounded-pill`}
                      >
                        {productForm?.status}
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <button
                  type="button"
                  className="btn btn-danger float-end"
                  onClick={() => handleBack()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsComponent;
