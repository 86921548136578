import { useEffect, useState } from "react";
import { BatchManagementService } from "../../api/services/batchmanagement.service";
import {
  IBatchManagement,
  IBatchMangementContext,
  IProducts,
} from "../../common/models";
import { toast } from "react-toastify";
import { ProductService } from "../../api/services/product.service";
import { MasterService } from "../../api/services/master.service";
import { IProductCategory } from "../../common/models/master";
import _ from "lodash";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import { SamplingService } from "../../api/services/sampling.service";
import { getProductCode } from "../../common/methods/globalmethods";

const useBatchmanagement = () => {
  const productService: ProductService = new ProductService();
  const masterService: MasterService = new MasterService();
  const samplineService: SamplingService = new SamplingService();

  const batchManagementService: BatchManagementService =
    new BatchManagementService();
  const [batchAllList, setBatchAllDetails] = useState([]);
  const [productsList, setProductsList] = useState<IProducts[]>([]);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );
  const [batchLogs, setBatchLogs] = useState<ILogsData[]>([]);

  const getLogsBatch = async (id: number) => {
    const _logs: ILogsProps[] = await samplineService.getLogs(`BA${id}`);
    setBatchLogs(_logs);
  };

  useEffect(() => {
    getProductsList();
    getProductCategoriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProductsList = async (updateSelection = true) => {
    const _products = await productService.getProducts();
    if (updateSelection) {
      _products?.map((item) => {
        item.selected = false;
      });
    }
    let _filteredProducts = _.filter(_products, { status: "ACTIVE" });
    setProductsList(_filteredProducts ?? []);
    return _filteredProducts;
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };

  const getAllBatchDetails = async () => {
    let prodsList = productsList;
    prodsList = await getProductsList(false);
    let _BatchAllList = await batchManagementService.getAllBatchDetails();
    _BatchAllList?.map((item: any) => {
      let prodCat = getProductCode(item?.prodId, prodsList);
      item.value = `${item?.grDetId}~${item?.goodsRecId}~${item?.batchNo}`;
      item.label = `${item?.batchNo} | ${prodCat}`;
    });
    setBatchAllDetails(_BatchAllList ?? []);
    console.log(_BatchAllList);
  };

  const updateBatchStatus = async (id: any, data: any) => {
    const _batchSave = await batchManagementService.updateBatchStatus(id, data);
    if (_batchSave && typeof _batchSave == "string") {
      toast.success("Batch status updated successfully.");
    } else {
      toast.error(_batchSave?.errorMessage ?? "Error updating status.");
    }
  };

  return {
    getAllBatchDetails,
    batchAllList,
    updateBatchStatus,
    productsList,
    productCateList,
    batchLogs,
    getLogsBatch,
  } as IBatchMangementContext;
};

export default useBatchmanagement;
