import {
  OrdersApiBase,
  getNextRoNumber,
  recentlyDeliveredOrders,
  returnOrderPriceDetails,
  returnOrderSave,
  returnOrderUpdate,
  returnOrderUpdateById,
  returnOrdersList,
  roSearch,
} from "../../common/constants/apiConstants";
import { IListConfigRO, IStatusUpdate } from "../../common/models";
import {
  IReturnOrders,
  IReturnOrdersPriceDetails,
} from "../../common/models/returnorders";
import { AxiosService } from "../generics/axios.service";

export class ReturnOrdersService {
  private axiosService: AxiosService = new AxiosService();

  async getReturnOrders(pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(
        returnOrdersList(pageNo),
        OrdersApiBase
      );
      return result.data ?? null;
    } catch (error) {
      console.error("Error: Return order List - ", error);
      return null;
    }
  }

  async searchROOrders(filter: IListConfigRO, pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        roSearch(pageNo),
        filter,
        OrdersApiBase
      );
      return result?.data ?? null;
    } catch (error) {
      console.error("Error: SearchPOOrders List - ", error);
      return null;
    }
  }

  async getROPriceDetails(
    soNum: string,
    doNum: string
  ): Promise<IReturnOrdersPriceDetails> {
    try {
      const result: any = await this.axiosService.Get(
        returnOrderPriceDetails(soNum, doNum),
        OrdersApiBase
      );
      const _returnOrders: IReturnOrdersPriceDetails = result.data ?? [];
      console.log("Response: getROPriceDetails List - ", _returnOrders);
      return _returnOrders;
    } catch (error) {
      console.error("Error: getROPriceDetails List - ", error);
      return null;
    }
  }

  async updateReturnOrder(returnOrder: IReturnOrders): Promise<any> {
    try {
      const _result = await this.axiosService.Put(
        returnOrderUpdateById(returnOrder?.id),
        returnOrder,
        OrdersApiBase
      );
      return { success: true, message: _result.data.returnOrderNum };
    } catch (error) {
      console.error("Error: update Return order - ", error.response.data);
      return {
        success: false,
        message: error.response?.data?.errorMessage ?? "Error saving Return.",
      };
    }
  }
  async createNewReturnOrder(
    newReturnOrder: IReturnOrders
  ): Promise<string | number> {
    try {
      const result: any = await this.axiosService.Post(
        returnOrderSave,
        newReturnOrder,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Save Return order - ", error.response.data);
      return (
        error.response.data[0]?.errorMessage ?? "Error saving new Return Order."
      );
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Post(returnOrderUpdate, status, OrdersApiBase);
      return true;
    } catch (error) {
      console.error(
        "Error: Update PO Status - ",
        error.response.data[0]?.errorMessage
      );
      return error.response.data[0]?.errorMessage ?? false;
    }
  }

  async getNextRoNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextRoNumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next PO Number - ", error);
      return [];
    }
  }

  async getRecentlyDeliveredOrders() {
    try {
      const result: any = await this.axiosService.Get(
        recentlyDeliveredOrders,
        OrdersApiBase
      );
      console.log("getRecentlyDeliveredOrders - ", result.data);
      return result.data;
    } catch (error) {
      console.error("Error: getRecentlyDeliveredOrders - ", error);
      return [];
    }
  }
}
