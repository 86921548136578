import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { useContext, useEffect, useRef, useState } from "react";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  getProductCatNameById,
  getProductNameById,
  isNullUndefined,
} from "../../common/methods/globalmethods";
import moment from "moment";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { LabelConstants } from "../../common/constants/labelConstants";
import _ from "lodash";
import { ILocationDetails } from "../../common/models";

var rightModalCloseBtn;

const WarehouseMapDetailsComponent = () => {
  const {
    warehouseMap,
    selectedLocation,
    deleteLocation,
    selectedLocationDtls,
    productsList,
    productCateList,
    saveLocationTransfer,
    getLocationDtlsById,
    updateLocation,
    getWarehouseMap,
    selectedWarehouse,
    warehouseList,
    setSelectedLocationDtls,
  } = useContext(WarehouseContext);

  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const [editLocDtls, setEditLocDtls] = useState(null);

  useEffect(() => {
    rightModalCloseBtn = document.getElementById("modal-close-btn");
  }, []);

  useEffect(() => {
    if (selectedLocationDtls && selectedLocationDtls.length > 0) {
      setEditLocDtls({
        locId: selectedLocationDtls[0].warehouseModel.locId,
        shelfId: selectedLocationDtls[0].shelfId,
        assilesId: selectedLocationDtls[0].warehouseModel.assilesId,
        locName: selectedLocationDtls[0].warehouseModel.locName,
        status: selectedLocationDtls[0].warehouseModel.status,
        comments: selectedLocationDtls[0].warehouseModel.comments,
        batchStatus: selectedLocationDtls[0].warehouseModel.batchStatus,
        deleted: false,
      });
    }
  }, [selectedLocationDtls]);

  const handleLocDataChange = (key: string, e: any) => {
    let locDtls = { ...editLocDtls };
    locDtls[key] = e.target.value;
    setEditLocDtls(locDtls);
  };

  const handleLocChangesSave = async () => {
    console.log(editLocDtls);
    await updateLocation(editLocDtls);
    setEditLocDtls(null);
    getWarehouseMap(selectedWarehouse);
  };

  const shelfIds = warehouseMap.reduce((acc, aisle) => {
    aisle.bays.forEach((bay) => {
      bay.baysLocations.forEach((location) => {
        if (location.status === "INUSE") {
          acc.push({ value: location.shelfId, label: location.shelfId });
        }
      });
    });
    return acc;
  }, []);

  const handleLocationTransferDetails = async () => {};

  const truncateProductName = (productName) => {
    // In order to not to extend screen width added this
    // const maxLength = 12;
    // if (productName && productName.length > maxLength) {
    //   return productName.substring(0, maxLength) + '...';
    // }
    return productName;
  };

  const getLocId = (shelfId) => {
    let selectedLocId = null;
    let stopLoop = false;
    warehouseMap.forEach((aisle) => {
      if (stopLoop) {
        return selectedLocId;
      }
      aisle.bays.forEach((bay) => {
        bay.baysLocations.forEach((location) => {
          if (location.shelfId === shelfId) {
            selectedLocId = location.locId;
            stopLoop = true;
          }
        });
      });
    });
    return selectedLocId;
  };

  const handleLocationTransfer = async () => {
    if (!validateTransfer()) {
      toast.error("Please enter valid inputs!!");
      return;
    }

    let request = [];
    selectedLocationDtls?.map((item) => {
      const data = {
        warehouseId: item.warehouseId,
        transferDate: new Date().toISOString(),
        fromLocId: selectedLocation.locId,
        toLocId: getLocId(item?.transferShelfId),
        fromShelfId: item?.shelfId,
        toShelfId: item?.transferShelfId,
        batchNo: item?.batchNo,
        batchExpiryDate: item?.batchExpiryDate,
        prodId: item?.prodId,
        prodCatId: item?.prodCatId,
        batchCreatedDate: "2024-04-02T10:05:52", //not getting from api
        quantity: item?.transferQty,
        status: "DELIVERED",
        fromPalletRefNum: item?.palletRefNum,
        toPalletRefNum: item?.palletRefNum,
        locationStatus: item?.status,
        reason: item?.transferReason,
        createdDate: new Date().toISOString(),
        createdBy: null,
        modifiedDate: new Date().toISOString(),
        modifiedBy: null,
        deleted: false,
      };
      request.push(data);
    });

    const result = await saveLocationTransfer(request);
    if (result) {
      toast.success("Transfered Goods Successfully");
      rightModalCloseBtn.click();
      getLocationDtlsById(selectedLocation);
      const _warehouse = warehouseList.find(
        (_) => _.wareHouseId === selectedLocationDtls[0].warehouseId
      );
      if (!isNullUndefined(_warehouse?.wareHouseId)) {
        getWarehouseMap(_warehouse);
      }
      return result;
    } else {
      toast.error("Failed to transfer Goods");
    }
  };

  const handleLocDetailsChange = (
    key,
    value,
    batchno,
    palletrefno,
    batchexp
  ) => {
    let locDetails: ILocationDetails[] = _.cloneDeep(selectedLocationDtls);
    locDetails?.map((item) => {
      if (
        item.batchNo === batchno &&
        item.palletRefNum === palletrefno &&
        item.batchExpiryDate === batchexp
      ) {
        item[key] = value;
      }
    });
    setSelectedLocationDtls(locDetails);
  };

  const headerRef = useRef(null);
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: 0 });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  const deleteConfirm = async () => {
    deleteLocation();
  };

  const iterateAndGetValuesForKey = (array, key: string, type = "string") => {
    let result = "";
    let data = [];
    var out = _.each(array, (value, _) => {
      let res = value[key];
      if (type === "date") {
        res = moment(res).format("DD/MM/yyyy");
      }
      data.push(res);
    });
    if (data?.length > 0) {
      result = data.join(" , ");
    }
    return result;
  };

  const renderLocationItem = (key: string, value: any) => (
    <tr>
      <td className="w-150">{key}</td>
      <td>:</td>
      <td>{value || "-"}</td>
    </tr>
  );

  const validateTransfer = () => {
    let result = true;

    selectedLocationDtls?.map((item) => {
      if (
        item.transferQty == 0 ||
        item.transferQty > item.locQty ||
        item.transferShelfId === "" ||
        item.transferReason === ""
      ) {
        result = false;
      }
    });

    return result;
  };

  return (
    <>
      <div
        className={`box-location`}
        ref={headerRef}
        style={sticky.isSticky ? { position: "fixed", top: 200 } : {}}
      >
        <div className="box-header bg-primary p-4">
          <h4 className="box-title">Location Details</h4>
          <div className="box-controls pull-right">
            <button
              disabled={!selectedLocationDtls}
              type="button"
              className="waves-effect waves-light btn btn-sm btn-danger rounded"
              data-bs-toggle="modal"
              data-bs-target="#editLocation"
              data-bs-dismiss="modal"
              onClick={() => {}}
            >
              Edit Location
            </button>
          </div>
        </div>

        <div className="box-body">
          {selectedLocationDtls && selectedLocationDtls.length > 0 ? (
            <>
              <table className="listingTable">
                {renderLocationItem(
                  "Location Name",
                  selectedLocationDtls[0]?.warehouseModel?.locName
                )}
                {renderLocationItem(
                  "Shelf Id",
                  selectedLocationDtls[0].shelfId
                )}
                {renderLocationItem(
                  "Prod Category",
                  getProductCatNameById(
                    selectedLocationDtls[0].prodCatId,
                    productCateList
                  )
                )}
                {renderLocationItem(
                  "Product Name",
                  truncateProductName(
                    getProductNameById(
                      selectedLocationDtls[0].prodId,
                      productsList
                    )
                  )
                )}
                {renderLocationItem("Condition", selectedLocation?.status)}
                {renderLocationItem(
                  "Comments",
                  selectedLocationDtls[0]?.warehouseModel?.comments
                )}
                <tr>
                  <td colSpan={5}>
                    <table className="table table-bordered col-12 mt-10">
                      <thead>
                        <tr>
                          <th className="col-md-3">Batch No</th>
                          <th className="col-md-4">Batch Exp.</th>
                          <th className="col-md-3">Pallet Ref. No</th>
                          <th className="col-md-1">Qty</th>
                          <th className="col-md-1">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedLocationDtls?.map((item) => (
                          <tr>
                            <td>{item?.batchNo}</td>
                            <td>{item?.batchExpiryDate}</td>
                            <td>{item?.palletRefNum ?? "-"}</td>
                            <td>{item?.locQty}</td>
                            <td>{item?.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
              {!selectedLocationDtls[0].notTransferAble &&
                selectedLocationDtls[0].batchExpiryDate !== null &&
                selectedLocationDtls[0].prodId !== undefined &&
                selectedLocationDtls[0].warehouseId !== null && (
                  <div className="box-controls pull-right">
                    <button
                      type="button"
                      className="waves-effect waves-light btn btn-sm btn-success rounded"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-right"
                      onClick={handleLocationTransferDetails}
                    >
                      Transfer Goods
                    </button>
                  </div>
                )}
            </>
          ) : (
            <>
              <div className="p-3">
                <h4 className="box-title">
                  Please select a location to see details.
                </h4>
              </div>
            </>
          )}
        </div>
      </div>
      {selectedLocationDtls && selectedLocationDtls.length > 0 && (
        <DeleteConfirmationComponent
          title={selectedLocationDtls[0].shelfId ?? ""}
          confirm={() => deleteConfirm()}
          cancel={() => {}}
        />
      )}
      <div className="modal modal-center fade overflow-auto" id="modal-right">
        <div
          className="modal-dialog modal-lg"
          style={{ width: "1000px", maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                id="modal-close-btn"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <table className="table table-bordered text-center">
                <thead className="table-primary">
                  <tr>
                    <th>Product Category</th>
                    <th>Product Name</th>
                    <th>Location Id</th>
                    <th>Batch No</th>
                    <th>Batch Exp.</th>
                    <th>Status</th>
                    <th>Pallet Ref. No</th>
                    <th>Shelf Id</th>
                    <th>Available Qty</th>
                    <th>Transfer Qty</th>
                    <th>Transfer Shelf Id</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedLocationDtls?.map((item) => (
                    <>
                      <tr>
                        <td>
                          {getProductCatNameById(
                            item.prodCatId,
                            productCateList
                          )}
                        </td>
                        <td>{getProductNameById(item.prodId, productsList)}</td>
                        <td>{selectedLocation?.locId}</td>
                        <td>{item?.batchNo}</td>
                        <td>{item?.batchExpiryDate}</td>
                        <td>{item?.status}</td>
                        <td>{item?.palletRefNum ?? "-"}</td>
                        <td>{item.shelfId}</td>
                        <td>{item.locQty}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control text-start"
                            min={0}
                            max={item.locQty}
                            onChange={(e) => {
                              handleLocDetailsChange(
                                "transferQty",
                                e.target.value,
                                item.batchNo,
                                item.palletRefNum,
                                item.batchExpiryDate
                              );
                            }}
                            value={item?.transferQty ?? 0}
                          />
                        </td>
                        <td>
                          <Autocomplete
                            options={shelfIds.map((option) => option.value)}
                            value={item?.transferShelfId}
                            onChange={(event, newValue) =>
                              handleLocDetailsChange(
                                "transferShelfId",
                                newValue,
                                item.batchNo,
                                item.palletRefNum,
                                item.batchExpiryDate
                              )
                            }
                            renderInput={(params) => (
                              <TextField {...params} sx={{ width: 120 }} />
                            )}
                          />
                        </td>

                        <td>
                          <textarea
                            className="form-control"
                            value={item?.transferReason}
                            onChange={(e) => {
                              handleLocDetailsChange(
                                "transferReason",
                                e.target.value,
                                item.batchNo,
                                item.palletRefNum,
                                item.batchExpiryDate
                              );
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-primary float-end"
                onClick={handleLocationTransfer}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="editLocation" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered w-25">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Location</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedLocationDtls &&
              selectedLocationDtls.length > 0 &&
              editLocDtls &&
              Object.keys(editLocDtls).length > 0 ? (
                <div className="row">
                  {/* {JSON.stringify(selectedLocationDtls)} */}
                  <div className="row">
                    <div className="form-group col-12">
                      <label className="form-label">
                        Location Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={editLocDtls.locName || ""}
                        onChange={(e) => handleLocDataChange("locName", e)}
                      />
                    </div>
                    {(editLocDtls.status === "INUSE" ||
                      editLocDtls.status === "NOT_INUSE") && (
                      <div className="form-group col-12">
                        <label className="form-label">
                          {LabelConstants.Status}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          value={editLocDtls.status}
                          onChange={(e) => handleLocDataChange("status", e)}
                        >
                          <option value={"INUSE"}>IN USE</option>
                          <option value={"NOT_INUSE"}>NOT IN USE</option>
                        </select>
                      </div>
                    )}
                    <div className="form-group col-12">
                      <label className="form-label" htmlFor="comments">
                        {LabelConstants.Comments}
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        value={editLocDtls.comments || ""}
                        className="form-control"
                        onChange={(e) => handleLocDataChange("comments", e)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                "No Data Available"
              )}
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {}}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary float-end"
                data-bs-dismiss="modal"
                onClick={() => handleLocChangesSave()}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseMapDetailsComponent;
