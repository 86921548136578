import { useEffect, useState } from "react";
import {
  ICustomer,
  IListConfig,
  IProducts,
  IDeliveryOrderContext,
  IStatusUpdate,
  ITransporter,
  IDocuments,
  IListConfigDO,
  IResult,
} from "../../common/models";
import { IDeliveryOrder } from "../../common/models/deliveryorder";
import { ISalesOrder } from "../../common/models/salesorder";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { DeliveryOrderService } from "../../api/services/deliveryOrderService.service";
import {
  EmptyListConfig,
  EmptyListConfigDO,
  EmptyListConfigSO,
  EmptyStatusUpdate,
  NewSalesOrder,
} from "../../common/constants/generalConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { ProductService } from "../../api/services/product.service";
import _ from "lodash";
import { IProductCategory } from "../../common/models/master";
import { MasterService } from "../../api/services/master.service";
import { CustomerService } from "../../api/services/customer.service";
import { SalesOrderService } from "../../api/services/SalesOrderService.service";
import { useNavigate } from "react-router-dom";
import { GenericHelperService } from "../../api/generics/helper.service";
import { TransporterService } from "../../api/services/transporter.Service";
import { DocumentService } from "../../api/services/document.service";
import { PickingOrder } from "../../common/models/pickingorder";
import { PickingOrderService } from "../../api/services/pickingorder.service";
import { isNullUndefined } from "../../common/methods/globalmethods";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import { SamplingService } from "../../api/services/sampling.service";

const useDeliveryOrder = () => {
  const deliveryOrderService: DeliveryOrderService = new DeliveryOrderService();
  const customerService: CustomerService = new CustomerService();
  const productService: ProductService = new ProductService();
  const salesOrderService: SalesOrderService = new SalesOrderService();
  const masterService: MasterService = new MasterService();
  const transporterService: TransporterService = new TransporterService();
  const documentService: DocumentService = new DocumentService();
  const pickingOrderService: PickingOrderService = new PickingOrderService();
  const samplingService: SamplingService = new SamplingService();

  const navigate = useNavigate();

  const [nextDONumber, setnextDONumber] = useState(null);

  const [deliveryOrderList, setdeliveryOrderList] = useState<IDeliveryOrder[]>(
    []
  );
  const [deliveryOrderForm, setDeliveryOrderForm] =
    useState<IDeliveryOrder>(null);

  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);

  const [customersList, setCustomersList] = useState<ICustomer[]>([]);
  const [productsList, setProductsList] = useState<IProducts[]>([]);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );

  const [transporterList, setTransporterList] = useState<ITransporter[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [viewFiles, setViewFiles] = useState<IDocuments[]>([]);
  const [pickingOrderList, setPickingOrderList] = useState<PickingOrder[]>([]);
  const [pickingOrderListByStatus, setPickingOrderListByStatus] = useState<
    PickingOrder[]
  >([]);
  const [pickingOrderProducts, setPickingOrderProducts] = useState<any>([]);
  const [dofilters, setDOFilters] = useState<IListConfigDO>(EmptyListConfigDO);
  const [deliveryOrderLogs, setOrderDeliveryLogs] = useState<ILogsData[]>([]);

  const getDeliveryOrderLogs = async (id: number) => {
    const _logs: ILogsProps[] = await samplingService.getLogs(`DO${id}`);
    setOrderDeliveryLogs(_logs);
  };

  useEffect(() => {
    setDeliveryOrderForm(null);
    setSelectedList(EmptyStatusUpdate);
    initData();
  }, []);

  useEffect(() => {
    if (dofilters?.search === true) {
      queryDOList();
    } else if (dofilters?.search === false) {
      getDeliveryOrderList();
    }
  }, [dofilters?.search, filters.currentPage]);

  useEffect(() => {
    if (dofilters?.search) {
      queryDOList();
    }
  }, [dofilters?.search]);

  const initData = async () => {
    getProductsList();
    getProductCategoriesList();
    await getCustomersList();
    getDeliveryOrderList();
    getTransporterList();
    getPickingOrderList();
    getPickingOrderListByStatus();
    setUploadedFiles([]);
  };

  const queryDOList = async () => {
    let customers = customersList;
    let tempFilter = removeUnrequiredAttributes();
    if (Object.entries(tempFilter)?.length > 0) {
      let result = await deliveryOrderService.searchDOList(
        tempFilter,
        filters?.currentPage - 1
      );
      let _filteredList: IDeliveryOrder[] = result?.content ?? [];
      if (!isNullUndefined(customers) || customers?.length < 0) {
        customers = await getCustomersList();
      }
      _filteredList?.map((item) => {
        item.customerName = customers?.find(
          (cust) => cust?.custId === item?.customerId
        )?.custName;
      });
      updateFilters(_filteredList, result);
      setdeliveryOrderList(_filteredList ?? []);
    }
  };

  const getDeliveryPrintData = async (deliveryNum: string) => {
    return await deliveryOrderService.getDeliveryPrintData(deliveryNum);
  };

  const getSalesOrderBySoNum = async (soNum: string) => {
    let data = cloneDeep(EmptyListConfigSO);
    data.soNum = soNum;
    Object.keys(data)?.map((item) => {
      if (item !== "soNum") {
        delete data[item];
      }
    });
    let result = await salesOrderService.searchSOList(data, 0, 10000);
    return result?.content ?? [];
  };

  const removeUnrequiredAttributes = () => {
    let tempDOfilter: IListConfigDO = cloneDeep(dofilters);
    if (!isNullUndefined(tempDOfilter)) {
      for (const [key, value] of Object.entries(tempDOfilter)) {
        if (typeof key === "string" && _.isEmpty(value)) {
          delete tempDOfilter[key];
        } else if (typeof key === "boolean") {
          delete tempDOfilter[key];
        } else if (typeof key === "number" && value <= 0) {
          delete tempDOfilter[key];
        }
      }
    }
    return tempDOfilter;
  };

  const getNextDONumber = async () => {
    const _doNo = await deliveryOrderService.getNextDoNumber();
    if (_doNo) {
      setnextDONumber(_doNo);
    } else {
      toast.error("Error getting next Sales Order number");
    }
  };
  const clearDoNumber = () => {
    setnextDONumber(null);
  };

  const updateFilters = (data: IDeliveryOrder[], result: IResult) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.searchQuery = "";
    _filters.totalRecords = result?.totalElements ?? data.length;
    _filters.totalPageCount =
      result?.totalPages ?? Math.ceil(data.length / _filters.currentPageSize);
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const getDeliveryOrderList = async () => {
    let customers = customersList;
    const result = await deliveryOrderService.getDeliveryOrderList(
      filters?.currentPage - 1
    );
    let _list = result?.content ?? [];
    if (!isNullUndefined(customers) || customers?.length < 0) {
      customers = await getCustomersList();
    }
    _list?.map((item) => {
      item.customerName = customers?.find(
        (cust) => cust?.custId === item?.customerId
      )?.custName;
    });
    updateFilters(_list, result);
    setdeliveryOrderList(_list ?? []);
  };

  const viewDeliveryOrder = async (item: IDeliveryOrder, callback) => {
    console.log({ item });
    await getOrderPickingDetails(item.orderPickingId).then((data: any) => {
      console.log(data);

      item?.deliveryOrderDetails?.forEach((element) => {
        data?.productsDetailsDTOS?.map((product) => {
          if (
            product?.prodId === element?.prodId &&
            product?.prodCatId === element?.prodCatId &&
            product?.batchNo === element?.batchNo &&
            product?.orderPickDelId === element?.orderPickDelId
          ) {
            element.remainingQty = product.remainingQty;
            element.batchNo = product?.batchNo;
            element.batchExpiry = product?.batchExpiry;
          }
        });
      });

      setDeliveryOrderForm(item);
      if (callback) {
        callback();
      }
    });
  };

  const clearDeliveryOrder = () => {
    setUploadedFiles([]);
    setViewFiles([]);
    setDeliveryOrderForm(null);
  };

  const newDeliveryOrderForm = () => {
    setDeliveryOrderForm(cloneDeep(NewSalesOrder));
  };

  const getCustomersList = async () => {
    const _customers = await customerService.getCustomerAllList();
    setCustomersList(_customers ?? []);
    return _customers ?? [];
  };

  const getPickingOrderList = async () => {
    const _pickingOrders = await pickingOrderService.getPickingOrderList();
    setPickingOrderList(_pickingOrders ?? []);
  };

  const getPickingOrderListByStatus = async () => {
    const _pickingOrders =
      await pickingOrderService.getPickingOrderListByStatus();
    setPickingOrderListByStatus(_pickingOrders ?? []);
  };

  const getOrderPickingDetails = async (pickingID: string) => {
    const _OPProducts = await pickingOrderService.getOrderPickingDetails(
      pickingID
    );
    setPickingOrderProducts(_OPProducts ?? []);
    return _OPProducts;
  };

  const getProductsList = async () => {
    const _products = await productService.getProducts();
    _products?.map((item) => {
      item.selected = false;
    });
    let _filteredProducts = _.filter(_products, { status: "ACTIVE" });
    setProductsList(_filteredProducts ?? []);
    return _filteredProducts;
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };

  const getTransporterList = async () => {
    const _transporter = await transporterService.getTransporter();
    setTransporterList(_transporter ?? []);
  };

  const updateDeliveryOrder = async (data: IDeliveryOrder) => {
    let result = false;
    const _doUpdate = await deliveryOrderService.updateDeliveryOrderData(data);
    console.log({ _doUpdate });
    if (_doUpdate) {
      if (typeof _doUpdate == "string") {
        toast.error(_doUpdate);
      } else {
        await uploadFiles(data?.deliveryNum);
        await deleteFiles();
        initData();
        toast.success("Delivery order updated successfully.");
        result = true;
        // navigate(RoutingConstants.delivery_order);
      }
    } else {
      toast.error("Error updating delivery order.");
    }
    return result;
  };
  const createNewDeliveryOrder = async (data) => {
    const _soSave = await deliveryOrderService.createNewDeliveryOrder(data);
    if (_soSave) {
      if (typeof _soSave == "string") {
        toast.error(_soSave);
      } else {
        await uploadFiles(data?.deliveryNum);
        await deleteFiles();
        initData();
        toast.success("Delivery order saved successfully.");
        navigate(RoutingConstants.delivery_order);
      }
    } else {
      toast.error("Error saving delivery order.");
    }
  };
  const deleteDeliveryOrderItem = async () => {
    const _deleteItem: IDeliveryOrder = cloneDeep(deliveryOrderForm);
    _deleteItem.deleted = true;
    console.log("deleteDeliveryOrderItem - ", _deleteItem?.soNum);
    const _itemSave = await deliveryOrderService.updateDeliveryOrderData(
      _deleteItem
    );
    if (_itemSave) {
      if (typeof _itemSave == "string") {
        toast.error(_itemSave);
      } else {
        toast.success("Delivery order deleted successfully.");
        initData();
        navigate(RoutingConstants.delivery_order);
      }
    } else {
      toast.error("Error deleting delivery order.");
    }
  };
  const uploadFiles = async (itemId: any) => {
    console.log("itemId", itemId);
    if (uploadedFiles.length > 0 && itemId) {
      await documentService.uploadFiles(uploadedFiles, itemId);
    }
    return true;
  };
  const deleteFiles = async () => {
    const _deleteFiles: IDocuments[] = viewFiles.filter((_doc) =>
      _doc.deleted !== undefined ? _doc.deleted : false
    );
    for (let fileIdx = 0; fileIdx < _deleteFiles.length; fileIdx++) {
      const delFiles = _deleteFiles[fileIdx];
      await documentService.deleteFiles(delFiles.documentId);
    }
    return true;
  };
  const viewCustomer = async (itemId: any) => {
    const _documents: IDocuments[] = await documentService.getFiles(itemId);
    setViewFiles(_documents);
    return _documents;
  };
  const updateStatus = async () => {
    const _updateStatus: boolean = await deliveryOrderService.updateStatus(
      selectedList
    );
    console.log("SO STATUS - ", _updateStatus);
    if (_updateStatus) {
      if (typeof _updateStatus == "string") {
        toast.error(_updateStatus);
      } else {
        toast.success("Status updated successfully.");
        initData();
      }
    } else {
      toast.error("Error updating status.");
    }
  };

  return {
    deliveryOrderList,
    setdeliveryOrderList,
    filters,
    setFilters,
    selectedList,
    setSelectedList,
    deliveryOrderForm, // Form Data
    //functions
    viewDeliveryOrder,
    clearDeliveryOrder,
    newDeliveryOrderForm,
    getTransporterList,
    uploadFiles,
    deleteFiles,
    viewCustomer,
    setUploadedFiles,
    setViewFiles,
    updateDeliveryOrder,
    deleteDeliveryOrderItem,
    setDeliveryOrderForm,
    updateStatus,
    getPickingOrderList,
    getPickingOrderListByStatus,
    //other lists
    customersList,
    productsList,
    productCateList,
    transporterList,
    uploadedFiles,
    viewFiles,
    pickingOrderList,
    //crud
    createNewDeliveryOrder,
    //misc
    nextDONumber,
    getNextDONumber,
    clearDoNumber,
    pickingOrderListByStatus,
    getOrderPickingDetails,
    pickingOrderProducts,
    setPickingOrderProducts,
    dofilters,
    setDOFilters,
    deliveryOrderLogs,
    getDeliveryOrderLogs,
    getSalesOrderBySoNum,
    getDeliveryPrintData,
    getCustomersList,
    queryDOList,
  } as IDeliveryOrderContext;
};

export default useDeliveryOrder;
