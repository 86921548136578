import React, { useRef } from "react";
import { useState, useEffect } from "react";
import LoginComponent from "../auth/login.component";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

var myModal = null;

const SessionTimer = (props: any) => {
  const {
    initialMinute = 0,
    initialSeconds = 0,
    warnBeforeMinutes = 5,
    onSessionTimeout = null,
    upateSpinner,
    userName = null,
  } = props;
  const timerReference = useRef(null);
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [password, setPassword] = useState("");
  const [username, setusername] = useState(userName);

  useEffect(() => {
    if (username !== userName) {
      setusername(userName);
    }
  }, [userName]);

  const handleClose = () => {
    initModal();
    setPassword(" ");
    myModal.hide();
  };
  const handleShow = () => {
    initModal();
    setPassword("");
    myModal.show();
  };

  useEffect(() => {
    initModal();
  }, []);

  const initModal = () => {
    if (myModal === null && document.getElementById("sessionModal") !== null)
      myModal = new Modal(document.getElementById("sessionModal"), {});
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      // console.log(`${minutes}:${seconds}`);

      if (minutes === warnBeforeMinutes && seconds === 0) {
        handleShow();
      }

      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          handleClose();
          toast.error("Session timed out!!");
          onSessionTimeout();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    timerReference.current = myInterval;
    return () => {
      clearInterval(myInterval);
    };
  });

  const stopSessionClose = () => {
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
    handleClose();
  };

  const showTimerMessage = () => {
    return (
      <p>
        Your session is about to expire in
        <span
          style={{ color: minutes <= 1 ? "red" : "green" }}
        >{` 0${minutes}:${seconds < 10 ? "0" + seconds : seconds}`}</span>
      </p>
    );
  };

  const renderLogin = () => {
    return (
      <>
        <div
          className="modal fade"
          id="sessionModal"
          aria-labelledby="sessionModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="sessionModalLabel">
                  {showTimerMessage()}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <LoginComponent
                  onContinueSession={stopSessionClose}
                  upateSpinner={upateSpinner}
                  userName={username}
                  password={password}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div style={{ textAlign: "center", margin: "auto" }}>{renderLogin()}</div>
  );
};

export default SessionTimer;
