import React, { ChangeEvent } from "react";

export interface Option {
  value: string | number;
  label: string;
}

interface SelectProps {
  options: Option[];
  value?: string | number;
  onChange?: (value: ChangeEvent<HTMLSelectElement>, index: number) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  size?: "sm" | "lg";
  // Add other props as needed
}

const Select: React.FC<SelectProps> = ({
  options,
  value = "", // default value if not provided
  onChange = () => {}, // default to a no-op function if not provided
  placeholder = "Select...", // default placeholder text
  disabled = false, // default to not disabled
  className = "", // default to no additional class
  size,
}) => {
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(event, event?.target?.selectedIndex);
    }
  };

  const selectSizeClass = size ? `form-select-${size}` : "";

  return (
    <select
      className={`form-select ${selectSizeClass} ${className}`}
      value={value}
      onChange={handleSelectChange}
      disabled={disabled}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
