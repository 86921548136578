import { DosageFormList, Logs, ProductCategoryList, SaveDosageForm, SaveProductCategory, WarehouseApiBase } from "../../common/constants/apiConstants";
import { IProductCategory, IDosage } from "../../common/models/master";
import { AxiosService } from "../generics/axios.service";
import { IMasterService } from "../interfaces/IMaster";

export class MasterService implements IMasterService {
    private axiosService: AxiosService = new AxiosService();
    async getProductCategories(): Promise<IProductCategory[]> {
        try {
            const result: any = await this.axiosService.Get(ProductCategoryList);
            const _list: IProductCategory[] = result.data ?? [];
            console.log('Response: Category List - ', _list);
            return _list;
        } catch (error) {
            console.error('Error: Category List - ', error);
            return [];
        }
    }
    async getDosageForms(): Promise<IDosage[]> {
        try {
            const result: any = await this.axiosService.Get(DosageFormList);
            const _list: IDosage[] = result.data ?? [];
            console.log('Response: Dosage Forms List - ', _list);
            return _list;
        } catch (error) {
            console.error('Error: Dosage Forms List - ', error);
            return error.response.data[0]?.errorMessage ?? [];
        }
    }

    async saveProductCategories(listItem: string): Promise<boolean> {
        try {
            const request: IProductCategory = {
                categoryCode: listItem.toUpperCase(),
                categoryName: listItem,
            }
            await this.axiosService.Post(SaveProductCategory, request);
            return true;
        } catch (error) {
            console.error('Error: Save Category - ', error);
            return false;
        }
    }
    async saveDosageForms(listItem: string): Promise<boolean> {
        try {
            const request: IDosage = {
                dosageCode: listItem.toUpperCase(),
                dosageName: listItem,
            }
            await this.axiosService.Post(SaveDosageForm, request);
            return true;

        } catch (error) {
            console.error('Error: Save Dosage Forms - ', error);
            return false;
        }
    }
    async getLogs(id: string): Promise<any[]> {
        try {
            const result: any = await this.axiosService.Get(Logs(id));
            const _list: any[] = result.data ?? [];
            console.log('Response: Dosage Forms List - ', _list);
            return _list;
        } catch (error) {
            console.error('Error: Dosage Forms List - ', error);
            return error.response.data[0]?.errorMessage ?? [];
        }
    }
    async getLogsWarehouse(id: string): Promise<any[]> {
        try {
            const result: any = await this.axiosService.Get(Logs(id),WarehouseApiBase);
            const _list: any[] = result.data ?? [];
            console.log('Response: Dosage Forms List - ', _list);
            return _list;
        } catch (error) {
            console.error('Error: Dosage Forms List - ', error);
            return error.response.data[0]?.errorMessage ?? [];
        }
    }
}