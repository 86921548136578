import { ILoaderProps } from '../../common/models';

const Spinner = (props: ILoaderProps) => {
    const { Message } = props;

    return (<div className={`loadercontainer`}>
        <div className={`loader`}> </div>
        < h6 > {Message} </h6>
    </div>
    );
};
export default Spinner;
