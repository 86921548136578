import { Outlet } from "react-router-dom";
import { GrnContext } from "../../../controllers/contexts/grn.context";
import useGrn from "../../../controllers/hooks/useGrn";
import useWarehouse from "../../../controllers/hooks/useWarehouse";
import useProduct from "../../../controllers/hooks/useProduct";
import { WarehouseContext } from "../../../controllers/contexts/warehouse.context";
import { ProductContext } from "../../../controllers/contexts/product.context";

const GrnLayoutComponent = () => {
  const grnFunctions = useGrn();
  const wrhFunctions = useWarehouse();
  const productFunctions = useProduct();

  return (
    <WarehouseContext.Provider value={wrhFunctions}>
      <GrnContext.Provider value={grnFunctions}>
        <Outlet />
      </GrnContext.Provider>
    </WarehouseContext.Provider>
  );
};

export default GrnLayoutComponent;
