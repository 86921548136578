import { useEffect, useState } from "react";
import {
  IGRNAisleToBatchLocationInfo,
  IGRNAisleToBatchProps,
} from "../../../common/models";
import validator from "../../../common/models/validator";
import { LabelConstants } from "../../../common/constants/labelConstants";
import _ from "lodash";
import { cloneDeep } from "lodash";
import { NewGRNAisleToBatchMapDialog } from "../../../common/constants/generalConstants";
import { toast } from "react-toastify";

const VALIDATOR = {
  quantitydata: [
    (value: number) => (value > 0 ? null : `Enter valid quantity`),
  ],
  refnumdata: [
    (value: string) =>
      _.isEmpty(value?.trim()) ? `Enter valid reference number` : null,
  ],
};

interface FormErrors {
  quantitydata?: string;
  refnumdata?: string;
  index?: number;
}

const GRNAisleBatchDetailsComponent = (props) => {
  const [error, setError] = useState<FormErrors>({});
  const [batchToAisleInfo, setbatchToAisleInfo] =
    useState<IGRNAisleToBatchProps>(NewGRNAisleToBatchMapDialog);

  useEffect(() => {
    let data: IGRNAisleToBatchProps = cloneDeep(batchToAisleInfo);
    data.oncancel = props?.data?.oncancel;
    data.onconfirm = props?.data?.onconfirm;
    data.locationData = props?.data?.locationData;
    data.maxQty = props?.data?.maxQty;
    setbatchToAisleInfo(data);
    setError({});
  }, [props]);

  const validateData = () => {
    let isErrors: FormErrors;
    let totalQty = 0;
    batchToAisleInfo?.locationData?.map((_locData, _locIndex) => {
      let obj = {
        quantitydata: _locData?.quantity,
        refnumdata: _locData?.referenceNumber,
        index: _locIndex,
      };
      isErrors = validator(obj, VALIDATOR);
      setError(isErrors);
      if (!_.isEmpty(isErrors)) {
        setError({ ...isErrors, index: _locIndex });
      }
      totalQty += _locData?.quantity;
    });

    if (_.isEmpty(isErrors)) {
      if (totalQty !== batchToAisleInfo?.maxQty) {
        toast.error(
          `Assigned total(${totalQty}) not equal to received(${batchToAisleInfo?.maxQty})!!`
        );
        return;
      }
      batchToAisleInfo?.onconfirm(batchToAisleInfo?.locationData);
    }
  };

  const handleForm = (type: string, value: any, index: number) => {
    let batchData: IGRNAisleToBatchProps = cloneDeep(batchToAisleInfo);
    batchData.locationData[index][type] = value;
    setbatchToAisleInfo(batchData);
  };

  return (
    <div className="modal fade" id="grnaislebatchdetails" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center">
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table product-overview table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>{LabelConstants.Shelf}</th>
                        <th>{LabelConstants.Qty}</th>
                        <th>{LabelConstants.PalletRefNum}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batchToAisleInfo?.locationData?.map(
                        (
                          _location: IGRNAisleToBatchLocationInfo,
                          _locIndx: number
                        ) => {
                          return (
                            <tr className="col-12">
                              <td className="col-3">{_location?.shelfId}</td>
                              <td className="col-3">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  placeholder={LabelConstants.Qty}
                                  value={_location?.quantity}
                                  style={{
                                    borderColor:
                                      error?.index === _locIndx &&
                                      error?.quantitydata
                                        ? "red"
                                        : "",
                                  }}
                                  onChange={(e) => {
                                    handleForm(
                                      "quantity",
                                      parseInt(e.target.value),
                                      _locIndx
                                    );
                                  }}
                                />
                              </td>
                              <td className="col-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={LabelConstants.PalletRefNum}
                                  value={_location?.referenceNumber}
                                  style={{
                                    borderColor:
                                      error?.index === _locIndx &&
                                      error?.refnumdata
                                        ? "red"
                                        : "",
                                  }}
                                  onChange={(e) => {
                                    handleForm(
                                      "referenceNumber",
                                      e.target.value,
                                      _locIndx
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="w-p100 d-flex justify-content-center">
                <button
                  type="button"
                  className="waves-effect waves-light btn btn-secondary w-p25"
                  onClick={() => {
                    batchToAisleInfo?.oncancel();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="waves-effect waves-light btn btn-danger w-p25 ms-4"
                  onClick={validateData}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GRNAisleBatchDetailsComponent;
