import { Outlet } from "react-router-dom";
import useReturnOrders from "../../controllers/hooks/useReturnOrders";
import { ReturnOrdersContext } from "../../controllers/contexts/returnorders.context";

const ReturnOrdersLayoutComponent = () => {
  const ReturnOrdersFunctions = useReturnOrders();

  return (
    <ReturnOrdersContext.Provider value={ReturnOrdersFunctions}>
      <Outlet />
    </ReturnOrdersContext.Provider>
  );
};

export default ReturnOrdersLayoutComponent;
