import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IGRDetailsListServerResponseProdLocation } from "../../../common/models";
import { GrnContext } from "../../../controllers/contexts/grn.context";
import { pagePrintLSStyle } from "../../../common/methods/globalmethods";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { LabelConstants } from "../../../common/constants/labelConstants";
import moment from "moment";
import PrintHeader from "../../prints/PrintHeader";
import BoxItemWithKeyValue from "../../prints/Components/BoxItemWithKeyValue";

const GRNPrintComponent = () => {
  const { masterProductList, getSupplierInfoByPONumber } =
    useContext(GrnContext);
  const location = useLocation();
  const [orderItem, setorderItem] = useState<any>(
    location?.state?.selectedItem
  );
  const [supplierName, setsupplierName] = useState("");

  useEffect(() => {
    console.log("Order Item - ", orderItem);
    getSupplierInfo();
  }, []);

  const getSupplierInfo = async () => {
    let supplierDetails = await getSupplierInfoByPONumber(orderItem?.orderNum);
    setsupplierName(supplierDetails?.supName);
  };

  const getProductDetails = (productId: string) => {
    let result = masterProductList.find(
      (item) => item?.productId + "" === productId
    );
    return result;
  };

  return (
    <div>
      <style>{pagePrintLSStyle}</style>
      {/* <div style={{ width: 297 + "mm" }}> */}
      <div className="content-header row">
        <div className="d-flex align-items-center col-md-11">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.grn}
                    >
                      {LabelConstants.OrderIn}
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.grn}
                    >
                      {LabelConstants.ListGRN}
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Print
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <button
            type="button"
            className="waves-effect waves-light btn btn-success rounded"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
        </div>
      </div>

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <style>{pagePrintLSStyle}</style>
        <div style={{ width: 297 + "mm" }}>
          <div
            className="col-12 p-30 pt-0 pb-0"
            style={{ backgroundColor: "white" }}
          >
            <PrintHeader centerText="GOODS RECEIPT NOTE" />
            <div className="row">
              <div className="col-7">
                {BoxItemWithKeyValue("GRN#", orderItem?.goodsReceiptNumber)}
                {BoxItemWithKeyValue(
                  "Date of receipt",
                  moment(orderItem?.receiptDate)?.format("DD-MM-YYYY")
                )}
                {BoxItemWithKeyValue("PO#", orderItem?.orderNum)}
                {BoxItemWithKeyValue(
                  "Approved Manufacturing Site",
                  orderItem?.supValid ? "Yes" : "No"
                )}
              </div>
              <div className="col-5">
                {BoxItemWithKeyValue(
                  "Approved Supplier",
                  orderItem?.supValid ? "Yes" : "No"
                )}
                {BoxItemWithKeyValue("Supplier Name", orderItem?.supplierName)}
                {BoxItemWithKeyValue(
                  "Container Sealed",
                  orderItem?.sealed ? "Yes" : "No"
                )}
                {BoxItemWithKeyValue("Container Seal No.", orderItem?.sealNum)}
              </div>
            </div>
          </div>
          <div
            className="col-12 table-responsive"
            style={{ backgroundColor: "white", padding: 20 }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ fontSize: 12, margin: 0 }}>Location</th>
                  <th style={{ fontSize: 12, margin: 0 }}>
                    Pallet Reference #
                  </th>
                  <th style={{ fontSize: 12, margin: 0 }}>Product Code</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Product Name</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Batch #</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Expiry Date</th>
                  <th style={{ fontSize: 12 }}>
                    Supplier Theoretical Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderItem?.grDetails?.map((_orderItem) =>
                  _orderItem.prodLocs?.map(
                    (
                      _product: IGRDetailsListServerResponseProdLocation,
                      _productIdx: number
                    ) => {
                      let productdata = getProductDetails(_orderItem?.prodId);
                      return (
                        <tr className="col-12 p-0">
                          <td className="col-1">
                            <p style={{ fontSize: 12, margin: 0 }}>
                              {_product?.shelfId}
                            </p>
                          </td>
                          <td className="col-2">
                            <p style={{ fontSize: 12, margin: 0 }}>
                              {_product?.palletRefNum}
                            </p>
                          </td>
                          <td className="col-2">
                            <p
                              style={{ fontSize: 12, margin: 0 }}
                            >{`${productdata?.productCode}`}</p>
                          </td>
                          <td className="col-3">
                            <p style={{ fontSize: 12, margin: 0 }}>
                              {productdata?.productName
                                ? `${productdata?.productName}`
                                : ""}
                            </p>
                          </td>
                          <td className="col-1">
                            <p style={{ fontSize: 12, margin: 0 }}>
                              {_orderItem?.batchNo}
                            </p>
                          </td>
                          <td className="col-1">
                            <p style={{ fontSize: 12, margin: 0 }}>
                              {moment(_orderItem?.batchExpDate)?.format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </td>
                          <td className="col-1">
                            <p style={{ fontSize: 12, margin: 0 }}>
                              {_product?.locQty}
                            </p>
                          </td>
                        </tr>
                      );
                    }
                  )
                )}
              </tbody>
            </table>
            <div className="d-flex mt-20 justify-content-between">
              <p>
                Completed by <strong>{orderItem?.completedBy}</strong> at{" "}
                <strong>
                  {moment(orderItem?.completedAt)?.format("DD-MM-YYYY hh:mm A")}
                </strong>
              </p>
              <p>
                Approved by <strong>{orderItem?.approvedBy}</strong> at{" "}
                <strong>
                  {moment(orderItem?.approvedAt)?.format("DD-MM-YYYY hh:mm A")}
                </strong>
              </p>
            </div>
            <p style={{ color: "grey" }}>
              *This is an electronically generated receipt, hence does not
              require a signature
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GRNPrintComponent;
