import { Outlet } from "react-router-dom";
import { SupplierContext } from "../../../controllers/contexts/supplier.context";
import useSupplier from "../../../controllers/hooks/useSupplier";

const SupplierLayoutComponent = () => {
    const supplierFunctions = useSupplier();

    return <SupplierContext.Provider value={supplierFunctions}>
        <Outlet />
    </SupplierContext.Provider>;
}

export default SupplierLayoutComponent;
