import {
  BatchTraceablilityReports,
  DispatchReports,
  GoodsMovementReports,
  GoodsReceiptReports,
  OrdersApiBase,
  POReports,
  PendingSOReports,
  PlanningReports,
  ProductMovementReports,
  StockReports,
} from "../../common/constants/apiConstants";
import {
  IListConfigBatchTraceabilityRpt,
  IListConfigGoodsMovementRpt,
  IListConfigGoodsReceiptRpt,
  IListConfigPORpt,
  IListConfigPlanningRpt,
  IListConfigStockRpt,
} from "../../common/models";
import {
  IBatchTraceabilityReport,
  IBatchTraceabilityReportServerData,
  IDispatchReport,
  IGoodsMovementReport,
  IGoodsReceiptReport,
  IPOReport,
  IPendingSOReport,
  IPlanningReport,
  IProductMovementReport,
  IStockReport,
} from "../../common/models/reports";
import { AxiosService } from "../generics/axios.service";

export class ReportsService {
  private axiosService: AxiosService = new AxiosService();

  async getPOReports(filter: IListConfigPORpt): Promise<IPOReport[]> {
    try {
      const data = await this.axiosService.Post(
        POReports,
        filter,
        OrdersApiBase
      );
      return data?.data?.content;
    } catch (error) {
      console.error(
        "Error: getPOReports - ",
        error?.response?.data[0]?.errorMessage
      );
      return [];
    }
  }

  async getStockReports(filter: IListConfigStockRpt): Promise<IStockReport[]> {
    try {
      const data = await this.axiosService.Post(
        StockReports,
        filter,
        OrdersApiBase
      );
      return data?.data?.content;
    } catch (error) {
      console.error(
        "Error: getStockReports - ",
        error?.response?.data[0]?.errorMessage
      );
      return [];
    }
  }

  async getPlanningReports(
    filter: IListConfigPlanningRpt
  ): Promise<IPlanningReport[]> {
    try {
      const data = await this.axiosService.Post(
        PlanningReports,
        filter,
        OrdersApiBase
      );
      return data?.data?.content;
    } catch (error) {
      console.error(
        "Error: getPlanningReports - ",
        error?.response?.data[0]?.errorMessage
      );
      return [];
    }
  }

  async getGoodsMovementReports(
    query: string
  ): Promise<IGoodsMovementReport[]> {
    try {
      const data = await this.axiosService.Post(
        OrdersApiBase + GoodsMovementReports + query,
        null
      );
      return data?.data?.content;
    } catch (error) {
      console.error("Error: getGoodsMovementReports - ", error);
      return [];
    }
  }

  async getGoodsReceiptsReports(
    filter: IListConfigGoodsReceiptRpt
  ): Promise<IGoodsReceiptReport[]> {
    try {
      const data = await this.axiosService.Post(
        GoodsReceiptReports,
        filter,
        OrdersApiBase
      );
      return data?.data?.content;
    } catch (error) {
      console.error(
        "Error: getGoodsReceiptsReports - ",
        error?.response?.data[0]?.errorMessage
      );
      return [];
    }
  }

  async getBatchTraceabilityReports(
    query: string
  ): Promise<IBatchTraceabilityReportServerData[]> {
    try {
      const data = await this.axiosService.Post(
        OrdersApiBase + BatchTraceablilityReports + query,
        null
      );
      return data?.data;
    } catch (error) {
      console.error("Error: getBatchTraceabilityReports - ", error);
      return [];
    }
  }

  async getProductMovementReports(
    query: string
  ): Promise<IProductMovementReport[]> {
    try {
      const data = await this.axiosService.Get(
        ProductMovementReports + query,
        OrdersApiBase
      );
      return data?.data?.content;
    } catch (error) {
      console.error(
        "Error: getProductMovementReports - ",
        error?.response?.data[0]?.errorMessage
      );
      return [];
    }
  }

  async getDispatchReports(query: string): Promise<IDispatchReport[]> {
    try {
      const data = await this.axiosService.Get(
        DispatchReports + query,
        OrdersApiBase
      );
      return data?.data?.content;
    } catch (error) {
      console.error(
        "Error: getDispatchReports - ",
        error?.response?.data[0]?.errorMessage
      );
      return [];
    }
  }

  async getPendingSOReports(query: string): Promise<IPendingSOReport[]> {
    try {
      const data = await this.axiosService.Get(
        PendingSOReports + query,
        OrdersApiBase
      );
      return data?.data?.content;
    } catch (error) {
      console.error(
        "Error: getPendingSOReports - ",
        error?.response?.data[0]?.errorMessage
      );
      return [];
    }
  }
}
