import { Outlet } from "react-router-dom";
import { ProductContext } from "../../../controllers/contexts/product.context";
import useProduct from "../../../controllers/hooks/useProduct";

const ProductLayoutComponent = () => {
    const productFunctions = useProduct();

    return <ProductContext.Provider value={productFunctions}>
        <Outlet />
    </ProductContext.Provider>;
}

export default ProductLayoutComponent;
