import { NavLink, useLocation } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { useContext, useEffect, useState } from "react";
import {
  getProductCatNameById,
  getProductCode,
  getProductName,
  getProductNameById,
  pagePrintPorStyle,
} from "../../common/methods/globalmethods";
import moment from "moment";
import { ICustomerAddress } from "../../common/models";
import PrintHeader from "./PrintHeader";
import BoxItemWithKeyValue from "./Components/BoxItemWithKeyValue";
import { IReturnOrders } from "../../common/models/returnorders";
import { ReturnOrdersContext } from "../../controllers/contexts/returnorders.context";

const ReturnOrderPrintFormat = () => {
  const { productsList, customersList } = useContext(ReturnOrdersContext);
  const location = useLocation();
  const [roItem, setroItem] = useState<IReturnOrders>(
    location?.state?.selectedItem
  );
  const [customerAddress, setcustomerAddress] =
    useState<ICustomerAddress>(null);

  const getSubTotal = () => {
    let subtotal = 0;
    roItem?.returnOrderDetails?.map((item) => {
      subtotal += item?.productAmount;
    });
    return subtotal?.toFixed(2);
  };

  useEffect(() => {
    getCustomerAddress();
  }, []);

  const getCustomerAddress = () => {
    let customer = customersList?.filter(
      (item) => item.custId === roItem?.customerId
    );
    if (customer?.length > 0) {
      customer[0]?.custAddresses?.map((address) => {
        if (
          address?.custAddressId?.toString() === roItem?.addressId?.toString()
        ) {
          setcustomerAddress(address);
        }
      });
    }
  };

  return (
    <div>
      <div className="content-header row">
        <div className="d-flex align-items-center col-md-11">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.returnorders}
                    >
                      Return Orders
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Print
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <button
            type="button"
            className="waves-effect waves-light btn btn-success rounded"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <style>{pagePrintPorStyle}</style>
        <div style={{ width: 210 + "mm" }}>
          <div
            style={{
              backgroundColor: "white",
              padding: 30,
              paddingTop: 0,
              paddingBottom: 10,
            }}
          >
            <PrintHeader centerText="RETURN ORDER" />
            <div className="row">
              <div className="col-7">
                {BoxItemWithKeyValue("Delivery Order #", roItem?.deliveryNum)}
                {BoxItemWithKeyValue("Customer Name", roItem?.customerName)}
                {customerAddress?.address2 !== "" &&
                  BoxItemWithKeyValue(
                    "Customer Address",
                    `${customerAddress?.address1}\n${customerAddress?.address2}`
                  )}
                {BoxItemWithKeyValue(
                  "",
                  `${customerAddress?.city},${customerAddress?.county}`
                )}
                {BoxItemWithKeyValue(
                  "",
                  `${customerAddress?.country?.toUpperCase()},${customerAddress?.postCode?.toUpperCase()}`
                )}
              </div>
              <div className="col-5">
                {BoxItemWithKeyValue(
                  "Delivery Order Date",
                  moment(roItem?.roCreatedDate).format("DD/MM/YYYY")
                )}
                {BoxItemWithKeyValue(
                  "Ship Date",
                  moment(roItem?.createdDate).format("DD/MM/YYYY")
                )}
                {BoxItemWithKeyValue("SO #", roItem?.soNum)}
                {BoxItemWithKeyValue("Transporter", "")}
                {BoxItemWithKeyValue("Tracking #", "")}
              </div>
            </div>
          </div>
          <div
            className="col-12 table-responsive"
            style={{ backgroundColor: "white", padding: 20, paddingTop: 0 }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ fontSize: 12, margin: 0 }}>Product Code</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Description</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Batch #</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Exp. Date</th>
                  {/* <th className="">Pack Size</th> */}
                  <th style={{ fontSize: 12, margin: 0 }}>Price</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Dispatched Qty</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Return Qty</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {roItem?.returnOrderDetails?.map((item) => (
                  <tr className="col-12 p-0">
                    <td style={{ fontSize: 12, margin: 0 }}>
                      <p style={{ fontSize: 12, margin: 0 }}>
                        {getProductCode(item?.prodId, productsList)}
                      </p>
                    </td>
                    <td style={{ fontSize: 12, margin: 0 }}>
                      <p style={{ fontSize: 12, margin: 0 }}>
                        {getProductName(item?.prodId, productsList)}
                      </p>
                    </td>
                    <td style={{ fontSize: 12, margin: 0 }}>
                      <p style={{ fontSize: 12, margin: 0 }}>{item?.batchNo}</p>
                    </td>
                    <td style={{ fontSize: 12, margin: 0 }}>
                      <p style={{ fontSize: 12, margin: 0 }}>
                        {moment(item?.batchExpiry).format("DD/MM/yyyy")}
                      </p>
                    </td>
                    {/* <td className="">
                  <p style={{ fontSize: 12, margin: 0 }}>{item?.packSize}</p>
                </td> */}
                    <td style={{ fontSize: 12, margin: 0 }}>
                      <p style={{ fontSize: 12, margin: 0 }}>
                        {item?.unitPrice}
                      </p>
                    </td>
                    <td style={{ fontSize: 12, margin: 0 }}>
                      <p style={{ fontSize: 12, margin: 0 }}>
                        {item?.dispatchQty}
                      </p>
                    </td>
                    <td style={{ fontSize: 12, margin: 0 }}>
                      <p style={{ fontSize: 12, margin: 0 }}>
                        {item?.returnQty}
                      </p>
                    </td>
                    <td style={{ fontSize: 12, margin: 0 }}>
                      <p style={{ fontSize: 12, margin: 0 }}>
                        {item?.productAmount}
                      </p>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="b-1 border-black" colSpan={3} rowSpan={3}>
                    <p className="text-bold">VAT Analysis : </p>
                    <p></p>
                  </td>
                  <td className="text-bold" colSpan={4}>
                    SUB TOTAL
                  </td>
                  <td className="text-bold">{getSubTotal()}</td>
                </tr>
                <tr>
                  <td colSpan={4} className="text-bold">
                    TAX RATE
                  </td>
                  <td className="text-bold">{roItem?.vatAmount}</td>
                </tr>
                <tr>
                  <td colSpan={4} className="text-bold">
                    TOTAL
                  </td>
                  <td className="text-bold">{roItem?.totalAmount}</td>
                </tr>
              </tbody>
            </table>
            <div className="col-md-12 b-1 h-150 mt-30 p-10">
              <p className="text-bold">
                <u>Special Instructions:</u>
              </p>
              <p>
                All deliveries must be pre-booked prior to delivery : email -
                supplychain@medleypharma.co.uk
              </p>
              <p>Reason for Return : {roItem?.returnReason}</p>
            </div>
            <div className="col-md-4 b-1 mt-30 p-20 mb-30">
              <p className="text-bold" style={{ marginLeft: -5 }}>
                <u>Delivery Address:</u>
              </p>
              <p style={{ marginBottom: 0 }}>{roItem?.customerName}</p>
              <p style={{ marginBottom: 0 }}>{customerAddress?.address1}</p>
              {customerAddress?.address2 !== "" && (
                <p style={{ marginBottom: 0 }}>{customerAddress?.address2}</p>
              )}
              <p style={{ marginBottom: 0 }}>
                {customerAddress?.city},{customerAddress?.county}
              </p>
              <p style={{ marginBottom: 0 }}>
                {customerAddress?.country?.toUpperCase()},{" "}
                {customerAddress?.postCode?.toUpperCase()}
              </p>
            </div>
            <div className="d-flex mt-20 justify-content-between">
              <p>
                Created by <strong>{roItem?.createdBy}</strong> at{" "}
                <strong>
                  {moment(roItem?.modifiedDate)?.format("DD-MM-YYYY hh:mm A")}
                </strong>
              </p>
              <p>
                Approved by <strong>{roItem?.submittedBy}</strong> at{" "}
                <strong>
                  {moment(roItem?.submittedAt)?.format("DD-MM-YYYY hh:mm A")}
                </strong>
              </p>
            </div>
            <p style={{ color: "grey" }}>
              *This is an electronically generated receipt, hence does not
              require a signature
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnOrderPrintFormat;
