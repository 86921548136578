import { DocumentsDelete, DocumentsList, DocumentsUpload } from "../../common/constants/apiConstants";
import { IDocuments } from "../../common/models";
import { AxiosService } from "../generics/axios.service";
import { IDocumentService } from "../interfaces/IDocument";

export class DocumentService implements IDocumentService {
    private axiosService: AxiosService = new AxiosService();

    async uploadFiles(files: File[], userId: string): Promise<boolean> {
        try {
            const _filesRequest: FormData = new FormData();
            _filesRequest.append('userId', userId);
            files.forEach((file) => {
                _filesRequest.append('files', file);
            });
            await this.axiosService.Post(DocumentsUpload, _filesRequest);
            return true;
        } catch (error) {
            console.error('Error: Uploading Documents - ', error);
            return false;
        }
    }

    async getFiles(userId: string): Promise<IDocuments[]> {
        try {
            const result: any = await this.axiosService.Get(DocumentsList(userId));
            const _documents: IDocuments[] = result.data ?? [];
            console.log('Response: Document List - ', _documents);
            return _documents;
        } catch (error) {
            console.error('Error: Document List - ', error);
            return error.response.data[0]?.errorMessage ?? [];
        }
    }

    async deleteFiles(docId: number): Promise<boolean> {
        try {
            await this.axiosService.Delete(DocumentsDelete(docId));
            console.log('Response: Document Deleted');
            return true;
        } catch (error) {
            console.error('Error: Deleting Document - ', error);
            return false;
        }
    }
}
