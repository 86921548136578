import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IDeliveryOrder } from "../../common/models/deliveryorder";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import moment from "moment";
import { DeliveryOrderContext } from "../../controllers/contexts/deliveryorder.context";
import {
  getCustomerName,
  getProductCode,
  getProductName,
  isNullUndefined,
  pagePrintPorStyle,
} from "../../common/methods/globalmethods";
import PrintHeader from "./PrintHeader";
import BoxItemWithKeyValue from "./Components/BoxItemWithKeyValue";
import { ICustomerAddress } from "../../common/models";
import { ISalesOrder } from "../../common/models/salesorder";

const DeliveryPrintFormat = () => {
  const {
    productsList,
    customersList,
    getSalesOrderBySoNum,
    getCustomersList,
  } = useContext(DeliveryOrderContext);
  const [deliveraddressVal, setdeliveraddressVal] =
    useState<ICustomerAddress>(null);

  const location = useLocation();
  const [deliveryOrderItem, setDeliveryOrderItem] = useState<IDeliveryOrder>(
    location?.state?.selectedItem
  );

  useEffect(() => {
    getCustomerAddress();
  }, []);

  const getCustomerAddress = async () => {
    let customers = customersList;
    if (customers?.length <= 0) {
      customers = await getCustomersList();
    }
    await getSalesOrderBySoNum(deliveryOrderItem?.soNum).then(
      (soItem: ISalesOrder[]) => {
        if (!isNullUndefined(soItem) && soItem?.length > 0) {
          let customer = customers?.filter(
            (item) => item.custId === deliveryOrderItem?.customerId
          );
          if (customer?.length > 0) {
            customer[0]?.custAddresses?.map((address) => {
              if (
                address?.custAddressId?.toString() ===
                soItem[0]?.addressId?.toString()
              ) {
                setdeliveraddressVal(address);
              }
            });
          }
        }
      }
    );
  };

  return (
    <div>
      <div className="content-header row">
        <div className="d-flex align-items-center col-md-11">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.delivery_order}
                    >
                      {LabelConstants.OrderOut}
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.delivery_order}
                    >
                      Delivery Order
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Print
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <button
            type="button"
            className="waves-effect waves-light btn btn-success rounded"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <style>{pagePrintPorStyle}</style>
        <div style={{ width: 210 + "mm" }}>
          <div
            className="col-12 p-30 pt-0"
            style={{ backgroundColor: "white" }}
          >
            <PrintHeader
              centerText="DELIVERY NOTE"
              leftItemSize="col-2"
              centerItemSize="col-6"
            />
            <div className="row m-0">
              <div className="col-md-6 col-sm-6 mt-1">
                {BoxItemWithKeyValue(
                  "Delivery Order #",
                  deliveryOrderItem?.deliveryNum
                )}
                {BoxItemWithKeyValue("SO #", deliveryOrderItem?.soNum)}
                {BoxItemWithKeyValue(
                  "Order Ref. #",
                  deliveryOrderItem?.customerRefNum
                )}
                {BoxItemWithKeyValue(
                  "Customer Name",
                  getCustomerName(deliveryOrderItem?.customerId, customersList)
                )}

                {deliveraddressVal?.address2 !== "" &&
                  BoxItemWithKeyValue(
                    "Delivery Address",
                    `${deliveraddressVal?.address1}\n${deliveraddressVal?.address2}`
                  )}

                {BoxItemWithKeyValue(
                  "",
                  `${deliveraddressVal?.city},${deliveraddressVal?.county}`
                )}
                {BoxItemWithKeyValue(
                  "",
                  `${deliveraddressVal?.country.toUpperCase()}\n${deliveraddressVal?.postCode.toUpperCase()}\n`
                )}

                <div className="border-groove b-1"></div>
                {BoxItemWithKeyValue(
                  "Booking Ref. #",
                  deliveryOrderItem?.bookingRefNo
                )}
                {BoxItemWithKeyValue("Total No. of Pallets", "")}
                {BoxItemWithKeyValue("Total No. of Boxes", "")}
                {BoxItemWithKeyValue("Checked By", "")}
                {BoxItemWithKeyValue("", "---------------------------------")}
              </div>
              <div className="col-md-6">
                {BoxItemWithKeyValue(
                  "Date Printed",
                  moment(deliveryOrderItem?.createdDate).format("DD/MM/YYYY")
                )}
                {BoxItemWithKeyValue(
                  "Pick Order #",
                  deliveryOrderItem?.orderPickingId
                )}
                {BoxItemWithKeyValue(
                  "Data Logger Inside",
                  deliveryOrderItem?.dataLog === "Yes" ? "Yes" : "No"
                )}
                {BoxItemWithKeyValue(
                  "Date of Dispatch",
                  moment(deliveryOrderItem?.dispatchDate).format("DD/MM/yyyy")
                )}
                {BoxItemWithKeyValue(
                  "Collection Addres",
                  `Unit 2A, Olympic Way,`
                )}
                {BoxItemWithKeyValue("", `Sefton Business Park,`)}
                {BoxItemWithKeyValue("", `Liverpool L30 1RD UK`)}
                <div className="border-groove b-1"></div>
                {BoxItemWithKeyValue(
                  "Courier",
                  deliveryOrderItem?.transporterName
                )}
                {BoxItemWithKeyValue(
                  "Waybill/Tracking #",
                  deliveryOrderItem?.trackingNumber
                )}
                {BoxItemWithKeyValue("Vehicle Reg #", "")}
                {BoxItemWithKeyValue("Drivers Signature", "")}
                {BoxItemWithKeyValue("", "--------------------------------")}
              </div>
            </div>
            <div
              className="col-12 table-responsive"
              style={{ backgroundColor: "white", paddingTop: 20 }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ fontSize: 12, margin: 0 }}>Product Code</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Product Name</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Qty</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Batch #</th>
                    <th style={{ fontSize: 12, margin: 0 }}>Exp. Date</th>
                    <th style={{ fontSize: 12, margin: 0 }}>
                      {"Full Boxes x No. Of Packs"}
                    </th>
                    <th style={{ fontSize: 12, margin: 0 }}>
                      Part Boxes x No. Of Packs
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deliveryOrderItem?.deliveryOrderDetails?.map((item) => (
                    <tr>
                      <td style={{ fontSize: 12, margin: 0 }}>
                        {getProductCode(item.prodId, productsList)}
                      </td>
                      <td style={{ fontSize: 12, margin: 0 }}>
                        {getProductName(item.prodId, productsList)}
                      </td>
                      <td style={{ fontSize: 12, margin: 0 }}>
                        {item?.quantity}
                      </td>
                      <td style={{ fontSize: 12, margin: 0 }}>
                        {item?.batchNo}
                      </td>
                      <td style={{ fontSize: 12, margin: 0 }}>
                        {moment(item?.batchExpiry)?.format("DD-MM-YYYY")}
                      </td>
                      <td style={{ fontSize: 12, margin: 0 }}></td>
                      <td style={{ fontSize: 12, margin: 0 }}></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h6
              style={{
                textDecoration: "underline",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              *Please note some part boxes may be combined.
            </h6>
            <tfoot className="table-footer">
              <p
                className="text-bold"
                style={{ marginBottom: 0, marginTop: 20 }}
              >
                Return Address
              </p>
              <p style={{ marginBottom: 0 }}>Medley Pharma Limited</p>
              <p style={{ marginBottom: 0 }}>2A Olympic Way,</p>
              <p style={{ marginBottom: 0 }}>Sefton Business Park,</p>
              <p style={{ marginBottom: 0 }}>Aintree, L130 1RD</p>
              <p style={{ marginBottom: 0 }}>Tel: +44 (0) 1515214527</p>
            </tfoot>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPrintFormat;
