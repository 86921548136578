import {
  MAX_PAGE_RECORD_SIZE,
  OrdersApiBase,
  getNextSalesOrderNumber,
  getSalesOrderBySoNum,
  salesOrderList,
  salesOrderSave,
  salesOrderUpdate,
  salesOrderUpdateById,
  searchSOByCriteria,
  stockInHand,
} from "../../common/constants/apiConstants";
import { IListConfigSO, IStatusUpdate } from "../../common/models";
import {
  ISalesOrder,
  ISalesOrdreServerFormat,
  StockStatus,
} from "../../common/models/salesorder";
import { AxiosService } from "../generics/axios.service";

export class SalesOrderService {
  private axiosService: AxiosService = new AxiosService();

  async getNextSoNumber() {
    try {
      const result: any = await this.axiosService.Get(
        getNextSalesOrderNumber,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Next SO Number - ", error);
      return [];
    }
  }

  async searchSOList(
    filter: IListConfigSO,
    pageNo: number,
    maxSize: number = MAX_PAGE_RECORD_SIZE
  ): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        searchSOByCriteria(pageNo, maxSize),
        filter,
        OrdersApiBase
      );
      return result?.data ?? null;
    } catch (error) {
      console.error("Error: searchSOList List - ", error);
      return null;
    }
  }

  async createNewSalesOrder(
    newSalesOrder: ISalesOrdreServerFormat
  ): Promise<string | number> {
    try {
      const result: any = await this.axiosService.Post(
        salesOrderSave,
        newSalesOrder,
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Save Sales order - ", error.response.data);
      return (
        error?.response?.data[0]?.errorMessage ??
        "Error saving new Sales Order."
      );
    }
  }

  async getSalesList(
    pageNo: number,
    maxSize: number = MAX_PAGE_RECORD_SIZE
  ): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(
        salesOrderList(pageNo, maxSize),
        OrdersApiBase
      );
      return result.data ?? null;
    } catch (error) {
      console.error("Error: Sales order List - ", error);
      return null;
    }
  }

  async getSalesOrderBySONum(soNum: string): Promise<ISalesOrder> {
    try {
      const result: any = await this.axiosService.Get(
        getSalesOrderBySoNum(soNum),
        OrdersApiBase
      );
      const _salesOrders: ISalesOrder = result?.data?.content;
      console.log("Response: getSalesOrderBySONum - ", _salesOrders);
      return _salesOrders;
    } catch (error) {
      console.error("Error: getSalesOrderBySONum - ", error);
      return null;
    }
  }

  getStockInHand = async (
    productId: string,
    quantity: number
  ): Promise<StockStatus> => {
    try {
      const result: any = await this.axiosService.Get(
        stockInHand(productId, quantity),
        OrdersApiBase
      );
      return result.data;
    } catch (error) {
      console.error("Error: Sales order List - ", error);
      return { status: "" };
    }
  };

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Post(salesOrderUpdate, status, OrdersApiBase);
      return true;
    } catch (error) {
      console.error(
        "Error: Update SO Status - ",
        error.response.data[0]?.errorMessage
      );
      return error.response.data[0]?.errorMessage ?? false;
    }
  }

  async saveSalesData(salesOrder: ISalesOrder): Promise<any> {
    try {
      if (!salesOrder?.id)
        return await this.axiosService.Post(
          salesOrderSave,
          salesOrder,
          OrdersApiBase
        );
      else {
        const _result = await this.axiosService.Put(
          salesOrderUpdateById(salesOrder?.id),
          salesOrder,
          OrdersApiBase
        );
        return _result.data?.soNum;
      }
    } catch (error) {
      console.error("Error: Save sales order - ", error.response.data);
      return error?.response?.data;
    }
  }
}
