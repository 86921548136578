import React from "react";
import { isNullUndefined } from "../../../common/methods/globalmethods";

export default function BoxItemWithValue(
  value,
  styleval = "",
  fontSize = 11,
  bgcolor = "#f3f6f9"
) {
  return (
    <div
      className={`col-12 pt-5 ${styleval}`}
      style={{
        backgroundColor: isNullUndefined(bgcolor) ? "" : bgcolor,
        minHeight: 30,
      }}
    >
      <p style={{ fontSize: isNullUndefined(fontSize) ? 11 : fontSize }}>
        {value}
      </p>
    </div>
  );
}
