import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { NavLink, useNavigate } from "react-router-dom";
import { ProductStatuses } from "../../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import { ProductContext } from "../../../controllers/contexts/product.context";
import { IDosage, IProductCategory } from "../../../common/models/master";
import { AppContext } from "../../../controllers/contexts/app.context";
import ProductListOptionsComponent from "./productListOptions.component";
import _ from "lodash";
import validator from "../../../common/models/validator";
const VALIDATOR = {
  productName: [(value) => (_.isEmpty(value) ? `Enter Product Name` : null)],
  productCode: [(value) => (_.isEmpty(value) ? `Enter Product Code` : null)],
  packSize: [(value) => (_.isEmpty(value) ? `Enter Pack Size` : null)],
  prodLicenseNo: [
    (value) => (_.isEmpty(value) ? `Enter  Product License No` : null),
  ],
  prodManufacturer: [
    (value) => (_.isEmpty(value) ? `Enter Product Manufacturer` : null),
  ],
  prodCategory: [
    (value) => (_.isEmpty(value) ? `Enter  Product Category` : null),
  ],
  dosageForm: [(value) => (_.isEmpty(value) ? `Enter Dosage Form` : null)],
  gtin: [(value) => (_.isEmpty(value) ? `Enter  GTINBarcode` : null)],
  minStockQty: [
    (value) =>
      _.isEmpty(value)
        ? `Enter MinStock Qty`
        : Number(value) <= 0
        ? `Enter MinStock Qty`
        : null,
  ],
  pipCode: [
    (value) =>
      _.isEmpty(value)
        ? `Enter  PIP Code`
        : Number(value) <= 0
        ? `Enter  PipCode`
        : null,
  ],
  comments: [(value) => (_.isEmpty(value) ? `Enter  Comments` : null)],
};
interface FormErrors {
  productName?: string;
  productCode?: string;
  packSize?: string;
  prodLicenseNo?: string;
  prodManufacturer?: string;
  prodCategory?: string;
  dosageForm?: string;
  gtin?: string;
  minStockQty?: string;
  comments?: string;
  pipCode?: string;
  status?: string; // ACTIVE / Inactive /
  // Add other form field errors as needed
}
const ProductFormComponent = () => {
  const { productForm, setProductForm, saveProduct } =
    useContext(ProductContext);
  const { DosageForms, ProductCategories } = useContext(AppContext);
  const [selectedListOption, setSelectedListOption] = useState<string>("");
  const navigate = useNavigate();
  const [error, setError] = useState<FormErrors>({});
  const [addError, setAddError] = useState(false);
  useEffect(() => {
    if (productForm === null) {
      navigate(RoutingConstants.product);
    }
  }, [productForm, navigate]);

  const handleProductForm = (type: string, value: any) => {
    if (
      (type === "prodCategory" || type === "dosageForm") &&
      value === "newOption"
    ) {
      document.getElementById("openNewListOptionModal").click();
      setSelectedListOption(LabelConstants[type]);
    } else {
      const _form = cloneDeep(productForm);
      _form[type] = value;
      setProductForm(_form);
    }
  };

  const handleClose = () => {
    setProductForm(null);
  };
  const checkForm = () => {
    let obj = {
      productName: productForm?.productName,
      productCode: productForm?.productCode,
      packSize: productForm?.packSize,
      prodLicenseNo: productForm?.prodLicenseNo,
      prodManufacturer: productForm?.prodManufacturer,
      prodCategory: productForm?.prodCategory,
      dosageForm: productForm?.dosageForm,
      gtin: productForm?.gtin,
      minStockQty:
        typeof productForm?.minStockQty == "number"
          ? productForm?.minStockQty.toString()
          : productForm?.minStockQty,
      comments: productForm?.comments,
      pipCode:
        typeof productForm?.pipCode == "number"
          ? productForm?.pipCode.toString()
          : productForm?.pipCode,
    };
    console.log("error", typeof productForm?.minStockQty, _.isEmpty(1));
    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);

    let isValid = false;
    if (_.isEmpty(isErrors)) {
      saveProduct();
    }
  };
  const isFormValid = (): boolean => {
    let isValid = false;
    isValid =
      productForm?.productName !== "" &&
      productForm?.productCode !== "" &&
      productForm?.packSize !== "" &&
      productForm?.prodLicenseNo !== "" &&
      productForm?.prodManufacturer !== "" &&
      productForm?.prodCategory !== "" &&
      productForm?.dosageForm !== "" &&
      productForm?.comments !== "" &&
      productForm?.gtin !== "" &&
      productForm?.pipCode > 0 &&
      productForm?.minStockQty > 0 &&
      productForm?.status !== "";
    return !isValid;
  };
  console.log(productForm?.minStockQty, error);
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${productForm?.productId ? LabelConstants.Edit : LabelConstants.Create} ${LabelConstants.Products}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.Master}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.product}
                      title={LabelConstants.Products}
                    >
                      {LabelConstants.Products}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${
                    productForm?.productId
                      ? LabelConstants.Edit
                      : LabelConstants.Create
                  } ${LabelConstants.Products}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <h4 className="box-title">{LabelConstants.ProductInfo}</h4>
                <div className="row">
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="productName">
                      {LabelConstants.ProductName}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="productName"
                      type="text"
                      className="form-control"
                      placeholder="Product Name"
                      style={error?.productName && { borderColor: "red" }}
                      value={productForm?.productName}
                      onChange={(e) =>
                        handleProductForm("productName", e.target.value)
                      }
                    />
                    {error?.productName && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.productName}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="productCode">
                      {LabelConstants.productCode}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="productCode"
                      type="text"
                      placeholder="Product Code"
                      style={error?.productCode && { borderColor: "red" }}
                      value={productForm?.productCode}
                      onChange={(e) =>
                        handleProductForm("productCode", e.target.value)
                      }
                    />
                    {error?.productCode && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.productCode}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="packSize">
                      {LabelConstants.PackSize}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="packSize"
                      type="text"
                      placeholder="Pack Size"
                      style={error?.packSize && { borderColor: "red" }}
                      value={productForm?.packSize}
                      onChange={(e) =>
                        handleProductForm("packSize", e.target.value)
                      }
                    />
                    {error?.packSize && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.packSize}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="prodLicenseNo">
                      {LabelConstants.ProductLicenseNo}.
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="prodLicenseNo"
                      type="text"
                      placeholder="Product License No."
                      value={productForm?.prodLicenseNo}
                      style={error?.prodLicenseNo && { borderColor: "red" }}
                      onChange={(e) =>
                        handleProductForm("prodLicenseNo", e.target.value)
                      }
                    />
                    {error?.prodLicenseNo && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.prodLicenseNo}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="prodManufacturer">
                      {LabelConstants.ProductManufacturer}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="prodManufacturer"
                      type="text"
                      placeholder="Product Manufacturer"
                      style={error?.prodManufacturer && { borderColor: "red" }}
                      value={productForm?.prodManufacturer}
                      onChange={(e) =>
                        handleProductForm("prodManufacturer", e.target.value)
                      }
                    />
                    {error?.prodManufacturer && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.prodManufacturer}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label">
                      {LabelConstants.ProductCategory}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={productForm?.prodCategory}
                      onChange={(e) =>
                        handleProductForm("prodCategory", e.target.value)
                      }
                      style={error?.prodCategory && { borderColor: "red" }}
                    >
                      <option value={""} hidden>
                        {LabelConstants.SelectProductCategory}
                      </option>
                      {ProductCategories.map(
                        (_prodCat: IProductCategory, _prodCatIdx: number) => (
                          <option
                            key={`prodCatOption${_prodCatIdx}`}
                            value={_prodCat.categoryId}
                          >
                            {_prodCat.categoryName}
                          </option>
                        )
                      )}
                      <option value={"newOption"}>
                        {LabelConstants.Others}
                      </option>
                    </select>
                    {error?.prodCategory && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.prodCategory}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label">
                      {LabelConstants.DosageForm}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={productForm?.dosageForm}
                      onChange={(e) =>
                        handleProductForm("dosageForm", e.target.value)
                      }
                      style={error?.dosageForm && { borderColor: "red" }}
                    >
                      <option value={""} hidden>
                        {LabelConstants.SelectDosageForm}
                      </option>
                      {DosageForms.map((_dsg: IDosage, _dsgIdx: number) => (
                        <option
                          key={`dosageOption${_dsgIdx}`}
                          value={_dsg.dosageCode}
                        >
                          {_dsg.dosageName}
                        </option>
                      ))}
                      <option value={"newOption"}>
                        {LabelConstants.Others}
                      </option>
                    </select>
                    {error?.dosageForm && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.dosageForm}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="gtin">
                      {LabelConstants.GTINBarcode}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="gtin"
                      type="text"
                      placeholder="GTIN Barcode"
                      style={error?.gtin && { borderColor: "red" }}
                      value={productForm?.gtin}
                      onChange={(e) =>
                        handleProductForm("gtin", e.target.value)
                      }
                    />
                    {error?.gtin && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.gtin}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="pipCode">
                      {LabelConstants.PIPCode}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="pipCode"
                      type="number"
                      placeholder="PIP Code"
                      value={productForm?.pipCode}
                      style={error?.pipCode && { borderColor: "red" }}
                      onChange={(e) =>
                        handleProductForm("pipCode", e.target.value)
                      }
                    />
                    {error?.pipCode && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.pipCode}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="minStockQty">
                      {LabelConstants.MinStockQty}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="minStockQty"
                      type="number"
                      placeholder="Minimum Stock Qty"
                      style={error?.minStockQty && { borderColor: "red" }}
                      value={productForm?.minStockQty}
                      onChange={(e) =>
                        handleProductForm("minStockQty", e.target.value)
                      }
                    />
                    {error?.minStockQty && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.minStockQty}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={productForm?.status}
                      onChange={(e) =>
                        handleProductForm("status", e.target.value)
                      }
                    >
                      <option value={""} hidden>
                        {LabelConstants.SelectStatus}
                      </option>
                      {ProductStatuses.map(
                        (_status: string, _statusIdx: number) => {
                          return (
                            <option
                              key={`statusOption${_statusIdx}`}
                              value={_status}
                            >
                              {_status}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div className="form-group col-12">
                    <label className="form-label" htmlFor="comments">
                      {LabelConstants.Comments}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      id={"comments"}
                      value={productForm?.comments}
                      onChange={(e) =>
                        handleProductForm("comments", e.target.value)
                      }
                      rows={5}
                      cols={5}
                      className="form-control"
                      placeholder="Commnets"
                      style={error?.comments && { borderColor: "red" }}
                    />
                    {error?.comments && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.comments}</span>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleClose()}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary float-end"
                  onClick={() => checkForm()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductListOptionsComponent
        title={selectedListOption}
        handleTitle={setSelectedListOption}
      />
    </>
  );
};
export default ProductFormComponent;
