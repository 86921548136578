import { useContext } from "react";
import { WarehouseContext } from "../../../controllers/contexts/warehouse.context";
import {
  getProductCatNameById,
  getProductNameById,
} from "../../../common/methods/globalmethods";
import moment from "moment";

const GRNAisleBatchLocationDetailsComponent = (props) => {
  const {
    selectedLocation,
    selectedLocationDtls,
    productsList,
    productCateList,
  } = useContext(WarehouseContext);

  return (
    <div className="modal fade" id="grnaislebatchlocationdetails" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center">
              <div className="box-body">
                <div className={`box`}>
                  <div className="box-header bg-primary p-4">
                    <h4 className="box-title">Location Details</h4>
                  </div>
                  <div className="box-body">
                    {selectedLocationDtls && selectedLocationDtls.length > 0 ? (
                      <>
                        <table className="listingTable">
                          <tr>
                            <td className="w-200">Shelf Id</td>
                            <td>:</td>
                            <td className="w-600">
                              {selectedLocationDtls[0].shelfId}
                            </td>
                          </tr>
                          <tr>
                            <td>Prod Category</td>
                            <td>:</td>
                            <td>
                              {getProductCatNameById(
                                selectedLocationDtls[0].prodCatId,
                                productCateList
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Product Name</td>
                            <td>:</td>
                            <td>
                              {getProductNameById(
                                selectedLocationDtls[0].prodId,
                                productsList
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Condition</td>
                            <td>:</td>
                            <td>{selectedLocation?.status}</td>
                          </tr>
                          <tr>
                            <td colSpan={5}>
                              <table className="table table-bordered col-12 mt-10">
                                <thead>
                                  <tr>
                                    <th className="col-md-3">Batch No</th>
                                    <th className="col-md-3">Batch Exp.</th>
                                    <th className="col-md-1">Qty</th>
                                    <th className="col-md-1">Status</th>
                                    <th className="col-md-4">Pallet Ref.No</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedLocationDtls?.map((item) => (
                                    <tr>
                                      <td>{item?.batchNo}</td>
                                      <td>{item?.batchExpiryDate}</td>
                                      <td>{item?.locQty}</td>
                                      <td>{item?.status}</td>
                                      <td>{item?.palletRefNum ?? "-"}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className="p-3">
                          <h4 className="box-title">
                            Please select a location to see details.
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-p100 d-flex justify-content-center">
                <button
                  type="button"
                  className="waves-effect waves-light btn btn-secondary w-p25"
                  onClick={() => {
                    props?.close();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GRNAisleBatchLocationDetailsComponent;
