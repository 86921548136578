import React, { CSSProperties } from "react";

interface RadioButtonProps {
  autoFocus?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  value: string;
  style?: CSSProperties;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ autoFocus, checked, defaultChecked, disabled, value, style, onChange }) => {
  return (
    <input
      type="radio"
      className="form-check-input"
      id={`btn-radio-${value}`}
      name={`btn-radio-${value}`}
      autoComplete="off"
      autoFocus={autoFocus}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      value={value}
      onChange={onChange}
      style={{ ...style, position: "static", opacity: "unset" }}
    />
  );
};

interface RadioGroupProps {
  buttonStyle?: "outline" | "solid";
  defaultValue?: string;
  disabled?: boolean;
  name: string;
  options: Array<{ label: React.ReactNode; value: string; disabled?: boolean }>;
  optionType?: "default" | "button";
  size?: "large" | "middle" | "small";
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ defaultValue, disabled = false, options, value, onChange }) => {
  return (
    <div>
      {options.map((option, i) => (
        <React.Fragment key={option.value}>
          <RadioButton
            autoFocus={value === option.value}
            checked={value === option.value}
            defaultChecked={defaultValue === option.value}
            disabled={disabled || option.disabled}
            value={option.value}
            onChange={onChange}
            style={i > 0 ? { paddingLeft: "5px" } : {}}
          />
          <span style={{ paddingLeft: "5px" }}>{option.label}</span>
        </React.Fragment>
      ))}
    </div>
  );
};

export { RadioGroup, RadioButton };
