import {
  SupplierAllList,
  SupplierList,
  SupplierSave,
  SupplierStatusUpdate,
  SupplierUpdateById,
} from "../../common/constants/apiConstants";
import { IStatusUpdate } from "../../common/models";

import { ISupplier } from "../../common/models/supplier";
import { AxiosService } from "../generics/axios.service";
import { ISupplierService } from "../interfaces/ISupplier";

export class SupplierService implements ISupplierService {
  private axiosService: AxiosService = new AxiosService();

  async getSupplier(): Promise<ISupplier[]> {
    try {
      const result: any = await this.axiosService.Get(SupplierList);
      const _supplier: ISupplier[] = result.data ?? [];
      console.log("Response: Supplier List - ", _supplier);
      return _supplier;
    } catch (error) {
      console.error("Error: Supplier List - ", error);
      return [];
    }
  }

  async getSupplierAllList(): Promise<ISupplier[]> {
    try {
      const result: any = await this.axiosService.Post(SupplierAllList, {});
      const _supplier: ISupplier[] = result.data?.content ?? [];
      console.log("Response: getSupplierAllList List - ", _supplier);
      return _supplier;
    } catch (error) {
      console.error("Error: getSupplierAllList List - ", error);
      return [];
    }
  }

  async saveSupplier(Supplier: ISupplier): Promise<boolean | number> {
    console.log("Supplier", Supplier);
    try {
      if (!Supplier?.supId)
        return await this.axiosService.Post(SupplierSave, Supplier);
      else
        await this.axiosService.Put(
          SupplierUpdateById(Supplier?.supId),
          Supplier
        );
      return true;
    } catch (error) {
      console.error(
        "Error: Save Supplier - ",
        error?.response?.data[0]?.errorMessage
      );
      return error.response.data[0]?.errorMessage ?? false;
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Put(SupplierStatusUpdate, status);
      return true;
    } catch (error) {
      console.error("Error: Update Supplier Status - ", error);
      return false;
    }
  }
}
