import { Outlet } from "react-router-dom";
import { TransporterContext } from "../../../controllers/contexts/transporter.context";
import useTransporter from "../../../controllers/hooks/useTransporter";

const TransporterLayoutComponent = () => {
    const transporterFunctions = useTransporter();

    return <TransporterContext.Provider value={transporterFunctions}>
        <Outlet />
    </TransporterContext.Provider>;
}

export default TransporterLayoutComponent;
