import React, { CSSProperties, ReactNode } from 'react';

interface CountConfig {
  formatter?: (info: { value: string; count: number; maxLength?: number }) => ReactNode;
}

interface InputTextProps {
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  allowClear?: boolean | { clearIcon: ReactNode };
  classNames?: string;
  count?: CountConfig;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  prefix?: ReactNode;
  showCount?: boolean | { formatter: (info: { value: string; count: number; maxLength: number }) => ReactNode };
  status?: 'error' | 'warning';
  styles?: CSSProperties;
  placeholder?: string;
  size?: 'large' | 'middle' | 'small';
  suffix?: ReactNode;
  type?: string;
  value?: string;
  variant?: 'outlined' | 'borderless' | 'filled';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const InputText: React.FC<InputTextProps> = ({
  addonAfter,
  addonBefore,
  allowClear = false,
  classNames,
  count,
  defaultValue,
  disabled = false,
  id,
  maxLength,
  prefix,
  showCount = false,
  status,
  styles,
  placeholder,
  size = 'middle',
  suffix,
  type = 'text',
  value,
  variant = 'outlined',
  onChange,
  onPressEnter,
}) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onPressEnter) {
      onPressEnter(e);
    }
  };

  const sizeClass = size === 'large' ? 'form-control-lg' : size === 'small' ? 'form-control-sm' : '';
  const variantClass = variant === 'filled' ? 'form-control' : variant === 'outlined' ? '' : 'form-control-plaintext';

  return (
    <div className={`input-group ${classNames}`}>
      {addonBefore && <div className="input-group-prepend">{addonBefore}</div>}
      <input
        type={type}
        id={id}
        className={`form-control ${sizeClass} ${variantClass} ${status ? `is-${status}` : ''}`}
        defaultValue={defaultValue}
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        disabled={disabled}
        style={styles}
        placeholder={placeholder}
      />
      {showCount && typeof showCount === 'object' && showCount.formatter && (
        <small className="form-text text-muted">{showCount.formatter({ value: value || '', count: value?.length || 0, maxLength: maxLength || value?.length || 0 })}</small>
      )}
      {addonAfter && <div className="input-group-append">{addonAfter}</div>}
      {suffix}
    </div>
  );
};

export default InputText;
