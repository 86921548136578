type RouteCategory =
  | "orderIn"
  | "orderOut"
  | "master"
  | "warehouse"
  | "misc"
  | "warehousel2l";
type RouteAction =
  | ""
  | "create"
  | "edit"
  | "view"
  | "details"
  | "map"
  | "print"
  | "order"
  | "batch";

const ROUTE_PREFIXES: Record<RouteCategory, string> = {
  orderIn: "/orderin",
  orderOut: "/orderout",
  master: "/master",
  warehouse: "/warehouse",
  warehousel2l: "/warehousel2l",
  misc: "",
};

const createRoute = (
  category: RouteCategory,
  base: string,
  action?: RouteAction
) => {
  return `${ROUTE_PREFIXES[category]}${base}${action ? `/${action}` : ""}`;
};

const createSubRoutes = (
  category: RouteCategory,
  base: string,
  actions: RouteAction[]
) => {
  const baseKey = base.replace(/^\//, "").replace(/\//g, "_");
  return actions.reduce((subRoutes, action) => {
    const subRoutesKey = action === "" ? baseKey : `${baseKey}_${action}`;
    subRoutes[subRoutesKey] = createRoute(category, base, action);
    return subRoutes;
  }, {} as Record<string, string>);
};

const deliveryOrder = createSubRoutes("orderOut", "/delivery_order", [
  "",
  "create",
  "view",
  "edit",
  "print",
]);
const salesOrder = createSubRoutes("orderOut", "/sales_order", [
  "",
  "create",
  "view",
  "edit",
  "print",
]);

type RoutingConstantsType = {
  [key: string]: string;
};

const RoutingConstants: RoutingConstantsType = {
  login: createRoute("misc", "/login"),
  create: createRoute("misc", "/create"),
  edit: createRoute("misc", "/edit"),
  dashboard: createRoute("misc", "/dashboard"),
  returns: createRoute("misc", "/returns"),

  //reports
  reports: "/reports",
  stk_reports: "/reports/stock_reports",
  sp_reports: "/reports/stock_planning_reports",
  po_reports: "/reports/purchase_order_reports",
  gm_reports: "/reports/goods_movement_reports",
  gr_reports: "/reports/goods_receipt_reports",
  bt_reports: "/reports/batch_traceability_reports",
  pm_reports: "/reports/product_movement_reports",
  pso_reports: "/reports/pending_so_reports",
  dsp_reports: "/reports/dispatch_reports",

  setup: "/setup",
  permissionsandaccess: "/setup/permissionsandaccess",

  //profile
  changePassword: "/changePassword",
  profile: "/profile",

  // Warehouse
  warehouse: createRoute("warehouse", ""),
  warehouseCreate: createRoute("warehouse", "/create"),
  warehouseEdit: createRoute("warehouse", "/edit"),
  warehouseDetails: createRoute("warehouse", "/details"),
  warehouseMap: createRoute("warehouse", "/map"),

  warehousel2l: createRoute("warehousel2l", ""),
  warehousel2lCreate: createRoute("warehousel2l", "/create"),
  warehousel2lEdit: createRoute("warehousel2l", "/edit"),
  warehousel2lDetails: createRoute("warehousel2l", "/details"),
  warehousel2lMap: createRoute("warehousel2l", "/map"),

  // default: "/",

  // Order In Routes
  po: createRoute("orderIn", "/po"),
  grn: createRoute("orderIn", "/grn"),

  grn_order_print: "/orderin/grn/order_print",
  grn_order_create: "/orderin/grn/create",
  grn_order_edit: "/orderin/grn/order_edit",
  grn_batch_create: "/orderin/grn/batch",
  grn_batch_edit: "/orderin/grn/batch_edit",
  grn_order_view: "/orderin/grn/order_view",
  grn_batch_view: "/orderin/grn/batch_view",

  orderPicking: "/orderout/orderpicking",
  warehouseTransfer: "/warehouse/warehouseTransfer",
  batchmanagement: "/batchmanagement",

  sampling: "/sampling",
  samplingCreate: "/sampling/create",
  samplingEdit: "/sampling/edit",
  samplingDetails: "/sampling/details",

  franchise: "/franchises",

  //purchase
  purchase: "/orderin/purchaseorder",
  purchaseCreate: "/orderin/purchaseorder/create",
  purchaseDetails: "/orderin/purchaseorder/details",
  purchaseEdit: "/orderin/purchaseorder/edit",
  purchasePrint: "/orderin/purchaseorder/print",

  //returnorder
  returnorders: "/orderin/returnorders",
  returnordersCreate: "/orderin/returnorders/create",
  returnordersDetails: "/orderin/returnorders/details",
  returnordersEdit: "/orderin/returnorders/edit",
  returnordersPrint: "/orderin/returnorders/print",

  ...salesOrder,
  ...deliveryOrder,

  // Products
  product: "/master/product",
  productDetails: "/master/product/details",
  productCreate: "/master/product/create",
  productEdit: "/master/product/edit",
  // Supplier
  supplier: "/master/supplier",
  supplierDetails: "/master/supplier/details",
  supplierCreate: "/master/supplier/create",
  supplierEdit: "/master/supplier/edit",
  // Transporter
  transporter: "/master/transporter",
  transporterDetails: "/master/transporter/details",
  transporterCreate: "/master/transporter/create",
  transporterEdit: "/master/transporter/edit",
  // Customer
  customer: "/master/customer",
  customerDetails: "/master/customer/details",
  customerCreate: "/master/customer/create",
  customerEdit: "/master/customer/edit",
  // Employee
  employee: "/master/employee",
  employeeDetails: "/master/employee/details",
  employeeCreate: "/master/employee/create",
  employeeEdit: "/master/employee/edit",
  //OrderPicking
  orderPickingList: "/orderout/orderPicking",
  createOrderPicking: "/orderout/orderPicking/createOrderPicking",
  orderPickingDetails: "/orderout/orderPicking/orderPickingDetails",
  orderPickingPrint: "/orderout/orderPicking/orderPickingPrint",

  //Warehouse
  warehouseTransferList: "/warehouse/warehouseTransfer",
  warehouseTransferCreate: "/warehouse/warehouseTransfer/createOrderTransfer",
  warehouseTransferDetailsList:
    "/warehouse/warehouseTransfer/warehouseTransferDetails",
};

export { RoutingConstants };
