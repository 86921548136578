/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { useContext } from "react";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { LabelConstants } from "../../common/constants/labelConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import {
  GENERAL_CONSTANTS,
  LOCATION_STATUS_COLORS,
  NewLocationForm,
} from "../../common/constants/generalConstants";
import useAuth from "../../controllers/hooks/useAuth";

const WarehouseMapComponent = () => {
  const {
    warehouseMap,
    selectedWarehouse,
    setPreviewMapForm,
    setPreviewMap,
    setSelectedLocation,
    getLocationDtlsById,
  } = useContext(WarehouseContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAccessDisabled } = useAuth();

  const isDisabled = (
    warehouseFun: Function,
    stockTransferFunction: Function
  ) => {
    let result = false;
    if (location.pathname === "") {
      result = warehouseFun();
    } else if (location.pathname === "") {
      result = stockTransferFunction();
    }
    return result;
  };

  return (
    <>
      <div className="box position-static">
        <div className="box-header">
          <h4 className="box-title">Virtual Map</h4>
          <div className="box-controls pull-right">
            <button
              type="button"
              disabled={isDisabled(
                () => isAccessDisabled("WH", "createLocations"),
                () => isAccessDisabled("ST", "createLocations", true, "L2L")
              )}
              className="waves-effect waves-light btn btn-sm btn-success rounded"
              onClick={() => {
                setPreviewMap(null);
                setPreviewMapForm({
                  ...NewLocationForm,
                  warehouseId: selectedWarehouse?.wareHouseId,
                });
                navigate(
                  location.pathname === RoutingConstants?.warehouse
                    ? RoutingConstants.warehouseMap
                    : RoutingConstants?.warehousel2lMap
                );
              }}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>{" "}
              {`${LabelConstants.Add} ${LabelConstants.Locations}`}
            </button>
            {/*  <input className="form-control no-border bg-lightest" id="e" type="date" /> */}
          </div>
        </div>
        <div className="box-body">
          <div className="row">
            {(warehouseMap ?? []).map((_warehouseMap, _warehouseMapIdx) => {
              return (
                <div className="col-12 " style={{ overflowY: "scroll" }}>
                  <div className="box box-transparent no-shadow border">
                    <div className="box-body">
                      <h4 className="box-title">
                        Aisle {_warehouseMap?.asileName}
                      </h4>
                      <div className="d-flex">
                        <div className="d-flex flex-column-reverse">
                          <div className="section-bx">
                            <div className="w-40 h-40 m-5 d-flex align-items-center justify-content-center border border-warning rounded10" />
                          </div>
                          {(_warehouseMap.bays[0].baysLocations ?? []).map(
                            (_loc, _locIdx) => (
                              <div className="section-bx">
                                <div className="w-40 h-40 m-5 d-flex align-items-center justify-content-center border border-warning rounded10">
                                  {_locIdx}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        {(_warehouseMap.bays ?? []).map((_bay, _bayIdx) => {
                          return (
                            <div className="d-flex flex-column-reverse">
                              <div className="section-bx">
                                <div className="w-40 h-40 m-5 d-flex align-items-center justify-content-center border border-warning rounded10">
                                  {_bayIdx + 1}
                                </div>
                              </div>
                              {/* onClick={() => setSelectedLocation(_loc)} */}
                              {(_bay.baysLocations ?? [])
                                .filter((_loc) => _loc.deleted === false)
                                .map((_loc, _locIdx) => {
                                  return (
                                    <div
                                      className="section-bx"
                                      onClick={() => getLocationDtlsById(_loc)}
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        className={`w-40 h-40 m-5 bg-warning-light d-block rounded10`}
                                      >
                                        <div
                                          className={`align-items-center justify-content-center d-flex h-p100 rounded10 ${_loc?.themeColor}`}
                                        >
                                          {_loc.shelfId}
                                        </div>
                                        {/* <div className="bx-dec">
                                                                <div className="section-dec d-flex align-items-center">
                                                                    <div className="box-img">
                                                                        <img src="../images/box.png" className="img-fluid" alt="" />
                                                                    </div>
                                                                    <div className="dec">
                                                                        <h4 className="text-white my-0">Empty</h4>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="col">
                    <div className="section-bx">
                        <a href="#" className="w-40 h-40 m-5 bg-warning-light d-block rounded10">
                            <div className="bx-dec">
                                <div className="section-dec d-flex align-items-center">
                                    <div className="box-img">
                                        <img src="../images/box.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="dec">
                                        <h4 className="text-white my-0">Empty</h4>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col">
                    <div className="section-bx">
                        <a href="#" className="w-40 h-40 m-5 bg-warning d-block rounded10">
                            <div className="bx-dec">
                                <div className="section-dec d-flex align-items-center">
                                    <div className="box-img">
                                        <img src="../images/box.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="dec">
                                        <h4 className="text-white my-0">Row 3 #124578</h4>
                                        <p className="text-white">H60 x W60 x 20 KG</p>
                                        <p className="mb-0 text-white-50">Delivered 06:15PM</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div> */}
        </div>
      </div>
      <div
        className="d-flex mb-10"
        style={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "white",
          padding: 20,
        }}
      >
        {Object.entries(LOCATION_STATUS_COLORS).map((item) => {
          console.log(JSON.stringify(item));
          return (
            <div className="d-flex">
              <div className={`w-20 h-20 ${item[1]?.color} rounded`}></div>
              <h5 className="mx-15 my-0">{item[1]?.text}</h5>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default WarehouseMapComponent;
