import {
  ProductList,
  ProductSave,
  ProductStatusUpdate,
  ProductUpdateById,
  searchProductsByCriteria,
} from "../../common/constants/apiConstants";
import { IListConfigProduct, IStatusUpdate } from "../../common/models";
import { IProducts } from "../../common/models/products";
import { AxiosService } from "../generics/axios.service";
import { IProductService } from "../interfaces/IProduct";

export class ProductService implements IProductService {
  private axiosService: AxiosService = new AxiosService();

  async getProducts(): Promise<IProducts[]> {
    try {
      const result: any = await this.axiosService.Get(ProductList);
      const _products: IProducts[] = result.data ?? [];
      console.log("Response: Product List - ", _products);
      return _products;
    } catch (error) {
      console.error("Error: Product List - ", error);
      return [];
    }
  }

  async searchProducts(filter: IListConfigProduct): Promise<IProducts[]> {
    try {
      const result: any = await this.axiosService.Post(
        searchProductsByCriteria,
        filter
      );
      const _products: IProducts[] = result?.data?.content ?? [];
      console.log("Response: searchProducts List - ", _products);
      return _products;
    } catch (error) {
      console.error("Error: searchProducts List - ", error);
      return [];
    }
  }

  async saveProduct(product: IProducts): Promise<boolean> {
    try {
      if (!product?.productId)
        await this.axiosService.Post(ProductSave, product);
      else
        await this.axiosService.Put(
          ProductUpdateById(product?.productId),
          product
        );
      return true;
    } catch (error) {
      console.error("Error: Save Product - ", error);
      return false;
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Put(ProductStatusUpdate, status);
      return true;
    } catch (error) {
      console.error("Error: Update Product Status - ", error);
      return false;
    }
  }
}
