import { useContext, useEffect, useMemo, useState } from "react";
import { LabelConstants } from "../../common/constants/labelConstants";
import { IDocuments, IProducts } from "../../common/models";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import moment from "moment";
import {
  getDateIfValid,
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import { DatePicker } from "antd";
import { DeliveryOrderContext } from "../../controllers/contexts/deliveryorder.context";
import validator from "../../common/models/validator";
import {
  DeliveryOrderStatuses,
  DeliveryOrderUpdateStatuses,
  NewDeliveryOrder,
} from "../../common/constants/generalConstants";
import { toast } from "react-toastify";
import _ from "lodash";
import Select from "../../Components/Select";
import InputText from "../../Components/InputText";
import Textarea from "../../Components/Textarea";
import { RadioGroup } from "../../Components/RadioButton";
import Dropzone from "react-dropzone";
import { OrderPickingDetail } from "../../common/models/pickingorder";

const DeliveryOrderDetailsComponent = () => {
  // props, context and init items
  // const { deliveryOrderForm, clearDeliveryOrder, customersList, productsList, productCateList, salesOrderList } = useContext(DeliveryOrderContext);
  const {
    deliveryOrderForm,
    productsList,
    productCateList,
    customersList,
    transporterList,
    pickingOrderList,
    viewCustomer,
    setUploadedFiles,
    uploadedFiles,
    viewFiles,
    setViewFiles,
    updateDeliveryOrder,
    clearDeliveryOrder,
  } = useContext(DeliveryOrderContext);
  const navigate = useNavigate();
  const location = useLocation();
  console.log({ location });
  const isEditableSo = location?.state?.isEditable ?? false;

  const VALIDATOR = {
    soNum: [(value) => (_.isEmpty(value) ? `Select So Numbner!!` : null)],
    status: [(value) => (_.isEmpty(value) ? `Enter valid status!!` : null)],
    deliveryAddress: [
      (value) => (_.isEmpty(value) ? `Enter Delivery Address!!` : null),
    ],
    reason: [(value) => (_.isEmpty(value) ? `Enter description!!` : null)],
    dataLog: [(value) => (_.isEmpty(value) ? `Enter Datalog!!` : null)],
    estDeliveryDate: [
      (value) => (_.isEmpty(value) ? `Enter Estimated Delivery Date!!` : null),
    ],
    pickingDate: [
      (value) => (_.isEmpty(value) ? `Enter Picking Date!!` : null),
    ],
    transporterId: [(value) => (value <= 0 ? `Select Transporter!!` : null)],
    bookingRefNo: [
      (value) => (_.isEmpty(value) ? `Enter Booking Refrence No!!` : null),
    ],
    deliveryNum: [(value) => (_.isEmpty(value) ? `Enter Delivery No!!` : null)],
  };

  interface FormErrors {
    soNum?: string;
    dispatchDate?: string;
    status?: string;
    deliveryAddress?: string;
    dataLog?: boolean;
    reason?: string;
    transporterId?: number;
    bookingRefNo?: string;
    deliveryNum?: string;
    estDeliveryDate?: string;
    pickingDate?: string;
  }

  //Dropdown Options
  const salesOrderSelectOptions = pickingOrderList.map((pickingOrder) => {
    const currentContact =
      (customersList || []).find(
        (customer) =>
          Number(customer.custId) === Number(pickingOrder?.customerId)
      ) || {};
    return {
      label:
        `${pickingOrder?.soNum ?? "-"} | ${
          deliveryOrderForm?.orderPickingId ?? "-"
        } | ${currentContact?.custName ?? "-"}` || "",
      value: pickingOrder?.soNum?.toString() || "",
    };
  });

  const deliveryOrderStatusSelectOptions = (
    isEditableSo ? DeliveryOrderUpdateStatuses : DeliveryOrderStatuses
  ).map((status) => ({
    label: status?.toString() || "",
    value: status?.toString() || "",
  }));
  const customerSelectOptions = customersList.map(({ custId, custName }) => ({
    label: custName?.toString() || "",
    value: custId?.toString() || "",
  }));
  const transportSelectOptions = transporterList.map(
    ({ transName, transId }) => ({
      label: transName || "",
      value: Number(transId) || "",
    })
  );

  const formFields = {
    soNum: {
      type: "select",
      label: LabelConstants.SalesOrder,
      options: salesOrderSelectOptions,
      value: "",
      disabled: true,
      required: true,
      placeholder: "--",
    },
    pickingDate: {
      type: "datePicker",
      label: LabelConstants.PickingDate,
      value: new Date() + "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "--",
    },
    dispatchDate: {
      type: "datePicker",
      label: LabelConstants.ShipDate,
      value: new Date() + "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "--",
    },
    customerId: {
      type: "select",
      label: LabelConstants.CustomerName,
      options: customerSelectOptions,
      value: "",
      disabled: true,
      required: true,
      placeholder: "--",
    },
    deliveryAddress: {
      type: "select",
      label: LabelConstants.DeliveryAddress,
      options: [],
      value: "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "--",
    },
    transporterId: {
      type: "select",
      label: LabelConstants.Transporter,
      options: transportSelectOptions,
      value: "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "--",
    },
    bookingRefNo: {
      type: "input",
      label: LabelConstants.BookingReference,
      value: "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "",
    },
    trackingNumber: {
      type: "input",
      label: LabelConstants.TrackingNumber,
      value: "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "",
    },
    estDeliveryDate: {
      type: "datePicker",
      label: LabelConstants.EstimatedDeliveryDate,
      value: new Date() + "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "--",
    },
    status: {
      type: "select",
      label: LabelConstants.Status,
      options: deliveryOrderStatusSelectOptions,
      value: "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "--",
    },
    dataLog: {
      type: "radio",
      label: LabelConstants.DataLogger,
      options: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
      value: "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "",
    },
    attachment: {
      type: "file",
      label: LabelConstants.Attachment,
      value: "",
      disabled: !isEditableSo,
      required: false,
      placeholder: "Drag 'n' drop some files here or click to select files",
    },
    reason: {
      type: "textarea",
      label: LabelConstants.Description,
      value: "",
      disabled: !isEditableSo,
      required: true,
      placeholder: "Description",
    },
  };

  // Local State
  const [formData, setFormData] = useState(formFields);
  const [deliveryOrderList, setDeliveryOrderList] = useState(
    deliveryOrderForm || _.cloneDeep(NewDeliveryOrder)
  );
  const [currentCustomerAddress, setCurrentCustomerAddress] = useState<any>([]);
  const [searchProduct, setSearchProduct] = useState<any>("");
  const [filteredProducts, setFilteredProducts] =
    useState<IProducts[]>(productsList);
  const [error, setError] = useState<FormErrors>({});
  const [activeURL, setactiveURL] = useState("");
  const viewFilesList = viewFiles.filter((_file) =>
    _file.deleted !== undefined ? _file.deleted === false : true
  );

  const customerAddressSelectOptions = useMemo(() => {
    return (currentCustomerAddress || []).map((address) => ({
      label: `${address.address1}, ${address.address2}` || "",
      value:
        `${address.address1},${address.address2},${address.city},${address.country},${address.postCode}test` ||
        "",
      addressId: address?.custAddressId,
    }));
  }, [currentCustomerAddress]);
  const orderDetails: OrderPickingDetail[] = useMemo(() => {
    const order = (pickingOrderList || []).find(
      (po) => po?.soNum === deliveryOrderList?.soNum
    );
    console.log({ order, pickingOrderList });
    return order?.orderPickingDetails ?? [];
  }, [deliveryOrderList?.soNum, pickingOrderList]);

  // useEffects
  useEffect(() => {
    return () => {
      clearDeliveryOrder();
    };
  }, []);
  useEffect(() => {
    if (deliveryOrderForm === null) {
      navigate(RoutingConstants.delivery_order);
    }
  }, [deliveryOrderForm]);
  useEffect(() => {
    if (deliveryOrderList) {
      const updatedFormValues = { ...formData };

      const updateField = (key, value) => {
        if (updatedFormValues.hasOwnProperty(key)) {
          // Update the field value
          updatedFormValues[key] = {
            ...updatedFormValues[key],
            value: value,
          };

          // Additional logic for specific keys
          switch (key) {
            case "status":
              ["bookingRefNo", "deliveryNum"].forEach((updateKey) => {
                if (updatedFormValues.hasOwnProperty(updateKey)) {
                  updatedFormValues[updateKey] = {
                    ...updatedFormValues[updateKey],
                    disabled: value
                      ? value.toLowerCase() !== "draft" || !isEditableSo
                      : false,
                  };
                }
              });
              break;
            // Add more cases as needed
          }
        }
      };

      Object.entries(deliveryOrderList).forEach(([key, value]) => {
        updateField(key, value);
      });

      setFormData(updatedFormValues);
      const files = viewCustomer(deliveryOrderList?.documentId);
      console.log(files);
    }
  }, [deliveryOrderList]);
  useEffect(() => {
    const selected = { ...deliveryOrderList };
    if (deliveryOrderList?.customerId) {
      // Find the customer in the customersList
      const matchedCustomer = customersList.find(
        (customer) =>
          Number(customer?.custId) === Number(deliveryOrderList?.customerId)
      );

      if (matchedCustomer) {
        // Update the state with the customer addresses
        setCurrentCustomerAddress(matchedCustomer?.custAddresses || []);

        setDeliveryOrderList({
          ...selected,
          custContactDetails:
            matchedCustomer?.custAddresses[0]?.custCommunication[0]
              ?.phoneNumber || "",
        });
      }
    }
  }, [deliveryOrderList?.customerId, customersList]);
  useEffect(() => {
    // Update the formData with the new options
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      deliveryAddress: {
        ...prevFormData.deliveryAddress,
        options: customerAddressSelectOptions,
      },
    }));
  }, [customerAddressSelectOptions]);

  // components
  const ErrorLabel = ({ errorMessage }) => {
    if (!errorMessage) {
      return null; // Don't render anything if there's no error message
    }

    return (
      <label className="form-label">
        <span style={{ color: "red" }}>{errorMessage}</span>
      </label>
    );
  };

  // functions
  const handleUpdateDetailsOrder = async () => {
    let _updatedDo = { ...deliveryOrderList };

    let obj = {};

    Object.keys(formData).forEach((key) => {
      if (!!formData[key].required) {
        obj[key] = formData[key].value;
      }
    });

    console.log({ obj });

    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);
    console.log({ isErrors, _updatedDo });
    if (!_.isEmpty(isErrors)) {
      toast.error("Please fill mandatory fields");
      return;
    }

    _updatedDo.documentId = _updatedDo?.deliveryNum;
    _updatedDo.pickingDate = moment(_updatedDo?.pickingDate)?.format(
      "yyyy-MM-DD"
    );
    _updatedDo.dispatchDate = moment(_updatedDo?.dispatchDate)?.format(
      "yyyy-MM-DD"
    );
    _updatedDo.estDeliveryDate = moment(_updatedDo?.estDeliveryDate)?.format(
      "yyyy-MM-DD"
    );
    delete _updatedDo["modifiedDate"];
    delete _updatedDo["modifiedBy"];
    if (await updateDeliveryOrder(_updatedDo))
      navigate(RoutingConstants.delivery_order);
  };
  const onFormFieldsChange = (
    fieldName: string,
    value: any,
    idval: string = null
  ) => {
    try {
      let updatedState = { ...deliveryOrderList };

      if (value === undefined || value === "") {
        throw new Error(`Field '${fieldName}' is empty`);
      }

      if (fieldName === "customerId") {
        const currentCustomer = customersList.find(
          (cus) => Number(cus.custId) === Number(value)
        );
        if (currentCustomer) {
          const customerPhoneNumber =
            currentCustomer.custAddresses?.[0]?.custCommunication?.[0]
              ?.phoneNumber || "";
          if (customerPhoneNumber) {
            updatedState.custContactDetails = customerPhoneNumber;
          } else {
            throw new Error(
              `No phone number available for customer: ${currentCustomer.custName}`
            );
          }
        }
      }

      if (fieldName === "transporterId") {
        const currentTransporter = transporterList.find(
          (transport) => Number(transport.transId) === Number(value)
        );
        if (currentTransporter) {
          const transName = currentTransporter.transName || "";
          const transAddress =
            currentTransporter.transAddresses?.[0]?.address1 || "";
          const transContact =
            currentTransporter.transAddresses?.[0]?.transCommunication?.[0]
              ?.phoneNumber || "";

          updatedState.transporterName = transName;
          updatedState.transporterAddress = transAddress;
          updatedState.transContactDetails = transContact;
        }
      }

      if (fieldName === "deliveryAddress") {
        updatedState["addressId"] = idval;
      }

      updatedState[fieldName] = value;

      setDeliveryOrderList(updatedState);
    } catch (e) {
      toast.error(e.message);
    }
  };
  const handleQuantityChange = (prod, newQuantity) => {
    setDeliveryOrderList((prevState) => {
      return {
        ...prevState,
        deliveryOrderDetails: prevState.deliveryOrderDetails.map((product) => {
          if (
            Number(product.prodId === prod.prodId) &&
            Number(product.id === prod.id)
          ) {
            return { ...product, quantity: newQuantity };
          }
          return product;
        }),
      };
    });
  };

  // File functions
  const handleFileUpload = (files: any[]) => {
    console.log(files);
    const _viewFilesCount: number = viewFilesList.length;
    const _filesCount: number =
      files.length + uploadedFiles.length + (_viewFilesCount ?? 0);
    if (files.length > 0) {
      if (_filesCount > 5) {
        toast.warning(LabelConstants.FileUploadCountExceeded);
      } else {
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setUploadedFiles([...uploadedFiles, ...files]);
      }
    }
  };
  const handleRemoveFile = (idx: number) => {
    const _files = _.cloneDeep(uploadedFiles);
    _files.splice(idx, 1);
    setUploadedFiles(_files);
  };
  const handleRemoveOldFile = (docId: number) => {
    const _files: IDocuments[] = _.cloneDeep(viewFiles);
    const idx: number = _files.findIndex((_) => _.documentId === docId);
    if (idx >= 0) {
      _files[idx].deleted = true;
      setViewFiles(_files);
    }
  };

  const getStatusOption = (options, val) => {
    if (val === "DISPATCHED") {
      let _options = options.filter((opt) => opt.value !== "CANCELLED");
      return _options;
    } else {
      return options;
    }
  };
  // Component or function that renders form fields based on the provided formData
  const renderFormFields = (formData, handleChange) => {
    const dynamicDropDownStyles = {
      cursor: "pointer",
      height: "calc(100% - 31px) !important",
      opacity: isEditableSo ? 1 : "0.5",
    };

    return (
      <>
        {Object.keys(formData).map((key) => {
          const field = formData[key];
          switch (field.type) {
            case "select":
              return (
                <div className="row mt-20" key={key}>
                  <div className="col-4">
                    <label className="form-label">
                      {field.label}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Select
                      disabled={field.disabled}
                      options={getStatusOption(field.options, field.value)}
                      value={field.value}
                      onChange={(e) => {
                        let result = field?.options?.filter(
                          (item) => item.value === e.target.value
                        );
                        handleChange(key, e.target.value, result[0]?.addressId);
                      }}
                      placeholder="--"
                    />
                    {field.required && error[key] && (
                      <ErrorLabel errorMessage={error[key]} />
                    )}
                  </div>
                </div>
              );
            case "datePicker":
              return (
                <div className="row mt-20" key={key}>
                  <div className="col-4">
                    <label className="form-label">
                      {field.label}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      disabled={field.disabled}
                      value={getDateIfValid(field.value)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleChange(key, date);
                      }}
                    />
                    {field.required && error[key] && (
                      <ErrorLabel errorMessage={error[key]} />
                    )}
                  </div>
                </div>
              );
            case "textarea":
              return (
                <div className="row mt-20" key={key}>
                  <div className="col-4">
                    <label className="form-label">
                      {field.label}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Textarea
                      value={field.value}
                      disabled={field.disabled}
                      onChange={(e) => handleChange(key, e.target.value)}
                      placeholder={field.placeholder}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                    {field.required && error[key] && (
                      <ErrorLabel errorMessage={error[key]} />
                    )}
                  </div>
                </div>
              );
            case "radio":
              return (
                <div className="row mt-20" key={key}>
                  <div className="col-4">
                    <label className="form-label">
                      {field.label}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <RadioGroup
                      name={`{key}RadioGroup`}
                      options={field.options}
                      value={field.value}
                      disabled={field.disabled}
                      onChange={(e) => handleChange(key, e.target.value)}
                    />
                    {field.required && error[key] && (
                      <ErrorLabel errorMessage={error[key]} />
                    )}
                  </div>
                </div>
              );
            case "file":
              return (
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Attachment}
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div
                      className={`form-group d-flex flex-column flex-lg-row col-12 h-auto`}
                    >
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleFileUpload(acceptedFiles)
                        }
                        disabled={!isEditableSo}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className={`dropzone rounded d-flex justify-content-center align-items-center`}
                            style={dynamicDropDownStyles}
                          >
                            <input {...getInputProps()} />
                            <h5 className="text-center">
                              Drag 'n' drop some files here
                              <br /> or click to select files
                            </h5>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {(uploadedFiles?.length > 0 ||
                      viewFilesList?.length > 0) && (
                      <div className={`form-group col-12 h-auto`}>
                        <label className="form-label" htmlFor="comments">
                          {LabelConstants.Preview}
                        </label>
                        <ul className="list-group">
                          {viewFilesList.map(
                            (_file: IDocuments, _fileIdx: number) => (
                              <li
                                key={_file.documentId}
                                className="list-group-item d-flex justify-content-between align-items-center"
                                style={{ opacity: isEditableSo ? "1" : "0.5" }}
                              >
                                <span
                                  className="badge badge-pill"
                                  role="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#showPreviewFile"
                                  onClick={() =>
                                    setactiveURL(
                                      `${
                                        LabelConstants.urlHost
                                      }${_file.documentPath.substring(
                                        _file.documentPath.indexOf("/images")
                                      )}`
                                    )
                                  }
                                >
                                  <i className="fa fa-file-o text-primary fs-16"></i>
                                </span>
                                <span>{_file.documentName}</span>
                                <span
                                  className="badge badge-pill"
                                  role="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#showPreviewFile"
                                  onClick={() =>
                                    setactiveURL(
                                      `${
                                        LabelConstants.urlHost
                                      }${_file.documentPath.substring(
                                        _file.documentPath.indexOf("/images")
                                      )}`
                                    )
                                  }
                                >
                                  <i className="fa fa-eye text-primary"></i>
                                </span>
                                {isEditableSo ? (
                                  <span
                                    className="badge badge-pill"
                                    onClick={() =>
                                      handleRemoveOldFile(_file.documentId)
                                    }
                                  >
                                    <i className="fa fa-trash text-danger"></i>
                                  </span>
                                ) : null}
                              </li>
                            )
                          )}
                          {uploadedFiles.map((_file: any, _fileIdx: number) => (
                            <li
                              key={_fileIdx}
                              className="list-group-item d-flex justify-content-between align-items-center"
                              style={{ opacity: isEditableSo ? "1" : "0.5" }}
                            >
                              <span
                                className="badge badge-pill"
                                role="button"
                                data-bs-toggle="modal"
                                data-bs-target="#showPreviewFile"
                                onClick={() => setactiveURL(_file.preview)}
                              >
                                <i className="fa fa-file-o text-primary fs-16"></i>
                              </span>
                              <span>{_file.name}</span>
                              {isEditableSo ? (
                                <span
                                  className="badge badge-pill"
                                  onClick={() => handleRemoveFile(_fileIdx)}
                                >
                                  <i className="fa fa-trash text-danger"></i>
                                </span>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              );
            case "input":
              return (
                <div className="row mt-20" key={key}>
                  <div className="col-4">
                    <label className="form-label">
                      {field.label}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <InputText
                      value={field.value}
                      onChange={(e) => handleChange(key, e.target.value)}
                      disabled={field.disabled}
                      placeholder={field.placeholder}
                    />
                    {field.required && error[key] && (
                      <ErrorLabel errorMessage={error[key]} />
                    )}
                  </div>
                </div>
              );
            default:
              return;
          }
        })}
      </>
    );
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${LabelConstants.Customer} ${LabelConstants.Details}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderOut}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.delivery_order}
                      title={LabelConstants.DeliveryOrder}
                    >
                      {LabelConstants.DeliveryOrder}
                    </NavLink>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.DeliveryOrder} ${LabelConstants.Details}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {deliveryOrderList ? (
        <>
          <section className="content">
            <div className="row">
              {/* left part start */}
              <div className="col-12 col-lg-8">
                <div className="box">
                  <div className="box-header  bg-primary d-flex justify-content-between">
                    <h4 className="box-title">Products </h4>
                  </div>

                  <div className="box-body">
                    {/* products Table */}
                    <div className="table-responsive">
                      <table className="table product-overview table-bordered text-center">
                        <thead className="table-primary">
                          <tr>
                            <th>Product Category</th>
                            <th>Product Details</th>
                            <th>Batch No.</th>
                            <th>Batch Exp.</th>
                            <th>Shipping Quantity</th>
                            {/* <th>Batch Details</th> */}
                            {/* <th className="text-align:center">Action</th> */}
                          </tr>
                        </thead>
                        {
                          <tbody>
                            {(
                              deliveryOrderList?.deliveryOrderDetails || []
                            ).map((product, i: number) => {
                              const pickOrder =
                                orderDetails.find(
                                  (order) =>
                                    Number(order?.prodId) ===
                                    Number(product?.prodId)
                                ) || {};
                              let maxOrderQty = 0;
                              if (pickOrder) {
                                maxOrderQty =
                                  Number(pickOrder?.saleOrderQty || 0) +
                                  Number(pickOrder?.availableQty || 0);
                              }
                              console.log({ product });
                              return (
                                <tr key={`product-${i}`}>
                                  <td className="text-start">
                                    {getProductCatNameById(
                                      product?.prodCatId,
                                      productCateList
                                    )}
                                  </td>
                                  <td className="text-start">
                                    {getProductNameById(
                                      product?.prodId,
                                      productsList
                                    )}
                                  </td>
                                  <td className="text-start">
                                    {product?.batchNo}
                                  </td>
                                  <td className="text-start">
                                    {product?.batchExpiry}
                                  </td>
                                  <td className="fw-900 w-150">
                                    <input
                                      disabled={
                                        isEditableSo &&
                                        deliveryOrderList.status === "DRAFT"
                                          ? false
                                          : true
                                      }
                                      type="text"
                                      className="form-control"
                                      min={0}
                                      value={
                                        product?.quantity
                                          ? product?.quantity
                                          : 0
                                      }
                                      onChange={(e) => {
                                        if (
                                          Number(e.target.value) <=
                                          Number(product?.remainingQty)
                                        ) {
                                          handleQuantityChange(
                                            product,
                                            Number(e.target.value)
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                  {/* <td className="w-90"></td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        }
                      </table>

                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          clearDeliveryOrder();
                          navigate(RoutingConstants.delivery_order);
                        }}
                      >
                        Close
                      </button>
                      {isEditableSo && (
                        <button
                          type="button"
                          className="btn btn-primary float-end"
                          onClick={() => handleUpdateDetailsOrder()}
                        >
                          Save changes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* left part end */}

              {/* form start*/}
              <div className="col-12 col-lg-4">
                <div className="box">
                  <div className="box-header bg-info p-3">
                    <h4 className="box-title">Delivery Order Details</h4>
                    <p className="p-0 m-0">
                      #{LabelConstants.DeliveryOrder} :{" "}
                      {deliveryOrderList?.deliveryNum}
                    </p>
                  </div>
                  <div className="box-body">
                    {renderFormFields(formData, onFormFieldsChange)}
                  </div>
                </div>
              </div>
              {/* form container end*/}
            </div>
          </section>
          <div className="modal fade" id="showPreviewFile" tabIndex={-1}>
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">File Preview</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setactiveURL("");
                    }}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ height: "90vh" }}>
                  <iframe src={activeURL} width={"100%"} height={"100%"} />
                </div>
                <div className="modal-footer modal-footer-uniform">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setactiveURL("");
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default DeliveryOrderDetailsComponent;
