import { useContext, useEffect, useState } from "react";
import {
  IProducts,
  IStatusUpdate,
  IListConfig,
  IReturnOrdersContext,
  IListConfigRO,
  IDocuments,
  ICustomer,
  IResult,
} from "../../common/models";
import { cloneDeep } from "lodash";
import { GenericHelperService } from "../../api/generics/helper.service";
import { AppContext } from "../contexts/app.context";
import {
  EmptyListConfig,
  EmptyListROSearchConfig,
  EmptyROProductDetails,
  EmptyStatusUpdate,
  NewCustomer,
} from "../../common/constants/generalConstants";
import { toast } from "react-toastify";
import _ from "lodash";
import { IProductCategory } from "../../common/models/master";
import { MasterService } from "../../api/services/master.service";
import {
  getNumberForString,
  isNullUndefined,
} from "../../common/methods/globalmethods";
import {
  IReturnOrders,
  IReturnOrdersProductAndPriceDetails,
  IReturnsSalesOrderDetails,
  IROProductDetails,
} from "../../common/models/returnorders";
import { ReturnOrdersService } from "../../api/services/returnorders.service";
import { ProductService } from "../../api/services/product.service";
import { DocumentService } from "../../api/services/document.service";
import { CustomerService } from "../../api/services/customer.service";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import { SamplingService } from "../../api/services/sampling.service";

const useReturnOrders = () => {
  const productService: ProductService = new ProductService();
  const returnOrdersService: ReturnOrdersService = new ReturnOrdersService();
  const masterService: MasterService = new MasterService();
  const samplingService: SamplingService = new SamplingService();
  const documentService: DocumentService = new DocumentService();
  const customerService: CustomerService = new CustomerService();

  const [roproductsList, setRoproductsList] = useState<IROProductDetails[]>([]);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );
  const [salesOrderList, setSalesOrderList] = useState<
    IReturnsSalesOrderDetails[]
  >([]);
  const [returnLogs, setReturnLogs] = useState<ILogsData[]>([]);

  const getReturnLogs = async (id: number) => {
    const _logs: ILogsProps[] = await samplingService.getLogs(`RO${id}`);
    setReturnLogs(_logs);
  };

  const { setLoader } = useContext(AppContext);
  const [returnOrdersList, setReturnOrdersList] = useState<IReturnOrders[]>([]);
  const [returnOrdersForm, setReturnOrdersForm] = useState<IReturnOrders>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [nextRO, setNextRO] = useState(null);
  const [rofilters, setROFilters] = useState<IListConfigRO>(
    EmptyListROSearchConfig
  );
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [viewFiles, setViewFiles] = useState<IDocuments[]>([]);
  const [customersList, setCustomersList] = useState<ICustomer[]>([]);
  const [productsList, setProductsList] = useState<IProducts[]>([]);

  useEffect(() => {
    setLoader(true);
    setReturnOrdersForm(null);
    setSelectedList(EmptyStatusUpdate);
    initData();
  }, []);

  useEffect(() => {
    if (rofilters?.search === true) {
      queryROData();
    } else if (rofilters?.search === false) {
      getReturnOrdersList();
    }
  }, [rofilters?.search, filters.currentPage]);

  const initData = () => {
    getProductCategoriesList();
    setUploadedFiles([]);
    getCustomersList();
    getProductsList();
    getReturnOrdersList();
  };

  const getSalesOrderList = async () => {
    const _salesorders: IReturnsSalesOrderDetails[] =
      await returnOrdersService.getRecentlyDeliveredOrders();
    await getCustomersList().then((_customersList) => {
      _salesorders?.map((item) => {
        item.customerName = _customersList?.find(
          (val) => val.custId === item?.customerId
        )?.custName;
        item.value = `${item?.soNum} | ${item?.doNumber}`;
        item.label = `${item?.soNum} | ${item?.doNumber} | ${item?.customerName}`;
      });
      setSalesOrderList(_salesorders ?? []);
    });
  };

  const getCustomersList = async (): Promise<ICustomer[]> => {
    const _customers = await customerService.getCustomerAllList();
    // let _filteredCustomers = _.filter(_customers, { status: "ACTIVE" });
    setCustomersList(_customers ?? []);
    return _customers;
  };

  const getProductsList = async () => {
    const _products = await productService.getProducts();
    setProductsList(_products ?? []);
  };

  const getROProductsList = async (
    soNum: string,
    doNum: string,
    callback = null
  ) => {
    let _products: IROProductDetails[] = [];
    const orderData = await returnOrdersService.getROPriceDetails(soNum, doNum);

    const groupByOrdersData = orderData?.productAndPriceDetails?.reduce(
      (group: IReturnOrdersProductAndPriceDetails[], product) => {
        const productIndex = group.findIndex(
          (p) => p.prodId === product.prodId && p.batchNo === product.batchNo
        );
        if (productIndex !== -1) {
          group?.map((item) => {
            if (
              item?.prodId === product?.prodId &&
              item?.batchNo === product?.batchNo
            ) {
              item.shippedQty += product?.shippedQty;
            }
          });
        } else {
          group.push(product);
        }
        return group;
      },
      []
    );

    groupByOrdersData?.map((product) => {
      productsList?.map((_prod) => {
        if (_prod?.productId === product?.prodId) {
          product.productName = _prod?.productName;
          product.productCode = _prod?.productCode;
        }
      });
      _products.push(formROObjectFromPriceDetails(product));
    });
    setRoproductsList(_products ?? []);
    if (callback != null) {
      callback(_products);
    }
  };

  const getROFiles = async (itemId: any) => {
    const _documents: IDocuments[] = await documentService.getFiles(itemId);
    setViewFiles(_documents);
    return _documents;
  };

  const formROObjectFromPriceDetails = (
    item: IReturnOrdersProductAndPriceDetails
  ): IROProductDetails => {
    let product: IROProductDetails = cloneDeep(EmptyROProductDetails);
    product.prodId = item.prodId + "";
    product.productId = item.prodId;
    product.prodCatId = item.prodCatId + "";
    product.prodCategory = item.prodCatId + "";
    product.productCode = item.productCode;
    product.totalorderQty = item.orderQty;
    product.unitPrice = item.price;
    product.vatPercentage = item.vat;
    product.shippedQty = item.shippedQty;
    // product.vat = item.taxAmount;
    product.productAmount = item.prodAmount;
    product.productName = item.productName;
    product.batchExpiry = item.batchExpiry;
    product.batchNo = item.batchNo;
    return product;
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };

  const removeUnrequiredROAttributes = () => {
    let tempROfilter: IListConfigRO = cloneDeep(rofilters);
    if (!isNullUndefined(tempROfilter)) {
      for (const [key, value] of Object.entries(tempROfilter)) {
        if (typeof key === "string" && _.isEmpty(value)) {
          delete tempROfilter[key];
        } else if (typeof key === "boolean") {
          delete tempROfilter[key];
        } else if (typeof key === "number" && value <= 0) {
          delete tempROfilter[key];
        }
      }
    }
    return tempROfilter;
  };

  const queryROData = async () => {
    let tempROfilter: IListConfigRO = removeUnrequiredROAttributes();
    if (Object.entries(tempROfilter)?.length > 0) {
      await returnOrdersService
        .searchROOrders(tempROfilter, filters?.currentPage - 1)
        .then((result) => {
          let _filteredList = result?.content ?? [];
          updateFiltersByData(_filteredList, result);
          setReturnOrdersList(_filteredList ?? []);
        });
    }
  };

  const getReturnOrdersList = async () => {
    await returnOrdersService
      .getReturnOrders(filters?.currentPage - 1)
      .then((result) => {
        let data = result?.content ?? [];
        updateFiltersByData(data, result);
        setReturnOrdersList(data ?? []);
      });
  };

  const updateFiltersByData = (rodata: IReturnOrders[], result: IResult) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.searchQuery = "";
    _filters.totalRecords = result?.totalElements ?? rodata?.length;
    _filters.totalPageCount =
      result?.totalPages ??
      Math.ceil(rodata?.length / _filters.currentPageSize);
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const viewReturnOrder = async (_item: IReturnOrders, callback) => {
    let item: IReturnOrders = cloneDeep(_item);
    setNextRO(item?.returnOrderNum);
    await getROFiles(_item?.documentId);
    getROProductsList(
      item.soNum,
      item.deliveryNum,
      (_products: IROProductDetails[]) => {
        item?.returnOrderDetails?.map((data) => {
          _products?.map((_prod) => {
            if (
              _prod?.productId === getNumberForString(data?.prodId) &&
              _prod?.batchNo === data?.batchNo
            ) {
              data.prodId = _prod.prodId + "";
              data.productId = getNumberForString(_prod.prodId);
              data.prodCatId = _prod.prodCatId + "";
              data.prodCategory = _prod.prodCatId + "";
              data.productCode = _prod.productCode;
              data.productName = _prod.productName;
              data.shippedQty = _prod.shippedQty;
              data.vatPercentage = data.vat;
              data.vat = data.taxAmount;
              data.batchNo = _prod?.batchNo;
              data.batchExpiry = _prod?.batchExpiry;
            }
          });

          data.selected = true;
        });
        setReturnOrdersForm(item);
        callback();
      }
    );
  };

  const clearViewReturnOrder = () => {
    setReturnOrdersForm(null);
    setUploadedFiles([]);
    setViewFiles([]);
    setRoproductsList([]);
    setSalesOrderList([]);
  };

  const uploadFiles = async (itemId: any) => {
    console.log("itemId", itemId);
    if (uploadedFiles.length > 0 && itemId) {
      await documentService.uploadFiles(uploadedFiles, itemId);
    }
    return true;
  };

  const deleteFiles = async () => {
    const _deleteFiles: IDocuments[] = viewFiles.filter((_doc) =>
      _doc.deleted !== undefined ? _doc.deleted : false
    );
    for (let fileIdx = 0; fileIdx < _deleteFiles.length; fileIdx++) {
      const delFiles = _deleteFiles[fileIdx];
      await documentService.deleteFiles(delFiles.documentId);
    }
    return true;
  };

  const viewCustomer = async (itemId: any) => {
    const _documents: IDocuments[] = await documentService.getFiles(itemId);
    setViewFiles(_documents);
    return _documents;
  };

  const createNewReturnOrders = async (
    _newReturnOrder: IReturnOrders,
    callback
  ) => {
    const _roSave = await returnOrdersService.createNewReturnOrder(
      _newReturnOrder
    );
    if (_roSave) {
      if (typeof _roSave == "string") {
        toast.error(_roSave);
      } else {
        await uploadFiles(_newReturnOrder?.returnOrderNum);
        await deleteFiles();
        toast.success("Return order saved successfully.");
        initData();
        callback();
      }
    } else {
      toast.error("Error saving Return order.");
    }
  };

  const updateReturnOrders = async (
    _newReturnOrder: IReturnOrders,
    callback
  ) => {
    const result = await returnOrdersService.updateReturnOrder(_newReturnOrder);
    if (result?.success) {
      await uploadFiles(_newReturnOrder?.returnOrderNum);
      await deleteFiles();
      toast.success("Return order saved successfully.");
      initData();
      callback();
    } else {
      toast.error(result?.message);
    }
  };

  const newReturnOrders = () => {
    setReturnOrdersForm(cloneDeep(NewCustomer));
  };

  const updateStatus = async () => {
    const _updateStatus: boolean = await returnOrdersService.updateStatus(
      selectedList
    );
    if (_updateStatus) {
      if (typeof _updateStatus == "string") {
        toast.error(_updateStatus);
      } else {
        toast.success("Status updated successfully.");
        initData();
      }
    } else {
      toast.error("Error updating status.");
    }
  };

  const deleteReturnOrder = async () => {
    const _deleteItem: IReturnOrders = cloneDeep(returnOrdersForm);
    _deleteItem.deleted = true;
    const _itemSave = await returnOrdersService.updateReturnOrder(_deleteItem);
    if (_itemSave?.success) {
      toast.success("Return order deleted successfully.");
      initData();
    } else {
      toast.error(_itemSave?.message);
    }
  };

  const getNextRONumber = async () => {
    const _poNo = await returnOrdersService.getNextRoNumber();
    if (_poNo) {
      setNextRO(_poNo);
    } else {
      toast.error("Error getting next PO number");
    }
  };
  const clearRONumber = () => {
    setNextRO(null);
  };

  return {
    returnOrdersList,
    roproductsList,
    productCateList,
    salesOrderList,
    filters,
    returnOrdersForm,
    selectedList,
    setSelectedList,
    setFilters,
    setReturnOrdersForm,
    newReturnOrders,
    updateStatus,
    deleteReturnOrder,
    viewReturnOrder,
    clearViewReturnOrder,
    createNewReturnOrders,
    getNextRONumber,
    nextRO,
    clearRONumber,
    getROProductsList,
    getProductCategoriesList,
    rofilters,
    setROFilters,
    uploadFiles,
    deleteFiles,
    setUploadedFiles,
    setViewFiles,
    uploadedFiles,
    viewFiles,
    getSalesOrderList,
    customersList,
    getReturnLogs,
    returnLogs,
    productsList,
    queryROData,
    updateReturnOrders,
  } as IReturnOrdersContext;
};

export default useReturnOrders;
