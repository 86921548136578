import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import {
  getDateIfValid,
  getProductCatNameById,
  getProductNameById,
  isNullUndefined,
} from "../../common/methods/globalmethods";
import axios from "axios";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import moment from "moment";
import { DatePicker } from "antd";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { toast } from "react-toastify";
import Select from "react-select";
import dayjs from "dayjs";

const orderPickingData = {
  id: null,
  pickingNum: null,
  pickingDate: new Date(),
  warehouseId: null,
  pickingType: "AUTO",
  soNum: null,
  status: "DRAFT",
  customerId: null,
  isDeleted: false,
  createdDate: null,
  createdBy: "",
  modifiedDate: null,
  modifiedBy: "",
  description: "",
  orderPickingDetails: [],
};
const CreateOrderPickingComponent = () => {
  const {
    productsList,
    productCateList,
    salesOrdersList,
    getProductsBySO,
    salesOrderProducts,
    getProductLocations,
    productLocations,
    clearLocations,
    createNewOrderPicking,
    nextOP,
    clearOpNumber,
    getNextOpNumber,
  } = useContext(OrderPickingContext);
  const { masterWarehouseList, getWarehouseMap } = useContext(WarehouseContext);
  const [selectedSO, setSelectedSO] = useState<string | null>(null);
  const [selSalesOrderDtls, setSelectedSoDtls] = useState<any>(null);
  const [newOP, setNewOP] = useState<any>(orderPickingData);
  const [activePrs, setActivePrs] = useState<any>([]);
  const [pickedLocations, setPickedLocations] = useState<any>([]);
  const [tempProds, setTempProds] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!nextOP) {
      getNextOpNumber();
    }
    return () => clearOpNumber();
  }, []);

  useEffect(() => {
    if (newOP?.pickingType === "AUTO" && productLocations?.length > 0) {
      let _pickedLocs = [];
      productLocations?.map((item) => {
        item.pickingQty = item?.proposalQty;
        _pickedLocs.push(item);
      });
      setPickedLocations(_pickedLocs);
    }
  }, [productLocations]);

  const handleSelectedSalesOrder = (soNum, customerId) => {
    if (!isNullUndefined(newOP?.warehouseId)) {
      getProductsBySO(soNum, newOP?.warehouseId);
    }
    let _newOP = { ...newOP };
    _newOP.soNum = soNum;
    _newOP.customerId = customerId;
    setNewOP(_newOP);
  };
  const handleWerehouseChange = (e: any) => {
    if (!isNullUndefined(newOP?.soNum)) {
      getProductsBySO(newOP?.soNum, e.target.value);
    }
    let _newOP = { ...newOP };
    _newOP.warehouseId = e.target.value;
    setNewOP(_newOP);
  };
  const handlePickingTypeChange = (e: any) => {
    let _newOP = { ...newOP };
    _newOP.pickingType = e.target.value;
    setNewOP(_newOP);
  };
  const handleOPCreatedDate = (e: any) => {
    let _newOP = { ...newOP };
    _newOP.pickingDate = e;
    setNewOP(_newOP);
  };
  const handleOPStatus = (e: any) => {
    let _newOP = { ...newOP };
    _newOP.status = e.target.value;
    setNewOP(_newOP);
  };
  const handleDescriptionChange = (e: any) => {
    let _newOP = { ...newOP };
    _newOP.description = e.target.value;
    setNewOP(_newOP);
  };
  const handlePrLocations = (prId: any, Qty: any) => {
    let _activePrs = [...activePrs];
    let prIndex = _activePrs.indexOf(prId);
    if (prIndex > -1) {
      _activePrs.splice(prIndex, 1);
    } else {
      _activePrs.push(prId);
      if (!tempProds.includes(prId)) {
        getProductLocations(newOP.pickingType, prId, Qty, newOP.warehouseId);
      }
    }
    setActivePrs(_activePrs);
    let _tempPr = [...tempProds];
    _tempPr.push(prId);
    setTempProds(_tempPr);
  };

  const handleLocCheckbox = (loc: any, e: any) => {
    let _pickedLocs = [...pickedLocations];
    if (e.target.checked) {
      loc.pickingQty = loc?.proposalQty;
      _pickedLocs.push(loc);
    } else {
      const _locIdx = _pickedLocs.findIndex(
        (_itm) => _itm.locationId === loc.locationId
      );
      if (_locIdx > -1) {
        _pickedLocs.splice(_locIdx, 1);
      }
    }
    setPickedLocations(_pickedLocs);
  };
  const getProductQty = (_prod: any) => {
    let qty = 0;
    salesOrderProducts.map((soPr) => {
      if (soPr.prodId === _prod.prodId) {
        qty = soPr.orderedQty;
      }
    });
    return qty;
  };
  const handlePickedQty = (prod: any, e: any) => {
    if (
      e.target.value <= prod.quantity &&
      e.target.value <= getProductQty(prod)
    ) {
      let _pickedLocs = [...pickedLocations];
      _pickedLocs.map((_loc) => {
        if (_loc.locationId === prod.locationId) {
          _loc.pickingQty = e.target.value;
        }
      });
      setPickedLocations(_pickedLocs);
    } else {
      toast.error("Please enter valid quantity");
    }
  };
  const handleqtyBreakDown = (prod: any, e: any) => {
    let _pickedLocs = [...pickedLocations];
    _pickedLocs.map((_loc) => {
      if (_loc.locationId === prod.locationId) {
        _loc.qtyBreakDown = e.target.value;
      }
    });
    setPickedLocations(_pickedLocs);
  };
  const handleSaveOrderPicking = () => {
    newOP.pickingNum = nextOP;
    if (newOP.soNum && newOP.warehouseId) {
      if (pickedLocations.length > 0) {
        newOP.orderPickingDetails = [];
        pickedLocations.map((loc) => {
          if (loc.pickingQty > 0) {
            newOP.orderPickingDetails.push({
              id: null,
              orderPickId: null,
              locationId: loc.locationId,
              shelfId: loc.shelfId,
              prodId: loc.prodId,
              prodCatId: loc.prodCatId,
              saleOrderQty: getProductQty(loc),
              batchNo: loc.batchNo,
              batchExpiry: loc.batchExpiryDate,
              availableQty: loc.quantity,
              pickingQty: loc.pickingQty,
              quantityBreakDown: "",
            });
          }
        });
        createNewOrderPicking(newOP);
      } else {
        toast.error("Please add products to OP");
      }
    } else {
      toast.error("Please select mandatory fields");
    }
  };

  const getPickedQty = (productId: number, productCatId: number) => {
    let result = 0;
    pickedLocations?.map((item) => {
      if (item?.prodId === productId && item?.prodCatId === productCatId) {
        result += item?.quantity;
      }
    });
    return result;
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.orderPickingList}
                    >
                      Order Picking
                    </NavLink>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Order Picking
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-success float-end"
            onClick={() => {
              handleSaveOrderPicking();
            }}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Order Picking </h4>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-right"
                  disabled={
                    newOP.soNum === null ||
                    newOP.soNum === "" ||
                    newOP.warehouseId === null ||
                    newOP.warehouseId === ""
                  }
                >
                  Add Product
                </button>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Batch</th>
                        <th>Location</th>
                        <th>Pallet</th>
                        <th>Ordered Qty</th>
                        <th>Available Qty</th>
                        <th>Picking Qty</th>
                        {/* <th>Remaining Qty</th> */}
                        {/* <th>Qty Breakdown</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {pickedLocations.length > 0 ? (
                        pickedLocations.map((_prod: any, j: number) => {
                          return (
                            <tr key={j}>
                              <td>{j + 1}</td>
                              <td className="text-start">
                                {getProductCatNameById(
                                  _prod.prodCatId,
                                  productCateList
                                )}
                              </td>
                              <td className="text-start">
                                {getProductNameById(_prod.prodId, productsList)}
                              </td>
                              <td>{_prod.batchNo}</td>
                              <td>{_prod.shelfId}</td>
                              <td>{_prod.palletRefNum}</td>
                              <td>{getProductQty(_prod)}</td>
                              <td>{_prod.quantity}</td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={_prod.pickingQty}
                                  onChange={(e) => handlePickedQty(_prod, e)}
                                />
                              </td>
                              {/* <td>0</td> */}
                              {/* <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={_prod.qtyBreakDown}
                                  onChange={(e) => handleqtyBreakDown(_prod, e)}
                                />
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} align="center">
                            <br />
                            <p>No products added to Order Picking</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => navigate(RoutingConstants.orderPickingList)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success float-end"
                    onClick={() => {
                      handleSaveOrderPicking();
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">Order Picking Details</h4>
                <p className="p-0 m-0">
                  #{LabelConstants.PoNumber} : {nextOP}
                </p>
              </div>
              <div className="box-body">
                <div className="row align-items-center">
                  <div className="col-4">
                    <label className="form-label">
                      Picking Type
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        handlePickingTypeChange(e);
                      }}
                      value={newOP.pickingType}
                    >
                      <option value="AUTO">AUTO</option>
                      <option value="MANUAL">MANUAL</option>
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Sales Order
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    {salesOrdersList?.length > 0 && (
                      <Select
                        value={salesOrdersList.filter(
                          (option) =>
                            option.value ===
                            `${newOP?.soNum} - ${newOP?.customerId}`
                        )}
                        options={salesOrdersList}
                        isSearchable={true}
                        onChange={(e) =>
                          handleSelectedSalesOrder(e?.soNum, e?.customerId)
                        }
                      />
                    )}

                    {/* <select
                      className="form-select"
                      onChange={handleSelectedSalesOrder}
                      value={newOP.soNum + "-" + newOP.customerId}
                    >
                      <option value={""}>--</option>
                      {salesOrdersList &&
                        salesOrdersList.length > 0 &&
                        salesOrdersList.map((so: any, i: number) => {
                          if (so.status !== "DRAFT") {
                            return (
                              <option
                                key={i}
                                value={so.soNum + "-" + so.customerId}
                              >
                                {so.soNum} | {so.customerName}
                              </option>
                            );
                          }
                        })}
                    </select> */}
                  </div>
                </div>
                <div className="row mt-20 align-items-center">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.CreatedDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(newOP.pickingDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleOPCreatedDate(date);
                      }}
                    />
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      Status
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        handleOPStatus(e);
                      }}
                      value={newOP.status}
                    >
                      <option>--</option>
                      <option value={"DRAFT"}>DRAFT</option>
                      <option value={"PICKING"}>PICKING</option>
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      WareHouse Name
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      onChange={(e) => handleWerehouseChange(e)}
                      value={newOP.warehouseId}
                    >
                      <option value={""}>--</option>
                      {masterWarehouseList.length > 0 &&
                        masterWarehouseList.map((wereHouse: any, i: number) => {
                          return (
                            <option key={i} value={wereHouse.wareHouseId}>
                              {wereHouse.warehouseName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      Description
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      value={newOP.description}
                      onChange={(e) => handleDescriptionChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products list modal */}
      <div className="modal modal-center fade overflow-auto" id="modal-right">
        <div
          className="modal-dialog modal-lg"
          style={{ width: "1000px", maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  clearLocations();
                  setActivePrs([]);
                  setTempProds([]);
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <table className="table table-bordered text-center">
                <thead className="table-primary">
                  <tr>
                    <th>S.No</th>
                    <th>Product Category</th>
                    <th>Product Name</th>
                    <th>Ordered Qty</th>
                    <th>Total Availability</th>
                    <th>Picked Qty</th>
                    <th>Picking Qty</th>
                    <th>Remaining Qty</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {salesOrderProducts.length > 0 ? (
                    salesOrderProducts.map((pr: any, i: number) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {getProductCatNameById(
                                pr.prodCatId,
                                productCateList
                              )}
                            </td>
                            <td>
                              {getProductNameById(pr.prodId, productsList)}
                            </td>
                            <td>{pr?.orderedQty}</td>
                            <td>{pr?.totalProductQtyInWarehouse ?? ""}</td>
                            <td>{pr?.receivedQty}</td>
                            <td>{getPickedQty(pr?.prodId, pr?.prodCatId)}</td>
                            <td>{pr.orderedQty - pr.receivedQty}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-success"
                                onClick={() => {
                                  let qty = pr.orderedQty;
                                  if (newOP?.pickingType === "AUTO") {
                                    qty = pr.orderedQty - pr.receivedQty; //remaining qty
                                  }
                                  handlePrLocations(pr.prodId, qty);
                                }}
                              >
                                Locations
                              </button>
                            </td>
                          </tr>
                          {activePrs.includes(pr.prodId) && (
                            <tr style={{ background: "#f8f8f8" }}>
                              <td colSpan={8}>
                                <table className="table table-bordered m-0">
                                  <thead className="table-secondary">
                                    <tr>
                                      <th></th>
                                      {/* <td>GRN No</td> */}
                                      <td>Location</td>
                                      <td>Batch No</td>
                                      <td>Batch Exp.</td>
                                      <td>Pallet Ref No</td>
                                      <td>Qty</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {productLocations.length > 0 ? (
                                      productLocations.map(
                                        (loc: any, j: number) => {
                                          if (loc.prodId === pr.prodId) {
                                            return (
                                              <tr>
                                                <td>
                                                  <input
                                                    type="checkbox"
                                                    id={`md_checkbox_all${j}`}
                                                    className="chk-col-primary"
                                                    checked={
                                                      pickedLocations.findIndex(
                                                        (_itm: any) =>
                                                          _itm.locationId ===
                                                          loc.locationId
                                                      ) >= 0
                                                    }
                                                    onChange={(e) => {
                                                      handleLocCheckbox(loc, e);
                                                    }}
                                                  />
                                                  <label
                                                    htmlFor={`md_checkbox_all${j}`}
                                                    style={{
                                                      paddingLeft: "20px",
                                                      height: "15px",
                                                    }}
                                                  ></label>
                                                </td>
                                                {/* <td>{loc.grnNum}</td> */}
                                                <td>{loc?.shelfId}</td>
                                                <td>{loc?.batchNo}</td>
                                                <td>
                                                  {moment(
                                                    loc?.batchExpiryDate
                                                  ).format("DD/MM/yyyy")}
                                                </td>
                                                <td>{loc?.palletRefNum}</td>
                                                <td>{loc?.quantity}</td>
                                              </tr>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={6}>
                                          Product not Available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}> No Products Available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer modal-footer-uniform">
              {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button> */}
              <button
                type="button"
                className="btn btn-primary float-end"
                data-bs-dismiss="modal"
                onClick={() => {
                  clearLocations();
                  setActivePrs([]);
                  setTempProds([]);
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrderPickingComponent;
