import { Outlet } from "react-router-dom";
import useCustomer from "../../../controllers/hooks/useCustomer";
import { CustomerContext } from "../../../controllers/contexts/customer.context";

const CustomerLayoutComponent = () => {
    const customerFunctions = useCustomer();

    return <CustomerContext.Provider value={customerFunctions}>
        <Outlet />
    </CustomerContext.Provider>;
}

export default CustomerLayoutComponent;
