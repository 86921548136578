import { useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import {
  NewDeleteConfirmationDialog,
  NewDeliveryOrder,
  DeliveryOrderStatusesToCreate,
} from "../../common/constants/generalConstants";
import {
  IDeleteConfirmationProps,
  IDocuments,
  IProducts,
  ITransporter,
} from "../../common/models";
import moment from "moment";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  getDateIfValid,
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import { DeliveryOrderContext } from "../../controllers/contexts/deliveryorder.context";
import validator from "../../common/models/validator";
import { Modal } from "bootstrap";
import { cloneDeep } from "lodash";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import Select from "react-select";
import { IDeliveryOrder } from "../../common/models/deliveryorder";
import Dropzone from "react-dropzone";
import { OrderPickingDetail } from "../../common/models/pickingorder";
import SelectCustom from "../../Components/Select";
import dayjs from "dayjs";
var myModal;
const VALIDATOR = {
  soNum: [(value) => (_.isEmpty(value) ? `Select So Numbner!!` : null)],
  status: [(value) => (_.isEmpty(value) ? `Enter valid status!!` : null)],
  deliveryAddress: [
    (value) => (_.isEmpty(value) ? `Enter Delivery Address!!` : null),
  ],
  reason: [(value) => (_.isEmpty(value) ? `Enter description!!` : null)],
  dataLog: [(value) => (_.isEmpty(value) ? `Enter Datalog!!` : null)],
  estDeliveryDate: [
    (value) => (_.isEmpty(value) ? `Enter Estimated Delivery Date!!` : null),
  ],
  pickingDate: [(value) => (_.isEmpty(value) ? `Enter Picking Date!!` : null)],
  transporterId: [(value) => (value <= 0 ? `Select Transporter!!` : null)],
  bookingRefNo: [
    (value) => (_.isEmpty(value) ? `Enter Booking Refrence No!!` : null),
  ],
  dispatchDate: [(value) => (_.isEmpty(value) ? `Enter valid date!!` : null)],
  trackingNumber: [
    (value) => (_.isEmpty(value) ? `Enter tracking number!!` : null),
  ],
};

interface FormErrors {
  soNum?: string;
  dispatchDate?: string;
  status?: string;
  deliveryAddress?: string;
  dataLog?: boolean;
  reason?: string;
  transporterId?: number;
  bookingRefNo?: string;
  shipDate?: string;
  deliveryNum?: string;
  estDeliveryDate?: string;
  pickingDate?: string;
  trackingNumber?: string;
}

const DeliveryOrderFormcomponent = () => {
  const {
    getNextDONumber,
    clearDoNumber,
    nextDONumber,
    productsList,
    productCateList,
    customersList,
    transporterList,
    createNewDeliveryOrder,
    // getProductsList,
    pickingOrderList,
    viewCustomer,
    setUploadedFiles,
    uploadedFiles,
    viewFiles,
    setViewFiles,
    clearDeliveryOrder,
    pickingOrderListByStatus,
    getOrderPickingDetails,
    pickingOrderProducts,
  } = useContext(DeliveryOrderContext);

  const navigate = useNavigate();

  const [selectedProducts, setSelectedProducts] = useState<IDeliveryOrder>(
    cloneDeep(NewDeliveryOrder)
  );
  const [searchProduct, setSearchProduct] = useState<any>("");
  const [currentCustomerAddress, setCurrentCustomerAddress] = useState<any>([]);
  const [filteredProducts, setFilteredProducts] =
    useState<IProducts[]>(productsList);
  const [error, setError] = useState<FormErrors>({});
  const [dialoginfo, setDialoginfo] = useState<IDeleteConfirmationProps>();
  const [activeURL, setactiveURL] = useState("");
  const viewFilesList = viewFiles.filter((_file) =>
    _file.deleted !== undefined ? _file.deleted === false : true
  );

  useEffect(() => {
    if (!nextDONumber) {
      getNextDONumber();
    }
    myModal = new Modal(document.getElementById("deleteConfirmation"), {});
    addProductsIfEmpty();
    return () => {
      clearDoNumber();
      clearDeliveryOrder();
    };
  }, []);

  const addProductsIfEmpty = async () => {
    // if (filteredProducts.length <= 0) {
    //   let products = await getProductsList();
    //   setFilteredProducts(products);
    // }
  };

  useEffect(() => {
    const filteredList = productsList.filter((product: IProducts) => {
      const searchItem = searchProduct.toLowerCase();
      return (
        product?.productName?.toLowerCase()?.includes(searchItem) ||
        product?.productCode?.toLowerCase()?.includes(searchItem)
      );
    });

    setFilteredProducts(filteredList);
  }, [searchProduct]);

  useEffect(() => {
    const selected = { ...selectedProducts };
    if (selectedProducts?.customerId) {
      // Find the customer in the customersList
      const matchedCustomer = customersList.find(
        (customer) =>
          Number(customer?.custId) === Number(selectedProducts?.customerId)
      );

      if (matchedCustomer) {
        // Update the state with the customer addresses
        setCurrentCustomerAddress(matchedCustomer?.custAddresses || []);

        setSelectedProducts({
          ...selected,
          custContactDetails:
            matchedCustomer?.custAddresses[0]?.custCommunication[0]
              ?.phoneNumber || "",
        });
      }
    }
  }, [selectedProducts?.customerId, customersList]);

  const orderDetails: OrderPickingDetail[] = useMemo(() => {
    const order = (pickingOrderList || []).find(
      (po) => po?.soNum === selectedProducts?.soNum
    );
    console.log({ order, pickingOrderList });
    return order?.orderPickingDetails ?? [];
  }, [selectedProducts?.soNum, pickingOrderList]);

  const showDialog = (
    onCancel: Function,
    onConfirm: Function,
    header: string,
    title_prefix: string,
    title: string = null
  ) => {
    let dialog: IDeleteConfirmationProps = cloneDeep(
      NewDeleteConfirmationDialog
    );
    dialog.cancel = onCancel;
    dialog.confirm = onConfirm;
    dialog.title = title;
    dialog.header = header;
    dialog.title_prefix = title_prefix;
    setDialoginfo(dialog);
    myModal.show();
  };

  const onClickSearch = (e) => {
    setSearchProduct(e.target.value);
  };

  const handleCreateSo = async () => {
    let obj = {
      soNum: selectedProducts?.soNum,
      dispatchDate: selectedProducts?.dispatchDate,
      status: selectedProducts?.status,
      deliveryAddress: selectedProducts?.deliveryAddress,
      dataLog: selectedProducts?.dataLog,
      reason: selectedProducts?.reason,
      transporterId: selectedProducts?.transporterId,
      bookingRefNo: selectedProducts?.bookingRefNo,
      deliveryNum: nextDONumber,
      estDeliveryDate: selectedProducts?.estDeliveryDate,
      pickingDate: selectedProducts?.pickingDate,
      trackingNumber: selectedProducts?.trackingNumber,
    };
    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);
    console.log({ isErrors, selectedProducts });
    if (!_.isEmpty(isErrors)) {
      toast.error("Please fill mandatory fields");
      return;
    }

    await viewCustomer(nextDONumber);

    const newSelectedProducts = { ...selectedProducts };

    newSelectedProducts["deliveryNum"] = nextDONumber;
    newSelectedProducts["deliveryDate"] = moment(
      selectedProducts?.deliveryDate
    )?.format("yyyy-MM-DD");
    newSelectedProducts["pickingDate"] = moment(
      selectedProducts?.pickingDate
    )?.format("yyyy-MM-DD");
    newSelectedProducts["dispatchDate"] = moment(
      selectedProducts?.dispatchDate
    )?.format("yyyy-MM-DD");
    newSelectedProducts["estDeliveryDate"] = moment(
      selectedProducts?.estDeliveryDate
    )?.format("yyyy-MM-DD");
    newSelectedProducts["documentId"] = nextDONumber;

    createNewDeliveryOrder(newSelectedProducts);
  };
  const handleFormChange = async (fieldNames: string[], values: any[]) => {
    setSelectedProducts((prevState) => {
      let updatedState = { ...prevState };

      // If soNum is one of the fieldNames, find and update the corresponding sales order details
      if (fieldNames.includes("soNum")) {
        const soNumIndex = fieldNames.indexOf("soNum");
        const poNumIndex = fieldNames.indexOf("poNum");
        // const matchingOrder = salesOrderList.find((order) => order.soNum === values[soNumIndex]);
        const matchingOrder = pickingOrderList.find((order) => {
          if (
            order.soNum === values[soNumIndex] &&
            order?.pickingNum === values[poNumIndex]
          ) {
            return true;
          }
          return false;
        });
        let tempOrder = pickingOrderListByStatus.find((order) => {
          if (
            order.saleOrderId === values[soNumIndex] &&
            order?.orderPickingId === values[poNumIndex]
          ) {
            return true;
          }
          return false;
        });
        updatedState["deliveryAddress"] = tempOrder?.address;
        getOrderPickingDetails(tempOrder?.orderPickingId);
        console.log({ matchingOrder });

        if (matchingOrder) {
          // Update only the fields present in both matchingOrder and updatedState
          Object.keys(matchingOrder).forEach((key) => {
            // if(key === "description"){
            //   updatedState["reason"] = matchingOrder[key];
            //   updatedState["reason"] = '';
            // }
            if (key === "pickingNum") {
              updatedState["orderPickingId"] = matchingOrder[key];
            }
            if (key === "deliveryAddress") {
              let tempOrder = pickingOrderListByStatus.find((order) => {
                if (
                  order.soNum === values[soNumIndex] &&
                  order?.pickingNum === values[poNumIndex]
                ) {
                  return true;
                }
                return false;
              });
              updatedState["deliveryAddress"] = tempOrder?.address;
            }
            if (updatedState.hasOwnProperty(key) && key !== "status") {
              updatedState[key] = matchingOrder[key];
            }
          });

          // updatedState["deliveryOrderDetails"] = matchingOrder["orderPickingDetails"].map((order) => ({
          //   prodId: Number(order.prodId),
          //   prodCatId: Number(order.prodCatId),
          //   pickedQuantity: Number(order.pickingQty),
          //   quantity: Number(order.pickingQty),
          // }));
        }
        // if(pickingOrderProducts?.productsDetailsDTOS?.length > 0){
        //   updatedState["deliveryOrderDetails"] = pickingOrderProducts?.productsDetailsDTOS.map((pr:any) => ({
        //       prodId: Number(pr.prodId),
        //       prodCatId: Number(pr.prodCatId),
        //       pickedQuantity: Number(pr.pickedQty),
        //       quantity: Number(pr.pickedQty),
        //   }));
        // }
      }

      // Update fields based on fieldNames and values
      fieldNames.forEach((fieldName, index) => {
        updatedState[fieldName] = values[index];
      });

      return updatedState;
    });
  };
  useEffect(() => {
    if (pickingOrderProducts?.productsDetailsDTOS?.length > 0) {
      let _selectedProducts = { ...selectedProducts };
      _selectedProducts["deliveryOrderDetails"] =
        pickingOrderProducts?.productsDetailsDTOS.map((pr: any) => ({
          prodId: Number(pr.prodId),
          prodCatId: Number(pr.prodCatId),
          pickedQuantity: Number(pr.pickedQty),
          quantity: Number(pr.remainingQty),
          remainingQty: Number(pr?.remainingQty),
          batchNo: pr?.batchNo,
          batchExpiry: pr?.batchExpiry,
          orderPickDelId: pr?.orderPickDelId,
        }));
      setSelectedProducts(_selectedProducts);
    }
  }, [pickingOrderProducts]);
  const handleFileUpload = (files: any[]) => {
    console.log(files);
    const _viewFilesCount: number = viewFilesList.length;
    const _filesCount: number =
      files.length + uploadedFiles.length + (_viewFilesCount ?? 0);
    if (files.length > 0) {
      if (_filesCount > 5) {
        toast.warning(LabelConstants.FileUploadCountExceeded);
      } else {
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setUploadedFiles([...uploadedFiles, ...files]);
      }
    }
  };
  const handleRemoveFile = (idx: number) => {
    const _files = cloneDeep(uploadedFiles);
    _files.splice(idx, 1);
    setUploadedFiles(_files);
  };
  const handleRemoveOldFile = (docId: number) => {
    const _files: IDocuments[] = cloneDeep(viewFiles);
    const idx: number = _files.findIndex((_) => _.documentId === docId);
    if (idx >= 0) {
      _files[idx].deleted = true;
      setViewFiles(_files);
    }
  };
  const deleteProductInSo = (product: any) => {
    let _newSalesOrder = { ...selectedProducts };
    _.remove(_newSalesOrder?.deliveryOrderDetails, function (n) {
      return (
        n?.prodId === product?.prodId &&
        n?.orderPickDelId === product?.orderPickDelId
      );
    });
    setSelectedProducts(_newSalesOrder);
  };
  const handleQuantityChange = (productId, index, newQuantity) => {
    setSelectedProducts((prevState) => {
      return {
        ...prevState,
        deliveryOrderDetails: prevState.deliveryOrderDetails.map(
          (product, i) => {
            if (product.prodId === productId && index === i) {
              return { ...product, quantity: newQuantity };
            }
            return product;
          }
        ),
      };
    });
  };

  //Dropdown Options
  const salesOrderSelectOptions = pickingOrderListByStatus.map(
    (pickingOrder) => {
      //const currentContact =  (customersList || []).find((customer) => Number(customer.custId) === Number(pickingOrder?.customerId)) || {}
      return {
        label:
          `${pickingOrder?.saleOrderId ?? "-"} | ${
            pickingOrder?.orderPickingId ?? "-"
          } | ${pickingOrder?.customerName ?? "-"}` || "",
        value:
          `${pickingOrder?.saleOrderId ?? "-"} | ${
            pickingOrder?.orderPickingId ?? "-"
          }` || "",
      };
    }
  );
  const deliveryOrderStatusSelectOptions = DeliveryOrderStatusesToCreate.map(
    (status) => ({
      label: status?.toString() || "",
      value: status?.toString() || "",
    })
  );
  const customerSelectOptions = customersList.map(({ custId, custName }) => ({
    label: custName?.toString() || "",
    value: custId?.toString() || "",
  }));

  console.log("trans", transporterList);
  const transportSelectOptions = transporterList
    .filter(({ status }) => status === "ACTIVE")
    .map(({ transName, transId }) => ({
      label: transName || "",
      value: Number(transId) || "",
    }));
  const customerAddressSelectOptions = (currentCustomerAddress || []).map(
    (address) => ({
      label: `${address.address1}, ${address.address2}` || "",
      value:
        `${address.address1},${address.address2},${address.city},${address.country},${address.postCode}test` ||
        "",
      addressId: address?.custAddressId,
    })
  );

  console.log({ selectedProducts, pickingOrderList });

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderOut}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.delivery_order}
                      title={LabelConstants.DeliveryOrder}
                    >
                      {LabelConstants.DeliveryOrder}
                    </NavLink>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Create} ${LabelConstants.DeliveryOrder}`}</li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary float-end"
            onClick={() => handleCreateSo()}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Products </h4>
              </div>
              <div className="box-body">
                {/* products Table */}
                <div className="table-responsive">
                  <table className="table product-overview table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>Product Category</th>
                        <th>Product Details</th>
                        <th>Batch No.</th>
                        <th>Batch Exp.</th>
                        <th>Picked Quantity</th>
                        <th>Shiping Quantity</th>
                        {/* <th>Batch Details</th> */}
                        <th className="text-align:center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProducts.deliveryOrderDetails?.length > 0 ? (
                        selectedProducts.deliveryOrderDetails.map(
                          (product, i: number) => {
                            // const pickOrder = orderDetails.find((order) => Number(order?.prodId) === Number(product?.prodId)) || {};
                            // let maxOrderQty = 0;
                            // if (pickOrder) {
                            //   maxOrderQty = Number(pickOrder?.saleOrderQty || 0) + Number(pickOrder?.availableQty || 0);
                            // }
                            return (
                              <tr key={i}>
                                <td className="text-start">
                                  {getProductCatNameById(
                                    product?.prodCatId,
                                    productCateList
                                  )}
                                </td>
                                <td className="text-start">
                                  {getProductNameById(
                                    product?.prodId,
                                    productsList
                                  )}
                                </td>
                                <td>{product?.batchNo}</td>
                                <td>{product?.batchExpiry}</td>
                                <td className="fw-900 w-150">
                                  {product?.pickedQuantity
                                    ? product.pickedQuantity
                                    : 0}
                                </td>
                                <td className="w-90">
                                  <input
                                    type="text"
                                    className="form-control"
                                    min={0}
                                    value={
                                      product?.quantity ? product?.quantity : 0
                                    }
                                    onChange={(e) => {
                                      if (
                                        Number(e.target.value) <=
                                        product?.remainingQty
                                      ) {
                                        handleQuantityChange(
                                          product.prodId,
                                          i,
                                          Number(e.target.value)
                                        );
                                      } else {
                                        toast.error(
                                          "Please enter valid quantity"
                                        );
                                      }
                                    }}
                                  />
                                </td>
                                <td align="center">
                                  <div className="d-flex justify-content-center">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        showDialog(
                                          () => {},
                                          () => {
                                            deleteProductInSo(product);
                                          },
                                          undefined,
                                          undefined,
                                          getProductNameById(
                                            product?.prodId,
                                            productsList
                                          )
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash text-danger fs-3"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={8} align="center">
                            <br />
                            <p>No products added to sales order</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      clearDeliveryOrder();
                      navigate(RoutingConstants.delivery_order);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    onClick={() => handleCreateSo()}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* sales order container*/}
          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">{`${LabelConstants.FormTitle} ${LabelConstants.Details}`}</h4>
                <p className="p-0 m-0">
                  {LabelConstants.DeliveryOrder}#:
                  {nextDONumber ? nextDONumber : "N/A"}
                </p>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.SalesOrder}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Select
                      options={salesOrderSelectOptions}
                      value={salesOrderSelectOptions.find(
                        (option) =>
                          option.value ===
                          `${selectedProducts.soNum} | ${selectedProducts.poNum}`
                      )}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          const [soNum, poNum] =
                            selectedOption.value.split(" | ");
                          handleFormChange(["soNum", "poNum"], [soNum, poNum]);
                        }
                      }}
                      isSearchable={true}
                      placeholder="--"
                    />

                    {error?.soNum && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.soNum}</span>
                      </label>
                    )}
                  </div>
                </div>
                {/* <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.PickingDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      showIcon
                      className="form-control"
                      selected={selectedProducts?.pickingDate}
                      value={moment(selectedProducts?.pickingDate)?.format("MM/DD/yyyy")}
                      minDate={new Date()}
                      dateFormat="MM/dd/yyyy"
                      onChange={(e: any) => {
                        handleFormChange(["pickingDate"], [e]);
                      }}
                    />
                    {error?.pickingDate && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.pickingDate}</span>
                      </label>
                    )}
                  </div>
                </div> */}
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.ShipDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(selectedProducts?.dispatchDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleFormChange(["dispatchDate"], [date]);
                      }}
                    />
                    {error?.dispatchDate && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.dispatchDate}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.CustomerName}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <SelectCustom
                      disabled
                      options={customerSelectOptions}
                      value={selectedProducts?.customerId}
                      onChange={(e) => {
                        const currentCustomer = customersList.find(
                          (cus) => Number(cus.custId) === Number(e.target.value)
                        );
                        handleFormChange(
                          ["customerId", "custContactDetails"],
                          [
                            e.target.value,
                            currentCustomer?.custAddresses[0]
                              ?.custCommunication[0]?.phoneNumber || "",
                          ]
                        );
                      }}
                      placeholder="--"
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.DeliveryAddress}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <SelectCustom
                      options={customerAddressSelectOptions}
                      value={selectedProducts?.deliveryAddress}
                      onChange={(e) => {
                        let result = customerAddressSelectOptions?.filter(
                          (item) => item.value === e.target.value
                        );
                        handleFormChange(
                          ["deliveryAddress", "addressId"],
                          [e.target.value, result[0]?.addressId]
                        );
                      }}
                      placeholder="--"
                    />
                    {error?.deliveryAddress && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.deliveryAddress}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Transporter}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <SelectCustom
                      options={transportSelectOptions}
                      value={selectedProducts.transporterId}
                      onChange={(e) => {
                        const id: Number = Number(e.target.value);
                        const currentTranporter: ITransporter =
                          transporterList.find(
                            (tranport) => tranport.transId === id
                          );
                        const { transName, transAddresses } = currentTranporter;
                        handleFormChange(
                          [
                            "transporterId",
                            "transporterName",
                            "transporterAddress",
                            "transContactDetails",
                          ],
                          [
                            id,
                            transName,
                            transAddresses[0]?.address1 || "",
                            transAddresses[0]?.transCommunication[0]
                              ?.phoneNumber || "",
                          ]
                        );
                      }}
                      placeholder="--"
                    />
                    {error?.transporterId && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.transporterId}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <SelectCustom
                      options={deliveryOrderStatusSelectOptions}
                      value={selectedProducts.status}
                      onChange={(e) => {
                        handleFormChange(["status"], [e.target.value]);
                      }}
                      placeholder="--"
                    />
                    {error?.status && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.status}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.BookingReference}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      // disabled={
                      //   selectedProducts?.status
                      //     ? selectedProducts.status.toLowerCase() !== "draft"
                      //     : false
                      // }
                      className="form-control"
                      value={selectedProducts?.bookingRefNo}
                      onChange={(e) =>
                        handleFormChange(["bookingRefNo"], [e.target.value])
                      }
                    />
                    {error?.bookingRefNo && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.bookingRefNo}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.TrackingNumber}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      // disabled={
                      //   selectedProducts?.status
                      //     ? selectedProducts.status.toLowerCase() !== "draft"
                      //     : false
                      // }
                      className="form-control"
                      value={selectedProducts?.trackingNumber}
                      onChange={(e) =>
                        handleFormChange(["trackingNumber"], [e.target.value])
                      }
                    />
                    {error?.trackingNumber && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.trackingNumber}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.EstimatedDeliveryDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(selectedProducts?.estDeliveryDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleFormChange(["estDeliveryDate"], [date]);
                      }}
                    />
                    {error?.estDeliveryDate && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.estDeliveryDate}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.DataLogger}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {["Yes", "No"].map((_status, _statusIdx) => (
                        <div
                          key={`statusOption${_statusIdx}`}
                          style={{
                            marginRight: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="radio"
                            id={`status${_statusIdx}`}
                            name="status"
                            value={_status}
                            checked={selectedProducts?.dataLog === _status}
                            onChange={(e) => {
                              handleFormChange(["dataLog"], [e.target.value]);
                            }}
                          />
                          <label htmlFor={`status${_statusIdx}`}>
                            {_status}
                          </label>
                        </div>
                      ))}
                    </div>

                    {error?.dataLog && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.dataLog}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Attachment}
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div
                      className={`form-group d-flex flex-column flex-lg-row col-12 h-auto`}
                    >
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleFileUpload(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="dropzone rounded d-flex justify-content-center align-items-center"
                            style={{
                              cursor: "pointer",
                              height: "calc(100% - 31px) !important",
                            }}
                          >
                            <input {...getInputProps()} />
                            <h5 className="text-center">
                              Drag 'n' drop some files here
                              <br /> or click to select files
                            </h5>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {(uploadedFiles?.length > 0 ||
                      viewFilesList?.length > 0) && (
                      <div className={`form-group col-12 h-auto`}>
                        <label className="form-label" htmlFor="comments">
                          {LabelConstants.Preview}
                        </label>
                        <ul className="list-group">
                          {viewFilesList.map(
                            (_file: IDocuments, _fileIdx: number) => (
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span className="badge badge-pill">
                                  <i className="fa fa-file-o text-primary fs-16"></i>
                                </span>
                                <span>{_file.documentName}</span>
                                <span
                                  className="badge badge-pill"
                                  onClick={() =>
                                    handleRemoveOldFile(_file.documentId)
                                  }
                                >
                                  <i className="fa fa-trash text-danger"></i>
                                </span>
                              </li>
                            )
                          )}
                          {uploadedFiles.map((_file: any, _fileIdx: number) => (
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span
                                className="badge badge-pill"
                                role="button"
                                data-bs-toggle="modal"
                                data-bs-target="#showPreviewFile"
                                onClick={() => setactiveURL(_file.preview)}
                              >
                                <i className="fa fa-file-o text-primary fs-16"></i>
                              </span>
                              <span>{_file.name}</span>
                              <span
                                className="badge badge-pill"
                                role="button"
                                data-bs-toggle="modal"
                                data-bs-target="#showPreviewFile"
                                onClick={() => setactiveURL(_file.preview)}
                              >
                                <i className="fa fa-eye text-primary"></i>
                              </span>
                              <span
                                className="badge badge-pill"
                                onClick={() => handleRemoveFile(_fileIdx)}
                              >
                                <i className="fa fa-trash text-danger"></i>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.Description}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      value={selectedProducts?.reason}
                      onChange={(e) =>
                        handleFormChange(["reason"], [e.target.value])
                      }
                      rows={5}
                      className="form-control"
                      placeholder={LabelConstants.Description}
                    />
                    {error?.reason && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.reason}</span>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade" id="showPreviewFile" tabIndex={-1}>
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">File Preview</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  setactiveURL("");
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ height: "90vh" }}>
              <iframe src={activeURL} width={"100%"} height={"100%"} />
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  setactiveURL("");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationComponent
        title={dialoginfo?.title}
        cancel={dialoginfo?.cancel}
        confirm={dialoginfo?.confirm}
        header={dialoginfo?.header}
        title_prefix={dialoginfo?.title_prefix}
      />
    </>
  );
};
export default DeliveryOrderFormcomponent;
