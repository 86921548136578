import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import {
  BatchStatusTypes,
  EmptyListConfigPlanningRpt,
  EmptyPlanningReport,
  PlanningReportKeys,
} from "../../../common/constants/generalConstants";
import { IListConfig, IListConfigPlanningRpt } from "../../../common/models";
import { cloneDeep } from "lodash";
import { ReportsContext } from "../../../controllers/contexts/reports.context";
import { IPlanningReport } from "../../../common/models/reports";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import { isNullUndefined } from "../../../common/methods/globalmethods";

const StockPlanningViewComponent = () => {
  const {
    masterFilter,
    setMasterfilter,
    filteredMasterData,
    getPlanningReports,
    clearMasterData,
    setFilteredMasterData,
    masterData,
    products,
    warehouses,
  } = useContext(ReportsContext);

  const [filter, setfilter] = useState<IListConfigPlanningRpt>(
    EmptyListConfigPlanningRpt
  );

  useEffect(() => {
    clearSearch();
  }, []);

  const handleQueryChange = (
    type: string,
    value: any,
    search: boolean = false
  ) => {
    const _filter: IListConfigPlanningRpt = cloneDeep(filter);
    if (search) {
      _filter.searchData[type] = value;
      searchData(_filter);
    } else {
      _filter[type] = value;
    }
    setfilter(_filter);
  };

  const returnSearchFilter = (_filter: IPlanningReport) => {
    let tempfilter: IPlanningReport = cloneDeep(_filter);
    for (const [key, value] of Object.entries(tempfilter)) {
      if (typeof value === "string" && _.isEmpty(value)) {
        delete tempfilter[key];
      } else if (Array.isArray(value) && value?.length <= 0) {
        delete tempfilter[key];
      } else if (typeof value === "number" && value <= 0) {
        delete tempfilter[key];
      } else if (value === null || value === undefined) {
        delete tempfilter[key];
      }
    }
    return tempfilter;
  };

  const searchData = (_filter: IListConfigPlanningRpt) => {
    let tempFilter: IPlanningReport = returnSearchFilter(_filter.searchData);
    let _filteredList = masterData?.filter((_item: any, _itemIdx: number) => {
      let result = true;
      for (const [key, value] of Object.entries(tempFilter)) {
        const data = _item[key]?.toString()?.toLowerCase();
        result = result && data?.includes(value?.toLowerCase());
      }
      return result;
    });
    setFilteredMasterData(_filteredList);
  };

  const clearSearch = () => {
    const _filter: IListConfigPlanningRpt = cloneDeep(filter);
    _filter.batchNo = "";
    _filter.prodId = null;
    _filter.status = "";
    _filter.warehouseId = null;
    _filter.searchData = cloneDeep(EmptyPlanningReport);
    setfilter(_filter);
    clearMasterData();
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filter: IListConfig = cloneDeep(masterFilter);
    if (_filter.sortItem === column) {
      _filter.sortDirection = _filter.sortDirection === "asc" ? "desc" : "asc";
      _filter.sortIsDate = isDate;
    } else {
      _filter.sortItem = column;
      _filter.sortDirection = "asc";
      _filter.sortIsDate = isDate;
    }
    setMasterfilter(_filter);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      filter?.batchNo === "" &&
      filter?.status === "" &&
      (filter?.prodId === null ||
        filter?.prodId === undefined ||
        filter?.prodId === 0) &&
      (filter?.warehouseId === null ||
        filter?.warehouseId === undefined ||
        filter?.warehouseId === 0) &&
      filter?.status?.length <= 0
    ) {
      result = true;
    }
    return result;
  };

  return (
    <>
      <div className="box">
        <div className="box-body">
          <div className="col-lg-12 d-flex">
            {/* <div className="input-group w-p45 ms-10">
              <input
                className="form-control"
                placeholder="Batch Num"
                value={filter?.batchNo}
                onChange={(event) =>
                  handleQueryChange("batchNo", event.target.value)
                }
              />
            </div>

            <div className="input-group w-p45 ms-10">
              <select
                className="form-select"
                value={filter?.status}
                onChange={(e) => handleQueryChange("status", e.target.value)}
              >
                <option value={""}>Status</option>
                {BatchStatusTypes?.map((item: any, id: number) => {
                  if (item.value === "SAMP") return;
                  return (
                    <option key={`status${id}`} value={item?.value}>
                      {item?.text}
                    </option>
                  );
                })}
              </select>
            </div> */}
            <div className="col-3">
              <Select
                value={products?.filter(
                  (option: any) => option.value === filter?.prodId
                )}
                placeholder="Product"
                options={products}
                isSearchable={true}
                onChange={(e: any) => handleQueryChange("prodId", e.value)}
              />
            </div>
            <div className="m-10" />

            <div className="col-3">
              <Select
                value={warehouses?.filter(
                  (option: any) => option.value === filter?.warehouseId
                )}
                placeholder="Warehouse"
                options={warehouses}
                isSearchable={true}
                onChange={(e: any) => handleQueryChange("warehouseId", e.value)}
              />
            </div>
            <div className="input-group w-p10 ms-10">
              <button
                type="button"
                className="waves-effect waves-light btn btn-success rounded"
                disabled={checkIfSearchStringsAreEmpty()}
                onClick={() => {
                  getPlanningReports(filter);
                }}
              >
                {LabelConstants.Search}
              </button>
            </div>
            <div className="input-group ms-10">
              <button
                type="button"
                className="waves-effect waves-light btn btn-success-light rounded"
                onClick={() => {
                  clearSearch();
                }}
              >
                {LabelConstants.Clear}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="box-body">
          <div className="table-responsive">
            <table
              className="table table-hover table-bordered mb-0"
              style={{ textAlign: "center" }}
            >
              <thead className="table-primary">
                <tr>
                  <th>S.No</th>
                  {PlanningReportKeys?.map((item) => (
                    <th onClick={() => handleSort(item.key)}>
                      {item.placeholder}
                      <i
                        className={`ms-2 fa fa-sort${
                          masterFilter?.sortItem === item.key
                            ? `-${masterFilter?.sortDirection} text-primary`
                            : " text-fade"
                        }`}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr key={"test"}>
                  <td></td>
                  {PlanningReportKeys?.map((item) => (
                    <td>
                      <input
                        className="form-control"
                        placeholder={item?.placeholder}
                        value={filter?.searchData[item.key] ?? ""}
                        onChange={(event) => {
                          handleQueryChange(item.key, event.target.value, true);
                        }}
                      />
                    </td>
                  ))}
                </tr>
                {filteredMasterData?.map(
                  (_item: IPlanningReport, _itemIdx: number) => {
                    return (
                      <tr key={_itemIdx}>
                        <td>{_itemIdx + 1}</td>
                        {PlanningReportKeys?.map((keydata) => {
                          if (keydata.type === "date") {
                            return (
                              <td>
                                {moment(_item[keydata.key]).format(
                                  "DD/MM/yyyy"
                                )}
                              </td>
                            );
                          } else {
                            return <td>{_item[keydata.key] ?? ""}</td>;
                          }
                        })}
                      </tr>
                    );
                  }
                )}
                {(isNullUndefined(filteredMasterData) ||
                  filteredMasterData?.length <= 0) && (
                  <tr>
                    <td colSpan={PlanningReportKeys?.length + 1} align="center">
                      No data to display!!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockPlanningViewComponent;
