import { useContext, useEffect, useState } from "react";
import { ProductService } from "../../api/services/product.service";
import { AppContext } from "../contexts/app.context";
import {
  IListConfig,
  IListConfigProduct,
  IProductContext,
  IProducts,
  IStatusUpdate,
} from "../../common/models";
import {
  EmptyListConfig,
  EmptyListConfigProduct,
  EmptyStatusUpdate,
} from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { GenericHelperService } from "../../api/generics/helper.service";
import { MasterService } from "../../api/services/master.service";
import { ILogsData, ILogsProps } from "../../common/models/logs";

const useProduct = () => {
  const productService: ProductService = new ProductService();
  const masterService: MasterService = new MasterService();
  const helperService: GenericHelperService = new GenericHelperService();
  const { setLoader, refreshData } = useContext(AppContext);
  const [masterProductList, setMasterProductList] = useState<IProducts[]>([]);
  const [filteredMasterProductList, setFilteredMasterProductList] = useState<
    IProducts[]
  >([]);
  const [productList, setProductList] = useState<IProducts[]>([]);
  const [productForm, setProductForm] = useState<IProducts>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [productfilters, setProductFilters] = useState<IListConfigProduct>(
    EmptyListConfigProduct
  );
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [productLogs, setProductLogs] = useState<ILogsData[]>([]);

  const getLogsProduct = async (id: number) => {
    const _logs: ILogsProps[] = await masterService.getLogs(`PROD${id}`);
    setProductLogs(_logs);
  };

  const getProductsList = async () => {
    const _products = await productService.getProducts();
    updateFilters(_products);
    setMasterProductList(_products ?? []);
  };

  const updateFilters = (data: IProducts[]) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = data?.length;
    _filters.totalPageCount = Math.ceil(
      data?.length / _filters.currentPageSize
    );
    // _filters.sortItem = "modifiedDate";
    // _filters.sortDirection = "desc";
    // _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const initData = () => {
    getProductsList();
    setProductForm(null);
    setSelectedList(EmptyStatusUpdate);
  };

  useEffect(() => {
    setLoader(true);
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const indexOfLastItem = filters.currentPage * filters.currentPageSize;
    const indexOfFirstItem = indexOfLastItem - filters.currentPageSize;
    let _filteredList: IProducts[];

    if (productfilters?.searchLocally) {
      _filteredList = cloneDeep(masterProductList);
    } else {
      _filteredList = cloneDeep(filteredMasterProductList);
    }

    _filteredList = _filteredList.filter((_sup: IProducts, _supIdx: number) => {
      return _sup.productName
        .toLowerCase()
        .includes(filters.searchQuery.toLowerCase());
    });
    _filteredList.sort((a, b) =>
      helperService.sort(
        filters.sortDirection === "asc" ? a : b,
        filters.sortDirection === "asc" ? b : a,
        filters.sortItem,
        filters.sortIsDate
      )
    );
    console.log("Filtered Sorted list - ", _filteredList);
    setProductList(
      _filteredList.slice(indexOfFirstItem, indexOfLastItem) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, masterProductList, filteredMasterProductList]);

  useEffect(() => {
    if (productfilters.search) {
      queryProductsList();
    }
  }, [productfilters?.search]);

  const queryProductsList = async (transporters: IProducts[] = null) => {
    let _filteredList: IProducts[] = await productService.searchProducts(
      productfilters
    );
    updateFilters(_filteredList);
    setFilteredMasterProductList(_filteredList ?? []);
  };

  const resetFilterWithMastersData = () => {
    updateFilters(masterProductList);
  };

  const saveProduct = async () => {
    const _productSave: boolean = await productService.saveProduct(productForm);
    if (_productSave) {
      if (typeof _productSave == "string") {
        toast.error(_productSave);
      } else {
        toast.success("Product saved successfully.");
        initData();
      }
    } else {
      toast.error("Error saving product.");
    }
  };

  const updateStatus = async () => {
    const _productSave: boolean = await productService.updateStatus(
      selectedList
    );
    if (_productSave) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };

  const deleteProduct = async () => {
    const _deleteProduct: IProducts = cloneDeep(productForm);
    _deleteProduct.deleted = true;
    const _productSave: boolean = await productService.saveProduct(
      _deleteProduct
    );
    if (_productSave) {
      toast.success("Product deleted successfully.");
      initData();
    } else {
      toast.error("Error deleting product.");
    }
  };
  const saveDosageForm = async (listOption: string) => {
    const _productSave: boolean = await masterService.saveDosageForms(
      listOption
    );
    if (_productSave) {
      refreshData();
      setProductForm((prod) => {
        return { ...prod, dosageForm: listOption.toUpperCase() };
      });
      toast.success("Dosage Form added successfully.");
    } else {
      toast.error("Error adding Dosage Form.");
    }
  };
  const saveProductCategories = async (listOption: string) => {
    const _productSave: boolean = await masterService.saveProductCategories(
      listOption
    );
    if (_productSave) {
      refreshData();
      setProductForm((prod) => {
        return { ...prod, prodCategory: listOption.toUpperCase() };
      });
      toast.success("Category added successfully.");
    } else {
      toast.error("Error adding Category.");
    }
  };

  return {
    productList,
    filters,
    productForm,
    selectedList,
    setSelectedList,
    setFilters,
    setProductForm,
    saveProduct,
    deleteProduct,
    updateStatus,
    saveDosageForm,
    saveProductCategories,
    resetFilterWithMastersData,
    productfilters,
    setProductFilters,
    getLogsProduct,
    productLogs,
  } as IProductContext;
};

export default useProduct;
