import React from "react";
import { BatchMangementContext } from "../../controllers/contexts/batchmangement.context";
import useBatchmanagement from "../../controllers/hooks/useBatchmanagement";
import { Outlet } from "react-router-dom";

const BatchManagementLayoutComponent = () => {
    const batchManagementFunctions = useBatchmanagement();

    return (
        <BatchMangementContext.Provider value={batchManagementFunctions}>
            <Outlet />
        </BatchMangementContext.Provider>
    );
}

export default BatchManagementLayoutComponent;
