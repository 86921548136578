import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts/app.context";
import { ICustomer } from "../../common/models/customer";
import {
  ICustomerContext,
  IDocuments,
  IListConfig,
  IListConfigCustomer,
  IStatusUpdate,
} from "../../common/models";
import {
  DocumentUploadKeys,
  EmptyListConfig,
  EmptyListConfigCustomer,
  EmptyStatusUpdate,
  NewCustomer,
  NewPurchaseOrder,
} from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { CustomerService } from "../../api/services/customer.service";
import { GenericHelperService } from "../../api/generics/helper.service";
import { DocumentService } from "../../api/services/document.service";
import { MasterService } from "../../api/services/master.service";
import { ILogsData, ILogsProps } from "../../common/models/logs";
//import moment from 'moment';

const useCustomer = () => {
  const customerService: CustomerService = new CustomerService();
  const masterService: MasterService = new MasterService();
  const documentService: DocumentService = new DocumentService();
  const helperService: GenericHelperService = new GenericHelperService();
  const { setLoader, refreshData } = useContext(AppContext);
  const [masterCustomerList, setMasterCustomerList] = useState<ICustomer[]>([]);
  const [filteredMasterCustomerList, setFilteredMasterCustomerList] = useState<
    ICustomer[]
  >([]);
  const [customerList, setCustomerList] = useState<ICustomer[]>([]);
  const [customerForm, setCustomerForm] = useState<ICustomer>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [customerfilters, setCustomerFilters] = useState<IListConfigCustomer>(
    EmptyListConfigCustomer
  );
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [viewFiles, setViewFiles] = useState<IDocuments[]>([]);
  const [customerLogs, setCustomerLogs] = useState<ILogsData[]>([]);


  const getCustomerList = async () => {
    const _customer = await customerService.getCustomer();
    updateFilters(_customer);
    setMasterCustomerList(_customer ?? []);
  };
  const getLogsCustomer = async (id: number) => {
    const _logs: ILogsProps[] = await masterService.getLogs(`CUST${id}`);
    setCustomerLogs(_logs);
}

  const updateFilters = (data: ICustomer[]) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = data?.length;
    _filters.totalPageCount = Math.ceil(
      data?.length / _filters.currentPageSize
    );
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const initData = () => {
    getCustomerList();
    setCustomerForm(null);
    setSelectedList(EmptyStatusUpdate);
    setUploadedFiles([]);
  };

  useEffect(() => {
    setLoader(true);
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const indexOfLastItem = filters.currentPage * filters.currentPageSize;
    const indexOfFirstItem = indexOfLastItem - filters.currentPageSize;
    let _filteredList: ICustomer[];

    if (customerfilters?.searchLocally) {
      _filteredList = cloneDeep(masterCustomerList ?? []);
    } else {
      _filteredList = cloneDeep(filteredMasterCustomerList);
    }

    _filteredList = _filteredList.filter((_sup: ICustomer, _supIdx: number) => {
      return _sup.custName
        .toLowerCase()
        .includes(filters.searchQuery.toLowerCase());
    });
    _filteredList.sort((a, b) =>
      helperService.sort(
        filters.sortDirection === "asc" ? a : b,
        filters.sortDirection === "asc" ? b : a,
        filters.sortItem,
        filters.sortIsDate
      )
    );
    console.log("Filtered Sorted list - ", _filteredList);
    setCustomerList(
      _filteredList.slice(indexOfFirstItem, indexOfLastItem) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, masterCustomerList, filteredMasterCustomerList]);

  useEffect(() => {
    if (customerfilters.search) {
      queryProductsList();
    }
  }, [customerfilters?.search]);

  const queryProductsList = async (transporters: ICustomer[] = null) => {
    let _filteredList: ICustomer[] = await customerService.searchCustomers(
      customerfilters
    );
    updateFilters(_filteredList);
    setFilteredMasterCustomerList(_filteredList ?? []);
  };

  const resetFilterWithMastersData = () => {
    updateFilters(masterCustomerList);
  };

  const viewCustomer = async (item: ICustomer) => {
    setCustomerForm(item);
    const _documents: IDocuments[] = await documentService.getFiles(
      `${DocumentUploadKeys.Customer}_${item?.custId}`
    );
    setViewFiles(_documents);
  };

  const clearViewCustomer = () => {
    setCustomerForm(null);
    setViewFiles([]);
  };

  const saveCustomer = async () => {
    const _customerSave = await customerService.saveCustomer(customerForm);
    if (_customerSave) {
      if (typeof _customerSave == "string") {
        toast.error(_customerSave);
      } else {
        await uploadFiles(_customerSave);
        await deleteFiles();
        toast.success("Customer saved successfully.");
        initData();
      }
    } else {
      toast.error("Error saving customer.");
    }
  };

  const newCustomer = () => {
    setCustomerForm(cloneDeep(NewCustomer));
  };

  const updateStatus = async () => {
    const _updateStatus: boolean = await customerService.updateStatus(
      selectedList
    );
    if (_updateStatus) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };

  const deleteCustomer = async () => {
    const _deleteItem: ICustomer = cloneDeep(customerForm);
    _deleteItem.deleted = true;

    const _itemSave = await customerService.saveCustomer(_deleteItem);
    if (_itemSave) {
      toast.success("Customer deleted successfully.");
      initData();
    } else {
      toast.error("Error deleting customer.");
    }
  };

  const uploadFiles = async (itemId: any) => {
    console.log("itemId", itemId);
    if (uploadedFiles.length > 0) {
      const _userId: string = `${DocumentUploadKeys.Customer}_${
        itemId?.data ? itemId?.data : itemId
      }`;
      await documentService.uploadFiles(uploadedFiles, _userId);
    }
    return true;
  };

  const deleteFiles = async () => {
    const _deleteFiles: IDocuments[] = viewFiles.filter((_doc) =>
      _doc.deleted !== undefined ? _doc.deleted : false
    );
    for (let fileIdx = 0; fileIdx < _deleteFiles.length; fileIdx++) {
      const delFiles = _deleteFiles[fileIdx];
      await documentService.deleteFiles(delFiles.documentId);
    }
    return true;
  };

  const saveProductCategories = async (listOption: string) => {
    const _productSave: boolean = await masterService.saveProductCategories(
      listOption
    );
    if (_productSave) {
      refreshData();
      setCustomerForm((prod) => {
        return {
          ...prod,
          prodCategory: `${prod.prodCategory},${listOption.toUpperCase()}`,
        };
      });
      toast.success("Category added successfully.");
    } else {
      toast.error("Error adding Category.");
    }
  };

  return {
    customerList,
    filters,
    viewFiles,
    customerForm,
    selectedList,
    setSelectedList,
    setFilters,
    setCustomerForm,
    newCustomer,
    saveCustomer,
    updateStatus,
    deleteCustomer,
    uploadedFiles,
    setViewFiles,
    setUploadedFiles,
    viewCustomer,
    clearViewCustomer,
    saveProductCategories,
    resetFilterWithMastersData,
    customerfilters,
    setCustomerFilters,
    getLogsCustomer,
    customerLogs
  } as ICustomerContext;
};

export default useCustomer;
