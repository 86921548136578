import { useContext, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import {
  EmptyStatusUpdate,
  NewProduct,
  ProductStatuses,
} from "../../../common/constants/generalConstants";
import { ProductContext } from "../../../controllers/contexts/product.context";
import { NavLink, useNavigate } from "react-router-dom";
import {
  IListConfig,
  IListConfigProduct,
  IProducts,
  IStatusUpdate,
} from "../../../common/models";
import { cloneDeep } from "lodash";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import PageSortDescription from "../../common/pageSortDescription.component";
import ReactPaginate from "react-paginate";
import { GenericHelperService } from "../../../api/generics/helper.service";
import ProductStatusUpdateComponent from "./productStatusUpdate.component";
import DeleteConfirmationComponent from "../../common/deleteConfirmation.component";
import { AppContext } from "../../../controllers/contexts/app.context";
import { getNumberForString } from "../../../common/methods/globalmethods";
import moment from "moment";
import useAuth from "../../../controllers/hooks/useAuth";
import LogsModalComponent from "../../common/logsModal.component";

const ProductComponent = () => {
  const {
    productList,
    filters,
    selectedList,
    setSelectedList,
    productForm,
    setFilters,
    setProductForm,
    deleteProduct,
    productfilters,
    setProductFilters,
    resetFilterWithMastersData,
    productLogs,
    getLogsProduct,
  } = useContext(ProductContext);
  const { isAccessDisabled } = useAuth();
  const { ProductCategories } = useContext(AppContext);
  const helperService: GenericHelperService = new GenericHelperService();
  const navigate = useNavigate();
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const handleShowLogsModal = (_prod: IProducts) => {
    getLogsProduct(_prod.productId);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };

  const handleQueryChange = (type: string, value: any) => {
    const _productfilters: IListConfigProduct = cloneDeep(productfilters);
    _productfilters[type] = value;
    if (type !== "search") {
      _productfilters.search = false;
      _productfilters.searchLocally = false;
    }
    setProductFilters(_productfilters);
  };

  const clearSearch = () => {
    const _productfilters: IListConfigProduct = cloneDeep(productfilters);
    _productfilters.searchLocally = true;
    _productfilters.status = [];
    _productfilters.productCode = null;
    _productfilters.productName = "";
    setProductFilters(_productfilters);
    resetFilterWithMastersData();
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      productfilters?.productName === "" &&
      productfilters?.status?.length <= 0 &&
      (productfilters?.productCode === null || productfilters.productCode <= 0)
    ) {
      result = true;
    }
    return result;
  };

  const cancelDelete = () => {
    setProductForm(null);
  };

  const confirmDelete = () => {
    deleteProduct();
  };

  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setFilters({ ...filters, searchQuery: _searchQuery });
  };

  const setUpdateStatus = (prod: IProducts) => {
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.productId];
    _status.status = prod.status;
    setSelectedList(_status);
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
      _status.ids = productList.map((_prd) => _prd.productId);
      setSelectedList(_status);
    } else {
      setSelectedList(cloneDeep(EmptyStatusUpdate));
    }
  };

  const handleSelectItem = (item: IProducts, event: any) => {
    const _status: IStatusUpdate = cloneDeep(selectedList);
    if (event.target.checked) {
      _status.ids.push(item.productId);
    } else {
      const _itmIdx = _status.ids.findIndex((_itm) => _itm === item.productId);
      if (_itmIdx > -1) {
        _status.ids.splice(_itmIdx, 1);
      }
    }
    setSelectedList(_status);
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.Products}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.Master}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.Products}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-10 d-flex">
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="Product Code"
                        value={productfilters?.productCode ?? ""}
                        onChange={(event) =>
                          handleQueryChange("productCode", event.target.value)
                        }
                      />
                    </div>
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="Product Name"
                        value={productfilters?.productName}
                        onChange={(event) =>
                          handleQueryChange("productName", event.target.value)
                        }
                      />
                    </div>

                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={productfilters?.status}
                        onChange={(e) =>
                          handleQueryChange("status", [e.target.value])
                        }
                      >
                        <option value={""}>Status</option>
                        {ProductStatuses.map(
                          (_statusType: any, _statusTypeIdx: number) => {
                            return (
                              <option
                                key={`Status${_statusTypeIdx}`}
                                value={_statusType}
                              >
                                {_statusType}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="input-group w-p10 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success rounded"
                        disabled={checkIfSearchStringsAreEmpty()}
                        onClick={() => {
                          handleQueryChange("search", true);
                        }}
                      >
                        {LabelConstants.Search}
                      </button>
                    </div>
                    <div className="input-group w-p50 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success-light rounded"
                        onClick={() => {
                          clearSearch();
                        }}
                      >
                        {LabelConstants.Clear}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-2 d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isAccessDisabled("MD", "create", true, "PROD")}
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        setProductForm(cloneDeep(NewProduct));
                        navigate(RoutingConstants.productCreate);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      {`${LabelConstants.Create}  ${LabelConstants.Products}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-hover table-bordered mb-0">
                    <thead className="table-primary">
                      <tr>
                        {/* <th>
                                                <input type="checkbox" id={`md_checkbox_all`} className="chk-col-primary" checked={productList?.length > 0 && selectedList?.ids.length === productList?.length} onChange={(event) => handleSelectAll(event)} />
                                                <label htmlFor={`md_checkbox_all`}></label>
                                            </th> */}
                        {/* <th onClick={() => handleSort("productId")}>
                          {LabelConstants.ID}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "productId"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th> */}
                        <th onClick={() => handleSort("productCode")}>
                          {LabelConstants.productCode}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "productCode"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("productName")}>
                          {LabelConstants.Name}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "productName"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("prodLicenseNo")}>
                          {LabelConstants.ProductLicenseNo}.
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "prodLicenseNo"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("prodManufacturer")}>
                          {LabelConstants.Manufacturer}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "prodManufacturer"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("prodCategory")}>
                          {LabelConstants.Category}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "prodCategory"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("modifiedDate")}>
                          {LabelConstants.ModifiedDate}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "modifiedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("status")}>
                          {LabelConstants.Status}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "status"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productList &&
                        productList.map((_prod, _prodIdx) => {
                          return (
                            <tr>
                              {/* <td>
                                                    <input type="checkbox" id={`md_checkbox_${_prodIdx}`} className="chk-col-primary" checked={selectedList?.ids.findIndex(_itm => _itm === _prod.productId) >= 0} onChange={(event) => handleSelectItem(_prod, event)} />
                                                    <label htmlFor={`md_checkbox_${_prodIdx}`}></label>
                                                </td> */}
                              {/* <td>{_prod.productId}</td> */}
                              <td>{_prod.productCode}</td>
                              <td>{_prod.productName}</td>
                              <td>{_prod.prodLicenseNo}</td>
                              <td>{_prod.prodManufacturer}</td>
                              <td>
                                {
                                  ProductCategories.find(
                                    (item) =>
                                      item?.categoryId ===
                                      getNumberForString(_prod.prodCategory)
                                  )?.categoryName
                                }
                              </td>
                              <td>
                                {moment(_prod.modifiedDate).format(
                                  "DD/MM/yyyy"
                                )}
                              </td>
                              <td>
                                <span
                                  role="button"
                                  style={
                                    isAccessDisabled(
                                      "MD",
                                      "update_status",
                                      true,
                                      "PROD"
                                    )
                                      ? { pointerEvents: "none", opacity: 0.5 }
                                      : {}
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#updateStatus"
                                  onClick={() => setUpdateStatus(_prod)}
                                  className={`badge bg-${helperService.getStatusColor(
                                    _prod.status
                                  )}-light rounded-pill`}
                                >
                                  {_prod.status}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-start">
                                  <button
                                    type="button"
                                    disabled={isAccessDisabled(
                                      "MD",
                                      "view",
                                      true,
                                      "PROD"
                                    )}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    onClick={() => {
                                      setProductForm(cloneDeep(_prod));
                                      navigate(RoutingConstants.productDetails);
                                    }}
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    disabled={isAccessDisabled(
                                      "MD",
                                      "edit",
                                      true,
                                      "PROD"
                                    )}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    onClick={() => {
                                      setProductForm(cloneDeep(_prod));
                                      navigate(RoutingConstants.productEdit);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </button>

                                  <button
                                    type="button"
                                    disabled={isAccessDisabled(
                                      "MD",
                                      "delete",
                                      true,
                                      "PROD"
                                    )}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setProductForm(cloneDeep(_prod));
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmation"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setcreatedDate(_prod?.createdDate);
                                      setcreatedBy(_prod?.createdBy);
                                      handleShowLogsModal(_prod);
                                    }}
                                  >
                                    <i
                                      className="fa fa-book"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filters}
                      dataList={productList}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {filters.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          forcePage={filters?.currentPage - 1}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            filters && filters.totalPageCount < 2
                              ? 0
                              : filters.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setFilters({
                              ...filters,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductStatusUpdateComponent />
      <DeleteConfirmationComponent
        title={productForm?.productName ?? ""}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={productLogs}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default ProductComponent;
