import { useEffect, useState } from "react";
import {
  ICustomer,
  IListConfig,
  IListConfigSO,
  IProducts,
  IResult,
  ISalesOrderContext,
  IStatusUpdate,
} from "../../common/models";
import {
  ISODetails,
  ISODetailsSeverFormat,
  ISalesOrder,
  ISalesOrdreServerFormat,
  StockStatus,
} from "../../common/models/salesorder";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { SalesOrderService } from "../../api/services/SalesOrderService.service";
import {
  EmptyListConfig,
  EmptyListConfigSO,
  EmptyStatusUpdate,
  INVENTORY_STATUS,
  NewSODetailsServerFormat,
  NewSalesOrder,
  NewSalesOrderServer,
} from "../../common/constants/generalConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { ProductService } from "../../api/services/product.service";
import _ from "lodash";
import { IProductCategory } from "../../common/models/master";
import { MasterService } from "../../api/services/master.service";
import { CustomerService } from "../../api/services/customer.service";
import { useNavigate } from "react-router-dom";
import { GenericHelperService } from "../../api/generics/helper.service";
import {
  getNumberForString,
  isNullUndefined,
} from "../../common/methods/globalmethods";
import { ILogsData, ILogsProps } from "../../common/models/logs";
import { SamplingService } from "../../api/services/sampling.service";

const useSalesOrder = () => {
  const salesOrderService: SalesOrderService = new SalesOrderService();
  const productService: ProductService = new ProductService();
  const masterService: MasterService = new MasterService();
  const samplingService: SamplingService = new SamplingService();
  const customerService: CustomerService = new CustomerService();
  const navigate = useNavigate();

  const [SOForm, setSalesOrderForm] = useState<ISalesOrder>(null);
  const [nextSONumber, setNextSONumber] = useState(null);
  const [productsList, setProductsList] = useState<IProducts[]>([]);
  const [productCateList, setProductCateList] = useState<IProductCategory[]>(
    []
  );
  const [customersList, setCustomersList] = useState<ICustomer[]>([]);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [salesOrderList, setSalesOrderList] = useState<ISalesOrder[]>([]);
  const [sofilters, setSOFilters] = useState<IListConfigSO>(EmptyListConfigSO);

  const [salesOrderLogs, setSalesOrderLogs] = useState<ILogsData[]>([]);

  const getSalesOrderLogs = async (id: number) => {
    const _logs: ILogsProps[] = await samplingService.getLogs(`SO${id}`);
    setSalesOrderLogs(_logs);
  };
  useEffect(() => {
    setSalesOrderForm(null);
    setSelectedList(EmptyStatusUpdate);
    initData();
  }, []);

  useEffect(() => {
    if (sofilters?.search) {
      querySOList();
    }
  }, [sofilters?.search]);

  useEffect(() => {
    if (sofilters?.search === true) {
      querySOList();
    } else if (sofilters?.search === false) {
      getSalesOrderList();
    }
  }, [sofilters?.search, filters.currentPage]);

  const querySOList = async () => {
    let tempSOfilter: IListConfigSO = removeUnrequiredPOAttributes();
    if (Object.entries(tempSOfilter)?.length > 0) {
      let result = await salesOrderService.searchSOList(
        tempSOfilter,
        filters?.currentPage - 1
      );
      let _filteredList: ISalesOrder[] = result?.content ?? [];
      updateFilters(_filteredList, result);
      setSalesOrderList(_filteredList ?? []);
    }
  };

  const removeUnrequiredPOAttributes = () => {
    let tempPOfilter: IListConfigSO = cloneDeep(sofilters);
    if (!isNullUndefined(tempPOfilter)) {
      for (const [key, value] of Object.entries(tempPOfilter)) {
        if (typeof key === "string" && _.isEmpty(value)) {
          delete tempPOfilter[key];
        } else if (typeof key === "boolean") {
          delete tempPOfilter[key];
        } else if (typeof key === "number" && value <= 0) {
          delete tempPOfilter[key];
        }
      }
    }
    return tempPOfilter;
  };

  const initData = () => {
    getProductsList();
    getProductCategoriesList();
    getCustomersList();
    getSalesOrderList();
  };

  const getSalesOrderList = async () => {
    const result = await salesOrderService.getSalesList(
      filters?.currentPage - 1
    );
    let _customer = result?.content ?? [];
    updateFilters(_customer, result);
    setSalesOrderList(_customer ?? []);
  };

  const updateFilters = (data: ISalesOrder[], result: IResult) => {
    const _filters: IListConfig = cloneDeep(filters);
    _filters.searchQuery = "";
    _filters.totalRecords = result?.totalElements ?? data.length;
    _filters.totalPageCount =
      result?.totalPages ?? Math.ceil(data.length / _filters.currentPageSize);
    _filters.sortItem = "modifiedDate";
    _filters.sortDirection = "desc";
    _filters.sortIsDate = true;
    setFilters(_filters);
  };

  const getProductsList = async () => {
    const _products = await productService.getProducts();
    _products?.map((item) => {
      item.selected = false;
    });
    let _filteredProducts = _.filter(_products, { status: "ACTIVE" });
    setProductsList(_filteredProducts ?? []);
    return _filteredProducts;
  };

  const getProductCategoriesList = async () => {
    const _productCateList = await masterService.getProductCategories();
    setProductCateList(_productCateList ?? []);
  };

  const getCustomersList = async () => {
    const _customers = await customerService.getCustomer();
    let _filteredCustomers = _.filter(_customers, { status: "ACTIVE" });
    _filteredCustomers?.map((item: ICustomer) => {
      item.value = item.custId;
      item.label = item.custName;
    });
    setCustomersList(_filteredCustomers ?? []);
  };

  const viewSalesOrder = async (
    item: ISalesOrder,
    callback: Function
  ): Promise<void> => {
    item?.soProductDetails?.map(async (product, index) => {
      product.productAmount = product?.unitPrice * product?.quantity;
      await checkStockOnHand(product?.prodId, product?.quantity).then(
        (status) => {
          product.status = status;
          if (index === item?.soProductDetails?.length - 1) {
            setSalesOrderForm(item);
            callback();
          }
        }
      );
    });
  };

  const clearSalerOrder = () => {
    setSalesOrderForm(null);
  };

  const newSalesOrderForm = () => {
    setSalesOrderForm(cloneDeep(NewSalesOrder));
  };

  const getNextSONumber = async () => {
    const _soNo = await salesOrderService.getNextSoNumber();
    if (_soNo) {
      setNextSONumber(_soNo);
    } else {
      toast.error("Error getting next Sales Order number");
    }
  };
  const clearSoNumber = () => {
    setNextSONumber(null);
  };

  const createNewSalesOrder = async (_newSalesOrder: ISalesOrder) => {
    let soServerFormat: ISalesOrdreServerFormat =
      formatSaleOrdreInServerFormat(_newSalesOrder);
    const _soSave = await salesOrderService.createNewSalesOrder(soServerFormat);
    if (_soSave) {
      if (typeof _soSave == "string") {
        toast.error(_soSave);
      } else {
        toast.success("Sales order saved successfully.");
        initData();
        navigate(RoutingConstants.sales_order);
      }
    } else {
      toast.error("Error saving sales order.");
    }
  };

  const formatSaleOrdreInServerFormat = (salesOrder: ISalesOrder) => {
    let soServerFormat: ISalesOrdreServerFormat =
      cloneDeep(NewSalesOrderServer);
    var dateoptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    soServerFormat.customerId = getNumberForString(salesOrder?.customerId);
    soServerFormat.customerName = salesOrder?.customerName;
    soServerFormat.soNum = salesOrder?.soNum + "";
    soServerFormat.soCreatedDate = new Date(
      salesOrder?.soCreatedDate
    )?.toLocaleDateString("en-CA");
    soServerFormat.deliveryDate = new Date(
      salesOrder?.deliveryDate
    )?.toLocaleDateString("en-CA");
    soServerFormat.customerRefNum = salesOrder?.customerRefNum;
    soServerFormat.deliveryAddress = salesOrder?.deliveryAddress + "test";
    soServerFormat.addressId = salesOrder?.addressId;
    soServerFormat.carrierChargeAmount = salesOrder?.carrierChargeAmount;
    soServerFormat.status = salesOrder?.status;
    soServerFormat.reason = salesOrder?.reason;
    salesOrder?.soProductDetails?.map((item: ISODetails, index: number) => {
      let soProductDetails: ISODetailsSeverFormat = cloneDeep(
        NewSODetailsServerFormat
      );
      soProductDetails.prodId = parseInt(item?.prodId);
      soProductDetails.prodCatId = parseInt(item?.prodCatId);
      soProductDetails.quantity = item?.quantity;
      soProductDetails.unitPrice = item?.unitPrice;
      soProductDetails.vat = item?.vat;
      soServerFormat.soProductDetails.push(soProductDetails);
    });
    return soServerFormat;
  };

  const saveSalesOrder = async (_salesOrder: ISalesOrder) => {
    const _customerSave = await salesOrderService.saveSalesData(_salesOrder);
    if (_customerSave && typeof _customerSave == "string") {
      toast.success("Sales order saved successfully.");
      initData();
    } else {
      toast.error(_customerSave?.errorMessage ?? "Error saving Sales order.");
    }
  };

  const deleteSalesOrderItem = async () => {
    const _deleteItem: ISalesOrder = cloneDeep(SOForm);
    _deleteItem.isDeleted = true;
    console.log("deleteSalesOrderItem - ", _deleteItem?.soNum);
    const _itemSave = await salesOrderService.saveSalesData(_deleteItem);
    if (_itemSave && typeof _itemSave == "string") {
      toast.success("Sales order deleted successfully.");
      initData();
    } else {
      toast.error(_itemSave?.errorMessage ?? "Error deleting sales order.");
    }
  };

  const updateStatus = async () => {
    const _updateStatus: boolean = await salesOrderService.updateStatus(
      selectedList
    );
    console.log("SO STATUS - ", _updateStatus);
    if (_updateStatus) {
      if (typeof _updateStatus == "string") {
        toast.error(_updateStatus);
      } else {
        toast.success("Status updated successfully.");
        initData();
      }
    } else {
      toast.error("Error updating status.");
    }
  };

  const checkStockOnHand = async (productId: string, quantity: number) => {
    const result: StockStatus = await salesOrderService.getStockInHand(
      productId,
      quantity
    );
    console.log("checkStockOnHand - ", result?.status);
    return result?.status;
  };

  const getStockStatusColor = (status: string) => {
    let color = "white";
    switch (status) {
      case INVENTORY_STATUS.IN_STOCK:
        color = "green";
        break;
      case INVENTORY_STATUS.LOW_INVENTORY:
        color = "orange";
        break;
      case INVENTORY_STATUS.OUT_OF_STOCK:
        color = "red";
        break;
    }
    return color;
  };

  return {
    setSalesOrderForm,
    viewSalesOrder,
    clearSalerOrder,
    nextSONumber,
    getNextSONumber,
    clearSoNumber,
    SOForm,
    newSalesOrderForm,
    createNewSalesOrder,
    productsList,
    productCateList,
    customersList,
    getProductsList,
    salesOrderList,
    filters,
    selectedList,
    setSelectedList,
    setFilters,
    deleteSalesOrderItem,
    saveSalesOrder,
    updateStatus,
    checkStockOnHand,
    getStockStatusColor,
    sofilters,
    setSOFilters,
    salesOrderLogs,
    getSalesOrderLogs,
    querySOList,
  } as ISalesOrderContext;
};

export default useSalesOrder;
