import { Outlet } from "react-router-dom";
import useReports from "../../controllers/hooks/useReports";
import { ReportsContext } from "../../controllers/contexts/reports.context";

const ReportsLayoutComponent = () => {
  const ReportFunctions = useReports();

  return (
    <ReportsContext.Provider value={ReportFunctions}>
      <Outlet />
    </ReportsContext.Provider>
  );
};

export default ReportsLayoutComponent;
