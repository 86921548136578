import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { cloneDeep } from "lodash";
import {
  IWarehouse,
  IWarehouseAddressContact,
  WarehouseFormErrors,
} from "../../common/models";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import validator from "../../common/models/validator";
import _ from "lodash";
import {
  NewCustomerAddressContact,
  WarehouseScopes,
  WarehouseStatuses,
  WarehouseTypes,
  validateEmailRegex,
} from "../../common/constants/generalConstants";

const VALIDATOR = {
  clientName: [(value) => (_.isEmpty(value) ? `Enter Client Name` : null)],
  warehouseName: [
    (value) => (_.isEmpty(value) ? `Enter Warehouse Name` : null),
  ],
  warehouseType: [
    (value) => (_.isEmpty(value) ? `Enter Warehouse Type` : null),
  ],
  warehouseScope: [
    (value) => (_.isEmpty(value) ? `Enter Warehouse Scope` : null),
  ],
  description: [(value) => (_.isEmpty(value) ? `Enter Description` : null)],
  status: [(value) => (_.isEmpty(value) ? `Select status` : null)],
};

const WarehouseFormComponent = () => {
  const { warehouseForm, setWarehouseForm, saveWarehouse } =
    useContext(WarehouseContext);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [error, setError] = useState<WarehouseFormErrors>({});
  const [addError, setAddError] = useState(false);

  useEffect(() => {
    if (warehouseForm === null) {
      console.log(pathname);
      navigate(
        [
          RoutingConstants.warehouseCreate,
          RoutingConstants.warehouseEdit,
        ].indexOf(pathname) > -1
          ? RoutingConstants.warehouse
          : RoutingConstants.warehousel2l
      );
    }
  }, [warehouseForm, navigate]);

  const handleWarehouseForm = (type: string, value: any) => {
    const _form = cloneDeep(warehouseForm);
    _form[type] = value;
    setWarehouseForm(_form);
  };

  const handlewareHouseAddressForm = (type: string, value: any) => {
    const _form = cloneDeep(warehouseForm);
    _form["wareHouseAddress"][type] = value;
    setWarehouseForm(_form);
  };

  const handlewareHouseAddressContactForm = (
    idx: number,
    type: string,
    value: any
  ) => {
    const _form = cloneDeep(warehouseForm);
    _form["wareHouseAddress"]["warehouseCom"][idx][type] = value;
    setWarehouseForm(_form);
  };

  const handleCustomerAddressContactPhone = (idx: number, value: any) => {
    const _form: IWarehouse = cloneDeep(warehouseForm);
    let _destCode: string = "";
    let _phoneNum: string = "";
    if (value !== "") {
      if (value.length > 4) {
        const { nationalNumber, countryCallingCode } = parsePhoneNumber(value);
        _destCode = "+" + countryCallingCode;
        _phoneNum = nationalNumber;
      } else {
        _destCode = value;
        _phoneNum = "";
      }
    }
    _form["wareHouseAddress"]["warehouseCom"][idx].designationCountryCode =
      _destCode;
    _form["wareHouseAddress"]["warehouseCom"][idx].phoneNumber = _phoneNum;
    setWarehouseForm(_form);
  };

  const checkForm = () => {
    let obj = {
      clientName: warehouseForm?.clientName,
      warehouseName: warehouseForm?.warehouseName,
      warehouseType: warehouseForm?.warehouseType,
      warehouseScope: warehouseForm?.warehouseScope,
      description: warehouseForm?.description,
      status: warehouseForm?.status,
    };
    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);

    if (_.isEmpty(isErrors)) {
      const _add = warehouseForm?.wareHouseAddress;
      if (
        _add.address1 !== "" &&
        _add.address2 !== "" &&
        _add.city !== "" &&
        _add.county !== "" &&
        _add.country !== "" &&
        _add.postCode !== ""
      ) {
        for (
          let _addContIdx = 0;
          _addContIdx < _add.warehouseCom.length;
          _addContIdx++
        ) {
          const _addCont = _add.warehouseCom[_addContIdx];
          if (
            _addCont.contactName !== "" &&
            _addCont.designationCountryCode !== "" &&
            _addCont.phoneNumber !== "" &&
            _addCont.phoneNumber.length === 10 &&
            _addCont.email !== "" &&
            validateEmailRegex(_addCont.email)
          ) {
            saveWarehouse();
          } else {
            setAddError(true);
          }
        }
      } else {
        setAddError(true);
      }
    } else {
      setAddError(true);
    }
  };

  const handleClose = () => {
    setWarehouseForm(null);
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${warehouseForm?.custId ? LabelConstants.Edit : LabelConstants.Create} ${LabelConstants.Customer}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.warehouse}
                      title={LabelConstants.Warehouse}
                    >
                      {LabelConstants.Warehouse}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${
                    warehouseForm?.wareHouseId
                      ? LabelConstants.Edit
                      : LabelConstants.Create
                  } ${LabelConstants.Warehouse}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <h4 className="box-title">{LabelConstants.CustomerInfo}</h4>
                <div className="row">
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="warehouseName">
                      {LabelConstants.WarehouseName}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="warehouseName"
                      type="text"
                      className="form-control"
                      placeholder="Warehouse Name"
                      style={error?.warehouseName && { borderColor: "red" }}
                      value={warehouseForm?.warehouseName}
                      onChange={(e) =>
                        handleWarehouseForm("warehouseName", e.target.value)
                      }
                    />
                    {error?.warehouseName && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.warehouseName}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="clientName">
                      {LabelConstants.ClientName}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="clientName"
                      type="text"
                      className="form-control"
                      placeholder="Client Name"
                      style={error?.clientName && { borderColor: "red" }}
                      value={warehouseForm?.clientName}
                      onChange={(e) =>
                        handleWarehouseForm("clientName", e.target.value)
                      }
                    />
                    {error?.clientName && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.clientName}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="description">
                      {LabelConstants.Description}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="description"
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      style={error?.description && { borderColor: "red" }}
                      value={warehouseForm?.description}
                      onChange={(e) =>
                        handleWarehouseForm("description", e.target.value)
                      }
                    />
                    {error?.description && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.description}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label">
                      {LabelConstants.WarehouseType}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={warehouseForm?.warehouseType}
                      style={error?.warehouseType && { borderColor: "red" }}
                      onChange={(e) =>
                        handleWarehouseForm("warehouseType", e.target.value)
                      }
                    >
                      <option value={""} hidden>
                        Select Warehouse Type
                      </option>
                      {WarehouseTypes.map(
                        (_status: any, _statusIdx: number) => {
                          return (
                            <option
                              key={`warehouseTypeOption${_statusIdx}`}
                              value={_status.value}
                            >
                              {_status.text}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {error?.warehouseType && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.warehouseType}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label">
                      {LabelConstants.WarehouseScope}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={warehouseForm?.warehouseScope}
                      style={error?.warehouseScope && { borderColor: "red" }}
                      onChange={(e) =>
                        handleWarehouseForm("warehouseScope", e.target.value)
                      }
                    >
                      <option value={""} hidden>
                        Select Warehouse Scope
                      </option>
                      {WarehouseScopes.map(
                        (_status: any, _statusIdx: number) => {
                          return (
                            <option
                              key={`warehouseScopeOption${_statusIdx}`}
                              value={_status.value}
                            >
                              {_status.text}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {error?.warehouseScope && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.warehouseScope}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={warehouseForm?.status}
                      onChange={(e) =>
                        handleWarehouseForm("status", e.target.value)
                      }
                    >
                      {WarehouseStatuses.map(
                        (_status: string, _statusIdx: number) => {
                          return (
                            <option
                              key={`statusOption${_statusIdx}`}
                              value={_status}
                            >
                              {_status}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h4 className="box-title">
                    {LabelConstants.CustomerAddress}
                  </h4>
                </div>
                <div className="box box-bordered">
                  <div
                    className="box-body collapse show"
                    id={`collapseExample`}
                  >
                    <div className="row">
                      <div className="form-group col-6">
                        <label className="form-label">
                          {LabelConstants.Address1}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address 1"
                          value={warehouseForm?.wareHouseAddress?.address1}
                          onChange={(e) =>
                            handlewareHouseAddressForm(
                              "address1",
                              e.target.value
                            )
                          }
                        />
                        {addError &&
                          _.isEmpty(
                            warehouseForm?.wareHouseAddress?.address1
                          ) && (
                            <label className="form-label">
                              <span style={{ color: "red" }}>
                                {"Enter address1"}
                              </span>
                            </label>
                          )}
                      </div>
                      <div className="form-group col-6">
                        <label className="form-label">
                          {LabelConstants.Address2}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address 2"
                          value={warehouseForm?.wareHouseAddress?.address2}
                          onChange={(e) =>
                            handlewareHouseAddressForm(
                              "address2",
                              e.target.value
                            )
                          }
                        />
                        {addError &&
                          _.isEmpty(
                            warehouseForm?.wareHouseAddress?.address2
                          ) && (
                            <label className="form-label">
                              <span style={{ color: "red" }}>
                                {"Enter address2"}
                              </span>
                            </label>
                          )}
                      </div>
                      <div className="form-group col-6">
                        <label className="form-label">
                          {LabelConstants.City}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="City"
                          value={warehouseForm?.wareHouseAddress?.city}
                          onChange={(e) =>
                            handlewareHouseAddressForm("city", e.target.value)
                          }
                        />
                        {addError &&
                          _.isEmpty(warehouseForm?.wareHouseAddress?.city) && (
                            <label className="form-label">
                              <span style={{ color: "red" }}>
                                {"Enter city"}
                              </span>
                            </label>
                          )}
                      </div>
                      <div className="form-group col-6">
                        <label className="form-label">
                          {LabelConstants.County}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="County"
                          value={warehouseForm?.wareHouseAddress?.county}
                          onChange={(e) =>
                            handlewareHouseAddressForm("county", e.target.value)
                          }
                        />
                        {addError &&
                          _.isEmpty(
                            warehouseForm?.wareHouseAddress?.county
                          ) && (
                            <label className="form-label">
                              <span style={{ color: "red" }}>
                                {"Enter county"}
                              </span>
                            </label>
                          )}
                      </div>
                      <div className="form-group col-6">
                        <label className="form-label">
                          {LabelConstants.Country}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          value={warehouseForm?.wareHouseAddress?.country}
                          onChange={(e) =>
                            handlewareHouseAddressForm(
                              "country",
                              e.target.value
                            )
                          }
                        />
                        {addError &&
                          _.isEmpty(
                            warehouseForm?.wareHouseAddress?.country
                          ) && (
                            <label className="form-label">
                              <span style={{ color: "red" }}>
                                {"Enter country"}
                              </span>
                            </label>
                          )}
                      </div>
                      <div className="form-group col-6">
                        <label className="form-label">
                          {LabelConstants.PostCode}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Post Code"
                          value={warehouseForm?.wareHouseAddress?.postCode}
                          onChange={(e) =>
                            handlewareHouseAddressForm(
                              "postCode",
                              e.target.value
                            )
                          }
                        />
                        {addError &&
                          _.isEmpty(
                            warehouseForm?.wareHouseAddress?.postCode
                          ) && (
                            <label className="form-label">
                              <span style={{ color: "red" }}>
                                {"Enter postCode"}
                              </span>
                            </label>
                          )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h4 className="box-title">
                        {LabelConstants.CommunicationDetails}
                      </h4>
                      <button
                        type="button"
                        className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-primary me-3"
                        onClick={() => {
                          const _form: IWarehouse = cloneDeep(warehouseForm);
                          _form.wareHouseAddress.warehouseCom.push(
                            cloneDeep(NewCustomerAddressContact)
                          );
                          setWarehouseForm(_form);
                        }}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                    {warehouseForm?.wareHouseAddress?.warehouseCom.map(
                      (
                        _addCont: IWarehouseAddressContact,
                        _addContIdx: number
                      ) => {
                        return (
                          <div key={`supplierAddressContact${_addContIdx}`}>
                            <div className="d-flex justify-content-between">
                              <h5 className="box-title m-0">
                                Contact #{_addContIdx + 1}
                              </h5>
                              {warehouseForm?.wareHouseAddress?.warehouseCom
                                .length > 1 && (
                                <button
                                  type="button"
                                  className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-danger me-3"
                                  onClick={() => {
                                    const _form: IWarehouse =
                                      cloneDeep(warehouseForm);
                                    _form.wareHouseAddress.warehouseCom.splice(
                                      _addContIdx,
                                      1
                                    );
                                    setWarehouseForm(_form);
                                  }}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {LabelConstants.ContactName}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Contact Name"
                                    value={_addCont.contactName}
                                    onChange={(e) =>
                                      handlewareHouseAddressContactForm(
                                        _addContIdx,
                                        "contactName",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {addError &&
                                    _.isEmpty(_addCont.contactName) && (
                                      <label className="form-label">
                                        <span style={{ color: "red" }}>
                                          {"Enter contact Name"}
                                        </span>
                                      </label>
                                    )}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {LabelConstants.Phone}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <PhoneInput
                                    className={`form-control`}
                                    defaultCountry="GB"
                                    limitMaxLength
                                    value={`${_addCont.designationCountryCode}${_addCont.phoneNumber}`}
                                    onChange={(e) => {
                                      console.log(e);
                                      handleCustomerAddressContactPhone(
                                        _addContIdx,
                                        e ?? ""
                                      );
                                    }}
                                  />
                                  {addError &&
                                  _.isEmpty(_addCont.phoneNumber) ? (
                                    <label className="form-label">
                                      <span style={{ color: "red" }}>
                                        {"Enter phone Number"}
                                      </span>
                                    </label>
                                  ) : addError &&
                                    _addCont.phoneNumber.length !== 10 ? (
                                    <label className="form-label">
                                      <span style={{ color: "red" }}>
                                        {"Enter Proper phone Number"}
                                      </span>
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {LabelConstants.Email}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    value={_addCont.email}
                                    onChange={(e) =>
                                      handlewareHouseAddressContactForm(
                                        _addContIdx,
                                        "email",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {addError && _.isEmpty(_addCont.email) ? (
                                    <label className="form-label">
                                      <span style={{ color: "red" }}>
                                        {"Enter Email"}
                                      </span>
                                    </label>
                                  ) : addError &&
                                    !validateEmailRegex(_addCont.email) ? (
                                    <label className="form-label">
                                      <span style={{ color: "red" }}>
                                        {"Enter Proper Email"}
                                      </span>
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleClose()}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary float-end"
                  onClick={() => checkForm()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseFormComponent;
