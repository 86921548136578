import dayjs from "dayjs";
import { ERROR } from "../constants/generalConstants";
import { ICustomer } from "../models";

export const pagePrintPorStyle = `@media print { @page {size: 210mm 297mm; @bottom-right {content: "Page " counter(page) " of " counter(pages);} @bottom-left {content: none}}}    table { page-break-inside: always;} div {page-break-before:auto;} }`;
export const pagePrintLSStyle =
  '@media print { @page {size: 297mm 210mm; @bottom-right {content: "Page " counter(page) " of " counter(pages);}}}    table { page-break-inside: always;}  div {page-break-before:avoid;}  }';

export function isNullUndefined(key: any) {
  let result = false;
  if (key === null || key === undefined) {
    result = true;
  }
  return result;
}

export function isNullUndefinedEmpty(key: any) {
  let result = false;
  if (key === null || key === undefined || key === "") {
    result = true;
  }
  return result;
}

export function isNumeric(value: any) {
  if (!isNullUndefined(value)) return /^-?\d+$/.test(value);
  return false;
}

export function isCharacter(key: string) {
  let result = true;
  const re = /[a-zA-Z]+/g;
  if (!re.test(key)) {
    result = false;
  }
  return result;
}

export function isSideMenuVisible() {
  var style = window.getComputedStyle(document.getElementById("logo-image"));
  return parseInt(style.width?.replace("px", "")) > 80;
}

export function getNumberForString(value: string) {
  let result: number = ERROR;
  if (!isNullUndefined(value) && isNumeric(value)) {
    result = parseInt(value);
  }
  return result;
}
export function getValueForArrayKey(array: any, key: string) {
  let result;
  array?.map((item) => {
    if (item?.value?.toLowerCase() === key?.toLowerCase()) result = item?.text;
  });
  return result;
}

export function getProductNameById(id: any, list: any) {
  let prName: string;
  list.map((pr: any) => {
    if (pr.productId == id) {
      prName = pr.productCode + " - " + pr.productName;
    }
  });
  return prName;
}

export function getProductCode(id: any, list: any) {
  let prName: string;
  list.map((pr: any) => {
    if (pr.productId == id) {
      prName = pr.productCode;
    }
  });
  return prName;
}

export function getProductName(id: any, list: any) {
  let prName: string;
  list.map((pr: any) => {
    if (pr.productId == id) {
      prName = pr.productName;
    }
  });
  return prName;
}

export function getCustomerName(id: any, list: any) {
  let prName: string;
  list.map((pr: ICustomer) => {
    if (pr.custId == id) {
      prName = pr.custName;
    }
  });
  return prName;
}

export function getProductPackSize(id: any, list: any) {
  let prName: string;
  list.map((pr: any) => {
    if (pr.productId == id) {
      prName = pr?.packSize;
    }
  });
  return prName;
}

export function getProductCatNameById(id: any, list: any) {
  let prCatName: string;
  list.map((pr: any) => {
    if (pr.categoryId == id) {
      prCatName = pr.categoryName;
    }
  });
  return prCatName;
}
export function getDateIfValid(value) {
  let result = null;
  if (dayjs(value, "DD-MM-YYYY").isValid()) {
    result = dayjs(value, "DD-MM-YYYY");
  } else if (dayjs(value, "YYYY-MM-DD").isValid()) {
    result = dayjs(value, "YYYY-MM-DD");
  }

  return result;
}
