import { useContext, useEffect, useState } from "react";
import { GenericHelperService } from "../../../api/generics/helper.service";
import { useNavigate } from "react-router-dom";
import {
  IDeleteConfirmationProps,
  IGoodsReceiptListServerResponseObject,
  IGrnContext,
  IListConfig,
  IListConfigGRN,
  ILoaderConfig,
  IStatusUpdate,
  IWarehouse,
} from "../../../common/models";
import { cloneDeep } from "lodash";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { LabelConstants } from "../../../common/constants/labelConstants";
import ReactPaginate from "react-paginate";
import PageSortDescription from "../../common/pageSortDescription.component";
import { GrnContext } from "../../../controllers/contexts/grn.context";
import { WarehouseContext } from "../../../controllers/contexts/warehouse.context";
import { getNumberForString } from "../../../common/methods/globalmethods";
import {
  EmptyStatusUpdate,
  GoodReceiptStatus,
  GoodReceiptTypes,
  NewDeleteConfirmationDialog,
} from "../../../common/constants/generalConstants";
import DeleteConfirmationComponent from "../../common/deleteConfirmation.component";
import { Modal } from "bootstrap";
import GRNStatusUpdateComponent from "./grn_status_update.component";
import { toast } from "react-toastify";
import Spinner from "../../common/spinner.component";
import moment from "moment";
import useAuth from "../../../controllers/hooks/useAuth";
import LogsModalComponent from "../../common/logsModal.component";

var myModal;
const GRNListingOrdersComponent = () => {
  const {
    grnOrdersList,
    filters,
    setFilters,
    setGrnOrderFormInFormat,
    setSelectedList,
    deleteOrder,
    grnfilters,
    setGrnFilters,
    loader,
    grnLogs,
    getGrnLogs,
    productCateList,
    productsList,
    queryGRNData,
  } = useContext(GrnContext);
  const { isAccessDisabled } = useAuth();
  const [dialoginfo, setDialoginfo] = useState<IDeleteConfirmationProps>();
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const handleShowLogsModal = (
    _order: IGoodsReceiptListServerResponseObject
  ) => {
    getGrnLogs(_order.goodsRecId);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };
  const { getWarehouseDetails, warehouseList, deallocateUnusedAislesIfAny } =
    useContext(WarehouseContext);
  const helperService: GenericHelperService = new GenericHelperService();

  const navigate = useNavigate();

  useEffect(() => {
    myModal = new Modal(document.getElementById("deleteConfirmation"), {});
  }, []);

  const handleQueryChange = (type: string, value: any) => {
    const grnfilter: IListConfigGRN = cloneDeep(grnfilters);
    grnfilter[type] = value;
    setGrnFilters(grnfilter);
  };

  const handleFilterChange = (type: string, value: any) => {
    const filter: IListConfig = cloneDeep(filters);
    filter[type] = value;
    setFilters(filter);
  };

  const clearSearch = () => {
    const grnfilter: IListConfigGRN = cloneDeep(grnfilters);
    grnfilter.grnNum = "";
    grnfilter.status = [];
    grnfilter.warehouse = "";
    grnfilter.grnTypes = [];
    grnfilter.createdDateFrom = "";
    grnfilter.createdDateTo = "";
    grnfilter.search = false;
    setGrnFilters(grnfilter);
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      grnfilters?.grnNum === "" &&
      grnfilters?.status?.length <= 0 &&
      grnfilters?.warehouse === "" &&
      grnfilters?.grnTypes?.length <= 0 &&
      grnfilters?.createdDateFrom === "" &&
      grnfilters?.createdDateTo === ""
    ) {
      result = true;
    }
    return result;
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };

  const showDialog = (
    onCancel: Function,
    onConfirm: Function,
    header: string,
    title_prefix: string,
    title: string = null
  ) => {
    let dialog: IDeleteConfirmationProps = cloneDeep(
      NewDeleteConfirmationDialog
    );
    dialog.cancel = onCancel;
    dialog.confirm = onConfirm;
    dialog.title = title;
    dialog.header = header;
    dialog.title_prefix = title_prefix;
    setDialoginfo(dialog);
    myModal.show();
  };

  const setUpdateStatus = (prod: IGoodsReceiptListServerResponseObject) => {
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod?.goodsRecId];
    _status.status = prod.status;
    setSelectedList(_status);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="box">
            <div className="box-body p-3">
              <div className="row">
                <div className="col-lg-10 d-flex">
                  <div className="input-group w-p40 ms-10">
                    <input
                      type="text"
                      className="form-control bg-lighter border-0"
                      placeholder="GRN number"
                      value={grnfilters?.grnNum}
                      onChange={(event) =>
                        handleQueryChange("grnNum", event.target.value)
                      }
                    />
                  </div>

                  <div className="input-group w-p45 ms-10">
                    <select
                      className="form-select"
                      value={grnfilters?.status}
                      onChange={(e) =>
                        handleQueryChange("status", [e.target.value])
                      }
                    >
                      <option value={""}>GRN Status</option>
                      {GoodReceiptStatus.map(
                        (_statusType: any, _statusTypeIdx: number) => {
                          return (
                            <option
                              key={`goodReceiptStatus${_statusTypeIdx}`}
                              value={_statusType.value}
                            >
                              {_statusType.text}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="input-group w-p60 ms-10">
                    <select
                      className="form-select"
                      value={grnfilters?.warehouse}
                      onChange={(e) =>
                        handleQueryChange("warehouse", e.target.value)
                      }
                    >
                      <option value={""}>Warehouse name</option>
                      {warehouseList?.map(
                        (_warehouse: IWarehouse, _warehouseIdx: number) => {
                          return (
                            <option
                              key={_warehouseIdx}
                              value={_warehouse?.wareHouseId}
                            >
                              {_warehouse?.warehouseName}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="input-group w-p50 ms-10">
                    <select
                      className="form-select"
                      value={grnfilters?.grnTypes}
                      onChange={(event) =>
                        handleQueryChange("grnTypes", [event.target.value])
                      }
                    >
                      <option value={""}>GRN type</option>
                      {GoodReceiptTypes.map(
                        (_statusType: any, _statusTypeIdx: number) => {
                          return (
                            <option
                              key={`goodReceiptType${_statusTypeIdx}`}
                              value={_statusType.value}
                            >
                              {_statusType.text}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div className="input-group w-p30 ms-10">
                    <input
                      className="form-control"
                      type="date"
                      placeholder="From date"
                      value={grnfilters?.createdDateFrom}
                      onChange={(event) =>
                        handleQueryChange("createdDateFrom", event.target.value)
                      }
                    />
                    <i
                      style={{
                        position: "absolute",
                        top: -15,
                        left: 0,
                        fontSize: 10,
                      }}
                    >
                      From date
                    </i>
                  </div>

                  <div className="input-group w-p30 ms-10">
                    <input
                      className="form-control"
                      type="date"
                      placeholder="To date"
                      value={grnfilters?.createdDateTo}
                      onChange={(event) =>
                        handleQueryChange("createdDateTo", event.target.value)
                      }
                    />
                    <i
                      style={{
                        position: "absolute",
                        top: -15,
                        left: 0,
                        fontSize: 10,
                      }}
                    >
                      To date
                    </i>
                  </div>
                  <div className="input-group w-p10 ms-10">
                    <button
                      type="button"
                      className="waves-effect waves-light btn btn-success rounded"
                      disabled={checkIfSearchStringsAreEmpty()}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          currentPage: 1,
                        });
                        if (grnfilters?.search) {
                          queryGRNData();
                        }
                        handleQueryChange("search", true);
                      }}
                    >
                      {LabelConstants.Search}
                    </button>
                  </div>
                  <div className="input-group w-p50 ms-10">
                    <button
                      type="button"
                      className="waves-effect waves-light btn btn-success-light rounded"
                      onClick={() => {
                        clearSearch();
                      }}
                    >
                      {LabelConstants.Clear}
                    </button>
                  </div>
                </div>
                <div className="col-lg-2 d-flex justify-content-end">
                  <button
                    type="button"
                    disabled={isAccessDisabled("OI", "create", true, "GRN")}
                    className="waves-effect waves-light btn btn-success rounded"
                    onClick={() => {
                      navigate(RoutingConstants.grn_order_create);
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    {` ${LabelConstants.CreateGRN}`}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="box-body">
              <div className="table-responsive">
                <table className="table table-hover table-bordered mb-0 text-center">
                  <thead className="table-primary">
                    <tr>
                      <th>S.No</th>
                      <th onClick={() => handleSort("createdDate")}>
                        {LabelConstants.CreatedDate}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "createdDate"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("warehouseId")}>
                        {LabelConstants.WareHouseName}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "warehouseId"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("supplierName")}>
                        {`${LabelConstants.Supplier}/${LabelConstants.Customer} Name`}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "supplierName"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("goodsReceiptNumber")}>
                        {LabelConstants.GoodsReceiptNumber}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "goodsReceiptNumber"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("orderNum")}>
                        {LabelConstants.Number}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "orderNum"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th onClick={() => handleSort("status")}>
                        {LabelConstants.GoodsReceiptStatus}
                        <i
                          className={`ms-2 fa fa-sort${
                            filters?.sortItem === "status"
                              ? `-${filters?.sortDirection} text-primary`
                              : " text-fade"
                          }`}
                        />
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grnOrdersList?.map((_order, _orderIdx) => (
                      <tr key={`grnorderListing${_order.goodsRecId}`}>
                        <td>
                          {(filters?.currentPage - 1) *
                            filters?.currentPageSize +
                            _orderIdx +
                            1}
                        </td>
                        <td>
                          {moment(_order?.createdDate).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          {
                            getWarehouseDetails(
                              getNumberForString(_order?.warehouseId)
                            )?.warehouseName
                          }
                        </td>
                        <td>
                          {_order?.supplierName ? _order?.supplierName : "-"}
                        </td>
                        <td>{_order?.goodsReceiptNumber}</td>
                        <td>{_order?.orderNum}</td>
                        <td>
                          <span
                            style={
                              isAccessDisabled(
                                "OI",
                                "update_status",
                                true,
                                "GRN"
                              )
                                ? { pointerEvents: "none", opacity: 0.5 }
                                : {}
                            }
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#grnupdateStatus"
                            onClick={() => {
                              setUpdateStatus(_order);
                            }}
                            className={`badge bg-${helperService.getStatusColor(
                              _order.status
                            )}-light rounded-pill point`}
                          >
                            {_order.status}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <button
                              type="button"
                              disabled={isAccessDisabled(
                                "OI",
                                "view",
                                true,
                                "GRN"
                              )}
                              className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                              onClick={async () => {
                                await setGrnOrderFormInFormat(_order);
                                navigate(RoutingConstants.grn_order_view);
                              }}
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </button>
                            <button
                              type="button"
                              disabled={
                                isAccessDisabled("OI", "edit", true, "GRN") ||
                                _order?.status ===
                                  GoodReceiptStatus.find(
                                    (item) => item.text === "Approval"
                                  )?.value
                              }
                              className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                              onClick={async () => {
                                await setGrnOrderFormInFormat(_order).then(
                                  () => {
                                    navigate(RoutingConstants.grn_order_edit);
                                  }
                                );
                              }}
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </button>

                            <button
                              type="button"
                              className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                              onClick={() => {
                                navigate(RoutingConstants.grn_order_print, {
                                  state: { selectedItem: _order },
                                });
                              }}
                            >
                              <i className="fa fa-print" aria-hidden="true"></i>
                            </button>

                            {_order?.status ===
                            GoodReceiptStatus.find(
                              (item) => item.text === "Approval"
                            )?.value ? (
                              <button
                                type="button"
                                disabled={isAccessDisabled(
                                  "OI",
                                  "delete",
                                  true,
                                  "GRN"
                                )}
                                className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                onClick={() => {}}
                                style={{ cursor: "no-drop" }}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            ) : (
                              <button
                                type="button"
                                disabled={isAccessDisabled(
                                  "OI",
                                  "delete",
                                  true,
                                  "GRN"
                                )}
                                className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                onClick={() => {
                                  showDialog(
                                    () => {},
                                    async () => {
                                      if (
                                        _order?.status ===
                                        GoodReceiptStatus.find(
                                          (item) => item.text === "Draft"
                                        )?.value
                                      ) {
                                        let locations = [];
                                        _order?.grDetails?.map((item) => {
                                          item?.prodLocs?.map((location) => {
                                            locations.push(
                                              location?.locationId
                                            );
                                          });
                                        });
                                        await deallocateUnusedAislesIfAny(
                                          locations,
                                          []
                                        );
                                      }
                                      let result = await deleteOrder(_order);
                                      if (result?.result) {
                                        toast.success(
                                          "Order deleted successfully!!"
                                        );
                                      } else {
                                        toast.error("Order delete failure!!");
                                      }
                                    },
                                    undefined,
                                    undefined,
                                    _order?.orderNum
                                  );
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#deleteConfirmation"
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}

                            <button
                              type="button"
                              className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                              onClick={() => {
                                setcreatedDate(_order?.createdDate);
                                setcreatedBy(_order?.createdBy);
                                handleShowLogsModal(_order);
                              }}
                            >
                              <i className="fa fa-book" aria-hidden="true"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="box-body p-3">
              <div className="row">
                <div className="col-lg-6 d-flex align-items-center">
                  <PageSortDescription
                    filters={filters}
                    dataList={grnOrdersList}
                  />
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  {filters?.totalPageCount > 1 && (
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="productorder_paginate"
                    >
                      <ReactPaginate
                        forcePage={filters?.currentPage - 1}
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={
                          filters && filters?.totalPageCount < 2
                            ? 0
                            : filters?.totalPageCount
                        }
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        pageClassName={`paginate_button page-item`}
                        pageLinkClassName="page-link"
                        activeLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        breakClassName={`paginate_button page-item`}
                        disabledClassName={`disabled`}
                        previousClassName={`paginate_button page-item previous`}
                        nextClassName={`paginate_button page-item next`}
                        onPageChange={(e) => {
                          handleFilterChange("currentPage", e.selected + 1);
                        }}
                        containerClassName={`pagination mb-1`}
                        activeClassName={`active`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <GRNStatusUpdateComponent />
        <DeleteConfirmationComponent
          title={dialoginfo?.title}
          cancel={dialoginfo?.cancel}
          confirm={dialoginfo?.confirm}
          header={dialoginfo?.header}
          title_prefix={dialoginfo?.title_prefix}
        />
        <LogsModalComponent
          onOpen={showLogsModal}
          onClose={handleCloseLogsModal}
          logs={grnLogs}
          productsList={productsList}
          productCateList={productCateList}
          createdDate={createdDate}
          createdBy={createdBy}
        />
        {loader?.showSpinner && (
          <div
            style={{
              display: "flex",
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
              width: "80%",
              flexDirection: "column",
            }}
          >
            <div className="spinner-border" />
            <Spinner Message={loader?.spinnerMessage} />
          </div>
        )}
      </div>
    </>
  );
};

export default GRNListingOrdersComponent;
