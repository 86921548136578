import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import moment from "moment";
import PageSortDescription from "../common/pageSortDescription.component";
import ReactPaginate from "react-paginate";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import { GenericHelperService } from "../../api/generics/helper.service";
import { IStatusUpdate } from "../../common/models";
import { EmptyStatusUpdate } from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import OPStatusUpdateComponent from "../orderpicking/orderPickingStatusUpdate.component";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import W2WStatusUpdateComponent from "./warehouseStatusUpdate.component";
import useAuth from "../../controllers/hooks/useAuth";
import LogsModalComponent from "../common/logsModal.component";

const WarehouseTransferListComponent = () => {
  const helperService: GenericHelperService = new GenericHelperService();
  const {
    filtersWarehouseTransfer,
    warehouseTransferList,
    setWarehouseTransferFilters,
    getWarehouseTranferList,
    deleteWarehouseTransfer,
    setSelectedListTransfer,
    updateTransferWarehouse,
  } = useContext(OrderPickingContext);
  const { masterWarehouseList, getLogsStockWarehouse, stockWarehouseLogs } =
    useContext(WarehouseContext);
  const navigate = useNavigate();
  const { isAccessDisabled } = useAuth();
  const [deleteW2W, setDeleteW2W] = useState<any>(null);
  const [tempStatus, setTempStatus] = useState("");
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const [showLogsModal, setShowLogsModal] = useState(false);
  const handleShowLogsModal = (_stock) => {
    getLogsStockWarehouse(_stock.id);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };

  useEffect(() => {
    getWarehouseTranferList();
  }, []);

  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setWarehouseTransferFilters({
      ...filtersWarehouseTransfer,
      searchQuery: _searchQuery,
    });
  };
  const setUpdateStatus = (prod: any) => {
    setTempStatus(prod.status);
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.id];
    _status.status = prod.status;
    setSelectedListTransfer(prod);
  };
  const cancelDelete = () => {
    setDeleteW2W(null);
  };
  const confirmDelete = () => {
    deleteWarehouseTransfer(deleteW2W);
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Stock Transfer
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    W2W Stock Transfer
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="row">
                  <div className="col-lg-6 d-flex">
                    <div className="input-group w-p50">
                      <span className="input-group-addon bg-lighter border-0">
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="Search..."
                        value={filtersWarehouseTransfer.searchQuery}
                        onChange={(event) => searchQueryChange(event)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isAccessDisabled("ST", "create", true, "W2W")}
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        navigate(RoutingConstants.warehouseTransferCreate);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Create Transfer Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-bordered mb-0"
                    style={{ textAlign: "center" }}
                  >
                    <thead className="table-primary">
                      <tr>
                        <th>S.No</th>
                        <th>Transfer Order No.</th>
                        <th>Transfer Date</th>
                        <th>Source Warehouse</th>
                        <th>Destination Warehouse</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {warehouseTransferList?.map((op, i) => {
                        const fromWarehouse = masterWarehouseList.find(
                          (warehouse) =>
                            warehouse.wareHouseId === op.fromWarehouseId
                        );
                        const toWarehouse = masterWarehouseList.find(
                          (warehouse) =>
                            warehouse.wareHouseId === op.toWarehouseId
                        );
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{op.transOrderNum}</td>
                            <td>
                              {moment(op.transferDate).format("DD/MM/yyyy")}
                            </td>
                            <td>{fromWarehouse?.warehouseName}</td>
                            <td>{toWarehouse?.warehouseName}</td>
                            <td>
                              <span
                                style={
                                  isAccessDisabled(
                                    "ST",
                                    "update_status",
                                    true,
                                    "W2W"
                                  )
                                    ? {
                                        pointerEvents: "none",
                                        opacity: 0.5,
                                      }
                                    : {}
                                }
                                role="button"
                                data-bs-toggle="modal"
                                data-bs-target="#updateStatus"
                                onClick={() => setUpdateStatus(op)}
                                className={`badge bg-${helperService.getStatusColor(
                                  op.status
                                )}-light rounded-pill`}
                              >
                                {op.status}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  disabled={isAccessDisabled(
                                    "ST",
                                    "view",
                                    true,
                                    "W2W"
                                  )}
                                  className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                  onClick={() => {
                                    navigate(
                                      RoutingConstants.warehouseTransferDetailsList,
                                      {
                                        state: {
                                          isEditable: false,
                                          op: op,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                  onClick={() => {
                                    navigate(
                                      RoutingConstants.warehouseTransferDetailsList,
                                      {
                                        state: {
                                          isEditable: true,
                                          op: op,
                                        },
                                      }
                                    );
                                  }}
                                  disabled={
                                    op.status !== "DRAFT" ||
                                    isAccessDisabled("ST", "edit", true, "W2W")
                                  }
                                >
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                  onClick={() => {
                                    setDeleteW2W(op);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteConfirmation"
                                  disabled={
                                    op.status !== "DRAFT" ||
                                    isAccessDisabled(
                                      "ST",
                                      "delete",
                                      true,
                                      "W2W"
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                  onClick={() => {
                                    setcreatedDate(op?.createdDate);
                                    setcreatedBy(op?.createdBy);
                                    handleShowLogsModal(op);
                                  }}
                                >
                                  <i
                                    className="fa fa-book"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filtersWarehouseTransfer}
                      dataList={warehouseTransferList}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {filtersWarehouseTransfer.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          forcePage={filtersWarehouseTransfer?.currentPage - 1}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            filtersWarehouseTransfer &&
                            filtersWarehouseTransfer.totalPageCount < 2
                              ? 0
                              : filtersWarehouseTransfer.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setWarehouseTransferFilters({
                              ...filtersWarehouseTransfer,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <W2WStatusUpdateComponent tempStatus={tempStatus} />
      <DeleteConfirmationComponent
        title={deleteW2W?.transOrderNum ? deleteW2W?.transOrderNum : ""}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={stockWarehouseLogs}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default WarehouseTransferListComponent;
