import {
  AllOrdersById,
  CreateGRN,
  GRNList,
  GRNStatusUpdate,
  GetAllPOsList,
  GetAllTOsList,
  GetEligiblePOsList,
  GetEligibleTOsList,
  GetROsList,
  NextGRNReceiptNumber,
  OrdersApiBase,
  OrdersById,
  RoOrdersById,
  ToOrdersById,
  UpdateGRN,
  searchByCriteria,
} from "../../common/constants/apiConstants";
import { SEQUENCE_PREFIXES } from "../../common/constants/generalConstants";
import {
  IGoodsDetails,
  IGoodsReceiptListServerResponseObject,
  IListConfigGRN,
  IStatusUpdate,
} from "../../common/models";
import { IReturnOrders } from "../../common/models/returnorders";
import { AxiosService } from "../generics/axios.service";
import { IGRNService } from "../interfaces/IGRNService";

export class GRNService implements IGRNService {
  private axiosService: AxiosService = new AxiosService();

  async getGRNOrdersById(id: string): Promise<IGoodsDetails> {
    try {
      const result: any = await this.axiosService.Get(
        OrdersById(id),
        OrdersApiBase
      );
      const _products: IGoodsDetails = result.data ?? [];
      console.log("Response: Product List - ", _products);
      return _products;
    } catch (error) {
      console.error("Error: Product List - ", error);
      return null;
    }
  }

  async searchGRNOrders(filter: IListConfigGRN, pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Post(
        searchByCriteria(pageNo),
        filter,
        OrdersApiBase
      );
      return result?.data ?? null;
    } catch (error) {
      console.error("Error: SearchGRNOrders List - ", error);
      return null;
    }
  }

  async getAllGRNOrdersById(id: string): Promise<IGoodsDetails> {
    try {
      const result: any = await this.axiosService.Get(
        AllOrdersById(id),
        OrdersApiBase
      );
      const _products: IGoodsDetails = result.data ?? [];
      console.log("Response: All GRN Orders List - ", _products);
      return _products;
    } catch (error) {
      console.error("Error: All GRN Orders List - ", error);
      return null;
    }
  }

  async getRODetailsById(id: string): Promise<IReturnOrders> {
    try {
      const result: any = await this.axiosService.Get(
        RoOrdersById(id),
        OrdersApiBase
      );
      const _products: IReturnOrders = result.data ?? [];
      console.log("Response: getRODetailsById List - ", _products);
      return _products;
    } catch (error) {
      console.error("Error: getRODetailsById List - ", error);
      return null;
    }
  }

  async getTODetailsById(id: string): Promise<IReturnOrders> {
    try {
      const result: any = await this.axiosService.Get(
        ToOrdersById(id),
        OrdersApiBase
      );
      const _products: IReturnOrders = result.data ?? [];
      console.log("Response: getRODetailsById List - ", _products);
      return _products;
    } catch (error) {
      console.error("Error: getRODetailsById List - ", error);
      return null;
    }
  }

  async getEligiblePOsList(): Promise<[]> {
    try {
      const result: any = await this.axiosService.Get(
        GetEligiblePOsList,
        OrdersApiBase
      );
      return result.data ?? [];
    } catch (error) {
      console.error("Error: PO's List - ", error);
      return null;
    }
  }

  async getEligibleTOsList(): Promise<[]> {
    try {
      const result: any = await this.axiosService.Get(
        GetEligibleTOsList,
        OrdersApiBase
      );
      return result.data ?? [];
    } catch (error) {
      console.error("Error: PO's List - ", error);
      return null;
    }
  }

  async getEligibleROsList(): Promise<[]> {
    try {
      const result: any = await this.axiosService.Get(
        GetROsList,
        OrdersApiBase
      );
      return result.data ?? [];
    } catch (error) {
      console.error("Error: RO's List - ", error);
      return null;
    }
  }

  async getAllPOsList(): Promise<[]> {
    try {
      const result: any = await this.axiosService.Get(
        GetAllPOsList,
        OrdersApiBase
      );
      return result.data ?? [];
    } catch (error) {
      console.error("Error: All PO's List - ", error);
      return null;
    }
  }

  async getAllTOsList(): Promise<[]> {
    try {
      const result: any = await this.axiosService.Get(
        GetAllTOsList,
        OrdersApiBase
      );
      return result.data ?? [];
    } catch (error) {
      console.error("Error: All PO's List - ", error);
      return null;
    }
  }

  async getNextReceiptNumberForType(type: string): Promise<string> {
    try {
      const result: any = await this.axiosService.Get(
        NextGRNReceiptNumber(type),
        OrdersApiBase
      );
      return result?.data;
    } catch (error) {
      console.error("Error: Next GRN Receipt Number - ", error);
      return "";
    }
  }

  async getGRNOrders(pageNo: number): Promise<any> {
    try {
      const result: any = await this.axiosService.Get(
        GRNList(pageNo),
        OrdersApiBase
      );
      return result?.data ?? null;
    } catch (error) {
      console.error("Error: Grn orders List - ", error);
      return null;
    }
  }

  updateStatus = async (status: IStatusUpdate): Promise<boolean> => {
    try {
      await this.axiosService.Post(GRNStatusUpdate, status, OrdersApiBase);
      return true;
    } catch (error) {
      console.error("Error: Update GRN Status - ", error);
      return false;
    }
  };

  createGRN = async (grnForm: any) => {
    try {
      console.log(JSON.stringify(grnForm));
      let result = await this.axiosService.Post(
        CreateGRN,
        grnForm,
        OrdersApiBase
      );
      return this.formMessage(true, result);
    } catch (error) {
      console.error("Error: Creating GRN - ", error);
      return this.formMessage(false, error?.request?.response);
    }
  };

  editGRNOrder = async (id: number, grnForm: any) => {
    try {
      console.log(JSON.stringify(grnForm));
      let result = await this.axiosService.Put(
        UpdateGRN(id),
        grnForm,
        OrdersApiBase
      );
      return this.formMessage(true, result);
    } catch (error) {
      console.error("Error: Creating GRN - ", error);
      return this.formMessage(false, error?.request?.response);
    }
  };

  formMessage = (result: boolean, message: string) => {
    return { result, message };
  };
}
