import { useEffect, useState } from "react";
import { ICustomer, IDashboardContext, IWarehouse } from "../../common/models";
import { DashboardService } from "../../api/services/dashboard.service";
import {
  getAislesLocationsUsage,
  getBestSellersData,
  getRevenueAndUnitsSold,
  getShelvesOverview,
  getTodayOrdersShippingDetails,
  WarehouseApiBase,
} from "../../common/constants/apiConstants";
import { isNullUndefined } from "../../common/methods/globalmethods";
import { WarehouseService } from "../../api/services/warehouse.service";
import {
  IAislesLocationsUsage,
  IBestSellerData,
  IRevenueAndUnitsSold,
  IShelfsOverview,
  ITodayOrdersShippingDetails,
} from "../../common/models/dashboard";
import { CustomerService } from "../../api/services/customer.service";

const useDashboard = () => {
  const dashboardService: DashboardService = new DashboardService();
  const warehouseService: WarehouseService = new WarehouseService();
  const customerService: CustomerService = new CustomerService();

  const [timePeriod, settimePeriod] = useState<number>(1);
  const [warehouseId, setwarehouseId] = useState<number>(null);
  const [warehouses, setwarehouses] = useState<IWarehouse[]>(null);
  const [customerList, setcustomerList] = useState<ICustomer[]>([]);
  const [revenueAndUnits, setrevenueAndUnits] =
    useState<IRevenueAndUnitsSold>(null);
  const [bestSellersData, setbestSellersData] = useState<IBestSellerData[]>([]);
  const [todaysOrderShippingDetails, settodaysOrderShippingDetails] =
    useState<ITodayOrdersShippingDetails>(null);
  const [shelvesOveview, setshelvesOveview] = useState<IShelfsOverview>(null);
  const [ailesLocationUsage, setailesLocationUsage] = useState<
    IAislesLocationsUsage[]
  >([]);

  useEffect(() => {
    GetRevenueAndUnitsSold();
    GetBestSellersData();
    GetTodayOrdersShippingDetails();
    GetShelvesOverview();
    getWarehouses().then((warehouses) => {
      if (warehouses?.length > 0) {
        setwarehouseId(warehouses[0]?.wareHouseId);
      }
    });
    getCustomersList();
  }, []);

  useEffect(() => {
    GetRevenueAndUnitsSold();
  }, [timePeriod]);

  useEffect(() => {
    GetAislesLocationsUsage();
  }, [warehouseId]);

  const getWarehouses = async () => {
    let _warehouses: IWarehouse[] = await warehouseService.getWarehouses();
    _warehouses?.map((item: any) => {
      item.value = item.wareHouseId;
      item.label = item.warehouseName;
    });
    setwarehouses(_warehouses ?? []);
    return _warehouses;
  };

  const getCustomersList = async () => {
    await customerService.getCustomerAllList().then((_customers) => {
      setcustomerList(_customers);
    });
  };

  const GetBestSellersData = async () => {
    let result: IBestSellerData[] =
      await dashboardService.makeGetCallWithEndPoint(getBestSellersData);
    if (!isNullUndefined(result)) {
      setbestSellersData(result);
    }
  };

  const GetRevenueAndUnitsSold = async () => {
    let result: IRevenueAndUnitsSold =
      await dashboardService.makeGetCallWithEndPoint(
        getRevenueAndUnitsSold(timePeriod)
      );
    if (!isNullUndefined(result)) {
      setrevenueAndUnits(result);
    }
  };

  const GetTodayOrdersShippingDetails = async () => {
    let result: ITodayOrdersShippingDetails =
      await dashboardService.makeGetCallWithEndPoint(
        getTodayOrdersShippingDetails
      );
    if (!isNullUndefined(result)) {
      settodaysOrderShippingDetails(result);
    }
  };

  const GetShelvesOverview = async () => {
    let result: IShelfsOverview =
      await dashboardService.makeGetCallWithEndPoint(
        getShelvesOverview,
        WarehouseApiBase
      );
    if (!isNullUndefined(result)) {
      setshelvesOveview(result);
    }
  };

  const GetAislesLocationsUsage = async () => {
    let result: IAislesLocationsUsage[] =
      await dashboardService.makeGetCallWithEndPoint(
        getAislesLocationsUsage(warehouseId),
        WarehouseApiBase
      );
    if (!isNullUndefined(result)) {
      setailesLocationUsage(result);
    }
  };

  return {
    timePeriod,
    settimePeriod,
    warehouseId,
    setwarehouseId,
    warehouses,
    revenueAndUnits,
    bestSellersData,
    todaysOrderShippingDetails,
    shelvesOveview,
    ailesLocationUsage,
    customerList,
  } as IDashboardContext;
};

export default useDashboard;
