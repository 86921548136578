import { useContext, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { CustomerContext } from "../../../controllers/contexts/customer.context";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ICustomer,
  IListConfig,
  IListConfigCustomer,
  IStatusUpdate,
} from "../../../common/models";
import { cloneDeep } from "lodash";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import {
  EmptyStatusUpdate,
  Statuses,
} from "../../../common/constants/generalConstants";
import PageSortDescription from "../../common/pageSortDescription.component";
import ReactPaginate from "react-paginate";
import { GenericHelperService } from "../../../api/generics/helper.service";
import CustomerStatusUpdateComponent from "./customerStatusUpdate.component";
import DeleteConfirmationComponent from "../../common/deleteConfirmation.component";
import moment from "moment";
import useAuth from "../../../controllers/hooks/useAuth";
import LogsModalComponent from "../../common/logsModal.component";

const CustomerComponent = () => {
  const {
    customerList,
    filters,
    selectedList,
    setSelectedList,
    setFilters,
    customerForm,
    setCustomerForm,
    newCustomer,
    deleteCustomer,
    viewCustomer,
    customerfilters,
    setCustomerFilters,
    resetFilterWithMastersData,
    getLogsCustomer,
    customerLogs,
  } = useContext(CustomerContext);
  const { isAccessDisabled } = useAuth();
  const helperService: GenericHelperService = new GenericHelperService();
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const handleShowLogsModal = (_cus: ICustomer) => {
    getLogsCustomer(_cus.custId);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };
  const navigate = useNavigate();

  const handleQueryChange = (type: string, value: any) => {
    const _customerfilters: IListConfigCustomer = cloneDeep(customerfilters);
    _customerfilters[type] = value;
    if (type !== "search") {
      _customerfilters.search = false;
      _customerfilters.searchLocally = false;
    }
    setCustomerFilters(_customerfilters);
  };

  const clearSearch = () => {
    const _customerfilters: IListConfigCustomer = cloneDeep(customerfilters);
    _customerfilters.searchLocally = true;
    _customerfilters.status = [];
    _customerfilters.customerName = "";
    setCustomerFilters(_customerfilters);
    resetFilterWithMastersData();
  };

  const checkIfSearchStringsAreEmpty = () => {
    let result = false;
    if (
      customerfilters?.customerName === "" &&
      customerfilters?.status?.length <= 0
    ) {
      result = true;
    }
    return result;
  };

  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setFilters({ ...filters, searchQuery: _searchQuery });
  };

  const setUpdateStatus = (prod: ICustomer) => {
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.custId];
    _status.status = prod.status;
    setSelectedList(_status);
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
      _status.ids = customerList.map((_prd) => _prd.custId);
      setSelectedList(_status);
    } else {
      setSelectedList(cloneDeep(EmptyStatusUpdate));
    }
  };

  const handleSelectItem = (item: ICustomer, event: any) => {
    const _status: IStatusUpdate = cloneDeep(selectedList);
    if (event.target.checked) {
      _status.ids.push(item.custId);
    } else {
      const _itmIdx = _status.ids.findIndex((_itm) => _itm === item.custId);
      if (_itmIdx > -1) {
        _status.ids.splice(_itmIdx, 1);
      }
    }
    setSelectedList(_status);
  };

  const cancelDelete = () => {
    setCustomerForm(null);
  };

  const confirmDelete = () => {
    deleteCustomer();
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.Customer}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.Master}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.Customer}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex">
                    <div className="input-group w-p40 ms-10">
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="Customer Name"
                        value={customerfilters?.customerName}
                        onChange={(event) =>
                          handleQueryChange("customerName", event.target.value)
                        }
                      />
                    </div>

                    <div className="input-group w-p45 ms-10">
                      <select
                        className="form-select"
                        value={customerfilters?.status}
                        onChange={(e) =>
                          handleQueryChange("status", [e.target.value])
                        }
                      >
                        <option value={""}>Status</option>
                        {Statuses.map(
                          (_statusType: any, _statusTypeIdx: number) => {
                            return (
                              <option
                                key={`Status${_statusTypeIdx}`}
                                value={_statusType}
                              >
                                {_statusType}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="input-group w-p10 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success rounded"
                        disabled={checkIfSearchStringsAreEmpty()}
                        onClick={() => {
                          handleQueryChange("search", true);
                        }}
                      >
                        {LabelConstants.Search}
                      </button>
                    </div>
                    <div className="input-group w-p50 ms-10">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-success-light rounded"
                        onClick={() => {
                          clearSearch();
                        }}
                      >
                        {LabelConstants.Clear}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isAccessDisabled("MD", "create", true, "CUST")}
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        newCustomer();
                        navigate(RoutingConstants.customerCreate);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      {`${LabelConstants.Create} ${LabelConstants.Customer}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-hover table-bordered mb-0">
                    <thead className="table-primary">
                      <tr>
                        {/* <th>
                        <input type="checkbox" id={`md_checkbox_all`} className="chk-col-primary" checked={customerList?.length > 0 && selectedList?.ids.length === customerList?.length} onChange={(event) => handleSelectAll(event)} />
                        <label htmlFor={`md_checkbox_all`}></label>
                      </th> */}
                        {/* <th onClick={() => handleSort("custId")}>
                          {LabelConstants.ID}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "custId"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th> */}
                        <th onClick={() => handleSort("custName")}>
                          {LabelConstants.CustomerName}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "custName"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("custType")}>
                          {LabelConstants.CustomerType}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "custType"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("prodCategory")}>
                          {LabelConstants.ProductCategory}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "prodCategory"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("validFrom")}>
                          {LabelConstants.ValidFrom}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "validFrom"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("validTo")}>
                          {LabelConstants.ValidTo}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "validTo"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("modifiedDate")}>
                          {LabelConstants.ModifiedDate}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "modifiedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("status")}>
                          {LabelConstants.Status}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "status"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerList &&
                        customerList.map((_cus, _cusIdx) => {
                          return (
                            <tr>
                              {/* <td>
                          <input type="checkbox" id={`md_checkbox_${_cusIdx}`} className="chk-col-primary" checked={selectedList.ids.findIndex(_itm => _itm === _cus.custId) >= 0} onChange={(event) => handleSelectItem(_cus, event)} />
                          <label htmlFor={`md_checkbox_${_cusIdx}`}></label>
                        </td> */}
                              {/* <td>{_cus.custId}</td> */}
                              <td>{_cus.custName}</td>
                              <td>{_cus.custType}</td>
                              <td>{_cus.prodCategory}</td>
                              <td>
                                {moment(_cus.validFrom).format("DD/MM/yyyy")}
                              </td>
                              <td>
                                {moment(_cus.validTo).format("DD/MM/yyyy")}
                              </td>
                              <td>
                                {moment(_cus.modifiedDate).format("DD/MM/yyyy")}
                              </td>
                              <td>
                                <span
                                  style={
                                    isAccessDisabled(
                                      "MD",
                                      "update_status",
                                      true,
                                      "CUST"
                                    )
                                      ? { pointerEvents: "none", opacity: 0.5 }
                                      : {}
                                  }
                                  role="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#updateStatus"
                                  onClick={() => setUpdateStatus(_cus)}
                                  className={`badge bg-${helperService.getStatusColor(
                                    _cus.status
                                  )}-light rounded-pill`}
                                >
                                  {_cus.status}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-start">
                                  <button
                                    type="button"
                                    disabled={isAccessDisabled(
                                      "MD",
                                      "view",
                                      true,
                                      "CUST"
                                    )}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    onClick={() => {
                                      viewCustomer(_cus);
                                      navigate(
                                        RoutingConstants.customerDetails
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    disabled={isAccessDisabled(
                                      "MD",
                                      "edit",
                                      true,
                                      "CUST"
                                    )}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                    onClick={() => {
                                      viewCustomer(_cus);
                                      navigate(RoutingConstants.customerEdit);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    disabled={isAccessDisabled(
                                      "MD",
                                      "delete",
                                      true,
                                      "CUST"
                                    )}
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setCustomerForm(cloneDeep(_cus));
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmation"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                    onClick={() => {
                                      setcreatedDate(_cus?.createdDate);
                                      setcreatedBy(_cus?.createdBy);
                                      handleShowLogsModal(_cus);
                                    }}
                                  >
                                    <i
                                      className="fa fa-book"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filters}
                      dataList={customerList}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {filters.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          forcePage={filters?.currentPage - 1}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            filters && filters.totalPageCount < 2
                              ? 0
                              : filters.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setFilters({
                              ...filters,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerStatusUpdateComponent />
      <DeleteConfirmationComponent
        title={customerForm?.custName ?? ""}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={customerLogs}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default CustomerComponent;
