/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { useContext } from "react";
import { WarehouseContext } from "../../controllers/contexts/warehouse.context";
import { cloneDeep } from "lodash";
import "../../assets/styles/locations.scss";
import { NewLocationForm } from "../../common/constants/generalConstants";

const WarehouseLocationFormComponent = () => {
  const {
    selectedWarehouse,
    previewMap,
    setPreviewMap,
    previewMapForm,
    setPreviewMapForm,
    generatePreview,
    saveLocations,
  } = useContext(WarehouseContext);
  const navigate = useNavigate();
  const pathname = useLocation()?.pathname;

  const handleWarehouseForm = (type: string, value: any) => {
    const _form = cloneDeep(previewMapForm);
    _form[type] = value;
    setPreviewMapForm(_form);
  };

  const deleteWarehouseLocation = (bayIdx: number, shelfIdx: number) => {
    const _preview = cloneDeep(previewMap);
    _preview.bays[bayIdx].shelfs.splice(shelfIdx, 1);
    setPreviewMap(_preview);
  };

  const deleteWarehouseBay = (bayIdx: number) => {
    const _preview = cloneDeep(previewMap);
    _preview.bays.splice(bayIdx, 1);
    setPreviewMap(_preview);
  };

  const addBays = () => {
    const _preview = cloneDeep(previewMap);
    const _newBay = {
      bayId: _preview.bays.length + 1,
      shelfs: [],
    };

    for (let shlfIdx = 0; shlfIdx < previewMapForm.shelves; shlfIdx++) {
      _newBay.shelfs.push(
        `${_preview.aisleName}${shlfIdx}${String(_newBay.bayId).padStart(
          2,
          "0"
        )}`
      );
    }
    _preview.bays.push(_newBay);
    setPreviewMap(_preview);
  };

  const discardChanges = async () => {
    setPreviewMap(null);
    setPreviewMapForm(null);
    navigate(
      pathname === RoutingConstants?.warehouseMap
        ? RoutingConstants.warehouse
        : RoutingConstants?.warehousel2l
    );
  };

  const handleSaveLocation = async () => {
    const result = await saveLocations();
    if (result) {
      navigate(
        pathname === RoutingConstants?.warehouseMap
          ? RoutingConstants.warehouse
          : RoutingConstants?.warehousel2l,
        { state: { tabIndex: 1 } }
      );
    }
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.warehouse}
                      title={LabelConstants.Warehouse}
                    >
                      {LabelConstants.Warehouse}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Warehouse} ${LabelConstants.Locations} `}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div className="box h-auto">
              <div className="box-body">
                <h4 className="box-title">
                  {LabelConstants.Warehouse} -{" "}
                  {selectedWarehouse?.warehouseName} {LabelConstants.Locations}
                </h4>
                <div className="row">
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="aisleName">
                      {"Aisle Name"}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="aisleName"
                      type="text"
                      className="form-control"
                      placeholder="Aisle Name"
                      value={previewMapForm?.asileName}
                      onChange={(e) =>
                        handleWarehouseForm("asileName", e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="bay">
                      {"No. of Bays"}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="bay"
                      type="number"
                      className="form-control"
                      placeholder="Bays"
                      value={previewMapForm?.bays}
                      onChange={(e) =>
                        handleWarehouseForm(
                          "bays",
                          e.target.value
                            ? Number(e.target.value)
                            : e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="shelf">
                      {"No. of Shelves"}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="shelf"
                      type="number"
                      className="form-control"
                      placeholder="Shelves"
                      value={previewMapForm?.shelves}
                      onChange={(e) =>
                        handleWarehouseForm("shelves", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <button
                    type="button"
                    className="waves-effect waves-light btn btn-sm btn-secondary rounded me-2"
                    onClick={() => {
                      setPreviewMap(null);
                      setPreviewMapForm({
                        ...NewLocationForm,
                        warehouseId: selectedWarehouse?.wareHouseId,
                      });
                    }}
                  >
                    {`Reset`}
                  </button>
                  <button
                    type="button"
                    className="waves-effect waves-light btn btn-sm btn-primary rounded me-2"
                    onClick={() => generatePreview()}
                  >
                    {`Generate ${LabelConstants.Preview}`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {previewMap && (
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-body">
                  <div className="row">
                    <div className="col-8">
                      <h4 className="box-title">{LabelConstants.Preview}</h4>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-sm btn-danger rounded me-2"
                        onClick={() => discardChanges()}
                      >{`Discard`}</button>
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-sm btn-primary rounded me-2"
                        onClick={() => {
                          handleSaveLocation();
                        }}
                      >{`Save Changes`}</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="box box-transparent no-shadow border">
                      <div className="box-body">
                        <h4 className="box-title">
                          Aisle {previewMap?.aisleName}
                        </h4>
                        <div className="d-flex">
                          <div className="d-flex flex-column-reverse">
                            <div className="section-bx">
                              <div className="w-40 h-40 m-5 d-flex align-items-center justify-content-center border border-warning rounded10" />
                            </div>
                            {(
                              (previewMap && previewMap?.bays[0]?.shelfs) ??
                              []
                            ).map((_loc, _locIdx) => (
                              <div className="section-bx">
                                <div className="w-40 h-40 m-5 d-flex align-items-center justify-content-center border border-warning rounded10">
                                  {_locIdx}
                                </div>
                              </div>
                            ))}
                          </div>
                          {(previewMap?.bays ?? []).map((_bay, _bayIdx) => {
                            return (
                              <div className="d-flex flex-column-reverse">
                                <div
                                  className="section-bx"
                                  onClick={() => deleteWarehouseBay(_bayIdx)}
                                >
                                  <div className="w-40 h-40 m-5 d-flex align-items-center justify-content-center border border-warning rounded10 previewLocation">
                                    <p className="m-0 title">{_bayIdx + 1}</p>
                                    <i
                                      className="fa fa-trash icon"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                {(_bay.shelfs ?? []).map((_loc, _locIdx) => {
                                  return (
                                    <div className="section-bx">
                                      <a
                                        onClick={() =>
                                          deleteWarehouseLocation(
                                            _bayIdx,
                                            _locIdx
                                          )
                                        }
                                        className={`w-40 h-40 m-5 bg-warning-light d-block rounded10 previewLocation`}
                                      >
                                        <div className="align-items-center justify-content-center d-flex h-p100">
                                          <p className="m-0 title"> {_loc}</p>
                                          <i
                                            className="fa fa-trash icon"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                          <div className="d-flex flex-column-reverse">
                            <div
                              className="section-bx"
                              onClick={() => addBays()}
                            >
                              <div className="w-40 h-40 m-5 d-flex align-items-center justify-content-center border border-success rounded10">
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WarehouseLocationFormComponent;
