import { IContextInfo } from "../../common/models";

export class StorageService {
  //#region Context
  public clearContextInfo() {
    window.localStorage.removeItem("contextInfo");
    return {
      context: null,
      loggedIn: false,
    };
  }

  public ContextInfo() {
    const userString = window.localStorage.getItem("contextInfo");
    if (userString == null) {
      return null;
    } else {
      let context: IContextInfo = JSON.parse(userString)?.data;
      // console.log("context - ", context);
      return context;
    }
  }
  //#endregion
}
