import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { NavLink, useNavigate } from "react-router-dom";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  NewTransporterAddress,
  Statuses,
  validateEmailRegex,
} from "../../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import {
  IDocuments,
  ITransporter,
  ITransporterAddress,
  ITransporterAddressContact,
} from "../../../common/models";
import { TransporterContext } from "../../../controllers/contexts/transporter.context";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import validator from "../../../common/models/validator";
import _isEqual from "lodash/isEqual";
import _ from "lodash";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { getDateIfValid } from "../../../common/methods/globalmethods";

const VALIDATOR = {
  transName: [(value) => (_.isEmpty(value) ? `Enter Transporter Name` : null)],
  validFrom: [(value) => (_.isEmpty(value) ? `Enter  Valid Form` : null)],
  validTo: [(value) => (_.isEmpty(value) ? `Enter  Valid To` : null)],
  comments: [(value) => (_.isEmpty(value) ? `Enter  Comments` : null)],
  status: [(value) => (_.isEmpty(value) ? `Enter  status` : null)],
};

interface FormErrors {
  transName?: string;
  status?: string;
  validFrom?: string;
  validTo?: string;
  comments?: string;
}
const TransporterFormComponent = () => {
  const {
    transporterForm,
    setTransporterForm,
    saveTransporter,
    viewFiles,
    setViewFiles,
    uploadedFiles,
    setUploadedFiles,
    clearViewTransporter,
  } = useContext(TransporterContext);
  const navigate = useNavigate();
  const viewFilesList = viewFiles.filter((_file) =>
    _file.deleted !== undefined ? _file.deleted === false : true
  );
  const [error, setError] = useState<FormErrors>({});
  const [addError, setAddError] = useState(false);
  const [activeURL, setactiveURL] = useState("");

  useEffect(() => {
    if (transporterForm === null) {
      navigate(RoutingConstants.transporter, { state: { clearSearch: true } });
    }
  }, [transporterForm, navigate]);

  const handleTransporterForm = (type: string, value: any) => {
    const _form = cloneDeep(transporterForm);
    _form[type] = value;
    setTransporterForm(_form);
  };

  const handleTransporterAddressForm = (
    idx: number,
    type: string,
    value: any
  ) => {
    const _form = cloneDeep(transporterForm);
    _form["transAddresses"][idx][type] = value;
    setTransporterForm(_form);
  };

  const handleTransporterAddressContactForm = (
    parentIdx: number,
    idx: number,
    type: string,
    value: any
  ) => {
    const _form = cloneDeep(transporterForm);
    _form["transAddresses"][parentIdx]["transCommunication"][idx][type] = value;
    setTransporterForm(_form);
  };
  const handleTraporterAddressContactPhone = (
    parentIdx: number,
    idx: number,
    value: any
  ) => {
    const _form: ITransporter = cloneDeep(transporterForm);
    let _destCode: string = "";
    let _phoneNum: string = "";
    if (value.length > 4) {
      const phoneNumberParsed = parsePhoneNumber(value);
      if (phoneNumberParsed) {
        _destCode = "+" + phoneNumberParsed.countryCallingCode;
        _phoneNum = phoneNumberParsed.nationalNumber;
      } else {
        toast.error(" Please enter a valid number.");
        return;
      }
    } else {
      _destCode = value;
      _phoneNum = "";
    }

    _form["transAddresses"][parentIdx]["transCommunication"][
      idx
    ].designationCountryCode = _destCode;
    _form["transAddresses"][parentIdx]["transCommunication"][idx].phoneNumber =
      _phoneNum;
    setTransporterForm(_form);
  };

  const handleClose = () => {
    setUploadedFiles([]);
    clearViewTransporter();
  };

  const handleFileUpload = (files: any[]) => {
    console.log(files);
    const _viewFilesCount: number = viewFilesList.length;
    const _filesCount: number =
      files.length + uploadedFiles.length + (_viewFilesCount ?? 0);
    if (files.length > 0) {
      if (_filesCount > 5) {
        toast.warning(LabelConstants.FileUploadCountExceeded);
      } else {
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setUploadedFiles([...uploadedFiles, ...files]);
      }
    }
  };

  const handleRemoveFile = (idx: number) => {
    const _files = cloneDeep(uploadedFiles);
    _files.splice(idx, 1);
    setUploadedFiles(_files);
  };

  const checkForm = () => {
    let obj = {
      transName: transporterForm?.transName,
      validFrom: moment(transporterForm?.validFrom).format("DD/MM/yyyy"),
      validTo: moment(transporterForm?.validTo).format("DD/MM/yyyy"),
      comments: transporterForm?.comments,
      status: transporterForm?.status,
    };
    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);

    let hasError = true;
    if (_.isEmpty(isErrors)) {
      for (
        let _addIdx = 0;
        _addIdx < transporterForm?.transAddresses.length;
        _addIdx++
      ) {
        const _add = transporterForm?.transAddresses[_addIdx];
        if (
          _add.address1 !== "" &&
          _add.address2 !== "" &&
          _add.city !== "" &&
          _add.county !== "" &&
          _add.country !== "" &&
          _add.postCode !== ""
        ) {
          for (
            let _addContIdx = 0;
            _addContIdx < _add.transCommunication.length;
            _addContIdx++
          ) {
            const _addCont = _add.transCommunication[_addContIdx];
            if (
              _addCont.contactName !== "" &&
              _addCont.designationCountryCode !== "" &&
              _addCont.phoneNumber !== "" &&
              _addCont.phoneNumber.length === 10 &&
              _addCont.email !== "" &&
              validateEmailRegex(_addCont.email)
            ) {
              hasError = false;
            }
          }
        }
      }
    }
    if (!hasError) {
      saveTransporter();
    }
    setAddError(hasError);
  };

  const handleRemoveOldFile = (docId: number) => {
    const _files: IDocuments[] = cloneDeep(viewFiles);
    const idx: number = _files.findIndex((_) => _.documentId === docId);
    if (idx >= 0) {
      _files[idx].deleted = true;
      setViewFiles(_files);
    }
  };

  const isFormValid = (): boolean => {
    let isValid = false;
    isValid =
      transporterForm?.transName !== "" &&
      transporterForm?.validFrom !== "" &&
      transporterForm?.validTo !== "" &&
      transporterForm?.status !== "" &&
      transporterForm?.comments !== "";

    if (isValid) {
      for (
        let _addIdx = 0;
        _addIdx < transporterForm?.transAddresses.length;
        _addIdx++
      ) {
        const _add = transporterForm?.transAddresses[_addIdx];
        isValid =
          _add.address1 !== "" &&
          _add.address2 !== "" &&
          _add.city !== "" &&
          _add.county !== "" &&
          _add.country !== "" &&
          _add.postCode !== "";

        for (
          let _addContIdx = 0;
          _addContIdx < _add.transCommunication.length;
          _addContIdx++
        ) {
          const _addCont = _add.transCommunication[_addContIdx];
          isValid =
            _addCont.contactName !== "" &&
            _addCont.designationCountryCode !== "" &&
            _addCont.phoneNumber !== "" &&
            _addCont.phoneNumber.length === 10 &&
            _addCont.email !== "" &&
            validateEmailRegex(_addCont.email);
          if (!isValid) {
            break;
          }
        }
        if (!isValid) {
          break;
        }
      }
    }

    return !isValid;
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${transporterForm?.transId ? LabelConstants.Edit : LabelConstants.Create} ${LabelConstants.Transporter}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.Master}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.transporter}
                      title={LabelConstants.Transporter}
                    >
                      {LabelConstants.Transporter}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${
                    transporterForm?.transId
                      ? LabelConstants.Edit
                      : LabelConstants.Create
                  } ${LabelConstants.Transporter}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <h4 className="box-title">{LabelConstants.TransporterInfo}</h4>
                <div className="row">
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="supName">
                      {LabelConstants.TransporterName}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="supName"
                      type="text"
                      className="form-control"
                      placeholder="Transporter Name"
                      value={transporterForm?.transName}
                      style={error?.transName && { borderColor: "red" }}
                      onChange={(e) =>
                        handleTransporterForm("transName", e.target.value)
                      }
                    />
                    {error?.transName && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.transName}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={transporterForm?.status}
                      onChange={(e) =>
                        handleTransporterForm("status", e.target.value)
                      }
                    >
                      {Statuses.map((_status: string, _statusIdx: number) => {
                        return (
                          <option
                            key={`statusOption${_statusIdx}`}
                            value={_status}
                          >
                            {_status}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="validFrom">
                      {LabelConstants.ValidFrom}
                      <span className="text-danger">*</span>
                    </label>
                    {/* <input
                      className="form-control"
                      id="validFrom"
                      type="date"
                      min={new Date().toJSON().slice(0, 10)}
                      placeholder="Valid From"
                      value={transporterForm?.validFrom}
                      style={error?.validFrom && { borderColor: "red" }}
                      onChange={(e) =>
                        handleTransporterForm("validFrom", e.target.value)
                      }
                    /> */}
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(transporterForm?.validFrom)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleTransporterForm("validFrom", date);
                      }}
                    />
                    {error?.validFrom && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.validFrom}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="validTo">
                      {LabelConstants.ValidTo}
                      <span className="text-danger">*</span>
                    </label>
                    {/* <input
                      className="form-control"
                      id="validTo"
                      type="date"
                      min={transporterForm ? transporterForm?.validFrom : null}
                      placeholder="Valid To"
                      value={transporterForm?.validTo}
                      style={error?.validTo && { borderColor: "red" }}
                      onChange={(e) =>
                        handleTransporterForm("validTo", e.target.value)
                      }
                    /> */}
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(transporterForm?.validTo)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleTransporterForm("validTo", date);
                      }}
                    />
                    {error?.validTo && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.validTo}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label className="form-label" htmlFor="comments">
                      {LabelConstants.Comments}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      id={"comments"}
                      value={transporterForm?.comments}
                      onChange={(e) =>
                        handleTransporterForm("comments", e.target.value)
                      }
                      rows={5}
                      cols={5}
                      className="form-control"
                      placeholder="Commnets"
                      style={error?.transName && { borderColor: "red" }}
                    />
                    {error?.comments && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.comments}</span>
                      </label>
                    )}
                  </div>
                  <div
                    className={`form-group ${
                      uploadedFiles?.length > 0 || viewFilesList?.length > 0
                        ? "col-6"
                        : "col-12"
                    } h-auto`}
                  >
                    <label className="form-label" htmlFor="comments">
                      {LabelConstants.Files}
                    </label>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleFileUpload(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="dropzone rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            height: "calc(100% - 31px) !important",
                          }}
                        >
                          <input {...getInputProps()} />
                          <h5 className="text-center">
                            Drag 'n' drop some files here
                            <br /> or click to select files
                          </h5>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  {(uploadedFiles?.length > 0 || viewFilesList?.length > 0) && (
                    <div className={`form-group col-6`}>
                      <label className="form-label" htmlFor="comments">
                        {LabelConstants.Preview}
                      </label>
                      <div className="dropzone row">
                        {viewFilesList.map(
                          (_file: IDocuments, _fileIdx: number) => {
                            return (
                              <div className="media media-single px-0 col-6">
                                <div className="ms-0 me-15 bg-primary-light h-50 w-50 l-h-50 rounded text-center">
                                  <span className="fs-24 text-primary">
                                    <i className="fa fa-file-o"></i>
                                  </span>
                                </div>
                                <span className="title fw-500 fs-16">
                                  {_file.documentName}
                                </span>
                                <button
                                  type="button"
                                  className="waves-effect waves-circle btn btn-circle btn-danger-light btn-xs"
                                  onClick={() =>
                                    handleRemoveOldFile(_file.documentId)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            );
                          }
                        )}
                        {uploadedFiles.map((_file: any, _fileIdx: number) => {
                          return (
                            <div className="media media-single px-0 col-6">
                              <div
                                className="ms-0 me-15 bg-primary-light h-50 w-50 l-h-50 rounded text-center"
                                role="button"
                                data-bs-toggle="modal"
                                data-bs-target="#showPreviewFile"
                                onClick={() => setactiveURL(_file.preview)}
                              >
                                <span className="fs-24 text-primary">
                                  <i className="fa fa-file-o"></i>
                                </span>
                              </div>
                              <span className="title fw-500 fs-16">
                                {_file.name}
                              </span>
                              <button
                                type="button"
                                className="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs"
                                data-bs-toggle="modal"
                                data-bs-target="#showPreviewFile"
                                onClick={() => setactiveURL(_file.preview)}
                              >
                                <i className="fa fa-eye"></i>
                              </button>
                              <button
                                type="button"
                                className="waves-effect waves-circle btn btn-circle btn-danger-light btn-xs"
                                onClick={() => handleRemoveFile(_fileIdx)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h4 className="box-title">
                    {LabelConstants.TransporterAddress}
                  </h4>
                  <button
                    type="button"
                    className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-primary me-3"
                    onClick={() => {
                      const _form: ITransporter = cloneDeep(transporterForm);
                      _form.transAddresses.push(
                        cloneDeep(NewTransporterAddress)
                      );
                      setTransporterForm(_form);
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                {transporterForm?.transAddresses.map(
                  (_add: ITransporterAddress, _addIdx: number) => {
                    return (
                      <div
                        className="box box-bordered"
                        key={`supplierAddress${_addIdx}`}
                      >
                        <div
                          className="box-header with-border"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseExample${_addIdx}`}
                        >
                          <div className="d-flex justify-content-between">
                            <h4 className="box-title m-0">
                              Address #{_addIdx + 1}
                            </h4>
                            {transporterForm?.transAddresses.length > 1 && (
                              <button
                                type="button"
                                className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-danger me-3"
                                onClick={() => {
                                  const _form: ITransporter =
                                    cloneDeep(transporterForm);
                                  _form.transAddresses.splice(_addIdx, 1);
                                  setTransporterForm(_form);
                                }}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          </div>
                        </div>
                        <div
                          className="box-body collapse show"
                          id={`collapseExample${_addIdx}`}
                        >
                          <div className="row">
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.Address1}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address 1"
                                value={_add.address1 ?? ""}
                                onChange={(e) =>
                                  handleTransporterAddressForm(
                                    _addIdx,
                                    "address1",
                                    e.target.value
                                  )
                                }
                                style={
                                  error?.transName && { borderColor: "red" }
                                }
                              />
                              {addError && _.isEmpty(_add.address1) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter Address1"}
                                  </span>
                                </label>
                              )}
                              {_add.address1.length > 100 && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {
                                      "Address1 must be less than 100 characters"
                                    }
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.Address2}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address 2"
                                value={_add.address2 ?? ""}
                                onChange={(e) =>
                                  handleTransporterAddressForm(
                                    _addIdx,
                                    "address2",
                                    e.target.value
                                  )
                                }
                                style={
                                  error?.transName && { borderColor: "red" }
                                }
                              />
                              {addError && _.isEmpty(_add.address2) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter Address2"}
                                  </span>
                                </label>
                              )}
                              {_add.address2.length > 100 && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {
                                      "Address1 must be less than 100 characters"
                                    }
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.City}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                value={_add.city ?? ""}
                                onChange={(e) =>
                                  handleTransporterAddressForm(
                                    _addIdx,
                                    "city",
                                    e.target.value
                                  )
                                }
                                style={
                                  error?.transName && { borderColor: "red" }
                                }
                              />

                              {addError && _.isEmpty(_add.city) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter City"}
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.County}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={LabelConstants.County}
                                value={_add.county ?? ""}
                                onChange={(e) =>
                                  handleTransporterAddressForm(
                                    _addIdx,
                                    "county",
                                    e.target.value
                                  )
                                }
                                style={
                                  error?.transName && { borderColor: "red" }
                                }
                              />
                              {addError && _.isEmpty(_add.county) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter County"}
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.Country}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                value={_add.country ?? ""}
                                onChange={(e) =>
                                  handleTransporterAddressForm(
                                    _addIdx,
                                    "country",
                                    e.target.value
                                  )
                                }
                                style={
                                  error?.transName && { borderColor: "red" }
                                }
                              />
                              {addError && _.isEmpty(_add.country) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter Counrty"}
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.PostCode}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Post Code"
                                value={_add.postCode ?? ""}
                                onChange={(e) =>
                                  handleTransporterAddressForm(
                                    _addIdx,
                                    "postCode",
                                    e.target.value
                                  )
                                }
                                style={
                                  error?.transName && { borderColor: "red" }
                                }
                              />
                              {addError && _.isEmpty(_add.postCode) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter PostCode"}
                                  </span>
                                </label>
                              )}
                            </div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <h4 className="box-title">
                              {LabelConstants.CommunicationDetails}
                            </h4>
                            <button
                              type="button"
                              className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-primary me-3"
                              onClick={() => {
                                const _form: ITransporter =
                                  cloneDeep(transporterForm);
                                _form.transAddresses[
                                  _addIdx
                                ].transCommunication.push(
                                  cloneDeep(NewTransporterAddress)
                                );
                                setTransporterForm(_form);
                              }}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                          {_add.transCommunication.map(
                            (
                              _addCont: ITransporterAddressContact,
                              _addContIdx: number
                            ) => {
                              return (
                                <div
                                  key={`supplierAddressContact${_addIdx}${_addContIdx}`}
                                >
                                  <div className="d-flex justify-content-between">
                                    <h5 className="box-title m-0">
                                      Contact #{_addContIdx + 1}
                                    </h5>
                                    {_add.transCommunication.length > 1 && (
                                      <button
                                        type="button"
                                        className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-danger me-3"
                                        onClick={() => {
                                          const _form: ITransporter =
                                            cloneDeep(transporterForm);
                                          _form.transAddresses[
                                            _addIdx
                                          ].transCommunication.splice(
                                            _addContIdx,
                                            1
                                          );
                                          setTransporterForm(_form);
                                        }}
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    )}
                                  </div>
                                  <div className="row">
                                    <div className="col-4">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {LabelConstants.ContactName}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Contact Name"
                                          value={_addCont.contactName}
                                          onChange={(e) => {
                                            const textOnly =
                                              e.target.value.replace(
                                                /[0-9]/g,
                                                ""
                                              ); // Remove numbers from input
                                            handleTransporterAddressContactForm(
                                              _addIdx,
                                              _addContIdx,
                                              "contactName",
                                              textOnly
                                            );
                                          }}
                                        />
                                        {addError &&
                                          _.isEmpty(_addCont.contactName) && (
                                            <label className="form-label">
                                              <span style={{ color: "red" }}>
                                                {"Enter Contact Name"}
                                              </span>
                                            </label>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {LabelConstants.Phone}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <PhoneInput
                                          className={`form-control`}
                                          defaultCountry="GB"
                                          value={`${_addCont.designationCountryCode}${_addCont.phoneNumber}`}
                                          onChange={(e) => {
                                            console.log(e);
                                            handleTraporterAddressContactPhone(
                                              _addIdx,
                                              _addContIdx,
                                              e ?? ""
                                            );
                                          }}
                                          style={
                                            error?.transName && {
                                              borderColor: "red",
                                            }
                                          }
                                        />
                                        {addError &&
                                        _.isEmpty(_addCont.phoneNumber) ? (
                                          <label className="form-label">
                                            <span style={{ color: "red" }}>
                                              {"Enter Phone Number"}
                                            </span>
                                          </label>
                                        ) : addError &&
                                          _addCont.phoneNumber.length !== 10 ? (
                                          <label className="form-label">
                                            <span style={{ color: "red" }}>
                                              {"Enter Proper phone Number"}
                                            </span>
                                          </label>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {LabelConstants.Email}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          placeholder="Email"
                                          value={_addCont.email ?? ""}
                                          onChange={(e) =>
                                            handleTransporterAddressContactForm(
                                              _addIdx,
                                              _addContIdx,
                                              "email",
                                              e.target.value
                                            )
                                          }
                                          style={
                                            error?.transName && {
                                              borderColor: "red",
                                            }
                                          }
                                        />
                                        {addError &&
                                        _.isEmpty(_addCont.email) ? (
                                          <label className="form-label">
                                            <span style={{ color: "red" }}>
                                              {"Enter Email"}
                                            </span>
                                          </label>
                                        ) : addError &&
                                          !validateEmailRegex(
                                            _addCont.email
                                          ) ? (
                                          <label className="form-label">
                                            <span style={{ color: "red" }}>
                                              {"Enter Proper Email"}
                                            </span>
                                          </label>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleClose()}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary float-end"
                  onClick={() => checkForm()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
          <div className="modal fade" id="showPreviewFile" tabIndex={-1}>
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">File Preview</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ height: "90vh" }}>
                  <iframe src={activeURL} width={"100%"} height={"100%"} />
                </div>
                <div className="modal-footer modal-footer-uniform">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    onClick={() => {}}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TransporterFormComponent;
