import { Outlet } from "react-router-dom";
import useEmployee from "../../../controllers/hooks/useEmployee";
import { EmployeeContext } from "../../../controllers/contexts/employee.context";

const EmployeeLayoutComponent = () => {
    const employeeFunctions = useEmployee();

    return <EmployeeContext.Provider value={employeeFunctions}>
        <Outlet />
    </EmployeeContext.Provider>;
}

export default EmployeeLayoutComponent;
