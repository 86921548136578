import {
  WarehouseApiBase,
  WarehouseLayoutList,
  WarehouseList,
  WarehouseLocationEdit,
  WarehouseLocationSave,
  WarehouseSave,
  WarehouseStatusUpdate,
  WarehouseUpdateById,
  GetLocationDtlsById,
  OrdersApiBase,
  getWarehousStatsById,
  LocationTransfer,
  updateLocationsArray,
} from "../../common/constants/apiConstants";
import {
  IStatusUpdate,
  IWarehouse,
  IWarehouseBayLocation,
  IWarehouseMap,
  IWarehouseMapForm,
  ILocationDetails,
  ILocationUpdater,
} from "../../common/models";
import { AxiosService } from "../generics/axios.service";
import { IWarehouseService } from "../interfaces/IWarehouse";

export class WarehouseService implements IWarehouseService {
  private axiosService: AxiosService = new AxiosService();

  getWarehouses = async (): Promise<IWarehouse[]> => {
    try {
      const result: any = await this.axiosService.Get(
        WarehouseList,
        WarehouseApiBase
      );
      const _list: IWarehouse[] = result.data ?? [];
      console.log("Response: Warehouse List - ", _list);
      return _list;
    } catch (error) {
      console.error("Error: Warehouse List - ", error);
      return [];
    }
  };

  getWarehouseMapById = async (id: number): Promise<IWarehouseMap[]> => {
    try {
      const result: any = await this.axiosService.Get(
        WarehouseLayoutList(id),
        WarehouseApiBase
      );
      const _list: IWarehouseMap[] = result.data ?? [];
      console.log("Response: Warehouse Map List - ", _list);
      return _list;
    } catch (error) {
      console.error("Error: Warehouse Map List - ", error);
      return [];
    }
  };

  getWarehousStatsById = async (id: number): Promise<any> => {
    try {
      const result: any = await this.axiosService.Get(
        getWarehousStatsById(id),
        OrdersApiBase
      );
      return result.data ?? [];
    } catch (error) {
      console.error("Error: Warehouse Stats List - ", error);
      return [];
    }
  };

  saveWarehouse = async (warehouseForm: IWarehouse) => {
    try {
      if (!warehouseForm?.wareHouseId)
        return await this.axiosService.Post(
          WarehouseSave,
          warehouseForm,
          WarehouseApiBase
        );
      else
        await this.axiosService.Put(
          WarehouseUpdateById(warehouseForm?.wareHouseId),
          warehouseForm,
          WarehouseApiBase
        );
      return true;
    } catch (error) {
      console.error(
        "Error: Save Warehouse - ",
        error?.response?.data[0]?.errorMessage
      );
      return error.response.data[0]?.errorMessage ?? false;
    }
  };

  saveWarehouseLocation = async (warehouseLocationForm: any) => {
    try {
      return await this.axiosService.Post(
        WarehouseLocationSave,
        warehouseLocationForm,
        WarehouseApiBase
      );
    } catch (error) {
      console.error("Error: Save Warehouse - ", error);
      return false;
    }
  };

  editWarehouseLocation = async (locData: any) => {
    try {
      return await this.axiosService.Put(
        WarehouseLocationEdit(locData.locId),
        locData,
        WarehouseApiBase
      );
    } catch (error) {
      console.error("Error: Save Warehouse - ", error);
      return false;
    }
  };

  updateLocation = async (editLocation: IWarehouseBayLocation) => {
    try {
      return await this.axiosService.Put(
        WarehouseLocationEdit(editLocation.locId),
        editLocation,
        WarehouseApiBase
      );
    } catch (error) {
      console.error("Error: Save Warehouse - ", error);
      return false;
    }
  };

  editWarehouseLocationsArray = async (updater: ILocationUpdater) => {
    try {
      return await this.axiosService.Post(
        updateLocationsArray,
        updater,
        WarehouseApiBase
      );
    } catch (error) {
      console.error("Error: editWarehouseArray - ", error);
      return false;
    }
  };

  updateStatus = async (status: IStatusUpdate): Promise<boolean> => {
    try {
      await this.axiosService.Put(
        WarehouseStatusUpdate,
        status,
        WarehouseApiBase
      );
      return true;
    } catch (error) {
      console.error("Error: Update Warehouse Status - ", error);
      return false;
    }
  };

  generatePreview = (previewForm: IWarehouseMapForm | null) => {
    if (!previewForm) {
      console.error("Preview form data is missing");
      return null;
    }

    const _newAsile: any = {
      warehouseId: previewForm.warehouseId || "",
      aisleName: previewForm.asileName || "",
      bays: [],
    };

    for (let bayIdx = 0; bayIdx < previewForm.bays; bayIdx++) {
      const _newBay: any = {
        bayId: bayIdx + 1,
        shelfs: [],
      };
      for (let shlfIdx = 0; shlfIdx < previewForm.shelves; shlfIdx++) {
        _newBay.shelfs.push(
          `${_newAsile.aisleName}${shlfIdx}${String(_newBay.bayId).padStart(
            2,
            "0"
          )}`
        );
      }
      _newAsile.bays.push(_newBay);
    }
    console.log("New Aisle - ", _newAsile);
    return _newAsile;
  };
  GetLocationDtlsById = async (locId: number): Promise<ILocationDetails[]> => {
    try {
      const result: any = await this.axiosService.Get(
        GetLocationDtlsById(locId),
        OrdersApiBase
      );
      const _list: ILocationDetails[] = result.data ? result.data : [];
      console.log("Response: Location Details - ", _list);
      return _list;
    } catch (error) {
      console.error("Error: Location Details - ", error);
      return [];
    }
  };

  saveLocationTransfer = async (locationTransfer: any): Promise<boolean> => {
    try {
      const locationTransferResponse = await this.axiosService.Post(
        LocationTransfer,
        locationTransfer,
        OrdersApiBase
      );
      if (locationTransferResponse) {
        console.log("location tran", locationTransferResponse);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(
        "Error: Save Warehouse - ",
        error?.response?.data[0]?.errorMessage
      );
      return false;
    }
  };
}
