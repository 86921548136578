import { useState } from "react";
import useAuth from "../../controllers/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
    const navigate = useNavigate();
    const { changePwdRequest, setChangePwdRequest, changePassword, changePasswordStep1 } = useAuth();
    const [newPasswordSectionInCP, setNewPasswordSectionInCP] = useState(false);
    const [CpformErrors, setCpFormErrors] = useState({
        username: "",
        passcode: "",
        newPassword: "",
        confirmPassword: "",
        oldPassword:""
      });
    const handleFpUsernameChange = (e) => {
        setChangePwdRequest({ ...changePwdRequest, username: e.target.value });
        setCpFormErrors({ ...CpformErrors, username: "" });
      };
      const handleFpOldPasswordChange = (e) => {
        setChangePwdRequest({ ...changePwdRequest, oldPassword: e.target.value });
        setCpFormErrors({ ...CpformErrors, oldPassword: "" });
      };
      const handleFpNewPasswordChange = (e) => {
        setChangePwdRequest({ ...changePwdRequest, newPassword: e.target.value });
        setCpFormErrors({ ...CpformErrors, newPassword: "" });
      };
      const handleFpConfirmPasswordChange = (e) => {
        setChangePwdRequest({ ...changePwdRequest, confirmPassword: e.target.value });
        setCpFormErrors({ ...CpformErrors, confirmPassword: "" });
      };
      const handleFpPasscodeChange = (e) => {
        setChangePwdRequest({ ...changePwdRequest, passcode: e.target.value });
        setCpFormErrors({ ...CpformErrors, passcode: "" });
      };
      const cpFormValidation = () => {
        const { username, newPassword, passcode, oldPassword, confirmPassword } = changePwdRequest;
        const usernameError = username ? "" : "Username is required";
        const newPasswordError = newPassword ? "" : "New Password is required";
        const oldPasswordError = oldPassword ? "" : "Old Password is required";
        const confirmPasswordError = confirmPassword ? newPassword === confirmPassword ? "" : "Password should match with New password" : "Confirm Password is required";
        const passcodeError = passcode ? "" : "Passcode is required";
        setCpFormErrors({
          username: usernameError,
          newPassword: newPasswordError,
          oldPassword: oldPasswordError,
          confirmPassword: confirmPasswordError,
          passcode: passcodeError,
        });
        return !usernameError && !newPasswordError && !oldPasswordError && !passcodeError && !confirmPasswordError;
      };

      const cpNewPassFormValidation = () => {
        const { username, newPassword, passcode, oldPassword, confirmPassword } = changePwdRequest;
        const usernameError = username ? "" : "Username is required";
        const oldPasswordError = oldPassword ? "" : "Old Password is required";
        const passcodeError = passcode ? "" : "Passcode is required";
        setCpFormErrors({
          username: usernameError,
          newPassword: '',
          oldPassword: oldPasswordError,
          confirmPassword: '',
          passcode: passcodeError,
        });
        return !usernameError && !oldPasswordError && !passcodeError;
      };
    
      const handleCpSubmit = async (e) => {
        e.preventDefault();
        console.table(changePwdRequest)
        const isValid = cpFormValidation();
        if(isValid){
            let successCP = await changePassword();
            if(successCP){
              setNewPasswordSectionInCP(false)
                navigate("/");
            }
        }
      };

      const handleNewPasswordSectionInCP = async (e) => {
        e.preventDefault();
        const isValid = cpNewPassFormValidation();
        if(isValid){
          let successCP = await changePasswordStep1();
          if(successCP){
            setNewPasswordSectionInCP(true)
          }
      }
      }

    return(
        <div className="hold-transition theme-primary" style={{height:'89vh'}}>
          <div className="container h-p100">
            <div className="row align-items-center justify-content-md-center h-p100">
              <div className="col-12">
                <div className="row justify-content-center g-0">
                  <div className="col-lg-5 col-md-5 col-12">
                    <div className="bg-white rounded10 shadow-lg">
                      <div className="content-top-agile p-20 pb-0">
                        <h2 className="text-primary"> Change Password</h2>
                        <p className="mb-0">Enter below details to continue.</p>
                      </div>
                      <div className="p-40 pt-10">
                        {/* <div className="form-group">
                          <div className="input-group">
                            <span className="input-group-text bg-transparent">
                              <i className="ti-user"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control ps-15 bg-transparent"
                              placeholder="Username"
                              value={changePwdRequest.username}
                              onChange={handleFpUsernameChange}
                            />
                          </div>
                          {CpformErrors.username && (
                            <p className="text-danger">{CpformErrors.username}</p>
                          )}
                        </div> */}
                        {!newPasswordSectionInCP ?
                        <>
                        <div className="form-group">
                          <div className="input-group mt-3">
                            <span className="input-group-text bg-transparent">
                              <i className="ti-lock"></i>
                            </span>
                            <input
                              type="password"
                              className="form-control ps-15 bg-transparent"
                              placeholder="Old password"
                              value={changePwdRequest.oldPassword}
                              onChange={handleFpOldPasswordChange}
                            />
                          </div>
                          {CpformErrors.oldPassword && (
                            <p className="text-danger">{CpformErrors.oldPassword}</p>
                          )}
                        </div>
                        <div className="form-group">
                        <div className="input-group mt-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-key"></i>
                          </span>
                          <input
                            type="number"
                            className="form-control ps-15 bg-transparent"
                            placeholder="Passcode"
                            value={changePwdRequest.passcode ? changePwdRequest.passcode : ''}
                            onChange={handleFpPasscodeChange}
                          />
                        </div>
                        {CpformErrors.passcode && (
                          <p className="text-danger">{CpformErrors.passcode}</p>
                        )}
                      </div>
                      </> :
                      <>
                        <div className="form-group">
                          <div className="input-group mt-3">
                            <span className="input-group-text bg-transparent">
                              <i className="ti-lock"></i>
                            </span>
                            <input
                              type="password"
                              className="form-control ps-15 bg-transparent"
                              placeholder="New password"
                              value={changePwdRequest.newPassword}
                              onChange={handleFpNewPasswordChange}
                            />
                          </div>
                          {CpformErrors.newPassword && (
                            <p className="text-danger">{CpformErrors.newPassword}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <div className="input-group mt-3">
                            <span className="input-group-text bg-transparent">
                              <i className="ti-lock"></i>
                            </span>
                            <input
                              type="password"
                              className="form-control ps-15 bg-transparent"
                              placeholder="Confirm password"
                              value={changePwdRequest.confirmPassword}
                              onChange={handleFpConfirmPasswordChange}
                            />
                          </div>
                          {CpformErrors.confirmPassword && (
                            <p className="text-danger">{CpformErrors.confirmPassword}</p>
                          )}
                        </div></>}
                        
                        
                        <div className="row">
                        <div className="col-12 text-center">
                        <button
                              type="submit"
                              className="btn btn-info mt-10 mx-10"
                              onClick={()=>navigate("/")}
                            >
                              Cancel
                            </button>
                            {newPasswordSectionInCP ?
                            <button
                              type="submit"
                              className="btn btn-danger mt-10 mx-10"
                              onClick={handleCpSubmit}
                            >
                              SUBMIT
                            </button>
                            :<button
                            type="submit"
                            className="btn btn-danger mt-10 mx-10"
                            onClick={handleNewPasswordSectionInCP}
                          >
                            SUBMIT
                          </button>}
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default ChangePassword;