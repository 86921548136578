import moment from "moment";
import { NavLink } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";

const Profile = () => {
    const profileData = localStorage.getItem("contextInfo") ? JSON.parse(localStorage.getItem("contextInfo")).data : null
    return (
        <>
        <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.Customer}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Profile
                  </li>                  
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
        <div className="col-12 col-lg-12 col-xl-12 p-4">
            {profileData ?
            <>
            <div className="box box-widget widget-user">
                <div className="widget-user-header bg-img bbsr-0 bber-0" style={{ "background": "url('../images/gallery/full/10.jpg') center center;" }} data-overlay="5">
                    <h3 className="widget-user-username text-white">{profileData?.empName}</h3>
                    <h6 className="widget-user-desc text-white">{profileData?.designation ?? 'N/A'}</h6>
                </div>
            </div>
            <div className="box">
                <div className="box-body box-profile">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <p>Email :<span className="text-gray ps-10">{profileData?.empAddresses[0]?.empCommunication[0]?.email}</span> </p>
                                <p>Phone Number :<span className="text-gray ps-10">{profileData?.empAddresses[0]?.empCommunication[0]?.phoneNumber}</span> </p>
                                <p>Date Of Birth :<span className="text-gray ps-10">{profileData?.dob ? moment(profileData?.dob).format('DD/MM/yyyy') : 'N/A'}</span> </p>                                
                                <p>Gender :<span className="text-gray ps-10">{profileData?.gender}</span></p>
                                <p>status :<span className="text-gray ps-10">{profileData?.status}</span></p>
                                <p>username :<span className="text-gray ps-10">{profileData?.username}</span></p>
                                <p>Date Of Joining :<span className="text-gray ps-10">{profileData?.dateOfJoining ? moment(profileData?.dateOfJoining).format('DD/MM/yyyy') : 'N/A'}</span> </p>
                                <p>Date Of Resign :<span className="text-gray ps-10">{profileData?.dateOfResign ? moment(profileData?.dateOfResign).format('DD/MM/yyyy') : 'N/A'}</span></p>
                                <p>Address :<span className="text-gray ps-10">{`${profileData?.empAddresses[0]?.address1}, ${profileData?.empAddresses[0]?.address2}, ${profileData?.empAddresses[0]?.city}, ${profileData?.empAddresses[0]?.county}, ${profileData?.empAddresses[0]?.country}, ${profileData?.empAddresses[0]?.postCode}`}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </> : 'No Data available'}
        </div>
    </>
    )
}

export default Profile;