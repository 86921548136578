import { useContext } from "react";

import { cloneDeep } from "lodash";
import { LabelConstants } from "../../../common/constants/labelConstants";
import {
  EmptyStatusUpdate,
  GoodReceiptStatus,
} from "../../../common/constants/generalConstants";
import { GrnContext } from "../../../controllers/contexts/grn.context";

const GRNStatusUpdateComponent = () => {
  const { selectedList, setSelectedList, updateStatus } =
    useContext(GrnContext);

  const handleSupplierForm = (type: string, value: any) => {
    const _form = cloneDeep(selectedList);
    _form[type] = value;
    setSelectedList(_form);
  };

  const isFormValid = (): boolean => {
    let isValid = false;
    isValid = selectedList?.comments !== "" && selectedList?.status !== "";
    return !isValid;
  };

  return (
    <div className="modal fade" id="grnupdateStatus" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-25">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{LabelConstants.UpdateStatus}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group col-6">
                <label className="form-label">
                  {LabelConstants.Status}
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  value={selectedList?.status}
                  onChange={(e) => handleSupplierForm("status", e.target.value)}
                >
                  <option value={""} hidden>
                    {LabelConstants.SelectStatus}
                  </option>
                  {GoodReceiptStatus?.map((_status, _statusIdx: number) => {
                    if (
                      selectedList?.status === "APPROVED" &&
                      _status.value !== "APPROVED"
                    ) {
                      return;
                    }

                    return (
                      <option
                        key={`statusOption${_statusIdx}`}
                        value={_status?.value}
                      >
                        {_status?.text}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group col-12">
                <label className="form-label" htmlFor="comments">
                  {LabelConstants.Comments}
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  id={"comments"}
                  value={selectedList?.comments}
                  onChange={(e) =>
                    handleSupplierForm("comments", e.target.value)
                  }
                  rows={5}
                  cols={5}
                  className="form-control"
                  placeholder="Commnets"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer modal-footer-uniform">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={() => setSelectedList(EmptyStatusUpdate)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary float-end"
              data-bs-dismiss="modal"
              disabled={isFormValid()}
              onClick={() => updateStatus()}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GRNStatusUpdateComponent;
