import React, { useContext, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { EmployeeContext } from "../../../controllers/contexts/employee.context";
import { IEmployee, IListConfig, IStatusUpdate } from "../../../common/models";
import { cloneDeep } from "lodash";
import {
  EmptyStatusUpdate,
  NewEmployee,
} from "../../../common/constants/generalConstants";
import PageSortDescription from "../../common/pageSortDescription.component";
import ReactPaginate from "react-paginate";
import { GenericHelperService } from "../../../api/generics/helper.service";
import EmployeeStatusUpdateComponent from "./employeeStatusUpdate.component";
import DeleteConfirmationComponent from "../../common/deleteConfirmation.component";
import moment from "moment";
import useAuth from "../../../controllers/hooks/useAuth";
import LogsModalComponent from "../../common/logsModal.component";

const EmployeeComponent = () => {
  const {
    employeeList,
    employeeForm,
    filters,
    selectedList,
    setSelectedList,
    setFilters,
    setEmployeeForm,
    deleteEmployee,
    getLogsEmployee,
    employeeLogs,
  } = useContext(EmployeeContext);
  const { isAccessDisabled } = useAuth();
  const helperService: GenericHelperService = new GenericHelperService();
  const navigate = useNavigate();
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [createdDate, setcreatedDate] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const handleShowLogsModal = (_emp: IEmployee) => {
    getLogsEmployee(_emp.empId);
    setShowLogsModal(true);
  };

  const handleCloseLogsModal = () => {
    setShowLogsModal(false);
  };

  const searchQueryChange = (event: any) => {
    const _searchQuery = event.target.value;
    setFilters({ ...filters, searchQuery: _searchQuery });
  };

  const handleSort = (column: string, isDate: boolean = false) => {
    const _filters: IListConfig = cloneDeep(filters);
    if (_filters.sortItem === column) {
      _filters.sortDirection =
        _filters.sortDirection === "asc" ? "desc" : "asc";
      _filters.sortIsDate = isDate;
    } else {
      _filters.sortItem = column;
      _filters.sortDirection = "asc";
      _filters.sortIsDate = isDate;
    }
    setFilters(_filters);
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
      _status.ids = employeeList.map((_prd) => _prd.empId);
      setSelectedList(_status);
    } else {
      setSelectedList(cloneDeep(EmptyStatusUpdate));
    }
  };

  const handleSelectItem = (item: IEmployee, event: any) => {
    const _status: IStatusUpdate = cloneDeep(selectedList);
    if (event.target.checked) {
      _status.ids.push(item.empId);
    } else {
      const _itmIdx = _status.ids.findIndex((_itm) => _itm === item.empId);
      if (_itmIdx > -1) {
        _status.ids.splice(_itmIdx, 1);
      }
    }
    setSelectedList(_status);
  };

  const setUpdateStatus = (prod: IEmployee) => {
    const _status: IStatusUpdate = cloneDeep(EmptyStatusUpdate);
    _status.ids = [prod.empId];
    _status.status = prod.status;
    setSelectedList(_status);
  };

  const cancelDelete = () => {
    setEmployeeForm(null);
  };

  const confirmDelete = () => {
    deleteEmployee();
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{LabelConstants.Employee}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.Master}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {LabelConstants.Employee}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex">
                    <div className="input-group w-p50">
                      <span className="input-group-addon bg-lighter border-0">
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control bg-lighter border-0"
                        placeholder="Search..."
                        id={`search`}
                        value={filters.searchQuery}
                        onChange={(event) => searchQueryChange(event)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    <button
                      disabled={isAccessDisabled("MD", "create", true, "EMP")}
                      type="button"
                      className="waves-effect waves-light btn btn-success rounded"
                      onClick={() => {
                        setEmployeeForm(cloneDeep(NewEmployee));
                        navigate(RoutingConstants.employeeCreate);
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      {`${LabelConstants.Create} ${LabelConstants.Employee}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="table-responsive">
                  <table className="table table-hover table-bordered mb-0">
                    <thead className="table-primary">
                      <tr>
                        {/* <th>
                        <input type="checkbox" id={`md_checkbox_all`} className="chk-col-primary" checked={employeeList?.length > 0 && selectedList?.ids.length === employeeList?.length} onChange={(event) => handleSelectAll(event)} />
                        <label htmlFor={`md_checkbox_all`}></label>
                      </th> */}
                        {/* <th onClick={() => handleSort("empId")}>
                          {LabelConstants.ID}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "empId"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th> */}
                        <th onClick={() => handleSort("empName")}>
                          {LabelConstants.EmployeeName}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "empName"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("gender")}>
                          {LabelConstants.Gender}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "gender"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("dob")}>
                          {LabelConstants.DateofBirth}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "dob"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("dateOfJoining")}>
                          {LabelConstants.DateofJoining}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "dateOfJoining"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("dateOfResign")}>
                          {LabelConstants.DateofResignation}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "dateOfResign"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("modifiedDate")}>
                          {LabelConstants.ModifiedDate}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "modifiedDate"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th onClick={() => handleSort("status")}>
                          {LabelConstants.Status}
                          <i
                            className={`ms-2 fa fa-sort${
                              filters?.sortItem === "status"
                                ? `-${filters?.sortDirection} text-primary`
                                : " text-fade"
                            }`}
                          />
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeList.map((_emp, _empIdx) => (
                        <tr>
                          {/* <td>
                          <input type="checkbox" id={`md_checkbox_${_empIdx}`} className="chk-col-primary" checked={selectedList?.ids.findIndex(_itm => _itm === _emp.empId) >= 0} onChange={(event) => handleSelectItem(_emp, event)} />
                          <label htmlFor={`md_checkbox_${_empIdx}`}></label>
                        </td> */}
                          {/* <td>{_emp.empId}</td> */}
                          <td>{_emp.empName}</td>
                          <td>{_emp.gender}</td>
                          <td>{moment(_emp.dob).format("DD/MM/yyyy")}</td>
                          <td>
                            {moment(_emp.dateOfJoining).format("DD/MM/yyyy")}
                          </td>
                          <td>
                            {moment(_emp.dateOfResign).format("DD/MM/yyyy")}
                          </td>
                          <td>
                            {moment(_emp.modifiedDate).format("DD/MM/yyyy")}
                          </td>
                          <td>
                            <span
                              style={
                                isAccessDisabled(
                                  "MD",
                                  "update_status",
                                  true,
                                  "EMP"
                                )
                                  ? { pointerEvents: "none", opacity: 0.5 }
                                  : {}
                              }
                              role="button"
                              data-bs-toggle="modal"
                              data-bs-target="#updateStatus"
                              onClick={() => setUpdateStatus(_emp)}
                              className={`badge bg-${helperService.getStatusColor(
                                _emp.status
                              )}-light rounded-pill`}
                            >
                              {_emp.status}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-start">
                              <button
                                type="button"
                                disabled={isAccessDisabled(
                                  "MD",
                                  "view",
                                  true,
                                  "EMP"
                                )}
                                className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                onClick={() => {
                                  setEmployeeForm(cloneDeep(_emp));
                                  navigate(RoutingConstants.employeeDetails);
                                }}
                              >
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </button>
                              <button
                                type="button"
                                disabled={isAccessDisabled(
                                  "MD",
                                  "edit",
                                  true,
                                  "EMP"
                                )}
                                className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary me-2 float-end`}
                                onClick={() => {
                                  setEmployeeForm(cloneDeep(_emp));
                                  navigate(RoutingConstants.employeeEdit);
                                }}
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <button
                                type="button"
                                disabled={isAccessDisabled(
                                  "MD",
                                  "delete",
                                  true,
                                  "EMP"
                                )}
                                className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                onClick={() => {
                                  setEmployeeForm(cloneDeep(_emp));
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#deleteConfirmation"
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <button
                                type="button"
                                className={`waves-effect waves-light btn btn-sm btn-rounded btn-secondary float-end me-2`}
                                onClick={() => {
                                  setcreatedDate(_emp?.createdDate);
                                  setcreatedBy(_emp?.createdBy);
                                  handleShowLogsModal(_emp);
                                }}
                              >
                                <i
                                  className="fa fa-book"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box-body p-3">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <PageSortDescription
                      filters={filters}
                      dataList={employeeList}
                    />
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    {filters.totalPageCount > 1 && (
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="productorder_paginate"
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={
                            filters && filters.totalPageCount < 2
                              ? 0
                              : filters.totalPageCount
                          }
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          pageClassName={`paginate_button page-item`}
                          pageLinkClassName="page-link"
                          activeLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          breakLinkClassName="page-link"
                          breakClassName={`paginate_button page-item`}
                          disabledClassName={`disabled`}
                          previousClassName={`paginate_button page-item previous`}
                          nextClassName={`paginate_button page-item next`}
                          onPageChange={(e) => {
                            setFilters({
                              ...filters,
                              currentPage: e.selected + 1,
                            });
                          }}
                          containerClassName={`pagination mb-1`}
                          activeClassName={`active`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeStatusUpdateComponent />
      <DeleteConfirmationComponent
        title={employeeForm?.empName ?? ""}
        cancel={cancelDelete}
        confirm={confirmDelete}
      />
      <LogsModalComponent
        onOpen={showLogsModal}
        onClose={handleCloseLogsModal}
        logs={employeeLogs}
        createdDate={createdDate}
        createdBy={createdBy}
      />
    </>
  );
};

export default EmployeeComponent;
