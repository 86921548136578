import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import {
  NewDeleteConfirmationDialog,
  NewSalesOrder,
  SalesOrderCreationStatuses,
} from "../../common/constants/generalConstants";
import {
  ICustomer,
  ICustomerAddress,
  IDeleteConfirmationProps,
  IProducts,
} from "../../common/models";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  getDateIfValid,
  getNumberForString,
  getProductCatNameById,
} from "../../common/methods/globalmethods";
import { SalesOrderContext } from "../../controllers/contexts/salesorder.context";
import validator from "../../common/models/validator";
import { Modal } from "bootstrap";
import { cloneDeep } from "lodash";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import { ISODetails, ISalesOrder } from "../../common/models/salesorder";
import Select from "react-select";
import dayjs from "dayjs";

var myModal;
const VALIDATOR = {
  customerId: [(value) => (value <= 0 ? `Enter customer name!!` : null)],
  status: [(value) => (_.isEmpty(value) ? `Enter valid status!!` : null)],
  customerRefNum: [
    (value) => (_.isEmpty(value) ? `Enter reference number!!` : null),
  ],
  reason: [(value) => (_.isEmpty(value) ? `Enter description!!` : null)],
  createdDate: [(value) => (_.isEmpty(value) ? `Enter valid date!!` : null)],
  expDelDate: [(value) => (_.isEmpty(value) ? `Enter valid date!!` : null)],
  totalTaxAmount: [(value) => (value <= 0 ? `Invalid tax amount!!` : null)],
  addressId: [(value) => (_.isEmpty(value) ? `Select valid address!!` : null)],
  carrierChargeAmount: [
    (value) => (value < 0 ? `Enter carrier charges!!` : null),
  ],
};

interface FormErrors {
  customerId?: string;
  status?: string;
  customerRefNum?: string;
  reason?: string;
  createdDate?: string;
  expDelDate?: string;
  totalTaxAmount?: number;
  addressId?: number;
  carrierChargeAmount?: number;
}

const SalesOrderFormcomponent = () => {
  const {
    getNextSONumber,
    clearSoNumber,
    nextSONumber,
    productsList,
    productCateList,
    createNewSalesOrder,
    customersList,
    getProductsList,
    clearSalerOrder,
    getStockStatusColor,
    checkStockOnHand,
  } = useContext(SalesOrderContext);
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState<ISalesOrder>(
    cloneDeep(NewSalesOrder)
  );
  const [searchProduct, setSearchProduct] = useState<any>("");
  const [filteredProducts, setFilteredProducts] =
    useState<IProducts[]>(productsList);
  const [error, setError] = useState<FormErrors>({});
  const [dialoginfo, setDialoginfo] = useState<IDeleteConfirmationProps>();

  useEffect(() => {
    if (!nextSONumber) {
      getNextSONumber();
    }
    myModal = new Modal(document.getElementById("deleteConfirmation"), {});
    addProductsIfEmpty();
    return () => {
      clearSoNumber();
      clearSalerOrder();
    };
  }, []);

  const addProductsIfEmpty = async () => {
    if (filteredProducts.length <= 0) {
      let products = await getProductsList();
      setFilteredProducts(products);
    }
  };

  useEffect(() => {
    const filteredList = productsList.filter((product: IProducts) => {
      const searchItem = searchProduct.toLowerCase();
      return (
        product?.productName?.toLowerCase()?.includes(searchItem) ||
        product?.productCode?.toLowerCase()?.includes(searchItem)
      );
    });

    setFilteredProducts(filteredList);
  }, [searchProduct]);

  const showDialog = (
    onCancel: Function,
    onConfirm: Function,
    header: string,
    title_prefix: string,
    title: string = null
  ) => {
    let dialog: IDeleteConfirmationProps = cloneDeep(
      NewDeleteConfirmationDialog
    );
    dialog.cancel = onCancel;
    dialog.confirm = onConfirm;
    dialog.title = title;
    dialog.header = header;
    dialog.title_prefix = title_prefix;
    setDialoginfo(dialog);
    myModal.show();
  };

  const onClickSearch = (e) => {
    setSearchProduct(e.target.value);
  };

  const handleCheckboxClick = (product: IProducts) => {
    const updatedProductsList: ISalesOrder = { ...selectedProducts }; // Create a copy
    const productIndex = updatedProductsList?.soProductDetails?.findIndex(
      (p) => p?.productCode === product?.productCode
    );
    if (productIndex !== -1) {
      // Item already in the list
      updatedProductsList?.soProductDetails?.splice(productIndex, 1); // Remove from the list
    } else {
      // Item not in the list
      updatedProductsList?.soProductDetails?.push({
        ...product,
        selected: true,
      }); // Add to the list with isChecked flag
    }
    setSelectedProducts(updatedProductsList);
  };

  const calculateTotalTaxVal = () => {
    let _salesOrderProducts: ISODetails[] = [
      ...selectedProducts?.soProductDetails,
    ];
    let totalTax = 0;
    _salesOrderProducts.map((_prod: ISODetails, i) => {
      totalTax = _prod?.taxAmount ? totalTax + _prod?.taxAmount : totalTax;
      totalTax = parseFloat(totalTax.toFixed(2));
    });
    return totalTax;
  };

  const handleCreateSo = () => {
    let _newSalesOrder = { ...selectedProducts };
    _newSalesOrder.totalTaxAmount = calculateTotalTaxVal();
    _newSalesOrder.totalSaleOrderAmount = calculateTotalAmountVal();
    _newSalesOrder.soNum = nextSONumber ? nextSONumber : "";

    let obj = {
      customerId: _newSalesOrder?.customerId,
      status: _newSalesOrder?.status,
      customerRefNum: _newSalesOrder?.customerRefNum,
      reason: _newSalesOrder?.reason,
      createdDate: _newSalesOrder?.soCreatedDate,
      expDelDate: _newSalesOrder?.deliveryDate,
      taxAmount: _newSalesOrder?.totalTaxAmount,
      addressId: _newSalesOrder?.addressId,
      carrierChargeAmount: _newSalesOrder?.carrierChargeAmount,
    };
    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);

    if (!_.isEmpty(isErrors)) {
      toast.error("Please fill mandatory fields");
      return;
    }

    let soProductsList: ISODetails[] = _newSalesOrder?.soProductDetails;
    let newSoProductsList: ISODetails[] = [];
    soProductsList?.map((pr: ISODetails) => {
      newSoProductsList?.push({
        productId: pr?.productId,
        prodId: pr?.productId + "",
        prodCatId: pr?.prodCategory,
        quantity: pr?.quantity,
        unitPrice: pr?.unitPrice,
        vat: pr?.vat,
        productAmount: pr?.productAmount,
        totalAmount: pr?.totalAmount,
        taxAmount: pr?.taxAmount,
        PIPCode: pr?.PIPCode,
      });
    });
    _newSalesOrder.soProductDetails = newSoProductsList;
    if (_newSalesOrder?.soProductDetails?.length > 0) {
      if (
        _newSalesOrder?.totalTaxAmount > 0 &&
        _newSalesOrder?.customerId &&
        _newSalesOrder?.customerRefNum
      ) {
        createNewSalesOrder(_newSalesOrder);
      } else {
        toast.error("Please fill mandatory fields");
      }
    } else {
      toast.error("Please add products to sales order");
    }
  };

  const calculateTotalAmountVal = (): any => {
    let _salesOrderProducts: ISODetails[] = [
      ...selectedProducts?.soProductDetails,
    ];
    let totalAmt = selectedProducts?.carrierChargeAmount * 1;
    _salesOrderProducts?.map((_prod: ISODetails, i) => {
      totalAmt = _prod?.totalAmount ? totalAmt + _prod?.totalAmount : totalAmt;
    });
    return (Math.round(totalAmt * 100) / 100).toFixed(2);
  };

  const handleFormChange = (types: string[], values: any[]) => {
    let _newSalesOrder = { ...selectedProducts };
    types?.map((item, index) => {
      _newSalesOrder[item] = values[index];
    });
    setSelectedProducts(_newSalesOrder);
  };

  const handleProductVatPrChange = (e: any, product: any) => {
    let _newSalesOrder = { ...selectedProducts };
    _newSalesOrder?.soProductDetails?.map((_prod: ISODetails, i) => {
      if (_prod?.productId === product?.productId) {
        _prod.vat = e?.target?.value;
        _prod.vat = isNaN(_prod.vat) ? 0 : _prod.vat;
        _prod.taxAmount = (_prod?.productAmount / 100) * _prod?.vat;
        _prod.taxAmount = parseFloat(_prod.taxAmount.toFixed(2));
        _prod.totalAmount = _prod?.productAmount + _prod?.taxAmount;
        _prod.totalAmount = parseFloat(_prod.totalAmount.toFixed(2));
      }
    });
    setSelectedProducts(_newSalesOrder);
  };

  const handleProductQtyChange = async (e: any, product: any) => {
    let _newSalesOrder = { ...selectedProducts };
    _newSalesOrder?.soProductDetails.map(async (_prod: ISODetails, i) => {
      if (_prod?.productId === product?.productId) {
        _prod.quantity = parseInt(e.target.value);
        _prod.productAmount = _prod?.unitPrice * _prod.quantity;
        if (!isNaN(_prod?.vat)) {
          _prod.taxAmount = (_prod?.productAmount / 100) * _prod?.vat;
          _prod.taxAmount = parseFloat(_prod.taxAmount.toFixed(2));
          _prod.totalAmount = _prod?.productAmount + _prod?.taxAmount;
          _prod.totalAmount = parseFloat(_prod.totalAmount.toFixed(2));
        }
        await checkStockOnHand(_prod.productId, _prod.quantity).then(
          (status) => {
            _prod.status = status;
            setSelectedProducts(_newSalesOrder);
          }
        );
      }
    });
  };

  const handleProductPriceChange = (e: any, product: any) => {
    let _newSalesOrder = { ...selectedProducts };
    _newSalesOrder?.soProductDetails?.map((_prod: ISODetails, i) => {
      if (_prod?.productId === product?.productId) {
        _prod.unitPrice = parseFloat(e?.target?.value);
        _prod.productAmount = _prod?.quantity * e?.target?.value;
        if (!isNaN(_prod?.vat)) {
          _prod.taxAmount = (_prod?.productAmount / 100) * _prod?.vat;
          _prod.taxAmount = parseFloat(_prod.taxAmount.toFixed(2));
          _prod.totalAmount = _prod?.productAmount + _prod?.taxAmount;
          _prod.totalAmount = parseFloat(_prod.totalAmount.toFixed(2));
        }
      }
    });
    setSelectedProducts(_newSalesOrder);
  };

  const deleteProductInSo = (product: any) => {
    let _newSalesOrder = { ...selectedProducts };
    _.remove(_newSalesOrder?.soProductDetails, function (n) {
      return n?.productId === product?.productId;
    });
    setSelectedProducts(_newSalesOrder);
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderOut}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.sales_order}
                      title={LabelConstants.SalesOrder}
                    >
                      {LabelConstants.SalesOrder}
                    </NavLink>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Create} ${LabelConstants.SalesOrder}`}</li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary float-end"
            onClick={() => handleCreateSo()}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Products </h4>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-right"
                >
                  Add Product
                </button>
              </div>
              <div className="box-body">
                {/* products Table */}
                <div className="table-responsive">
                  <table className="table product-overview table-bordered text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Price/ unit</th>
                        <th className="text-align:center">VAT(%)</th>
                        <th className="text-align:center">Tax</th>
                        <th className="text-align:center">Amount</th>
                        <th className="text-align:center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProducts?.soProductDetails?.length > 0 ? (
                        selectedProducts.soProductDetails.map(
                          (product: ISODetails, i: number) => (
                            <tr key={i}>
                              <td className="text-start">
                                {getProductCatNameById(
                                  product?.prodCategory,
                                  productCateList
                                )}
                              </td>
                              <td className="text-start">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div style={{ width: "96%" }}>
                                    {product.productCode} -{" "}
                                    {product.productName}
                                  </div>
                                  <i
                                    style={{
                                      backgroundColor: getStockStatusColor(
                                        product?.status
                                      ),
                                      height: 15,
                                      width: 15,
                                      borderRadius: 15,
                                      alignSelf: "center",
                                      marginLeft: 5,
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="fw-900 w-150">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={product?.quantity}
                                  onChange={(e) =>
                                    handleProductQtyChange(e, product)
                                  }
                                />
                              </td>
                              <td className="fw-900 w-150">
                                <input
                                  type="number"
                                  className="form-control"
                                  min={0}
                                  value={product?.unitPrice}
                                  onChange={(e) =>
                                    handleProductPriceChange(e, product)
                                  }
                                />
                              </td>
                              <td className="w-90">
                                <input
                                  type="text"
                                  className="form-control"
                                  min={0}
                                  value={product?.vat}
                                  onChange={(e) =>
                                    handleProductVatPrChange(e, product)
                                  }
                                />
                              </td>
                              <td>
                                {product?.taxAmount
                                  ? product.taxAmount.toFixed(2)
                                  : 0}
                              </td>
                              <td>
                                {product.totalAmount ? product.totalAmount : 0}
                              </td>
                              <td align="center">
                                <div className="d-flex justify-content-center">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      showDialog(
                                        () => {},
                                        () => {
                                          deleteProductInSo(product);
                                        },
                                        undefined,
                                        undefined,
                                        product?.productName
                                      );
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash text-danger fs-3"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={8} align="center">
                            <br />
                            <p>No products added to sales order</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      clearSalerOrder();
                      navigate(RoutingConstants.sales_order);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    onClick={() => handleCreateSo()}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* sales order container*/}
          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">{`${LabelConstants.SalesOrder} ${LabelConstants.Details}`}</h4>
                <p className="p-0 m-0">
                  #{LabelConstants.SoNumber}:
                  {nextSONumber ? nextSONumber : "N/A"}
                </p>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.CustomerName}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    {/* <select
                      className="form-select"
                      onChange={(e) => {
                        handleFormChange(
                          ["customerId", "customerName"],
                          [
                            e.target.value,
                            customersList?.find(
                              (item) => item?.custId + "" === e.target.value
                            )?.custName,
                          ]
                        );
                      }}
                      value={selectedProducts.customerId}
                    >
                      <option>--</option>
                      {customersList?.map(
                        (customer: ICustomer, customerIndex: number) => {
                          return (
                            <option
                              key={customer?.custId}
                              value={customer?.custId}
                            >
                              {customer.custName}
                            </option>
                          );
                        }
                      )}
                    </select> */}

                    {customersList?.length > 0 && (
                      <Select
                        value={customersList.filter(
                          (option) =>
                            option.value ===
                            getNumberForString(selectedProducts.customerId)
                        )}
                        options={customersList}
                        isSearchable={true}
                        onChange={(e) => {
                          handleFormChange(
                            ["customerId", "customerName"],
                            [e.custId, e.custName]
                          );
                        }}
                      />
                    )}

                    {error?.customerId && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.customerId}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.CreatedDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(selectedProducts?.soCreatedDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleFormChange(["soCreatedDate"], [date]);
                      }}
                    />
                    {error?.createdDate && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.createdDate}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.ExpectedDeliveryDate}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(selectedProducts?.deliveryDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleFormChange(["deliveryDate"], [date]);
                      }}
                    />
                    {error?.expDelDate && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.expDelDate}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.DeliveryAddress}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        let customer: ICustomer = customersList?.find(
                          (item) =>
                            item?.custId ===
                            parseInt(selectedProducts?.customerId)
                        );
                        let ca = customer?.custAddresses?.find(
                          (item) =>
                            item?.custAddressId === parseInt(e.target.value)
                        );
                        handleFormChange(
                          ["addressId", "deliveryAddress"],
                          [
                            e.target.value,
                            `${ca?.address1},${ca?.address2},${ca?.city},${ca?.country},${ca?.postCode}`,
                          ]
                        );
                      }}
                      value={selectedProducts?.addressId}
                    >
                      <option>--</option>
                      {customersList?.map(
                        (customer: ICustomer, customerIndex: number) => {
                          if (
                            customer?.custId !==
                            getNumberForString(selectedProducts?.customerId)
                          ) {
                            return;
                          }
                          return customer?.custAddresses?.map(
                            (
                              address: ICustomerAddress,
                              addressIndex: number
                            ) => {
                              return (
                                <option
                                  key={address?.custAddressId}
                                  value={address?.custAddressId}
                                >
                                  {`${address?.address1},
                                  ${address?.address2},
                                  ${address?.city},
                                  ${address?.country},
                                  ${address?.postCode}`}
                                </option>
                              );
                            }
                          );
                        }
                      )}
                    </select>
                    {error?.addressId && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.addressId}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select"
                      value={selectedProducts?.status}
                      onChange={(e) => {
                        handleFormChange(["status"], [e.target.value]);
                      }}
                    >
                      <option>--</option>
                      {SalesOrderCreationStatuses.map(
                        (_status: string, _statusIdx: number) => {
                          return (
                            <option
                              key={`statusOption${_statusIdx}`}
                              value={_status}
                            >
                              {_status}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {error?.status && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.status}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.referenceNumber}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      className="form-control"
                      value={selectedProducts?.customerRefNum}
                      onChange={(e) =>
                        handleFormChange(["customerRefNum"], [e.target.value])
                      }
                    />
                    {error?.customerRefNum && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.customerRefNum}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.Description}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <textarea
                      value={selectedProducts?.reason}
                      onChange={(e) =>
                        handleFormChange(["reason"], [e.target.value])
                      }
                      rows={5}
                      className="form-control"
                      placeholder={LabelConstants.Description}
                    />
                    {error?.reason && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.reason}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.Carriage}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      className="form-control"
                      type="number"
                      value={selectedProducts?.carrierChargeAmount}
                      onChange={(e) =>
                        handleFormChange(
                          ["carrierChargeAmount"],
                          [e.target.value]
                        )
                      }
                    />
                    {error?.carrierChargeAmount && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.carrierChargeAmount}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className=" d-flex mt-20">
                  <div className="col-4">
                    <h5 className="fw-bold">Tax</h5>
                  </div>
                  <div className="col-8">
                    <p className="text-end fw-700 h6">
                      £
                      {selectedProducts?.soProductDetails.length > 0
                        ? calculateTotalTaxVal()
                        : 0}
                    </p>
                    {error?.totalTaxAmount && (
                      <p className="text-end">
                        <span style={{ color: "red" }}>
                          {error?.totalTaxAmount}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex mt-20">
                  <div className="col-4">
                    <h5 className="bt-1 fw-bold">Receivable Amount</h5>
                  </div>
                  <div className="col-8">
                    <p className="bt-1 text-end fw-900 fs-18">
                      £
                      {selectedProducts?.soProductDetails?.length > 0
                        ? calculateTotalAmountVal()
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products list modal */}
      <div className="modal modal-right fade overflow-auto" id="modal-right">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <input
                type="search"
                placeholder="Search Product"
                value={searchProduct}
                className="form-control"
                onChange={onClickSearch}
              />
              <br />
              <ul className="list-group">
                {filteredProducts?.length > 0 &&
                  filteredProducts?.map((product: IProducts) => {
                    if (product?.selected) return;
                    return (
                      <li
                        className={`list-group-item ${
                          selectedProducts?.soProductDetails?.some(
                            (p) => p.productId === product.productId
                          ) && "d-none"
                        }`}
                        key={product.productId}
                      >
                        <input
                          type="checkbox"
                          id={product?.productId?.toString()}
                          checked={selectedProducts?.soProductDetails?.some(
                            (p) => p?.productId === product?.productId
                          )} // Set checked based on presence in selectedProducts
                          onChange={() => handleCheckboxClick(product)} // Set checked based on presence in selectedProducts
                        />
                        <label htmlFor={product?.productId?.toString()}>
                          <b>{product?.productCode}</b> - {product?.productName}
                        </label>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-primary float-end"
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationComponent
        title={dialoginfo?.title}
        cancel={dialoginfo?.cancel}
        confirm={dialoginfo?.confirm}
        header={dialoginfo?.header}
        title_prefix={dialoginfo?.title_prefix}
      />
    </>
  );
};
export default SalesOrderFormcomponent;
