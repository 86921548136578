import {
  TransporterList,
  TransporterSave,
  TransporterStatusUpdate,
  TransporterUpdateById,
  TransportersAllList,
  searchTranportByCriteria,
} from "../../common/constants/apiConstants";
import {
  IListConfigTransport,
  IStatusUpdate,
  ITransporter,
} from "../../common/models";
import { AxiosService } from "../generics/axios.service";
import { ITransporterService } from "../interfaces/ITransporter";

export class TransporterService implements ITransporterService {
  private axiosService: AxiosService = new AxiosService();

  async getTransporter(): Promise<ITransporter[]> {
    try {
      const result: any = await this.axiosService.Get(TransporterList);
      const _transporterList: ITransporter[] = result.data ?? [];
      console.log("Response: transporter List - ", _transporterList);
      return _transporterList;
    } catch (error) {
      console.error("Error: transporter List - ", error);
      return [];
    }
  }

  async getTransporterAllList(): Promise<ITransporter[]> {
    try {
      const result: any = await this.axiosService.Get(TransportersAllList);
      const _transporterList: ITransporter[] = result.data?.content ?? [];
      console.log("Response: transporter List - ", _transporterList);
      return _transporterList;
    } catch (error) {
      console.error("Error: transporter List - ", error);
      return [];
    }
  }

  async searchTransportOrders(
    filter: IListConfigTransport
  ): Promise<ITransporter[]> {
    try {
      const result: any = await this.axiosService.Post(
        searchTranportByCriteria,
        filter
      );
      const _products: ITransporter[] = result?.data?.content ?? [];
      console.log("Response: searchTransportOrders List - ", _products);
      return _products;
    } catch (error) {
      console.error("Error: searchTransportOrders List - ", error);
      return [];
    }
  }

  async saveTransporter(transporter: ITransporter): Promise<boolean | number> {
    try {
      if (!transporter?.transId)
        return await this.axiosService.Post(TransporterSave, transporter);
      else
        await this.axiosService.Put(
          TransporterUpdateById(transporter?.transId),
          transporter
        );
      return true;
    } catch (error) {
      console.error("Error: Save Transporter - ", error);
      return error.response.data[0]?.errorMessage ?? false;
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Put(TransporterStatusUpdate, status);
      return true;
    } catch (error) {
      console.error("Error: Update Transporter Status - ", error);
      return false;
    }
  }
}
