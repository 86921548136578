export class GenericHelperService {
  public dateFormat = (
    dateString: any,
    options: any,
    locale: string = "en-US"
  ): string => {
    if (dateString) {
      let date = new Date(dateString);
      // let resultDateString =
      return date.toLocaleDateString(locale, options);
    }
    return "";
  };

  public sort = (
    a: any,
    b: any,
    prop: string = "",
    isDate: boolean = false
  ) => {
    if (prop !== "") {
      try {
        const _aVal = isDate ? new Date(a[prop]).toISOString() : a[prop];
        const _bVal = isDate ? new Date(b[prop]).toISOString() : b[prop];
        if (_aVal > _bVal) return 1;
        if (_bVal > _aVal) return -1;
        return 0;
      } catch (error) {
        return -1;
      }
    } else {
      if (a > b) return 1;
      if (b < a) return -1;
      return 0;
    }
  };

  public isDateValid = (d: any) => {
    let result = false;
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (!isNaN(d)) {
        result = true;
      }
    }
    return result;
  };

  public getTime = (date?: Date) => {
    return date != null ? date.getTime() : 0;
  };

  public sortByDate = (a: any, b: any, prop: string = "") => {
    if (prop !== "") {
      if (this.getTime(new Date(a[prop])) > this.getTime(new Date(b[prop])))
        return 1;
      if (this.getTime(new Date(b[prop])) > this.getTime(new Date(a[prop])))
        return -1;
      return 0;
    } else {
      if (a > b) return 1;
      if (b < a) return -1;
      return 0;
    }
  };

  public getStatusColor = (status: string) => {
    switch (status) {
      case "ACTIVE":
      case "PASS":
      case "SUBMITTED":
      case "DELIVERED":
        return "success";

      case "INACTIVE":
      case "OPEN":
      case "DRAFT":
        return "info";

      case "TERMINATED":
      case "FAIL":
      case "CANCEL":
      case "NOT_DELIVERED":
      case "CANCELLED":
        return "danger";

      case "CLOSED":
      case "DISPATCHED":
        return "primary";

      case "FULL_FILL":
      case "OFD":
        return "dark";

      case "PART_FILL":
      case "APPROVED":
      case "SUSPENDED":
      case "IN_TRANSIT":
        return "warning";

      default:
        return "success";
    }
  };
}
