import { OrdersApiBase, orderPickingProducts, pickingOrderList, pickingOrderListByStatus } from "../../common/constants/apiConstants";
import { PickingOrder } from "../../common/models/pickingorder";
import { AxiosService } from "../generics/axios.service";

export class PickingOrderService {
  private axiosService: AxiosService = new AxiosService();

  async getPickingOrderList(): Promise<PickingOrder[]> {
    try {
      const result: any = await this.axiosService.Get(pickingOrderList, OrdersApiBase);
      const _pickingOrders: PickingOrder[] = result.data.content ?? [];
      console.log("Response: Picking order List - ", _pickingOrders);
      return _pickingOrders;
    } catch (error) {
      console.error("Error: Picking order List - ", error);
      return [];
    }
  }

  async getPickingOrderListByStatus(): Promise<PickingOrder[]> {
    try {
      const result: any = await this.axiosService.Get(pickingOrderListByStatus, OrdersApiBase);
      const _pickingOrders: PickingOrder[] = result.data ?? [];
      console.log("Response: Picking order List - ", _pickingOrders);
      return _pickingOrders;
    } catch (error) {
      console.error("Error: Picking order List - ", error);
      return [];
    }
  }

  async getOrderPickingDetails(pickingID:string): Promise<any[]> {
    try {
      const result: any = await this.axiosService.Get(orderPickingProducts(pickingID), OrdersApiBase);
      const _pickingOrders: PickingOrder[] = result.data ?? [];
      console.log("Response: Picking order List - ", _pickingOrders);
      return _pickingOrders;
    } catch (error) {
      console.error("Error: Picking order List - ", error);
      return [];
    }
  }
}
