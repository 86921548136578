import {
  EmployeeRoles,
  OrdersApiBase,
  UpdateUserAccessPermissionApiByRole,
} from "../../common/constants/apiConstants";
import { IAccessControlAndPermissions } from "../../common/models/settings";
import { AxiosService } from "../generics/axios.service";

export class SettingsService {
  private axiosService: AxiosService = new AxiosService();

  async updateUserAccessPermissionByRole(
    data: IAccessControlAndPermissions
  ): Promise<boolean> {
    try {
      await this.axiosService.Post(UpdateUserAccessPermissionApiByRole, data);
      return true;
    } catch (error) {
      console.error(
        "Error: updateUserAccessPermissionByRole - ",
        error.response.data
      );
      return false;
    }
  }
}
