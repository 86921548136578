import {
  EmployeeSave,
  EmployeeList,
  EmployeeUpdateById,
  EmployeeStatusUpdate,
  EmployeeRoles,
  OrdersApiBase,
  FetchUserAccessPermissionApiByRole,
} from "../../common/constants/apiConstants";
import { IStatusUpdate } from "../../common/models";
import { IEmployee, IEmployeeRole } from "../../common/models/employee";
import { IAccessControlAndPermissions } from "../../common/models/settings";
import { AxiosService } from "../generics/axios.service";
import { IEmployeeService } from "../interfaces/IEmployee";

export class EmployeeService implements IEmployeeService {
  private axiosService: AxiosService = new AxiosService();

  async getEmployee(): Promise<IEmployee[]> {
    try {
      const result: any = await this.axiosService.Get(EmployeeList);
      const _employee: IEmployee[] = result.data ?? [];
      console.log("Response: Employee List - ", _employee);
      return _employee;
    } catch (error) {
      console.error("Error: Employee List - ", error);
      return [];
    }
  }

  async saveEmployee(employee: IEmployee): Promise<boolean> {
    try {
      if (!employee?.empId)
        return await this.axiosService.Post(EmployeeSave, employee);
      else
        await this.axiosService.Put(
          EmployeeUpdateById(employee?.empId),
          employee
        );
      return true;
    } catch (error) {
      console.error("Error: Save employee - ", error.response.data);
      return error.response.data[0]?.errorMessage ?? false;
    }
  }

  async updateStatus(status: IStatusUpdate): Promise<boolean> {
    try {
      await this.axiosService.Put(EmployeeStatusUpdate, status);
      return true;
    } catch (error) {
      console.error("Error: Update Employee Status - ", error);
      return false;
    }
  }

  async getEmployeeRoles(): Promise<IEmployeeRole[]> {
    try {
      const result = await this.axiosService.Post(EmployeeRoles, {
        status: "active",
      });
      console.log("Response: getEmployeeRoles - ", result?.data?.content);
      return result?.data?.content;
    } catch (error) {
      console.error("Error: getEmployeeRoles - ", error);
      return [];
    }
  }

  async getUserAccessPermissionsForRole(
    roleCode: string
  ): Promise<IAccessControlAndPermissions> {
    try {
      const result = await this.axiosService.Get(
        FetchUserAccessPermissionApiByRole(roleCode)
      );
      console.log("Response: getUserAccessPermissionsForRole - ", result?.data);
      return result?.data;
    } catch (error) {
      console.error("Error: getUserAccessPermissionsForRole - ", error);
      return null;
    }
  }
}
