import { useContext, useEffect, useState } from "react";
import { SupplierService } from "../../api/services/supplier.service";
import { AppContext } from "../contexts/app.context";
import {
  IStatusUpdate,
  ISupplier,
  IListConfig,
  IDocuments,
  ISupplierContext,
} from "../../common/models";
import {
  DocumentUploadKeys,
  EmptyListConfig,
  EmptyStatusUpdate,
  NewSupplier,
} from "../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { GenericHelperService } from "../../api/generics/helper.service";
import { DocumentService } from "../../api/services/document.service";
import { MasterService } from "../../api/services/master.service";
import { ILogsData, ILogsProps } from "../../common/models/logs";

const useSupplier = () => {
  const supplierService: SupplierService = new SupplierService();
  const documentService: DocumentService = new DocumentService();
  const masterService: MasterService = new MasterService();
  const helperService: GenericHelperService = new GenericHelperService();
  const { setLoader } = useContext(AppContext);
  const [masterSupplierList, setMasterSupplierList] = useState<ISupplier[]>([]);
  const [SupplierList, setSupplierList] = useState<ISupplier[]>([]);
  const [SupplierForm, setSupplierForm] = useState<ISupplier>(null);
  const [filters, setFilters] = useState<IListConfig>(EmptyListConfig);
  const [selectedList, setSelectedList] =
    useState<IStatusUpdate>(EmptyStatusUpdate);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]); // uploaded files from form
  const [supplierFiles, setSupplierFiles] = useState<IDocuments[]>([]);
  const [supplierLogs, setSupplierLogs] = useState<ILogsData[]>([]);

  const getSupplierList = async () => {
    const _Supplier = await supplierService.getSupplier();
    const _filters: IListConfig = cloneDeep(filters);
    _filters.currentPage = 1;
    _filters.searchQuery = "";
    _filters.totalRecords = _Supplier.length;
    _filters.totalPageCount = Math.ceil(
      _Supplier.length / _filters.currentPageSize
    );
    // _filters.sortItem = 'modifiedDate';
    // _filters.sortDirection = 'desc';
    // _filters.sortIsDate = true;
    setFilters(_filters);
    setMasterSupplierList(_Supplier ?? []);
  };

  const initData = () => {
    getSupplierList();
    setSupplierForm(null);
    setSelectedList(EmptyStatusUpdate);
    setUploadedFiles([]);
    setSupplierFiles([]);
  };

  useEffect(() => {
    setLoader(true);
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const indexOfLastItem = filters.currentPage * filters.currentPageSize;
    const indexOfFirstItem = indexOfLastItem - filters.currentPageSize;
    let _filteredList: ISupplier[] = cloneDeep(masterSupplierList);
    _filteredList = _filteredList.filter((_sup: ISupplier, _supIdx: number) => {
      return _sup.supName
        .toLowerCase()
        .includes(filters.searchQuery.toLowerCase());
    });
    _filteredList.sort((a, b) =>
      helperService.sort(
        filters.sortDirection === "asc" ? a : b,
        filters.sortDirection === "asc" ? b : a,
        filters.sortItem,
        filters.sortIsDate
      )
    );
    console.log("Filtered Sorted list - ", _filteredList);
    setSupplierList(
      _filteredList.slice(indexOfFirstItem, indexOfLastItem) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, masterSupplierList]);

  const viewSupplier = async (item: ISupplier) => {
    setSupplierForm(item);
    const _documents: IDocuments[] = await documentService.getFiles(
      `${DocumentUploadKeys.Supplier}_${item?.supId}`
    );
    setSupplierFiles(_documents);
  };
  const getLogsSupplier = async (id: number) => {
    const _logs: ILogsProps[] = await masterService.getLogs(`SUP${id}`);
    setSupplierLogs(_logs);
  };

  const clearViewSupplier = () => {
    setSupplierForm(null);
    setSupplierFiles([]);
  };

  const newSupplier = () => {
    setSupplierForm(cloneDeep(NewSupplier));
    setUploadedFiles([]);
    setSupplierFiles([]);
  };

  const saveSupplier = async () => {
    const _productSave = await supplierService.saveSupplier(SupplierForm);
    if (_productSave) {
      console.log("eldktnb", _productSave);

      if (typeof _productSave == "string") {
        toast.error(_productSave);
      } else {
        if (typeof _productSave == "boolean") {
          await uploadFiles(SupplierForm?.supId);
          await deleteFiles();
        } else {
          await uploadFiles(_productSave);
        }
        toast.success("Supplier saved successfully.");
        initData();
      }
    } else {
      toast.error("Error saving supplier.");
    }
  };

  const updateStatus = async () => {
    const _updateStatus: boolean = await supplierService.updateStatus(
      selectedList
    );
    if (_updateStatus) {
      toast.success("Status updated successfully.");
      initData();
    } else {
      toast.error("Error updating status.");
    }
  };

  const deleteSupplier = async () => {
    const _deleteSupplier: ISupplier = cloneDeep(SupplierForm);
    _deleteSupplier.deleted = true;
    const _supplierSave = await supplierService.saveSupplier(_deleteSupplier);
    if (_supplierSave) {
      toast.success("Supplier deleted successfully.");
      initData();
    } else {
      toast.error("Error deleting supplier.");
    }
  };

  const uploadFiles = async (itemId: any) => {
    if (uploadedFiles.length > 0) {
      const _userId: string = `${DocumentUploadKeys.Supplier}_${
        itemId?.data ? itemId?.data : itemId
      }`;
      await documentService.uploadFiles(uploadedFiles, _userId);
    }
    return true;
  };

  const deleteFiles = async () => {
    const _deleteFiles: IDocuments[] = supplierFiles.filter((_doc) =>
      _doc.deleted !== undefined ? _doc.deleted : false
    );
    for (let fileIdx = 0; fileIdx < _deleteFiles.length; fileIdx++) {
      const delFiles = _deleteFiles[fileIdx];
      await documentService.deleteFiles(delFiles.documentId);
    }
    return true;
  };

  return {
    SupplierList,
    selectedList,
    filters,
    SupplierForm,
    supplierFiles,
    supplierLogs,
    setFilters,
    setSupplierForm,
    saveSupplier,
    setSelectedList,
    uploadedFiles,
    setUploadedFiles,
    setSupplierFiles,
    deleteSupplier,
    updateStatus,
    viewSupplier,
    clearViewSupplier,
    newSupplier,
    getLogsSupplier,
  } as ISupplierContext;
};

export default useSupplier;
